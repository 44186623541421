import React, {FC, useRef} from "react";
import {RolesEnum} from "../../../enums/RolesEnum";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {Link, useNavigate} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {NavigationItem} from "./NavigationItems";
import useHandleOutsideClick from "../../../hooks/useHandleOutsideClick";

interface Props {
    handleColors: (id: number | null) => void;
    navigationItems: NavigationItem[];
}

const HeaderNavigation: FC<Props> = ({handleColors, navigationItems}) => {
    const navigationRef = useRef<HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();

    useHandleOutsideClick(navigationRef, () => {
        handleColors(null);
    });

    const handleLinkClick = (path: string) => {
        navigate(path);
    };

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="me-auto user-navbar" ref={navigationRef}>
                            {user!.role_id === RolesEnum.User && (
                                <span onClick={() => handleColors(null)}>
                                    <Link className={'user-navbar__link'} to={'/user/sell'}>Inventory</Link>
                                </span>
                            )}
                            {navigationItems.map((item, index) => (
                                <NavDropdown
                                    key={item.title + index}
                                    onClick={() => {
                                        handleColors(index)
                                    }}
                                    className={'user-navbar__link'}
                                    title={item.title}
                                    id="basic-nav-dropdown"
                                >
                                    {item.items.map((subItem, subIndex) => {
                                        if (subItem.condition && !subItem.condition()) {
                                            return null;
                                        }

                                        return (
                                            <NavDropdown.Item
                                                key={subItem.itemTitle + subIndex}
                                                onClick={() => handleLinkClick(subItem.path)}
                                            >
                                                {subItem.itemTitle}
                                            </NavDropdown.Item>
                                        );
                                    })}
                                </NavDropdown>
                            ))}
                            {user!.role_id === RolesEnum.User && (
                                <span onClick={() => handleColors(null)}>
                                    <Link className={'user-navbar__link'}
                                          to="/user/teammates">Teammates</Link>
                                </span>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default HeaderNavigation;
