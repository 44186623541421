import * as Yup from 'yup';
import { Inventory } from '../../../../models/Inventory';
import { SPLIT_TYPES_LABEL } from '../../../../constants/SplitTypesLabels';
import { SplitTypes } from '../../../../enums/Skybox/SplitTypes';

export class TicketData implements Partial<Inventory>{
    disclosures = [];
    shown_quantity = undefined;
    section = '';
    row = '';
    unit_amount = undefined;
    face_value = 0;
    public_notes = '';
    internal_notes = '';
    currency_symbol = 'USD';
    split_type = SplitTypes.NeverLeaveOne;
    seat_type = 'CONSECUTIVE';
    purchased_from = '';
    purchased_for = '';
}

export const TicketFormValidationSchema = Yup.object().shape({
    shown_quantity: Yup.string().min(1, 'Too Short').max(255, 'Too Long').nullable(),
    section: Yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    row: Yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    unit_amount: Yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    face_value: Yup.string().min(1, 'Too Short').max(9, 'Too Long').required('Required').when(["unit_amount"], (unit_cost, schema) => {
        return schema.notOneOf([unit_cost], "This field should not be equal 'Unit price' field");
    }),
    public_notes: Yup.string().min(1, 'Too Short').max(255, 'Too Long').nullable(),
    internal_notes: Yup.string().min(1, 'Too Short').max(255, 'Too Long').nullable(),
    currency_symbol: Yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    split_type: Yup.string().required('Required').oneOf(SPLIT_TYPES_LABEL.map(type => type.key)),
    seat_type: Yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    purchased_from: Yup.string().min(1, 'Too Short').max(255, 'Too Long').nullable(),
    purchased_for: Yup.string().min(1, 'Too Short').max(255, 'Too Long').nullable(),
    stock_type: Yup.string().required('Required'),
});
