import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {SbHold} from "../models/SbHold";
import {InitialValuesTypes} from "../@interfaces/HoldInitialValuesTypes";

const urlPrefix = 'holds'

export const removeHold = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, SbHold>(
        axios.delete(`${urlPrefix}/${id}`),
    );
};

export const editHold = (id: number, params: InitialValuesTypes) => {
    return requestWrapper<GlobalStatusCodesEnum, SbHold>(
        axios.put(`${urlPrefix}/${id}`, params)
    );
};
