import React, {FC} from "react";
import {Form, Formik} from "formik";
import {Inventory} from "../../../../../models/Inventory";
import './LinkInventoryForm.scss'
import {Link} from "react-router-dom";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {WantedEvent} from "../../../../../models/WantedEvent";
import AppButton from "../../../../../ui/Buttons/AppButton/AppButton";
import AppSelect from "../../../../../ui/Inputs/AppSelect/AppSelect";

interface Props {
    onSubmit: (values: { inventory_id: string | number }) => void;
    inventories: Inventory[];
    wantedEvent: WantedEvent
    onClose: () => void;
}

const LinkInventoryForm: FC<Props> = ({onSubmit, inventories, wantedEvent, onClose}) => {
    const CreateItemsList = (inventory: { inventory: Inventory }) => {
        return (
            <div className={'select-items'}>
                <div className={'select-items__container'}>
                    <p>{inventory.inventory?.sb_event?.name}, {inventory.inventory?.sb_event?.venue}, {inventory.inventory?.sb_event?.city}</p>
                </div>
                <div className={'select-items__container'}>
                    <p>{dateToLocaleString(inventory.inventory.sb_event.occurs_at)}</p>
                </div>
                <div className={'select-items__container'}>
                    <div className={'select-items__container-item'}>
                        <p>QUANTITY:</p>
                        <p>{inventory.inventory.quantity}</p>
                    </div>
                    <div className={'select-items__container-item'}>
                        <p>SECTION:</p>
                        <p>{inventory.inventory.section}</p>
                    </div>
                    <div className={'select-items__container-item'}>
                        <p>ROW:</p>
                        <p>{inventory.inventory.row}</p>
                    </div>
                </div>
            </div>
        );
    };

    const selectInventoryOptions = inventories.map((inventory) => (
        {
            value: inventory.inventory_id,
            name: <CreateItemsList inventory={inventory}/>
        }
    ));

    const formInitial = {inventory_id: ""};

    return (
        <>
            {inventories.length ?
                <Formik initialValues={formInitial}
                        onSubmit={(values) => onSubmit({inventory_id: values.inventory_id})}>
                    {({values, isSubmitting}) =>
                        <Form>
                            <div className={'form-inputs'}>
                                <div className={'app-select-container'}>
                                    <AppSelect
                                        customLiClass={'pb-3'}
                                        placeholder={'Select inventory'}
                                        items={selectInventoryOptions}
                                        name={'inventory_id'}
                                        value={values.inventory_id}
                                    />
                                </div>
                                <div className={'order-form-buttons'}>
                                    <div className={'buy-inventory__buttons-item'}>
                                        <AppButton disabled={!values.inventory_id || isSubmitting} text={'Accept order'}
                                                   type={'submit'} color={'red'}/>
                                    </div>
                                    <div onClick={() => onClose()} className={'buy-inventory__buttons-item'}>
                                        <AppButton text={'Cancel'} type={'button'} color={'gray'}/>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }
                </Formik>
                :
                <div className={'link-container'}>
                    <p>It seems you do not have the appropriate inventory for
                        the {wantedEvent?.sb_event?.name} at {wantedEvent?.sb_event?.venue},
                        on {dateToLocaleString(wantedEvent.sb_event?.occurs_at)}. Please create the required inventory
                        to proceed
                        with
                        the order.</p>
                    <div className={'link-container__buttons'}>
                        <div className={'link-container__buttons-item'}>
                            <Link to={`/user/sell/sell-ticket?eventId=${wantedEvent!.sb_event!.event_id}`}>
                                <AppButton text={'Create inventory'} color={'red'}/>
                            </Link>
                        </div>
                        <div onClick={() => onClose()} className={'link-container__buttons-item'}>
                            <AppButton text={'Cancel'} color={'gray'}/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LinkInventoryForm;
