import React, {useState, useEffect, useRef} from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref: React.Ref<any> = useRef(null);

    const handleClickOutside = (event: any) => {
        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            (ref.current as HTMLElement).previousSibling?.firstChild !== event.target
        ) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return {ref, isComponentVisible, setIsComponentVisible};
}
