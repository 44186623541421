import * as Yup from 'yup';
import {PhoneNumberInfo} from "../../../@interfaces/PhoneNumberInfo";

export class updateUserData {
    email: string | undefined = '';
    phone_number: PhoneNumberInfo | undefined;
    domain: string | null = '';
}

export const SettingValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    phone_number: Yup.mixed().required('Required'),
    domain: Yup.string().min(1).max(63).nullable(true)
});

export const BalanceValidationSchema = Yup.object().shape({
    amount: Yup.number().required().typeError('Must be a number!').min(0.5)
})

export const PasswordValidationSchema = Yup.object().shape({
    password_old: Yup.string().required('Must enter old password'),
    password_first: Yup.string().min(6).max(64).required('Must enter new password'),
    password_second: Yup.string().oneOf([Yup.ref('password_first'), null], 'Passwords must match').required('Must confirm new password')
})

export const SocialValidationSchema = Yup.object().shape({
    tg_name: Yup.string(),
})

export const TicomboValidationSchema = Yup.object().shape({
    api_key: Yup.string().max(128).required('Required'),
});
