import {Container} from "react-bootstrap";
import CategoriesTable from "./components/CategoriesTable/CategoriesTable";
import {get} from "../../../@api/Categories";
import {useEffect, useState} from "react";
import {P1Category} from "../../../models/Category";

const P1CategoriesPage = () => {
    const [categories, setCategories] = useState<P1Category[]>([]);

    useEffect(() => {
        get().then((res) => setCategories(res.data));
    }, []);

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>P1 Categories</h1>
            <div className="events-page">
                <CategoriesTable categories={categories}/>
            </div>
        </Container>
    );
}

export default P1CategoriesPage;
