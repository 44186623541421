import {BaseSyntheticEvent, useEffect, useState} from 'react';
import usePagination from "../helpers/UsePagination";
import {SbEvent} from "../models/Event";
import {sbEventsPaginateRequest} from "../@api/Event";
import {FormikValues} from "formik";
import {SbEventsFilters} from "../@interfaces/SbEventsFilters";
import {uniqBy} from "lodash";
import {PAGINATION_PER_PAGE_OPTION_DEFAULT} from "../constants/Constants";

const preLoadChunks = 2;
const chunkSize = PAGINATION_PER_PAGE_OPTION_DEFAULT;

function useHandleSbEventsPaginationScroll(ref: any, filterInitial: SbEventsFilters) {
    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);
    const [events, setEvents] = useState<SbEvent[] | undefined>(undefined);
    const [preSavedEvents, setPreSavedEvents] = useState<SbEvent[]>([]);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { loadChunk } = pagination;

    const reset = () => {
        setEvents(undefined);
        setPreSavedEvents([]);
        setSelectedPage(1);
        setIsLoading(true);
    }

    const loadNextChunk = (filters: SbEventsFilters, itemsPerPage = chunkSize, page = selectedPage) => {
        loadChunk(page, itemsPerPage, filters).then(nextEvents => {
            const newPreSavedEvents = page !== 1 ? uniqBy([...preSavedEvents, ...nextEvents], (e) => e.id) : nextEvents;
            setPreSavedEvents(newPreSavedEvents);
            setSelectedPage(page + 1);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        const itemsPerPage = chunkSize * (selectedPage === 1 ? preLoadChunks : 1);
        loadNextChunk(filterInitial, itemsPerPage);
        setSelectedPage(preLoadChunks + 1);
    }, []);

    useEffect(() => {
        if ((!events?.length || events.length === preSavedEvents.length) && preSavedEvents?.length) {
            if (preSavedEvents.length > chunkSize) {
                setEvents(preSavedEvents.slice(0, preSavedEvents.length - chunkSize));
            } else {
                setEvents(preSavedEvents);
            }
        }
    }, [preSavedEvents]);

    async function handleChange(e: BaseSyntheticEvent) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (bottom && (e.target.clientHeight !== 0)) {
            setEvents(preSavedEvents);
            loadNextChunk(ref?.current!['values']);
        }
    }

    async function resetEvents() {
        reset();
        loadNextChunk(filterInitial, chunkSize * 2, 1)
        ref?.current.setValues(filterInitial);
    }

    async function filterEvents(eventFilters: FormikValues) {
        reset();
        loadNextChunk(eventFilters, chunkSize * 2, 1)
    }

    return {events, handleChange, resetEvents, filterEvents, isLoading};
}

export default useHandleSbEventsPaginationScroll;
