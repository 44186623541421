import {FieldHelperProps, FieldInputProps, useField} from 'formik';
import React, {useEffect, useId, useState} from 'react';
import {FieldMetaProps} from 'formik/dist/types';
import './DefaultFormikInput.scss';
import cutFields from '../../../helpers/CutFields';
import InputTooltipError from '../../Errors/InputTooltipError/InputTooltipError';
import {FieldHookConfig} from 'formik/dist/Field';

import showIcon from '../../../assets/images/other/show.svg';
import hideIcon from '../../../assets/images/other/hide.svg';


export type DefaultFormikFieldRenderParams = {
    field: FieldInputProps<any>,
    inputAttributes: DefaultFormikFieldInputAttributes,
    meta: FieldMetaProps<any>,
    helpers: FieldHelperProps<any>,
    isErrorExist: () => boolean,
}

type DefaultFormikFieldInputAttributes = {
    type?: string,
    class?: string,
    autocomplete?: string,
}

type DefaultFormikFieldParams = {
    render?: (p: DefaultFormikFieldRenderParams) => JSX.Element,
    isSubmitted?: boolean,
    needOverRideDefaultClass?: boolean,
    showErrorOnFocus?: boolean,
    noLabel?: boolean;
    endIcon?: JSX.Element | null
} & DefaultFormikFieldInputAttributes & Omit<FieldHookConfig<any>, 'render'>

const DefaultFormikInput = ({isSubmitted, noLabel, showErrorOnFocus, endIcon, ...attributes}: DefaultFormikFieldParams) => {
    showErrorOnFocus = typeof showErrorOnFocus === 'boolean' ? showErrorOnFocus : true;

    const fieldId = useId();
    const [normalizedAttributes, specialAttributes] = cutFields(
      attributes,
      ['prefix', 'render', 'class', 'type', 'needOverRideDefaultClass', 'autocomplete'],
    );
    const [field, meta, helpers] = useField(normalizedAttributes as any);
    const [isHover, setIsHover] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const placeholder = normalizedAttributes.placeholder;

    const isErrorExist = (errorMeta: FieldMetaProps<any>): boolean => !!(errorMeta.touched && errorMeta.error);
    const showError = () => ((isFocus && showErrorOnFocus) || (isHover && !isFocus)) && isErrorExist(meta);

    const inputClasses = [
        'form-control',
        specialAttributes.class ?? '',
        isErrorExist(meta) ? 'is-invalid' : '',
        !noLabel && 'pt-1'
    ].filter(a => !!a).join(' ');

    if (!!specialAttributes.prefix) {
        normalizedAttributes.className = `${normalizedAttributes.className ?? ''} ${inputClasses} with-prefix`.trim();
    }

    useEffect(() => {
        const input = document.getElementById(fieldId)?.querySelector('input');

        if (!input) return;

        const stopEventListenerController = new AbortController();

        input.addEventListener('mouseenter', () => setIsHover(true), {signal: stopEventListenerController.signal});
        input.addEventListener('mouseleave', () => setIsHover(false), {signal: stopEventListenerController.signal});
        input.addEventListener('focus', () => setIsFocus(true), {signal: stopEventListenerController.signal});
        input.addEventListener('blur', () => setIsFocus(false), {signal: stopEventListenerController.signal});

        return () => {
            stopEventListenerController.abort();
        };
    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const autocomplete = specialAttributes?.autocomplete ?? 'on';
    const renderParams: DefaultFormikFieldRenderParams = {
        field,
        meta,
        helpers,
        inputAttributes: {class: inputClasses, autocomplete},
        isErrorExist: () => isErrorExist(meta),
    };

    const renderedInput = (
        <>
          {specialAttributes.prefix ? <div className={'addon prefix'}><div>{specialAttributes.prefix}</div></div> : null}
          {
            specialAttributes.render
              ? specialAttributes.render(renderParams)
              : (
                <>
                  <input type={
                    specialAttributes.type
                      ? (
                        (specialAttributes.type === 'password' && showPassword)
                          ? 'text'
                          : specialAttributes.type
                      )
                      : 'text'
                  }
                         className={inputClasses}
                         autoComplete={autocomplete}
                         {...field}
                         {...normalizedAttributes as any}
                  />

                </>
              )
          }
        </>
    );

    return (
        <div className={`default-formik-input ${!noLabel && 'my-1'}`} id={fieldId}>
            {!noLabel && !!field.value && !!placeholder && <div className="input-label">{placeholder}</div>}
            {specialAttributes.prefix ? (
                <div className={specialAttributes.prefix ? 'd-flex flex-nowrap' : ''}>
                    {renderedInput}
                </div>
            ) : renderedInput}

            {specialAttributes.type === 'password'
                ?
                <div className={'addon end-icon'} onClick={toggleShowPassword}>
                    {showPassword
                        ? <img src={hideIcon} alt=""/>
                        : <img src={showIcon} alt=""/>
                    }
                </div>
                : endIcon ? (
                    <div className={'addon end-icon'}>{endIcon}</div>
                ) : null
            }

            <InputTooltipError message={meta.error || ''}
                               show={showError}
                               attributes={{
                                   class: `default-formik-input__error`,
                               }}
            />
        </div>
    );
};

export default DefaultFormikInput;
