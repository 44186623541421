import React, {FC, useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {getUserRequest} from "../../../@api/User";
import {useParams} from "react-router-dom";
import VendorIdForm from "../../../components/UserPage/VendorIdForm";
import EmailForm from "../../../components/UserPage/EmailForm"
import PasswordForm from "../../../components/UserPage/PasswordForm"
import {User} from "../../../models/User";
import {Tag, TagPicker} from "rsuite";
import {getPools, setUserPools} from "../../../@api/Pools";
import {Pool} from "../../../models/Pool";
import Integration from "../../../components/UserPage/Integration";
import AppCheckbox from "../../../ui/Inputs/AppCheckbox/AppCheckbox";

const UserEditPage: FC = () => {
    const {userId} = useParams();

    const [pools, setPools] = useState<any[]>([]);
    const [poolsData, setPoolsData] = useState<any[]>([]);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [selectedPools, setSelectedPools] = useState<any[]>([]);
    const [paidAccess, setPaidAccess] = useState<boolean>(false);

    useEffect(() => {
        fetchUserData()
        fetchPools()
    }, [])

    useEffect(() => {
        if (!pools.length) return
        const data = pools.map(
            item => ({
                label: item.name,
                value: item.name,
            })
        );
        setPoolsData(data);
    }, [pools])

    const fetchUserData = () => {
        getUserRequest(userId)
            .then(HttpSuccessResponse => {
                return HttpSuccessResponse.data
            })
            .then(data => {
                setUser(data)
                setSelectedPools(data.pools.map((pool: Pool) => pool.name));
                setPaidAccess(data.paid_access);
            });
    };

    const fetchPools = async () => {
        const res = await getPools();
        //@ts-ignore
        setPools(res.data);
    };

    const handleUpdate = async () => {
        await setUserPools(selectedPools, user!.id, paidAccess);
        alert('User pools has been updated!');
    };

    const getAllPossiblePools = () => {
        return poolsData;
    };

    const handlePoolChange = (values: string[]) => {
        setSelectedPools(values);
    };

    if (!user) return null;

    return (
        <div className={'container settings'}>
            <h2>Update user data</h2>
            <Tabs
                defaultActiveKey={'vendor_id'}
            >
                <Tab title={'Vendor Id'} eventKey={'vendor_id'}>
                    <VendorIdForm user={user}/>
                </Tab>
                <Tab title={'Email'} eventKey={'email'}>
                    <EmailForm user={user}/>
                </Tab>
                <Tab title={'Password'} eventKey={'password'}>
                    <PasswordForm user={user}/>
                </Tab>
                <Tab title={'Pools'} eventKey={'pools'}>
                    <div style={{maxWidth: '340px'}}>
                        <TagPicker
                            creatable={true}
                            onChange={handlePoolChange}
                            data={getAllPossiblePools()}
                            placeholder="Select pool"
                            block
                            value={selectedPools}
                            renderMenuItem={(label, item) => {
                                return (<>{label}</>);
                            }}
                            renderValue={(values, items, tags) => {
                                return values.map((tag: any,) => (
                                    <Tag key={tag}>{tag}</Tag>
                                ));
                            }}
                        />
                        <AppCheckbox
                            label="Paid access to Tickets Exchange"
                            id="paid_access"
                            value={paidAccess}
                            dispatchValue={(checked) => setPaidAccess(checked)}
                        />
                        <div>
                            <button onClick={handleUpdate} className="auth-wrapper__submit-btn" type="submit">Update
                            </button>
                        </div>
                    </div>
                </Tab>
                <Tab title={'Integration'} eventKey={'integration'}>
                    <Integration user={user}/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default UserEditPage;
