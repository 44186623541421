import axios from 'axios';
import {LocalStorageKeys} from './enums/LocalStorageKeys';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const authToken = localStorage.getItem(LocalStorageKeys.AuthToken);

  if (authToken) {
    // @ts-ignore
    config.headers.Authorization = 'Bearer ' + authToken
  }
  return config;
});
