import * as Yup from 'yup';
import {PhoneNumberInfo} from '../../../../@interfaces/PhoneNumberInfo';

export class PhoneNumberData {
  phoneNumber: PhoneNumberInfo | undefined;
}

export const PhoneNumberValidationSchema = Yup.object().shape({
  phoneNumber: Yup.mixed().required('Required'),
});
