import {User} from '../models/User';
import {HttpSuccessResponse} from '../@api/Responses/HttpSuccessResponse';
import {LocalStorageKeys} from '../enums/LocalStorageKeys';
import {checkUserRequest} from '../@api/Auth';
import {HiddenUser} from "../models/HiddenUser";
import {UserSettings} from "../models/UserSettings";
import {Pool} from "../models/Pool";

const checkUserLogin = async (
    setIsChecking: (v: boolean) => any,
    setUserAsLogged: (v1: User, v2: string, v3: Pool[], v4: HiddenUser[], v5: UserSettings) => any,
    setUserAsNotLogged: () => any,
): Promise<User | null> => {
    try {
        const token = localStorage.getItem(LocalStorageKeys.AuthToken);

        if (!token) {
            setUserAsNotLogged();
            setIsChecking(false);
            return null;
        }

        const res = await checkUserRequest(token);

        res instanceof HttpSuccessResponse
            ? setUserAsLogged(res.data.user, token, res.data.user.pools, res.data.user.hidden_users, res.data.user.settings)
            : setUserAsNotLogged();

        setIsChecking(false);
        return res instanceof HttpSuccessResponse ? res.data.user : null;
    } catch (e) {
        setIsChecking(false);
        setUserAsNotLogged();
    }

    return null;
};

export default checkUserLogin;
