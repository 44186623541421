import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {HiddenUser} from "../models/HiddenUser";
import {Pool} from "../models/Pool";

const urlPrefix = 'pools';

export const setUserPools = (names: string[], user_id: number, paid_access: boolean) => {
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.post(`${urlPrefix}`, {names, user_id, paid_access})
    );
};

export const getPools = () => {
    return RequestWrapper<GlobalStatusCodesEnum, Pool[]>(
        axios.get(`${urlPrefix}`)
    );
};

export const getUserPools = () => {
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}/my-pools`)
    );
};

export const getPoolUsers = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}/${id}`)
    )
};

export const getHiddenUsers = () => {
    return RequestWrapper<GlobalStatusCodesEnum, HiddenUser[]>(
        axios.get(`${urlPrefix}/hidden-users`)
    );
}

export const handleHiddenUsers = (id: number, poolId: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, HiddenUser>(
        axios.post(`${urlPrefix}/hidden-users/${id}`, {poolId})
    )
};

export const deletePool = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, { pool_users: Pool[] }>(
        axios.delete(`${urlPrefix}/${id}`)
    )
};
