import {FC} from "react";
import AppButton from "../../Buttons/AppButton/AppButton";
import './DeleteConfirmationModal.scss';
import {Customer} from "../../../models/Customer";
import {UserVendor} from "../../../models/UserVendor";

interface Props {
    modalTitle: string,
    closeModalFunc: () => void;
    confirmDeleteFunc: any;
    customer?: Customer;
    vendor?: UserVendor | null;
    disabled?: boolean
}

const DeleteConfirmationModal: FC<Props> =
    ({
         modalTitle,
         closeModalFunc,
         confirmDeleteFunc,
         customer,
         vendor,
         disabled
     }) => {
        return (
            <>
                <div className={'delete-confirmation-modal'}>
                    <div className={'delete-confirmation-modal__text'}>
                        <p>Are you sure you want </p>
                        <p>to delete this {modalTitle}?</p>
                    </div>
                    <div className={'delete-confirmation-modal__buttons'}>
                        <div onClick={() => closeModalFunc()}>
                            <AppButton text={'No'} color={'gray'}/>
                        </div>
                        <div onClick={() => confirmDeleteFunc(customer?.id || vendor?.id)}>
                            <AppButton text={'Yes'} color={'red'} disabled={disabled}/>
                        </div>
                    </div>

                </div>
            </>
        )
    }

export default DeleteConfirmationModal;
