import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {LTGVenue} from "../models/Venue";
import {FormikValues} from "formik";
import {Performer} from "../models/Performer";

export const ltgVenuesRequest = (params?: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, LTGVenue[]>(
        axios.get('/integrations/ltg/venues', {params})
    );
};

export const ltgVenueById = (id: string) => {
    return requestWrapper<GlobalStatusCodesEnum, LTGVenue>(
        axios.get('/integrations/ltg/venues/' + id)
    );
};

export const venuesNotPaginated = (venue: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Performer[]>(
        axios.get(`sb_events/notPaginated`, {
            params: {
                venue
            }
        })
    );
};
