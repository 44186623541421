import React, {FC, useEffect, useState} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import './LandingPage.scss';
import topPointsPng from '../../assets/images/landing/top-points.png';
import subtractPng from '../../assets/images/landing/substract.png';
import pointsForImage from '../../assets/images/landing/points-for-image.png';
import vector from '../../assets/images/landing/vector.png';
import pointsAfterImageRight from '../../assets/images/landing/points-after-image-right.png';
import pointsAfterImageLeft from '../../assets/images/landing/points-after-image-left.png';
import brokerLogo from '../../assets/images/landing/broker-logo.png';
import partnersLogo from '../../assets/images/landing/partners-logo.png';
import affiliatesLogo from '../../assets/images/landing/affiliates-logo.png';
import handshake from '../../assets/images/landing/handshake.png';
import twitterIcon from '../../assets/images/landing/twitter-icon.png';
import mediumIcon from '../../assets/images/landing/medium-icon.png';
import telegramIcon from '../../assets/images/landing/telegram-icon.png';
import redditIcon from '../../assets/images/landing/reddit-icon.png';
import Button from 'react-bootstrap/Button';
import LandingHeader from '../../layouts/Headers/LandingHeader/LandingHeader';
import {NavLink} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../store/Store";
import {RolesEnum} from "../../enums/RolesEnum";

const LandingPage: FC = () => {

    const loggedUser = useSelector((state: RootState) => state.auth.user);

    const [buttonLink, setButtonLink] = useState('/registration');

    useEffect(() => {
        if (loggedUser) {
            setButtonLink('/user/sell')

            if (loggedUser.role_id === RolesEnum.Admin || loggedUser.role_id === RolesEnum.SuperAdmin) {
                setButtonLink('/admin/users')
            }
        }

    }, [loggedUser])

    return (
        <>
            <LandingHeader/>
            <div className="landing">

                <div className="landing__welcome-block">
                    <div className="landing-welcome-block__block-top-points">
                        <img src={topPointsPng} alt="points" draggable="false"/>
                    </div>

                    <div className="landing-welcome-block__bottom-points-for-image">
                        <img src={pointsForImage} alt="points" draggable="false"/>
                    </div>

                    <div className="landing-welcome-block__main-img">
                        <img src={subtractPng} alt="main-img" draggable="false"/>
                    </div>

                    <div className="landing-welcome-block__main-img-line">
                        <img src={vector} alt="vector" draggable="false"/>
                    </div>

                    <div className="landing-welcome-block__info">
                        <div className="landing-welcome-block__info-title">
                            <p>Welcome to the Ticket Industry’s Premier B2B Exchange</p>
                        </div>
                        <div className="landing-welcome-block__info-sub-title">
                            <p>
                                The ticket API and marketplace that connects sellers to fans, buyers to sellers, and
                                everyone in between.
                            </p>
                        </div>
                        <div className={'landing__btn-container'}>
                            <NavLink to={buttonLink}>
                                <Button className="landing-welcome-block__join-exchange-btn mb-4">
                                    Enter portal
                                </Button>
                            </NavLink>
                        </div>
                    </div>

                    <Row className="landing-welcome-block__icons">
                        <Col><Image src={twitterIcon} className="landing-welcome-block__icons-item col-12"/></Col>
                        <Col><Image src={mediumIcon} className="landing-welcome-block__icons-item col-12"/></Col>
                        <Col><Image src={telegramIcon} className="landing-welcome-block__icons-item col-12"/></Col>
                        <Col><Image src={redditIcon} className="landing-welcome-block__icons-item col-12"/></Col>
                    </Row>
                </div>
                <div className="landing-welcome-block__handshake-pic">
                    <img src={handshake}/>
                </div>
                <div className="landing__promo-block">
                    <div className="landing-promo-block__points-top-right">
                        <img src={pointsAfterImageRight} alt="points" draggable="false"/>
                    </div>
                    <div className="landing-promo-block__points-bottom-left">
                        <img src={pointsAfterImageLeft} alt="points" draggable="false"/>
                    </div>
                    <div className="landing-promo-block__promo">
                        <div className="landing-promo-block__promo-title">
                            <p>What We Can Do For You</p>
                        </div>
                        <div className="landing-promo-block__promo-sub-title">
                            <p>If you have tickets, we’ll get ‘em sold. If you want tickets, we’ve got you covered. And
                                if you’re just
                                looking to tap into the ticketing industry, you sure are in the right place.</p>
                        </div>
                    </div>
                </div>

                <div className="landing__cards-block">
                    <Row className="landing-cards-block__cards-row">
                        <Col className={'landing-cards-block__card-item'}>
                            <div className="landing-cards-block__card-item-logo text-center">
                                <img src={brokerLogo} alt="broker-logo" draggable="false"/>
                            </div>
                            <div className="landing-cards-block__card-item-title text-center">
                                <p>Brokers</p>
                            </div>
                            <div className="landing-cards-block__card-item-text text-center">
                                <p>
                                    Ticket Evolution was founded by brokers, for brokers. We’ve expanded beyond our
                                    roots, but we’ll always be broker-friendly first. Tap into our ticket API to deal
                                    wholesale on our exchange, and sell your inventory through us to get paid fast.
                                </p>
                            </div>
                            <div className="text-center">
                                <NavLink to={buttonLink}>
                                    <Button className="landing-cards-block__card-item-btn">
                                        Enter portal
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col className={'landing-cards-block__card-item'}>
                            <div className="landing-cards-block__card-item-logo text-center">
                                <img src={partnersLogo} alt="broker-logo" draggable="false"/>
                            </div>
                            <div className="landing-cards-block__card-item-title text-center">
                                <p>Partners</p>
                            </div>
                            <div className="landing-cards-block__card-item-text text-center">
                                <p>
                                    Increase the value of your brand for customers and investors. Integrate our ticket
                                    API to feature a live ticket feed into your site. Then use our self-service portal
                                    to buy tickets off of our exchange, and best of all, never deal with a supplier
                                    again.
                                </p>
                            </div>
                            <div className="text-center">
                                <NavLink to={buttonLink}>
                                    <Button className="landing-cards-block__card-item-btn">
                                        Access the exchange
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col className={'landing-cards-block__card-item'}>
                            <div className="landing-cards-block__card-item-logo text-center">
                                <img src={affiliatesLogo} alt="brokerLogo" draggable="false"/>
                            </div>
                            <div className="landing-cards-block__card-item-title text-center">
                                <p>Affiliates</p>
                            </div>
                            <div className="landing-cards-block__card-item-text text-center">
                                <p>
                                    If you’re looking to get a piece of the ticket industry, start here. We provide
                                    total access to our ticket inventory, a fully customizable ticket API, full service
                                    customer support, real-time payment, and powerful distribution. Basically, we’ll do
                                    the work, and you’ll get the credit.
                                </p>
                            </div>
                            <div className="text-center">
                                <NavLink to={buttonLink}>
                                    <Button className="landing-cards-block__card-item-btn">
                                        Become an affiliate
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default LandingPage;
