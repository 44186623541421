import React, {useState} from 'react';
import './RegistrationPage.scss';
import {Form, Formik} from 'formik';
import DefaultFormikInput from '../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import renderPhoneInput from '../../../adapters/Formik/DefaultFormikPhoneInputAdapter';
import './Data';
import {RegistrationData, RegistrationFormValidationSchema} from './Data';
import {registrationRequest} from '../../../@api/Auth';
import {HttpSuccessResponse} from '../../../@api/Responses/HttpSuccessResponse';
import {HttpFailureResponse} from '../../../@api/Responses/HttpFailureResponse';
import {FormikErrors} from 'formik/dist/types';
import {GlobalStatusCodesEnum} from '../../../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {RegistrationSuccessResponse} from '../../../@interfaces/Response/Auth';
import {useDispatch} from 'react-redux';
import {setOtpToken} from '../../../store/Reducers/Auth';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import {useNavigate} from 'react-router-dom';
import {AuthResponseStatusesEnum} from '../../../enums/ResponseStatuses/AuthResponseStatusesEnum';

const RegistrationPage = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const registrationFormData = new RegistrationData();

  const redirectToLogin = () => {
    navigateTo('/login');
  };

  const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>) => {
    dispatch(setOtpToken({otpToken: res.data.otp_token}));
    navigateTo('/login');
  };

  const failureResponse = (res: HttpFailureResponse, setErrors: (errors: FormikErrors<any>) => void) => {
    setErrors(res.validation ?? {});
    alert(res.message);
  };

  return (
    <AuthPageWrapper>
      <div className={'registration-form'}>
        <div>
          <p className="registration-form__title">Registration Request</p>
          <p className="registration-form__sub-title">Quick and easy</p>
        </div>
        <Formik
          initialValues={registrationFormData}
          validationSchema={RegistrationFormValidationSchema}
          onSubmit={async (values, {setErrors}) => {
            setIsSubmitted(true);
            const res = await registrationRequest(values);

            return res instanceof HttpSuccessResponse ? successResponse(res) : failureResponse(res, setErrors);
          }}
        >
          {({isSubmitting}) =>
            <Form className="registration-form__form">
              <DefaultFormikInput name={'email'}
                                  placeholder={'Email'}
                                  autocomplete={'off'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'phoneNumber'}
                                  placeholder={'Phone Number'}
                                  render={renderPhoneInput}
                                  isSubmitted={isSubmitted}
                                  autocomplete={'off'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'name'}
                                  placeholder={'Full Name'}
                                  autocomplete={'off'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'companyName'}
                                  placeholder={'Company Name'}
                                  autocomplete={'off'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'companyAddress'}
                                  placeholder={'Company Address'}
                                  autocomplete={'off'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput type={'password'}
                                  name={'password'}
                                  placeholder={'Password'}
                                  class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'passwordConfirm'}
                                  type={'password'}
                                  placeholder={'Password Confirm'}
                                  class={'auth-wrapper__input registration-form__last-input'}
              ></DefaultFormikInput>

              <button className="auth-wrapper__submit-btn" type="submit" disabled={isSubmitting}>Submit</button>
            </Form>
          }
        </Formik>

        <div className="registration-form__row-under-form">
          <div>
          <span className="clickable-word" onClick={() => redirectToLogin()}>
            Already have an account?
          </span>
          </div>
        </div>
      </div>
    </AuthPageWrapper>
  );
};

export default RegistrationPage;
