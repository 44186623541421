import React, {FC} from 'react';
import PageLoading from '../components/PageLoading/PageLoading';

type Props = {
  isLoading: boolean,
  children: JSX.Element,
};

const LoadingRoute: FC<Props> = ({isLoading, children}) => {
  return isLoading
    ? (<PageLoading/>)
    : children;
};

export default LoadingRoute;
