import {PaginationParams} from '../@interfaces/PaginationParams';

export const paginationParamsForAxios = (params: PaginationParams<any>) => {
  const data: Record<string, any> = {
    page: params.page,
    per_page: params.perPage,
  };

  if (params.orderBy?.column) {
    data.order_by = params.orderBy.column;
  }

  if (params.orderBy?.column) {
    data.order_direction = params.orderBy.direction;
  }

  return data;
};
