export enum EventIntegrationStatus {
    ACTIVE = 'active',
    EVENT_NOT_MAPPED = 'event_not_mapped',
    EVENT_MAPPING_REQUESTED = 'event_mapping_requested',
    LISTING_PROCESSING = 'listing_processing',
    LISTING_NOT_MAPPED = 'listing_not_mapped',
    LISTING_REMOVED = 'listing_removed',
    TICOMBO_PUSH_DISABLED = 'ticombo_push_disabled',
    TICOMBO_PUSH_FAILED = 'ticombo_push_failed'
}
