import {GlobalStatusCodesEnum} from '../../enums/ResponseStatuses/GlobalStatusCodesEnum';
import isObject from '../../helpers/IsObject';
import normalizeValidationResponse from '../../helpers/NormalizeValidationResponse';
import {AxiosError, AxiosResponse} from 'axios';
import {HttpSuccessResponse} from './HttpSuccessResponse';

interface IHttpFailureResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, R extends Record<string, any> = Record<string, any>> {
  status: false,
  message: string,
  statusCode: Status,
  validation?: Record<string, string>,
  data?: R | undefined,
}

export class HttpFailureResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, R extends Record<string, any> = Record<string, any>>
  implements IHttpFailureResponse<Status, R> {

  status: false = false;
  message: string;
  statusCode: Status;
  validation?: Record<string, string>;
  data?: R | undefined;

  private constructor(
    {
      message,
      statusCode,
      validation,
      data,
    }: IHttpFailureResponse<Status, R>,
  ) {
    this.data = isObject(data) ? data : undefined;

    this.message = message;
    this.statusCode = statusCode;
    this.validation = isObject(validation) ? validation : {};
  }

  static createByAxiosResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, SR = any, FR = any>(
    res: AxiosResponse,
    validationMapper?: Record<string, string>,
  ): HttpSuccessResponse<Status, SR> | HttpFailureResponse<Status, FR> {
    const status = res.data?.status ?? false;

    if (status) {
      return HttpSuccessResponse.createByAxiosResponse(res, validationMapper);
    }

    return new HttpFailureResponse({
      status: false,
      statusCode: res?.data?.statusCode ?? GlobalStatusCodesEnum.Error,
      message: res.data?.message ?? 'Something went wrong',
      data: res?.data?.data ?? {},
      validation: normalizeValidationResponse(res?.data?.validation, validationMapper),
    });
  }

  static createByAxiosError(axiosError: AxiosError<any>, validationMapper?: Record<string, string>) {
    return new HttpFailureResponse({
      status: false,
      statusCode: axiosError.response?.data?.statusCode ?? GlobalStatusCodesEnum.Error,
      message: axiosError.response?.data?.message ?? 'Something went wrong',
      data: axiosError.response?.data?.data,
      validation: normalizeValidationResponse(axiosError.response?.data?.validation, validationMapper),
    });
  }

  static createByError(error: Error) {
    return new HttpFailureResponse({
      status: false,
      statusCode: GlobalStatusCodesEnum.Error as any,
      message: 'Something went wrong',
      data: undefined,
    });
  }
}
