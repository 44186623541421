import React, {FC, useEffect, useState} from "react";
import {getEuroStaticData, updateEuroVendor} from "../../../@api/Euro/Euro";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {EuroVendor} from "../../../models/Euro/EuroVendor";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {Container} from "react-bootstrap";

const EuroVendorPausePage: FC = () => {


    const [vendors, setVendors] = useState<EuroVendor[]>([]);

    const tableHeaders = [
        'ID',
        'Name',
        'Email',
        'Price suffix',
        'Tickets',
        'Pause status'
    ]

    useEffect(() => {
        getStaticData();
    }, [])

    const getStaticData = async () => {
        const response = await getEuroStaticData();

        if (response instanceof HttpSuccessResponse) {
            setVendors(response.data.vendors);
        }
    }

    const changePauseStatus = async (vendorId: number, checked: boolean) => {
        const response = await updateEuroVendor(vendorId, {pause: checked});

        if (response instanceof HttpFailureResponse) {
            alert(response?.message);
        }

        await getStaticData();
    }

    const vendorSuffix = (price: number[]) => {
        let result = '';
        price.forEach((item, index) => {
            let priceSuffix = String(item).length > 1 ? item : '0' + item;
            if (index !== price.length - 1) {
                result += '.' + priceSuffix + ', '
            } else {
                result += '.' + priceSuffix;
            }
        });
        return result;
    }

    return (
        <>
            <Container className={'admin-users-page events'}>
                <h1 className={'admin-users-page__title'}>Euro vendors</h1>
                <AppTable columns={tableHeaders}>
                    <>
                        {vendors.length ?
                            vendors.map((vendor, index) => (
                                <React.Fragment key={index}>
                                    <tr className={'admin-users-page__tr'} data-row={index}>
                                        <td>{vendor.id}</td>
                                        <td>{vendor.sb_vendor?.name}</td>
                                        <td>{vendor.sb_vendor?.user?.email}</td>
                                        <td>{vendorSuffix(vendor.price)}</td>
                                        <td>{vendor.sb_vendor?.inventories_sum_quantity ?? 0}</td>
                                        <td>
                                            {
                                                <input key={'pause'} name={'pause'} type='checkbox'
                                                       defaultChecked={vendor.pause}
                                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePauseStatus(vendor.id, e.target.checked)}/>
                                            }
                                        </td>

                                    </tr>
                                </React.Fragment>
                            )) :
                            <tr>
                                <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No
                                    results
                                </td>
                            </tr>
                        }
                    </>
                </AppTable>
            </Container>
        </>
    );
}

export default EuroVendorPausePage;
