import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BuyOffer, BuyOfferAsObject} from "../../@interfaces/ReducerData";
import {Inventory} from "../../models/Inventory";
import {SbEvent} from "../../models/Event";

export interface InventoryData {
    event: SbEvent,
    inventories: Inventory[]
}

export interface InventoryState {
    data: InventoryData[];
    newInv: any;
    pendingData: any;
    dataByID: any;
    buyRequests: BuyOffer[];
    buyOffers: BuyOffer[];
    unmappedInventories: Inventory[]
}

const initialState: InventoryState = {
    data: [],
    newInv: [],
    pendingData: [],
    dataByID: [],
    buyRequests: [],
    buyOffers: [],
    unmappedInventories: []
};

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setInventory: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },

        setBuyRequests: (state, action: PayloadAction<BuyOffer[]>) => {
            state.buyRequests = action.payload;
        },

        setBuyOffers: (state, action: PayloadAction<BuyOffer[]>) => {
            state.buyOffers = action.payload
        },

        removeOffer: (state, action: PayloadAction<BuyOfferAsObject>) => {
            const invId = action.payload.inventory.id
            state.buyOffers.forEach((offer, idx) => {
                state.buyOffers[idx].inventories = offer.inventories.filter((inventory) => inventory.id !== invId)
                if (!state.buyOffers[idx].inventories.length) {
                    state.buyOffers.splice(idx, 1);
                }
            })
        },

        updateBuyRequests: (state, action: PayloadAction<BuyOffer>) => {
            const currentObject = state.buyRequests.find(request => request.event.id === action.payload.inventories[0].event.id)

            if (currentObject) {
                currentObject.inventories.push(action.payload.inventories[0])
                return
            }
            state.buyRequests.push(action.payload)
        },

        setPendingInventory: (state, action: PayloadAction<any>) => {
            state.pendingData = action.payload;
        },

        setInventoryById: (state, action: PayloadAction<any>) => {
            state.dataByID = action.payload;
        },

        setNewEvent: (state, action: PayloadAction<any>) => {
            state.newInv.pop();
            state.newInv.push({
                event: action.payload,
                tickets: [],
            })
        },

        setNewTicket: (state, action: PayloadAction<any>) => {
            const payload = {
                ...action.payload
            };

            const updatedTickets = state.newInv.map((el:any) => {
                if (el.event.id === action.payload.eventId) {
                    return {
                        ...el,
                        tickets: [...el.tickets, payload]
                    }
                }
            });

            return {
                ...state,
                newInv: updatedTickets
            };
        },

        clearNewInv: (state) => {
            state.newInv = [];
        },

        deleteExistInv: (state, action: PayloadAction<any>) => {
            state.newInv = state.newInv.filter((el: any) => el.event.id !== action.payload.id)
        },

        setUnmappedInventories: (state, action: PayloadAction<Inventory[]>) => {
            state.unmappedInventories = action.payload;
        },

        deleteUnmappedInventory: (state, action: PayloadAction<Inventory>) => {
            state.unmappedInventories = state.unmappedInventories.filter(inventory => inventory.id !== action.payload.id);
        },

        clearInventory: (state) => {
            return initialState;
        }
    },
});

export const {
    setInventory,
    setPendingInventory,
    setInventoryById,
    setNewEvent,
    setNewTicket,
    clearNewInv,
    deleteExistInv,
    setBuyRequests,
    updateBuyRequests,
    setBuyOffers,
    removeOffer,
    setUnmappedInventories,
    deleteUnmappedInventory,
    clearInventory
} = inventorySlice.actions;

export default inventorySlice.reducer;
