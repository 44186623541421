import {Inventory} from "../models/Inventory";

export const setSeatsOptions = (quantityValue: number, inventory: Inventory, seatsSelectHTML: HTMLSelectElement) => {
    if (quantityValue === 1) {
        for (let i = inventory.low_seat; i <= inventory.high_seat; i++) {
            seatsSelectHTML.add(new Option(`${i}-${i}`, `${i}-${i}`));
        }
    } else {
        const groupCount = inventory.high_seat - inventory.low_seat - quantityValue + 1;

        for (let i = 0; i <= groupCount; i++) {
            const seats = `${inventory.low_seat + i}-${inventory.low_seat + i + quantityValue - 1}`;
            seatsSelectHTML.add(new Option(seats, seats));
        }
    }
}
