import './AppButton.scss';
import {FC, HTMLProps} from "react";
import DefaultSpinner from "../../Spinners/DefaultSpinner/DefaultSpinner";

interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
    text: string;
    type?: "button" | "submit" | "reset";
    size?: "sm" | "md" | "lg";
    color?: "red" | "gray";
    isLoading?: boolean;
}

const AppButton: FC<Props> = ({
    text,
    type,
    size = 'md',
    color = 'red',
    isLoading,
    ...props
}) => {

    let spinnerSize = 22;
    let thickness = 3;

    if (size === 'lg') {
        spinnerSize = 26;
        thickness = 5;
    }

    if (size === 'sm') {
        spinnerSize = 18;
        thickness = 2;
    }

    return (
        <button
            className={
                "app-button " + props.className +
                " app-button-" + color +
                " app-button-" + size
            }
            type={type ?? 'button'}
            {...props}
        >
            {isLoading && <DefaultSpinner size={spinnerSize} thickness={thickness} /> }
            {!isLoading && text}
        </button>
    );
}

export default AppButton;
