import React, {FC, SetStateAction, useEffect, useState} from "react";
import {Field, Form, Formik, FormikValues} from "formik";
import {splitInventory} from "../../../@api/Inventory";
import './SplitForm.scss'
import SplitGroup from "./SplitGroup";
import {Inventory} from "../../../models/Inventory";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";

interface SplitFormProps {
    inventory: Inventory;
    showHandle: () => void
}

const SplitForm: FC<SplitFormProps> = ({inventory, showHandle}) => {
    const [groupsCount, setGroupsCount] = useState(0);

    useEffect(() => {
        const select = document.getElementsByName('groups')[0] as HTMLSelectElement;
        select.selectedIndex = -1;
    }, [])

    const split = async (values: FormikValues) => {
        const res = await splitInventory(inventory.id, values);
        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
        }
        showHandle();
    }

    const handleChange = (event: { target: { value: React.SetStateAction<number>; }; }) => {
        setGroupsCount(event.target.value)
    };

    const options = (quantity: number, start: number) => {
        let content = [];

        for (let i = start; i <= quantity; i++) {
            content.push(i);
        }

        return content;
    }

    const element = (index: number) => {
        return document.getElementsByName('splitGroups['+ index +']')[0] as HTMLSelectElement;
    }

    return (
        <Formik
            initialValues={{
            }}
            onSubmit={async () => {
                const group = document.getElementsByName('groups')[0] as HTMLSelectElement;
                const groups = []
                for (let i = 1; i <= Number(group.value); i++) {
                    groups[i] = Number(element(i).value);
                }
                const data = {
                    group: Number(group.value),
                    groups: groups
                }
                await split(data)
            }}
        >
            <Form>
                <div className={'split-form_select'}>
                    <label>Split this listing into</label>
                    <Field name={'groups'} as="select"
                           className={'split-form_select_field'}
                           onChange={handleChange}>
                        {options(inventory.quantity, 2)
                            .map((option) =>
                                <option value={option}>{option}</option>
                            )
                        }
                    </Field>
                    <label>groups</label>
                </div>
                <SplitGroup inventory={inventory} groupsCount={groupsCount} options={options} />
                <button className="auth-wrapper__submit-btn mt-3" type="submit">
                    Split
                </button>
            </Form>
        </Formik>
    )
}

export default SplitForm;