import React, {FC, FormEvent, useEffect, useRef, useState} from "react";
import {Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../../../../../components/Inputs/FormikDateInput/FormikDateInput";
import MatchCard from "../../../components/MatchCard/MatchCard";
import {SbEventsFilters} from "../../../../../../@interfaces/SbEventsFilters";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../store/Store";
import useHandleSbEventsPaginationScroll from "../../../../../../hooks/useHandleSbEventsPaginationScroll";
import {useDynamicSortOptions} from "../../../../../../hooks/useDynamicSortOptions";
import {clearNewInv, deleteExistInv, setNewEvent} from "../../../../../../store/Reducers/Inventory";
import {clearEvent} from "../../../../../../store/Reducers/Event";
import ScrollToTop from "../../../../../../components/ScrollToTop";

interface Props {
    chooseCardEvent: (data: any) => void
    windowWidth: number,
    selectedEventIds: number[],
    currentCardId?: number
}

const FindMatches: FC<Props> = ({chooseCardEvent, windowWidth, selectedEventIds, currentCardId}) => {
    const dispatch = useDispatch();

    const event = useSelector((state: RootState) => state.event);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const ref = useRef<null | any>(null);
    const {setSortOptions, sortFunction} = useDynamicSortOptions();
    const filterInitial: SbEventsFilters = {name: '', from_date: new Date(), venue: '', city: ''};
    const handleScroll = useHandleSbEventsPaginationScroll(ref, filterInitial);

    const searchMatch = (event: FormikValues) => {
        handleScroll.filterEvents(event)
    }

    const resetFilterForm = async () => {
        setIsBtnDisabled(true);
        await handleScroll.resetEvents();
        setIsBtnDisabled(false);
    };

    return (
        <>
            {
                <>
                    <div className="matches-header matches-header-add-inventory">
                        <h3 className="matches-title">
                            Matches
                        </h3>
                    </div>

                    <div className="matches-body">

                        <Formik
                            initialValues={filterInitial}
                            onSubmit={async (values) => {
                                const data = {
                                    ...values
                                } as any;
                                await searchMatch(data);
                            }}
                            innerRef={ref}
                        >
                            {({isSubmitting, values, handleChange}) =>
                                <Form className="filters-form">
                                    <DefaultFormikInput
                                        name={'name'}
                                        placeholder={'Search events by name'}
                                        autoComplete={'on'}
                                        class={'filters-search'}
                                        onChange={handleChange}
                                        value={values.name}
                                    ></DefaultFormikInput>
                                    <DefaultFormikInput
                                        name={'venue'}
                                        placeholder={'Search events by venue'}
                                        autoComplete={'on'}
                                        class={'filters-search'}
                                        onChange={handleChange}
                                        value={values.venue}
                                    ></DefaultFormikInput>
                                    <DefaultFormikInput
                                        name={'city'}
                                        placeholder={'Search events by city'}
                                        autoComplete={'on'}
                                        class={'filters-search'}
                                        onChange={handleChange}
                                        value={values.city}
                                    ></DefaultFormikInput>
                                    <div className="filters-date">
                                        <div>
                                            <FormikDateInput
                                                name={'from_date'}
                                                placeholderText={'From date'}
                                                className={''}
                                                autoComplete={'off'}
                                                onChange={handleChange}
                                                value={values.to_date}
                                            />
                                        </div>
                                        <div>
                                            <FormikDateInput
                                                name={'to_date'}
                                                placeholderText={'To date'}
                                                className={''}
                                                autoComplete={'off'}
                                                onChange={handleChange}
                                                value={values.to_date}
                                            />
                                        </div>
                                    </div>

                                    <div className="filters-button">
                                        <button type='reset' onClick={() => resetFilterForm()}
                                                className="filters-button-reset"
                                                disabled={isBtnDisabled}>
                                            Reset
                                        </button>
                                        <button type="submit" className="filters-button-apply">
                                            Apply Filters
                                        </button>
                                    </div>
                                </Form>
                            }
                        </Formik>
                        <div className="matches matches-list matches-list-add-inventory"
                             onScroll={handleScroll.handleChange}>
                            {
                                handleScroll.events && handleScroll.events.slice().sort(sortFunction).map((data) =>
                                    <MatchCard key={data.event_id} event={data}
                                               isSelected={selectedEventIds.includes(data.id)}
                                               clickFunc={() => chooseCardEvent(data)}
                                               currentEventId={currentCardId}/>
                                )
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default FindMatches;
