import React, {FC, useState} from "react";
import {Accordion} from "react-bootstrap";
import {Form, Formik} from "formik";
import {clearFilter, setFilter} from "../../../../../store/Reducers/Filters";
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../../../../components/Inputs/FormikDateInput/FormikDateInput";
import {useDispatch} from "react-redux";
import './MobileFilter.scss';

const MobileFilter: FC = () => {
    const dispatch = useDispatch();

    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);

    const filterInitial = {section: '', from_date: undefined, to_date: undefined, row: '', name: '', inventory_id: ''};

    const filterInputs = [
        {
            name: 'name',
            placeholder: 'Search tickets by name',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'section',
            placeholder: 'Search tickets by section',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'row',
            placeholder: 'Search tickets by row',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'inventory_id',
            placeholder: 'Search tickets by Inventory ID',
            autoComplete: 'off',
            class: 'filters-search'
        },
    ]

    const formDateInputs = [
        {
            name: 'from_date',
            placeholderText: 'From date',
            className: '',
        },
        {
            name: 'to_date',
            placeholderText: 'To date',
            className: '',
        },
    ]

    const resetFilterForm = () => {
        dispatch(clearFilter());
    };

    return (
        <>

            <div className={isOpenSidebar ? 'filters filters-mobile filters-expand' : 'filters-collapse'}>
                {
                    isOpenSidebar
                    &&
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="filters-accordion-header">
                                Filters
                            </Accordion.Header>
                            <hr/>
                            <Accordion.Body className="filters-accordion-body">
                                <Formik
                                    initialValues={filterInitial}
                                    onSubmit={async (values) => {
                                        dispatch(setFilter(values));
                                    }}
                                >
                                    {({isSubmitting}) =>
                                        <Form className="filters-form">
                                            {
                                                filterInputs.map((formInput, index) => (
                                                    <DefaultFormikInput
                                                        key={`mobile-filters-form-${index}`}
                                                        name={formInput.name}
                                                        placeholder={formInput.placeholder}
                                                        autocomplete={formInput.autoComplete}
                                                        class={formInput.class}
                                                    ></DefaultFormikInput>
                                                ))
                                            }
                                            <div className="filters-date">
                                                {formDateInputs.map((formDateInput, index) => (
                                                    <div key={`mobile-filters-date-${index}`}>
                                                        <FormikDateInput
                                                            name={formDateInput.name}
                                                            placeholderText={formDateInput.placeholderText}
                                                            className={formDateInput.className}
                                                            autoComplete={'off'}
                                                        />
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            <div className="filters-button">
                                                <button type='reset' onClick={() => resetFilterForm()}
                                                        className="filters-button-reset">
                                                    Reset
                                                </button>
                                                <button type="submit" className="filters-button-apply">
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </Form>
                                    }
                                </Formik>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                }

            </div>

        </>
    )
}
export default MobileFilter;
