import React, {CSSProperties, FC, useEffect} from "react";
import {Modal} from "react-bootstrap";
import "./AppModal.scss";

interface Props {
    title: string;
    form: any;
    show: boolean
    showHandle: () => void;
    onClose: () => void;
    centered?: boolean,
    customClass?: string
    modalIndex?: number,
    backdrop?: boolean | "static",
    keyboard?: boolean,
    size?: "sm" | "lg" | "xl" | undefined,
    closeButton?: boolean | undefined,
    headerStyle?: CSSProperties,
    headerClassName?: string,
    headerTitleClassName?: string
    bodyClassName?: string
}

const AppModal: FC<Props> =
    ({
         title,
         form,
         show,
         showHandle,
         onClose,
         centered,
         customClass,
         modalIndex = 0,
         backdrop = true,
         keyboard = true,
         size,
         closeButton = true,
         headerStyle,
         headerClassName,
         headerTitleClassName,
         bodyClassName
     }) => {

        const handleClose = () => {
            onClose();
            showHandle();
        };

        useEffect(() => {
            if (show && customClass) {
                const modal = document.getElementsByClassName('modal-dialog')[modalIndex];
                modal.classList.add(customClass);
            }
        }, [show, customClass, modalIndex]);

        return (
            <>
                <Modal size={size} show={show} onHide={handleClose} centered={centered} backdrop={backdrop}
                       keyboard={keyboard}>
                    <Modal.Header className={headerClassName} style={headerStyle} closeButton={closeButton}>
                        <Modal.Title className={headerTitleClassName}>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={bodyClassName}>
                        {form}
                    </Modal.Body>
                </Modal>
            </>
        )
    }

export default AppModal;
