import {FC} from "react";
import "./CalendarCell.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";
import {CalendarDisplayData} from "../../../../../@interfaces/Euro/CalendarDisplayData";

interface CalendarCellProps {
    value?: string;
    headerCell: boolean;
    dateInfo?: CalendarDisplayData
}

const CalendarCell: FC<CalendarCellProps> = ({value = '', headerCell = false, dateInfo = {}}) => {

    const loggedUser = useSelector((state: RootState) => state.auth.user);

    return (
        <>
            <div className={
                loggedUser?.vendor_id === dateInfo?.vendor?.sb_vendor?.id
                    ? "calendar-cell-date-owner calendar-cell"
                    : "calendar-cell"
            }>
                {
                    headerCell
                        ?
                        <p className={"calendar-cell-title"}>
                            {value}
                        </p>
                        :
                        <>
                            <div
                                className={dateInfo.active
                                    ? "calendar-cell-date-active calendar-cell-date"
                                    : 'calendar-cell-date calendar-cell-date-inactive'}>
                                <p className={"calendar-cell-date-title"}>
                                    {dateInfo.date}
                                </p>
                                <p className={"calendar-cell-date-vendor"}>
                                    {dateInfo?.vendor?.sb_vendor?.name}
                                </p>
                            </div>
                        </>
                }
            </div>
        </>
    );
}

export default CalendarCell;
