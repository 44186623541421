import React, {FC} from "react";
import {User} from "../models/User";
import {Navigate} from "react-router-dom";

type Props = {
    user: User | undefined,
    children: JSX.Element,
};

const ProtectedRoute: FC<Props> = ({children, user}) => {
    if (!user) {
        Navigate({to: '/login'});
        return (<></>);
    }

    if ((user.pools && user.pools.length > 0) || (user.paid_access)) {
        return children;
    } else {
        return <Navigate to='/user/sell'/>;
    }


    return children;
}

export default ProtectedRoute;
