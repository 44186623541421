import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TicomboEvent} from "../../models/TicomboEvent";
import {StubhubEvent} from "../../models/StubhubEvent";

export interface EventState {
    event_city?: string,
    event_venue?: string,
    event?: string;
    id?: number;
    date?: string;
    ticombo_event?: TicomboEvent | null,
    stubhub_event?: StubhubEvent | null
}

const initialState: EventState = {
    event_city: undefined,
    event_venue: undefined,
    event: undefined,
    id: undefined,
    date: undefined,
    ticombo_event: undefined,
    stubhub_event: undefined
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEvent: (state, action: PayloadAction<
            {
                event_city: string,
                event_venue: string,
                event: string,
                id: number,
                date: string,
                ticombo_event?: TicomboEvent | null,
                stubhub_event?: StubhubEvent | null
            }>) => {
            state.event_venue = action.payload.event_venue
            state.event_city = action.payload.event_city
            state.event = action.payload.event;
            state.id = action.payload.id;
            state.date = action.payload.date;
            state.ticombo_event = action.payload.ticombo_event;
            state.stubhub_event = action.payload.stubhub_event;
        },
        clearEvent: (state) => {
            return initialState
        }
    },
});

export const {setEvent, clearEvent} = eventSlice.actions;

export default eventSlice.reducer;
