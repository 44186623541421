import './HeaderDropdownMenu.scss';
import React, {FC, useEffect, useRef, useState} from 'react';

interface HeaderDropdownMenuParams {
    toggle: JSX.Element,
    menuItems: JSX.Element,
    toggleClasses?: string,
    menuClasses?: string,
}

const HeaderDropdownMenu: FC<HeaderDropdownMenuParams> = ({toggle, menuItems, toggleClasses, menuClasses}) => {
    const [isMenuShow, setIsMenuShow] = useState(false);

    const useOutsideClick = () => {
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const handleClick = (event: MouseEvent): void => {
                if (
                    ref.current &&
                    !ref.current.contains(event.target as Node)
                ) {
                    setIsMenuShow(false);
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, []);

        return ref;
    };

    const ref = useOutsideClick();

    return (
        <div className="header-dropdown-menu">
            <div
                ref={ref}
                className={`header-dropdown-menu__toggle ${
                    toggleClasses ?? ""
                } ${isMenuShow ? "active" : ""}`}
                onClick={() => setIsMenuShow(!isMenuShow)}
            >
                {toggle}
            </div>

            <div
                className={`header-dropdown-menu__menu ${menuClasses ?? ""} ${
                    isMenuShow ? "d-block" : "d-none"
                }`}
            >
                {menuItems}
            </div>
        </div>
    );
};


export default HeaderDropdownMenu;
