import requestWrapper from './RequestWrapper';
import axios from 'axios';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import {EditInvoice, Invoice, NewInvoice} from "../models/Invoice";
import {FormikValues} from "formik";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {Inventory} from "../models/Inventory";

export const getInvoices = (email: string) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.get('/sb_invoices', {params: {email}})
    );
};

export const getInvoiceLines = (invoiceId: number, params: any = {}) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.get('/sb_invoices/' + invoiceId + '/lines', {params})
    );
};

export const getInvoiceStatistics = () => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.get('/sb_invoices/statistics')
    );
};

export const invoicesPaginateRequest = (params: any) => {
    const data = paginationParamsForAxios(params);

    if (!params.sort_by) params.sort_by = "ASC";

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Invoice>>(axios.get('/invoices', {
        params: {...data, ...params},
    }));
};

export const setInvoiceUnitCostRequest = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.patch(`/invoices/${id}/amount`, params)
    );
};

export const createInvoice = (values: NewInvoice, inventory: Inventory) => {
    return requestWrapper<GlobalStatusCodesEnum, { invoice: Invoice }>(
        axios.post(`/invoices`, {
            values, inventory
        })
    )
}

export const createViagogoInvoice = (values: any, inventory: Inventory) => {
    return requestWrapper<GlobalStatusCodesEnum, Invoice>(
        axios.post(`/invoices`, {
            values, inventory
        })
    )
}

export const purchaseHistoryPaginateRequest = (params: any) => {
    const data = paginationParamsForAxios(params);

    if (!params.sort_by) params.sort_by = "ASC";

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Invoice>>(axios.get('/invoices/history', {
        params: {...data, ...params},
    }));
};

export const updateInvoice = (values: EditInvoice, id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { invoice: Invoice }>(
        axios.patch(`invoices/${id}`, {...values, id})
    )
};
