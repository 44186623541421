import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getAllCurrencies} from "../@api/Currency";
import {HttpSuccessResponse} from "../@api/Responses/HttpSuccessResponse";
import {Currency} from "../models/Currency";
import {setCurrenciesState} from "../store/Reducers/Currency";

export default function useCurrenciesGet() {
    const milliseconds = 1000 * 60 * 60;

    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrencies();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const setCurrencies = () => {
        getAllCurrencies()
            .then((response) => {
                if (response instanceof HttpSuccessResponse) {
                    const tempCurrencies: Currency[] = [];

                    response.data.forEach((currency: Currency) => {
                        if (currency.is_main) tempCurrencies.push(currency);
                    });

                    dispatch(setCurrenciesState({currencies: tempCurrencies, allCurrencies: response.data}));
                }
            })
    };

    return setCurrencies;
};
