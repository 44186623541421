
import {FC, useEffect, useState} from "react";
import "./LTGAliasesPage.scss";
import {Container} from "react-bootstrap";
import usePagination from "../../../helpers/UsePagination";
import {LTGLocationCategory} from "../../../models/Category";
import {ltgLocationCategoriesPaginateRequest, updateLTGLocationCategory} from "../../../@api/Categories";
import CreatableSelect from 'react-select/creatable';
import {updateLTGSection} from "../../../@api/Section";
import {useParams} from "react-router-dom";
import {ltgVenueById} from "../../../@api/Venue";
import {LTGVenue} from "../../../models/Venue";

interface Option {
    readonly label: string;
    readonly value: string;
}

const LTGAliasesPage: FC = () => {
    const {venueId} = useParams();

    const [venue, setVenue] = useState<LTGVenue>();

    const paginationLocationCategory = usePagination<LTGLocationCategory>(
        ltgLocationCategoriesPaginateRequest
    );

    const getVenue = async () => {
        const response = await ltgVenueById(String(venueId));

        setVenue(response.data);
    }

    const getLocationCategories = async () => {
        paginationLocationCategory.paginate({ltg_venue_id: venueId});
    };

    const updateLocationCategory = async (locationCategoryId: number, optionsData: any) => {
        const options = optionsData as Option[];
        const alias = transformFromOption(options);

        await updateLTGLocationCategory(locationCategoryId, {alias});
        await getLocationCategories();
    };

    const updateSection = async (sectionId: number, optionsData: any) => {
        const options = optionsData as Option[];
        const alias = transformFromOption(options);

        await updateLTGSection(sectionId, {alias});
        await getLocationCategories();
    };

    const transformToOption = (alias: string | null) => {
        if (!alias) {
            return [];
        }

        return alias.split('###').map(alias => ({label: alias, value: alias})) as Option[];
    };

    const transformFromOption = (options: Option[]) => {
        let alias = '';

        options.forEach((option, index, array) => {
            alias = alias + option.value.trim();

            if (index !== array.length - 1) {
                alias = alias + '###';
            }
        });

        return alias;
    };

    useEffect(() => {
        getVenue();
        getLocationCategories();
    }, []);

    return <Container className={'admin-users-page venues'}>
        <h2>{venue?.name} - {venue?.country} - {venue?.city}</h2>
        <div className="ltg-venues mt-4">
            {
                paginationLocationCategory.items?.map(locationCategory => (
                    <div className="card p-2 mb-4" key={locationCategory.id}>
                        <h5>{locationCategory.name}</h5>

                        <label>Enter Location Category Aliases</label>
                        <CreatableSelect isMulti placeholder=''
                            value={transformToOption(locationCategory.alias)}
                            options={transformToOption(locationCategory.alias)}
                            onChange={(newValue) => updateLocationCategory(locationCategory.id, newValue)}
                        />

                        {
                            locationCategory.sections.map(section => (
                                <div className="card p-2 ms-4 mt-4" key={section.id}>
                                    <h5>{section.name}</h5>

                                    <label>Enter Section Aliases</label>
                                    <CreatableSelect isMulti placeholder=''
                                        value={transformToOption(section.alias)}
                                        options={transformToOption(section.alias)}
                                        onChange={(newValue) => updateSection(section.id, newValue)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    </Container>
}

export default LTGAliasesPage;
