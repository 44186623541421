import {useState, Dispatch, SetStateAction, useEffect} from 'react';
import {HttpSuccessResponse} from '../@api/Responses/HttpSuccessResponse';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {HttpFailureResponse} from '../@api/Responses/HttpFailureResponse';
import {PaginationResponseData} from '../@interfaces/Response/PaginationResponseData';
import {PaginationParams} from '../@interfaces/PaginationParams';
import {PAGINATION_PER_PAGE_OPTION_DEFAULT} from "../constants/Constants";
import {FormikValues} from "formik";
import _ from 'lodash';

export type TablePagination = {
    currentPage: number;
    totalItems: number;
    lastPage: number;
    perPage: number;
}

export type Pagination<T> = TablePagination & {
    filterValues: FormikValues;
    items: T[];
    paginate: (params?: any, filters?: any) => Promise<void>;
    loadChunk: (page?: number, perPage?: number, filters?: any) => Promise<T[]>;
    setItems: Dispatch<SetStateAction<T[]>>;
    setTotalItems: Dispatch<SetStateAction<number>>;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    setPerPage: Dispatch<SetStateAction<number>>;
    setFilterValues: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
    error: string;
}

const usePagination = <T>(
  loader: (params: PaginationParams<T>) => Promise<HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>> | HttpFailureResponse<GlobalStatusCodesEnum, any>>,
  filtersInitial = {}
) : Pagination<T> => {

  const [items, setItems] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(PAGINATION_PER_PAGE_OPTION_DEFAULT);
  const [lastPage, setLastPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [filterValues, _setFilterValues] = useState(_.cloneDeep(filtersInitial));

  const setFilterValues = (newValues: typeof filterValues) => {
      setCurrentPage(1);
      return _setFilterValues(newValues);
  }

    const loadChunk = async (chunkPage?: number, chunkPerPage?: number, filters?: typeof filterValues) => {
        setIsLoading(true);
        const requestParams = {
            page: chunkPage || currentPage,
            perPage: chunkPerPage || perPage,
            ...(filters || filterValues)
        };
        const res = await loader(requestParams);
        setIsLoading(false);
        if (res instanceof HttpSuccessResponse) {
            return res.data.items;
        } else {
            return [];
        }
    }

  const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>>) => {
    setItems(res.data.items);
    setTotalItems(res.data.total_items);
    setLastPage(res.data.last_page);
  };

  const failureResponse = (res: HttpFailureResponse<GlobalStatusCodesEnum, any>) => {
    setItems([]);
    setCurrentPage(1);
    setTotalItems(0);
    setLastPage(0);
    setError(res.message);
  };

  const paginate = async (params: any = {}) => {
    setError('');
    setIsLoading(true);
    const requestParams = {page: currentPage, perPage, ...params, ...filterValues};
    const res = await loader(requestParams);
    if (res instanceof HttpSuccessResponse) {
      successResponse(res);
    } else {
      failureResponse(res);
    }

    setIsLoading(false);
  };

  const paginationPair = `${currentPage}-${perPage}`;
  const filtersFlat = JSON.stringify(filterValues);

  useEffect(() => {
    paginate();
  }, [paginationPair, filtersFlat]);

  return {
    paginate,
    loadChunk,
    items,
    setItems,
    currentPage,
    setCurrentPage,
    totalItems,
    setTotalItems,
    lastPage,
    perPage,
    setPerPage,
    filterValues,
    setFilterValues,
    isLoading,
    error,
  };
};

export default usePagination;
