import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {Currency} from "../models/Currency";

const urlPrefix = 'currencies';

export const getAllCurrencies = () => {
    return requestWrapper<GlobalStatusCodesEnum, Currency[]>(
        axios.get(`${urlPrefix}`)
    );
}
