import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import DefaultFormikInput from '../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/Store';
import {
    createUserTicomboAccount,
    getUserTicomboAccount,
    deleteUserTicomboAccount
} from '../../../../../@api/Ticombo';
import { HttpSuccessResponse } from '../../../../../@api/Responses/HttpSuccessResponse';
import { IUserTicomboAccount } from '../../../../../models/IUserTicomboAccount';
import { resetUserTicomboAccount, setUserTicomboAccount } from '../../../../../store/Reducers/Ticombo';
import './TicomboAccount.scss';
import IconNo from '../../../../../assets/images/other/x-close.svg';
import AppModal from '../../../../../ui/Modal/AppModal/AppModal';
import { Message, useToaster } from 'rsuite';
import { TicomboValidationSchema } from '../../Data';
import { ERROR_DURATION, SUCCESS_DURATION } from '../../../../../constants/Constants';


const TicomboAccount: FC = () => {
    const toaster = useToaster();
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const userTicomboAccount = {
        ...useSelector((state: RootState) => state.ticombo.account),
        is_valid: false
    };
    const dispatch = useDispatch();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);


    const formInitial: Partial<IUserTicomboAccount> = {
        api_key: userTicomboAccount.api_key,
    };

    const getTicomboAccountSettings = async () => {
        try {
            if (loggedUser?.id) {
                const res = await getUserTicomboAccount();
                if (res instanceof HttpSuccessResponse) {
                    dispatch(setUserTicomboAccount(res.data));
                }
            }
        } catch (error) {
            alert('Error updating Ticombo Account');
        }
    };

    const saveTicomboAccountSettings = async (
        values: FormikValues,
        setSubmitting: (newVal: boolean) => void
    ) => {
        try {
            if (loggedUser?.id) {
                const res = await createUserTicomboAccount(values);
                if (res instanceof HttpSuccessResponse) {
                    dispatch(setUserTicomboAccount(res.data));
                    const message = <Message type={'info'}>{res.message}</Message>;
                    toaster.push(message, { duration: SUCCESS_DURATION });
                } else {
                    const message = <Message type={'error'}>{res.message}</Message>;
                    toaster.push(message, { duration: ERROR_DURATION });
                }
                setSubmitting(false);
            }
        } catch (error) {
            const message = <Message type={'error'}>Error updating Ticombo Account</Message>;
            toaster.push(message, { duration: SUCCESS_DURATION });
        }
    };

    const showConfirmDeleteAccount = () => {
        setShowConfirmDelete(true);
    }

    const closeConfirmDeleteAccount = () => setShowConfirmDelete(false);

    const handleAccountDelete = async () => {
        if (userTicomboAccount.id) {
            setIsDeletingAccount(true);
            let message;
            let type: 'success' | 'error' = 'error';
            try {
                const res = await deleteUserTicomboAccount(userTicomboAccount.id);
                type = res instanceof HttpSuccessResponse ? 'success' : 'error';
                message = res.message;
                if (type === 'success') {
                    dispatch(resetUserTicomboAccount());
                }
            } catch (e: any) {
                message = e?.message;
            }
            if (!message) {
                message = 'Something went wrong when deleting your account. Try again later. If the problem persists please contact support.';
            }
            setIsDeletingAccount(false);
            toaster.push(<Message showIcon={true} type={type}>{message}</Message>, { duration: 4000 });
            closeConfirmDeleteAccount();
        }
    };

    useEffect(() => {
        getTicomboAccountSettings();
    }, []);

    return (
        <>
            <AppModal title="Are you sure you want to delete the account?"
                      centered={true}
                      size={'sm'}
                      closeButton={false}
                      show={showConfirmDelete}
                      form={
                          <div className={'tc-account'}>
                              <p>All synchronized tickets would be removed from your Ticombo account.</p>
                              <br/>
                              <div className={'buttons'}>
                                  <button type={'submit'} className="auth-wrapper__submit-btn button-gray self-align"
                                          onClick={closeConfirmDeleteAccount}>
                                      Cancel
                                  </button>
                                  <button disabled={isDeletingAccount} className="auth-wrapper__submit-btn self-align"
                                          onClick={handleAccountDelete} type={'submit'}>
                                      Confirm
                                  </button>
                              </div>
                          </div>
                      }
                      showHandle={() => {
                      }}
                      onClose={closeConfirmDeleteAccount}/>
            <div className={'tc-account'}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formInitial}
                    onSubmit={(values, {setSubmitting}) => {
                        saveTicomboAccountSettings(values, setSubmitting);
                    }}
                    validationSchema={TicomboValidationSchema}
                >
                    {({isSubmitting, isValid, errors}) =>
                        <Form className="filters-form" autoComplete={'off'}>
                            <div className={'form-fields'}>
                                <div className={'field'}>
                                    <div className={'input'}>
                                        <p className={'tc-account_input-name'}>Ticombo Account Key:</p>
                                        <DefaultFormikInput
                                            disabled={!!userTicomboAccount.id}
                                            name={'api_key'}
                                            placeholder={'Api Key'}
                                            autoComplete={'off'}
                                            class={'filters-search'}
                                            endIcon={userTicomboAccount.id ? (
                                                <img className="self-align delete-btn"
                                                     onClick={showConfirmDeleteAccount} src={IconNo} alt="event icon"/>
                                            ) : null}
                                        ></DefaultFormikInput>
                                    </div>
                                </div>
                            </div>
                            <div className={'buttons'}>
                                <button type={'submit'} className="auth-wrapper__submit-btn self-align"
                                        disabled={isSubmitting || !isValid}>
                                    Save
                                </button>
                            </div>
                        </Form>
                    }
                </Formik>
            </div>
        </>
    );
};

export default TicomboAccount;
