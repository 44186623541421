import * as yup from 'yup';
import {appSmartSearchRule} from "../../../../helpers/YupRules";
import { SplitTypes } from '../../../../enums/Skybox/SplitTypes';
import { SPLIT_TYPES_LABEL } from '../../../../constants/SplitTypesLabels';

export class TicketData {
    description = '';
    disclosures = [];
    eventId = 0;
    quantity = '';
    shown_quantity = '';
    section = '';
    row = '';
    unit_cost = '';
    low_seat = '';
    stock_type = '';
    currentUser = 0;
    public_notes = '';
    internal_notes = '';
    currency = 'USD';
    split_type = SplitTypes.NeverLeaveOne;
    seat_type = 'CONSECUTIVE';
    broadcast = true;
    purchased_from = '';
    purchased_for = '';
    face_value = '';
    user_vendor_name = '';
    performer = '';
    po_currency = 'USD';
    stubhub_section = '';
    stubhub_stock_type = '';
    stubhub_payment_type = '';
    stubhub_split_type = '';
    stubhub_split_quantity = '';
    in_hand_date: null | Date = null;
}

export class TicomboFormData {
    category: string | null = null;
    fanSection: string | null = null;
    setSectionStatus = 0;
    shouldPush = false;
    customPrice: number | null = null;
}

export const TicketFormValidationSchema = yup.object().shape({
    description: yup.string().required('Required'),
    quantity: yup.number().min(1, 'Too Short').max(99, 'Too Long, max 99').required('Required'),
    shown_quantity: yup.number().min(1, 'Too Short').max(99, 'Too Long').typeError('Must be a number!'),
    section: yup.string().min(1, 'Too Short').required('Required'),
    row: yup.string().min(1, 'Too Short').required('Required'),
    unit_cost: yup.string().min(1, 'Too Short').max(9, 'Too Long').required('Required'),
    low_seat: yup.number().min(1, 'Too Short').required('Required').typeError('Must be a number!'),
    stock_type: yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    currentUser: yup.number().min(1, 'Too Short').required('Required'),
    public_notes: yup.string().min(1, 'Too Short').max(255, 'Too Long'),
    internal_notes: yup.string().min(1, 'Too Short').max(255, 'Too Long'),
    currency: yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    split_type: yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required')
        .oneOf(SPLIT_TYPES_LABEL.map(type => type.key)),
    seat_type: yup.string().min(1, 'Too Short').max(255, 'Too Long').required('Required'),
    purchased_from: yup.string().min(1, 'Too Short').max(255, 'Too Long'),
    purchased_for: yup.string().min(1, 'Too Short').max(255, 'Too Long'),
    face_value: yup.string().min(1, 'Too Short').max(9, 'Too Long').required('Required').when(["unit_cost"], (unit_cost, schema) => {
        return schema.notOneOf([unit_cost], "This field should not be equal 'Unit price' field");
    }),
    user_vendor_name: yup.string().required('Required').test(appSmartSearchRule('user_vendor_name')),
});
