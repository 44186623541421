import {RegistrationData} from '../pages/Auth/RegistrationPage/Data';
import axios from 'axios';
import {registrationDataAdapter} from '../adapters/Request/AuthRequestAdapter';
import requestWrapper from './RequestWrapper';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {
    CheckUserResponse,
    LoginFailureResponse,
    LoginResponse,
    RegistrationSuccessResponse,
    VerifyOtpSuccessResponse,
} from '../@interfaces/Response/Auth';
import {AuthResponseStatusesEnum} from '../enums/ResponseStatuses/AuthResponseStatusesEnum';
import {HttpSuccessResponse} from './Responses/HttpSuccessResponse';

const registrationValidationMapper = {
    phone_number: 'phoneNumber',
};

const urlPrefix = 'auth';


export const registrationRequest = (registrationData: RegistrationData) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/registration`, registrationDataAdapter(registrationData)),
        registrationValidationMapper,
    );
};

export const loginRequest = (credential?: { email: string, password: string }) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, LoginResponse, LoginFailureResponse>(
        axios.post(`${urlPrefix}/login`, credential),
    );
};

export const logoutRequest = () => {
    return requestWrapper<GlobalStatusCodesEnum, string>(
        axios.post(`${urlPrefix}/logout`)
    );
};

export const verifyOtpRequest = (token: string, code: string) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, VerifyOtpSuccessResponse>(
        axios.post(`${urlPrefix}/verify-otp`, {code, token}),
    );
};

export const checkUserRequest = (auth_token: string) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, CheckUserResponse>(
        axios.post(`${urlPrefix}/check-user`, {auth_token}),
    );
};

export const verifyEmailRequest = (token: string) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, HttpSuccessResponse>(
        axios.get(`${urlPrefix}/verify-email/` + token),
    );
};

export const resetPasswordRequest = (email: string) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, HttpSuccessResponse>(
        axios.post(`${urlPrefix}/reset-password-request`, {email}),
    );
};

export const resetPassword = (token: string, password: string) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, HttpSuccessResponse>(
        axios.post(`${urlPrefix}/reset-password`, {token, password}),
    );
};
