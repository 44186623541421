import React, {FC, useEffect} from "react";
import {Field, Form, Formik} from "formik";
import DefaultFormikInput from "../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {
    createCustomerCreation,
    Customer,
} from "../../../../models/Customer";
import {CustomerTypes} from "../../../../enums/CustomerTypes";
import './CustomerOrVendorForm.scss';
import {Tag, TagPicker} from "rsuite";
import {newCustomerValidationSchema} from "./NewCustomerValidation";
import {ErrorMessage} from 'formik';
import renderPhoneInput from "../../../../adapters/Formik/DefaultFormikPhoneInputAdapter";
import {StateTypes} from "../../../../enums/Skybox/StateTypes";
import {UserVendor, vendorFieldsForForm} from "../../../../models/UserVendor";
import {CountryTypes} from "../../../../enums/Skybox/CountryTypes";
import {PaymentMethods} from "../../../../enums/PaymentMethods";
import {UserVendorFormValidation} from "./UserVendorValidation";
import {editCustomer, postCustomers} from "../../../../@api/Customers";
import {editUserVendor, postUserVendor} from "../../../../@api/UserVendors";
import {setCustomerTags} from "../../../../@api/CustomTags";
import {setUserVendorTags} from "../../../../@api/VendorTags";
import {HttpSuccessResponse} from "../../../../@api/Responses/HttpSuccessResponse";
import {DeliveryMethods} from "../../../../enums/DeliveryMethods";

interface Props {
    editMode?: boolean;
    customer?: Customer;
    vendor?: UserVendor;
    tags: string[]
    selectedTags: string[],
    setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>
    onClose: () => void;
    customerFormMode?: boolean
    handleEntity?: (item: any) => void;
}

const CustomerOrVendorForm: FC<Props> = ({
                                             editMode,
                                             customer,
                                             tags,
                                             selectedTags,
                                             setSelectedTags,
                                             onClose,
                                             vendor,
                                             customerFormMode = true,
                                             handleEntity
                                         }) => {
    const getFormInitial = () => {
        let formInitial;

        if (editMode && customer) {
            formInitial = createCustomerCreation(customer ? Object.assign({...customer}, {tags: tags}) : undefined);
        } else if (editMode && vendor) {
            formInitial = vendorFieldsForForm(vendor ? Object.assign({...vendor}, {tags: tags}) : undefined);
        } else {
            formInitial = customer ? createCustomerCreation(undefined) : vendorFieldsForForm(undefined);
        }

        return formInitial;
    }

    const tagsData = tags.map((tag) => ({
        label: tag,
        value: tag,
    }));

    useEffect(() => {
        if (editMode && customer) {
            setSelectedTags(customer.customer_tags || []);
        } else if (editMode && vendor) {
            setSelectedTags(vendor.vendor_tags || []);
        } else {
            setSelectedTags([]);
        }
    }, [editMode, customer, vendor]);

    const handleSubmit = async (data: any) => {
        let res;
        if (customerFormMode) {
            if (editMode && customer) {
                res = await editCustomer({...data, customer_tags: selectedTags}, customer.id);
                await setCustomerTags(selectedTags, customer.id);
            } else {
                res = await postCustomers({...data, customer_tags: selectedTags});
                if (handleEntity) {
                    handleEntity(res.data);
                }
            }
        } else {
            if (editMode && vendor) {
                res = await editUserVendor({...data, vendor_tags: selectedTags}, vendor.id);
                await setUserVendorTags(selectedTags, vendor.id);
            } else {
                res = await postUserVendor({...data, vendor_tags: selectedTags});
                if (handleEntity) {
                    handleEntity(res.data);
                }
            }
        }

        res instanceof HttpSuccessResponse ? onClose() : alert(res.message);
    };

    return (
        <>
            <Formik
                initialValues={getFormInitial()}
                onSubmit={async (values) => {
                    await handleSubmit(values)
                }}
                validationSchema={customerFormMode ? newCustomerValidationSchema : UserVendorFormValidation}
            >
                {({isSubmitting}) =>
                    <Form>
                        <div className={'modal-form'}>
                            <div className={'modal-form__container'}>
                                <div className={'modal-form__header'}>
                                    <p className={'modal-form__header-title'}>{customerFormMode ? 'Customer Information' : 'Vendor Information'}</p>
                                </div>
                                <div className={'modal-form__inputs'}>
                                    <div className={'modal-form__input-container'}>
                                        <p className={'modal-form__input-title'}>DISPLAY NAME</p>
                                        <DefaultFormikInput
                                            name={'display_name'}
                                            placeholder={''}
                                            autocomplete={'off'}
                                            type={'text'}
                                        ></DefaultFormikInput>
                                    </div>
                                    <div className={'modal-form__input-container error-relative'}>
                                        <p className={'modal-form__input-title'}>SELECT TYPE</p>
                                        <Field name={'type'} as="select">
                                            <option value="" disabled>Type</option>
                                            {Object.entries(CustomerTypes).map((key, index) => (
                                                <option key={String(index) + key} value={key[0]}>{key[1]}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="type" component="div"
                                                      className="error-message custom-error"/>
                                    </div>
                                    <div className={'modal-form__input-container'}>
                                        <p className={'modal-form__input-title'}>TAGS</p>
                                        <TagPicker
                                            creatable={true}
                                            className={'customers-tag-picker'}
                                            onChange={(selected) => setSelectedTags(selected)}
                                            data={tagsData}
                                            placeholder="Select tags or create a new one"
                                            block
                                            value={selectedTags}
                                            renderMenuItem={(label, item) => {
                                                return <>{label}</>;
                                            }}
                                            renderValue={(values, items, tags) => {
                                                return values.map((tag: any) => (
                                                    <Tag key={tag}>
                                                        {tag}
                                                    </Tag>
                                                ));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-form__container'}>
                                <div className={'modal-form__header'}>
                                    <p className={'modal-form__header-title'}>Contact Info</p>
                                </div>
                                <div className={'modal-form__inputs modal-form__inputs_multiple'}>
                                    <div className={'modal-form__inputs'}>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>FIRST NAME</p>
                                            <DefaultFormikInput
                                                name={'first_name'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>LAST NAME</p>
                                            <DefaultFormikInput
                                                name={'last_name'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>COMPANY NAME</p>
                                            <DefaultFormikInput
                                                name={'company'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                    </div>
                                    <div className={'modal-form__inputs'}>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>STREET</p>
                                            <DefaultFormikInput
                                                name={'street_address'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>PHONE</p>
                                            <DefaultFormikInput name={'phone'}
                                                                render={renderPhoneInput}
                                                                placeholder={''}
                                                                autocomplete={'off'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>EMAIL</p>
                                            <DefaultFormikInput
                                                name={'email'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                    </div>
                                    <div className={'modal-form__inputs'}>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>APARTMENT</p>
                                            <DefaultFormikInput
                                                name={'apartment_floor'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                        {customerFormMode && (
                                            <>
                                                <div className={'modal-form__input-container'}>
                                                    <p className={'modal-form__input-title'}>TELEGRAM</p>
                                                    <DefaultFormikInput
                                                        name={'telegram'}
                                                        placeholder={''}
                                                        autocomplete={'off'}
                                                        type={'text'}
                                                    ></DefaultFormikInput>
                                                </div>
                                                <div className={'modal-form__input-container'}>
                                                    <p className={'modal-form__input-title'}>INSTAGRAM</p>
                                                    <DefaultFormikInput
                                                        name={'instagram'}
                                                        placeholder={''}
                                                        autocomplete={'off'}
                                                        type={'text'}
                                                    ></DefaultFormikInput>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className={'modal-form__inputs'}>
                                        <div className={'modal-form__input-container error-relative'}>
                                            <p className={'modal-form__input-title'}>COUNTRY</p>
                                            <Field name={'country'} as="select">
                                                <option selected disabled value="">Select country</option>
                                                {Object.values(CountryTypes)
                                                    .sort((a, b) =>
                                                        (a[1] > b[1]) ? 1 : ((b[1] > a[1]) ? -1 : 0))
                                                    .sort((a, b) => a.localeCompare(b))
                                                    .map((state, index) => (
                                                        <option key={index} value={state}>{state}</option>))}
                                            </Field>
                                            <ErrorMessage name="country" component="div"
                                                          className="error-message custom-error"/>
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>CITY</p>
                                            <DefaultFormikInput
                                                name={'city'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container error-relative'}>
                                            <p className={'modal-form__input-title'}>STATE</p>
                                            <Field name={'state'} as="select">
                                                <option selected disabled value="">Select state</option>
                                                <option selected value="Other">Other</option>
                                                <option selected value="Unknown">Unknown</option>
                                                {Object.values(StateTypes)
                                                    .sort((a, b) => a.localeCompare(b))
                                                    .map((state, index) => (
                                                        <option key={index} value={state}>{state}</option>))}
                                            </Field>
                                            <ErrorMessage
                                                name="state"
                                                component="div"
                                                className="error-message custom-error"
                                            />
                                        </div>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>POSTAL CODE</p>
                                            <DefaultFormikInput
                                                name={'postal_code'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'text'}
                                            ></DefaultFormikInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {customerFormMode && (
                                <div className={'modal-form__container'}>
                                    <div className={'modal-form__header'}>
                                        <p className={'modal-form__header-title'}>Payment</p>
                                    </div>
                                    <div className={'modal-form__inputs'}>
                                        <div className={'modal-form__input-container'}>
                                            <p className={'modal-form__input-title'}>CUSTOMER CREDIT</p>
                                            <DefaultFormikInput
                                                name={'customer_credit'}
                                                placeholder={''}
                                                autocomplete={'off'}
                                                type={'number'}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'modal-form__input-container error-relative'}>
                                            <p className={'modal-form__input-title'}>PAYMENT METHOD</p>
                                            <Field name={'payment_method'} as="select">
                                                <option disabled value="">Select method</option>
                                                {Object.entries(PaymentMethods).map((key, index) => (
                                                    <option key={String(index) + key} value={key[0]}>{key[1]}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="payment_method" component="div"
                                                          className="error-message custom-error"/>
                                        </div>
                                        <div className={'modal-form__input-container error-relative'}>
                                            <p className={'modal-form__input-title'}>DELIVERY METHOD</p>
                                            <Field
                                                className={'customer-block__selector'}
                                                as={'select'}
                                                name={'delivery_method'}
                                            >
                                                <option disabled value="">Select method</option>
                                                {Object.keys(DeliveryMethods).map((key, index) => (
                                                    <option key={index} value={key}>
                                                        {DeliveryMethods[key as keyof typeof DeliveryMethods]}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="delivery_method" component="div"
                                                          className="error-message custom-error"/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={'modal-form__footer'}>
                            <div className={'modal-form__buttons-container'}>
                                <button className={'app-button app-button-red'}
                                        disabled={isSubmitting}
                                        type={'submit'}
                                >
                                    {editMode ? 'Edit' : 'Save'}
                                </button>
                                <button className={'app-button app-button-gray'}
                                        type={'button'}
                                        onClick={onClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </>
    )
}

export default CustomerOrVendorForm;
