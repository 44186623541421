import {identifyPendo} from './Pendo';
import {identifyLogRocket} from './Logrocket';
import {User} from "../models/User";

export const identify = (user: User) => {
    const {
        id,
        email,
        name,
        role_id,
        created_at,
        visible_name,
        company_name,
        domain,
        status,
        vendor_id
    } = user;

    identifyPendo({
        visitor: {
            id: id,
            email: email,
            full_name: name ?? visible_name,
            role: role_id,
            creation_date: created_at,
            company_name: company_name,
            domain: domain,
            status: status,
            vendor_id: vendor_id,
        }
    });

    identifyLogRocket(
        id.toString(),
        {
            email: email,
            name: name ?? visible_name,
            role: role_id,
            creation_date: created_at,
            company_name: company_name,
            domain: domain,
            status: status,
            vendor_id: vendor_id,
        }
    );
};
