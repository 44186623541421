export enum MappedEntitiesEnum {
    mapTicomboEvent = 'map_ticombo_event',
    unmapTicomboEvent = 'unmap_ticombo_event',
    mapTicomboInventory = 'map_ticombo_inventory',
    mapLtgEvent = 'map_ltg_event',
    unmapLtgEvent = 'unmap_ltg_event',
    mapLtgInventory = 'map_ltg_inventory',
    mapStubhubEvent = 'map_stubhub_event',
    unmapStubhubEvent = 'unmap_stubhub_event',
    mapStubhubSection = 'map_stubhub_section'
}
