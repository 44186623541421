export enum DeliveryMethods {
    OTHER = 'Other',
    MOBILE_DELIVERY = 'Mobile Transfer',
    OFFICE_PICKUP = 'Office Pickup',
    FEDEX = 'FedEx',
    PAPERLESS = 'Paperless',
    MEETANDGREET = 'Meet and Greet',
    USPS = 'USPS',
    FLASHSEATS = 'Flash Seats',
    COURIER = 'Courier',
    UPS = 'UPS',
    WILLCALL = 'Will Call',
    EMAIL = 'Email'
}
