import {AxiosResponse} from 'axios';
import {HttpSuccessResponse} from '../@api/Responses/HttpSuccessResponse';
import {HttpFailureResponse} from '../@api/Responses/HttpFailureResponse';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';

const createResponseByAxiosResponse = <Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, SR = any, FR = any>(
  res: AxiosResponse,
  validationMapper?: Record<string, string>,
): HttpSuccessResponse<Status, SR> | HttpFailureResponse<Status, FR> => {
  const status = res.data?.status ?? false;

  return status
    ? HttpSuccessResponse.createByAxiosResponse<Status, SR, FR>(res)
    : HttpFailureResponse.createByAxiosResponse<Status, SR, FR>(res, validationMapper);
};

export default createResponseByAxiosResponse;
