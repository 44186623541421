import * as Yup from "yup";

export interface Performer {
    id: number;
    name: string;
    category_type?: string;
    category_name?: string;
    percent: number;
}

export const PerformerFormValidationSchema = Yup.object().shape({
    percent: Yup.number().min(0, 'Too Short').max(100, 'Too Long'),
});
