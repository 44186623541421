import React, {FC} from 'react';
import Container from 'react-bootstrap/Container';
import './HeaderWrapper.scss';

interface Props {
    children: JSX.Element | JSX.Element[],
    classes?: string | null
}

const HeaderWrapper: FC<Props> = ({children, classes}) => {
    return (
        <div className={'header-wrapper' + ' ' + classes}>
            <header className={'header'}>
                <nav className="header__navbar">
                    <Container className={'header__container'}>
                        <div className="header__content">
                            {children}
                        </div>
                    </Container>
                </nav>
            </header>
        </div>
    );
};

export default HeaderWrapper;
