import Table from "react-bootstrap/Table";
import React, {FC} from "react";
import {P1Category} from "../../../../../models/Category";
import {update} from "../../../../../@api/Categories";
import {HttpFailureResponse} from "../../../../../@api/Responses/HttpFailureResponse";

interface CategoriesTableProps {
    categories: P1Category[];
}

const CategoriesTable: FC<CategoriesTableProps> = ({categories}) => {
    const tableHeaders = ['Name', 'Load'];

    const handleToLoad = (category_id: number, checked: boolean) => {
        update(category_id, {'load': checked}).then(res => {
            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        });
    }

    return (
        <Table className={"events-page__table"}>
            <thead className={"events-page__table__thead"}>
                <tr>
                    {tableHeaders.map((value) =>
                        <th className={"p1-events-page__th"}>{value}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {categories.map((category) =>
                    <tr key={'row' + category.id}>
                        <td className={"p1-events-page__td"}>{category.name}</td>
                        <td className={"p1-events-page__td"}>
                            <input name={'to_map'}
                                   type='checkbox'
                                   defaultChecked={category.load}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleToLoad(category.id, e.target.checked)} />
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default CategoriesTable;
