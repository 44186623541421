import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {PortalNotification} from "../models/PortalNotification";
import {PaginationParams} from "../@interfaces/PaginationParams";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";

const urlPrefix = 'portalNotification';

export const portalNotificationsPaginateRequest = (params: PaginationParams<PortalNotification>) => {
    params.perPage = params.perPage ? params.perPage : 5;
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<PortalNotification>>(
        axios.get(`${urlPrefix}`, {
            params: {...params, ...data}
        })
    );
};

export const sendReleaseNote = (data: FormData) => {
    const headers = {
        'content-type': 'multipart/form-data',
    };

    return requestWrapper<GlobalStatusCodesEnum>(
        axios.post(`${urlPrefix}/releaseNote`, data, {headers: headers})
    );
};

export const setNotificationViewStatus = (params: { ids: number[] }) => {
    return requestWrapper<GlobalStatusCodesEnum, any>(
        axios.patch(`${urlPrefix}/viewStatus`, params),
    );
}

export const getLastReleaseNotes = (params: { type?: string, limit?: number, order_direction?: 'DESC' | 'ASC' }) => {
    return requestWrapper<GlobalStatusCodesEnum, {
        items: PortalNotification[],
        viewedStatus: boolean
    }>(
        axios.get(`${urlPrefix}/portalNotifications`, {
            params: params
        }),
    );
}


