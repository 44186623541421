import ReactPaginate from "react-paginate";
import React from "react";
import {Dropdown} from "react-bootstrap";
import "./AppPagination.scss";
import {PAGINATION_PER_PAGE_OPTIONS} from "../../../constants/Constants";
import {Pagination} from "../../../helpers/UsePagination";

interface Props {
    pagination: Pagination<any>,
}

const AppPagination: React.FC<Props> = ({pagination}) => {
    const { perPage, currentPage, setPerPage, setCurrentPage } = pagination;

    const handlePageChange = (s: { selected: number }) => {
        const newPage = s.selected + 1;
        if (newPage === currentPage) return;
        setCurrentPage(newPage);
    };

    const handlePerPageChange = (v: number) => {
        setPerPage(v);
        setCurrentPage(1);
    }

    if (!(currentPage && pagination.lastPage && pagination.totalItems)) {
        return null;
    }

    return (
        <div className="app-pagination">
            <div className="app-pagination__limit_wrapper">
                <Dropdown className="app-pagination__limit">
                    <Dropdown.Toggle>{perPage}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {PAGINATION_PER_PAGE_OPTIONS.map((item) => (
                            <Dropdown.Item
                                key={item}
                                onClick={() => handlePerPageChange(item)}
                                className="user-sell-page__dropdown-item"
                            >
                                {item}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                Items per page
            </div>

            <ReactPaginate
                className="app-pagination__pages"
                pageCount={pagination.lastPage}
                forcePage={currentPage - 1}
                onPageChange={handlePageChange}
                nextClassName="d-none"
                previousClassName="d-none"
            />

            <div className="app-pagination__info">
                Showing {(currentPage - 1) * Number(perPage) + 1} - {(pagination.totalItems < Number(perPage) * currentPage) ? pagination.totalItems : Number(perPage) * currentPage} of {pagination.totalItems}
            </div>
        </div>
    )
};

export default AppPagination;
