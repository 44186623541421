import {Inventory} from "./Inventory";

export interface UserVendor {
    id: number
    user_id: number,
    inventory_id: number,
    display_name: string,
    email: string,
    company: string,
    city: string,
    state: string,
    phone: string,
    type: string,
    apartment_floor: string,
    country: string,
    postal_code: string,
    street_address: string
    vendor_tags: string[],
    sb_inventory: Inventory[],
    first_name: string,
    last_name: string,
    delivery_method: string,
    payment_method: string,
}

export interface UserVendorCreation extends Omit<UserVendor, 'phone' | 'user_id' | 'id' | 'inventory_id'> {
    phone: {
        phone_number: string
    },
}

export const vendorFieldsForForm = (vendor?: Omit<UserVendor, 'user_id' | 'id'>): UserVendorCreation => {
    return {
        display_name: vendor?.display_name || '',
        email: vendor?.email || '',
        phone: {
            phone_number: vendor?.phone || '',
        },
        type: vendor?.type || '',
        company: vendor?.company || '',
        street_address: vendor?.street_address || '',
        apartment_floor: vendor?.apartment_floor || '',
        city: vendor?.city || '',
        state: vendor?.state || '',
        postal_code: vendor?.postal_code || '',
        country: vendor?.country || '',
        vendor_tags: vendor?.vendor_tags || [],
        sb_inventory: vendor?.sb_inventory || [],
        first_name: vendor?.first_name || '',
        last_name: vendor?.last_name || '',
        delivery_method: vendor?.delivery_method || '',
        payment_method: vendor?.payment_method || ''
    }
}
