import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../models/User';
import {LocalStorageKeys} from '../../enums/LocalStorageKeys';
import {HiddenUser} from "../../models/HiddenUser";
import {ApiKey} from "../../models/ApiKey";
import {UserSettings} from "../../models/UserSettings";
import {Pool} from "../../models/Pool";

export interface AuthState {
    user: User | undefined;
    otpToken: string | undefined;
    pools?: Pool[];
    balance: number,
    hidden_users: HiddenUser[],
    settings: UserSettings | undefined
}

const initialState: AuthState = {
    user: undefined,
    otpToken: undefined,
    pools: undefined,
    balance: 0,
    hidden_users: [],
    settings: undefined
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{
            user: User,
            authToken: string,
            pools: Pool[],
            hidden_users: HiddenUser[],
            settings: UserSettings
        }>) => {
            state.user = action.payload.user;
            state.pools = action.payload.user.pools;
            state.hidden_users = action.payload.user.hidden_users
            localStorage.setItem(LocalStorageKeys.AuthToken, action.payload.authToken);
            state.otpToken = undefined;
            state.balance = action.payload.user.balance / 100
        },

        logout: (state) => {
            state.user = undefined;
            state.pools = undefined;
            localStorage.removeItem(LocalStorageKeys.AuthToken);
            state.balance = 0
            state.hidden_users = []
            state.settings = undefined
        },

        updateSettings: (state, action: PayloadAction<UserSettings>) => {
            state.user!.settings = action.payload;
        },

        updateUserApiKeys: (state, action: PayloadAction<{ api_keys: ApiKey }>) => {
            state.user!.api_keys = action.payload.api_keys;
        },

        setOtpToken: (state, action: PayloadAction<{ otpToken: string | undefined }>) => {
            state.otpToken = action.payload.otpToken;
        },

        balanceUpdate: (state, action: PayloadAction<{ balance: number }>) => {
            state.balance = action.payload.balance;
        },
    },
});

export const {login, logout, setOtpToken, balanceUpdate, updateUserApiKeys, updateSettings} = authSlice.actions;

export default authSlice.reducer;
