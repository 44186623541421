import React, {FC} from "react";
import {Customer} from "../../../../models/Customer";
import {dateToLocaleString} from "../../../../helpers/DateFormatter";
import {Invoice} from "../../../../models/Invoice";

interface Props {
    customer: Customer
}

const CustomerSalesModal: FC<Props> = ({customer}) => {
    return (
        <>
            <div className={'info-main'}>
                {customer?.invoices && customer?.invoices.length ? customer?.invoices?.map((invoice: Invoice) => (
                        <React.Fragment key={invoice.id}>
                            {invoice.inventory
                                ?
                                <div className={'info-container'}>
                                    <h5 style={{textAlign: 'center'}}>
                                        {invoice.inventory?.sb_event?.name}, {dateToLocaleString(invoice.inventory?.sb_event?.occurs_at)}
                                    </h5>
                                    <div className={'info-container_fields'}>
                                        <div>
                                            <h6>Inventory ID:</h6>
                                            <p>{invoice.inventory?.id}</p>
                                        </div>
                                        <div>
                                            <h6>Event ID:</h6>
                                            <p>{invoice.inventory?.sb_event?.id}</p>
                                        </div>
                                        <div>
                                            <h6>City:</h6>
                                            <p>{invoice.inventory?.sb_event?.city}</p>
                                        </div>
                                        <div className={'venue'} title={invoice.inventory?.sb_event?.venue}>
                                            <h6>Venue:</h6>
                                            <p>{invoice.inventory?.sb_event?.venue}</p>
                                        </div>
                                        <div>
                                            <h6>Quantity:</h6>
                                            <p>{invoice.inventory?.quantity}</p>
                                        </div>
                                        <div>
                                            <h6>Cost:</h6>
                                            <p>{invoice.inventory?.currency_icon} {invoice.inventory?.unit_amount}</p>
                                        </div>
                                        <div>
                                            <h6>Seat type:</h6>
                                            <p>{invoice.inventory?.seat_type}</p>
                                        </div>
                                        <div>
                                            <h6>Low seat:</h6>
                                            <p>{invoice.inventory?.low_seat}</p>
                                        </div>
                                        <div>
                                            <h6>High seat:</h6>
                                            <p>{invoice.inventory?.high_seat}</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </React.Fragment>
                    ))
                    :
                    <p>No sold inventories</p>
                }
            </div>
        </>
    )
}

export default CustomerSalesModal;
