export const downloadFile = async (fileName: string, href?: string) => {
    const baseURL = process.env.REACT_APP_API_URL + '/storage/files/';
    const link = document.createElement('a');

    link.href = href ? href : (baseURL + fileName);
    link.target = '_blank';
    link.download = fileName;
    link.click();
    link.remove();
}
