import {PaginationParams} from "../@interfaces/PaginationParams";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";

const urlPrefix = 'reports';

export const reportsPaginateRequest = (params: PaginationParams<any>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<any>>(
        axios.get(`${urlPrefix}`,
            {params: params}
        )
    );
}

export const amountMapReportsByAdmins = (params: PaginationParams<any>) => {
    params.perPage = params.perPage ? params.perPage : 10;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<any>>(
        axios.get(`${urlPrefix}/mappingStatistic`,
            {params: params}
        )
    );
}
