import {StubhubEvent} from "../../../../../models/StubhubEvent";
import React, {FC} from "react";
import {Field} from "formik";
import {SplitTypes} from "../../../../../enums/Stubhub/SplitTypes";
import {StockTypes} from "../../../../../enums/Stubhub/StockTypes";
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import "./StubhubParams.scss";
import {PaymentTypes} from "../../../../../enums/Stubhub/PaymentTypes";

interface Props {
    stubhub_event: StubhubEvent,
    mobileClass: { container: string }
}

const StubhubParams: FC<Props> = ({stubhub_event, mobileClass}) => {
    return (
        <div className="w-100">
            <h3>Stubhub Listing Mapping</h3>
            <div className="stubhub__wrapper">
                <div className={mobileClass.container}>
                    <label htmlFor="stubhub_section" className="add-inventory-form-label">Section</label>
                    <Field
                        id="stubhub_section"
                        name="stubhub_section"
                        as="select"
                        style={{display: 'block', backgroundColor: 'white'}}
                        className={'add-inventory-form-input stubhub__input'}
                    >
                        <option key={'stubhub_section'} value=''></option>
                        {stubhub_event.stubhub_sections?.map((item) => (
                            <option key={'stubhub_section' + item.name} value={item.name}>{item.name}</option>
                        ))}
                    </Field>
                </div>
                <div className={mobileClass.container}>
                <label htmlFor="stubhub_stock_type" className="add-inventory-form-label">Stock Type</label>
                    <Field
                        id="stubhub_stock_type"
                        name="stubhub_stock_type"
                        as="select"
                        style={{display: 'block', backgroundColor: 'white'}}
                        className={'add-inventory-form-input stubhub__input'}
                    >
                        <option key={'stubhub_stock_type'} value=''></option>
                        {stubhub_event.stock_types?.map((item) => (
                            <option key={'stubhub_stock_type' + item} value={item}>
                                {Object.keys(StockTypes)[Object.values(StockTypes).indexOf(item as unknown as StockTypes)]}
                            </option>
                        ))}
                    </Field>
                </div>
                <div className={mobileClass.container}>
                    <label htmlFor="stubhub_payment_type" className="add-inventory-form-label">Payment Type</label>
                    <Field
                        id="stubhub_payment_type"
                        name="stubhub_payment_type"
                        as="select"
                        style={{display: 'block', backgroundColor: 'white'}}
                        className={'add-inventory-form-input stubhub__input'}
                    >
                        <option key={'stubhub_payment_type'} value=''></option>
                        {Object.entries(PaymentTypes)?.map((item) => (
                            <option key={'stubhub_payment_type' + item[0]} value={item[1]}>{item[0]}</option>
                        ))}
                    </Field>
                </div>
                <div className={mobileClass.container}>
                    <label htmlFor="stubhub_split_type" className="add-inventory-form-label">Split Type</label>
                    <Field
                        id="stubhub_split_type"
                        name="stubhub_split_type"
                        as="select"
                        style={{display: 'block', backgroundColor: 'white'}}
                        className={'add-inventory-form-input stubhub__input'}
                    >
                        <option key={'stubhub_split_type'} value=''></option>
                        {Object.entries(SplitTypes)?.map((item) => (
                            <option key={'stubhub_split_type' + item[0]} value={item[1]}>{item[0]}</option>
                        ))}
                    </Field>
                </div>
                <div className={mobileClass.container}>
                    <label htmlFor="stubhub_split_quantity" className="add-inventory-form-label">
                        Split Quantity
                        <span className="add-inventory-form-hint">?
                            <span className="add-inventory-form-tooltip">
                                Only required if Split Option = Multuples. For example, if you have 12 tickets and you specify a value of 4 for this parameter, the tickets will be sold in batches of 4, 8, or 12.
                            </span>
                        </span>
                    </label>
                    <DefaultFormikInput
                        id="stubhub_split_quantity"
                        name="stubhub_split_quantity"
                        type="number"
                        placeholder="Split Quantity"
                        autoComplete={'on'}
                        class={'add-inventory-form-input stubhub__input'}
                    ></DefaultFormikInput>
                </div>
            </div>
        </div>
    );
};

export default StubhubParams;
