import {useSelector} from 'react-redux';
import React, {FC} from 'react';
import {RootState} from '../store/Store';

type Props = {
  children: JSX.Element,
};

const NotLoginRoute: FC<Props> = ({children}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  if (user) {
    return (<></>);
  }

  return children;
};

export default NotLoginRoute;
