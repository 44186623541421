import React, {FC} from "react";
import {UserVendor} from "../../../../models/UserVendor";
import {dateToLocaleString} from "../../../../helpers/DateFormatter";
import './PurchasedInventories.scss';

interface Props {
    vendor: UserVendor | null
}

const PurchasedInventories: FC<Props> = ({vendor}) => {
    return (
        <>
            <div className={'info-main'}>
                {vendor?.sb_inventory && vendor?.sb_inventory.length ? vendor?.sb_inventory.map((inventory) => (
                    <div className={'info-container'}>
                            <h5 style={{textAlign: 'center'}}>
                                {inventory.sb_event.name},
                                {dateToLocaleString(inventory.sb_event.occurs_at)}

                            </h5>
                        <div className={'info-container_fields'}>
                            <div>
                                <h6>Inventory ID:</h6>
                                <p>{inventory.inventory_id}</p>
                            </div>
                            <div>
                                <h6>Event ID:</h6>
                                <p>{inventory.sb_event.id}</p>
                            </div>
                            <div>
                                <h6>City:</h6>
                                <p>{inventory.sb_event.city}</p>
                            </div>
                            <div className={'venue'} title={inventory.sb_event.venue}>
                                <h6>Venue:</h6>
                                <p>{inventory.sb_event.venue}</p>
                            </div>
                            <div>
                                <h6>Quantity:</h6>
                                <p>{inventory.initial_quantity}</p>
                            </div>
                            <div>
                                <h6>Cost:</h6>
                                <p>{inventory.currency_icon} {inventory.unit_amount}</p>
                            </div>
                            <div>
                                <h6>Seat type:</h6>
                                <p>{inventory.seat_type}</p>
                            </div>
                            <div>
                                <h6>Low seat:</h6>
                                <p>{inventory.low_seat}</p>
                            </div>
                            <div>
                                <h6>High seat:</h6>
                                <p>{inventory.high_seat}</p>
                            </div>
                        </div>
                    </div>
                ))
                    :
                    <p>No purchased inventories</p>
                }
            </div>
        </>

    )
}

export default PurchasedInventories;
