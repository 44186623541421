import React, {FC, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import './InvoiceActionsPage.scss'
import AppButton from "../../ui/Buttons/AppButton/AppButton";
import {customerSearchByDisplayName} from "../../@api/Customers";
import {Customer} from "../../models/Customer";
import {getAllPossibleTags} from "../../@api/CustomTags";
import {HttpSuccessResponse} from "../../@api/Responses/HttpSuccessResponse";
import AppModal from "../../ui/Modal/AppModal/AppModal";
import {Inventory} from "../../models/Inventory";
import {useSelector} from "react-redux";
import {RootState} from "../../store/Store";
import {parseInt} from "lodash";
import {createInvoice, updateInvoice} from "../../@api/Invoice";
import InvoiceResultModal from "./InvoiceResultModal/InvoiceResultModal";
import {Invoice} from "../../models/Invoice";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {InvoiceValidationSchema} from "./InvoiceValidationSchema";
import AppTable from "../../ui/Table/AppTable/AppTable";
import AppDatePicker from "../../ui/Inputs/AppDatePicker/AppDatePicker";
import DefaultFormikInput from "../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {InvoiceStatusesEnum} from "../../enums/Skybox/InvoiceStatusesEnum";
import {PaymentMethods} from "../../enums/PaymentMethods";
import {DeliveryMethods} from "../../enums/DeliveryMethods";
import {StockTypes} from "../../enums/Skybox/StockTypes";
import AppSmartSearch from "../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {convert, getCurrencySymbol} from "../../helpers/CurrencyConverter";
import {dateToLocaleString, getTimeWithoutSeconds} from "../../helpers/DateFormatter";
import CustomerOrVendorForm from "../User/CustomersPage/CustomerOrVendorForm/CustomerOrVendorForm";
import calendarIcon from '../../assets/images/other/kalendar.svg'
import {currenciesArray} from "../../constants/Constants";

interface LocationState {
    inventoryData?: Inventory;
    customer?: Customer
}

const InvoiceActionsPage: FC<LocationState> = () => {
    const params = useParams();

    const location = useLocation();
    const {inventoryData} = location.state as LocationState;
    const {customer} = location.state as LocationState

    const enabledEditMode = params.id !== 'new';

    const invoiceInfoHeaders = [
        'ID',
        'INVOICE DATE',
        'CREATED BY',
        'DUE DATE',
        'FULFILLMENT STATUS',
        'INVOICE STATUS',
        'EXTERNAL REFERENCE'
    ];

    const invoiceLinesHeaders = [
        'ID',
        'DATE',
        'TIME',
        'EVENT NAME',
        'LOCATION',
        'TICKET TYPE',
        'LOW SEAT',
        'HIGH SEAT',
        'QTY',
        'CURRENCY',
        'UNIT PRICE',
        'TOTAL'
    ];

    const currentDate = new Date().toLocaleDateString();
    const eventDate = new Date(inventoryData!.sb_event.occurs_at).toLocaleDateString();
    const eventTime = getTimeWithoutSeconds(inventoryData!.sb_event.occurs_at)
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const navigate = useNavigate();

    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);
    const [tags, setTags] = useState<string[]>([]);
    const [showCreateCustomer, setShowCreateCustomer] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>(inventoryData!.currency_symbol);
    const [selectedQuantity, setSelectedQuantity] = useState<number | null>(inventoryData!.quantity);
    const [selectedLowSeat, setSelectedLowSeat] = useState<number | null>(inventoryData!.low_seat);
    const [selectedHighSeat, setSelectedHighSeat] = useState<number | null>(inventoryData!.high_seat);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>('PAYMENT METHOD');
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<string | undefined>('DELIVERY METHOD');
    const [customerEmail, setCustomerEmail] = useState<string | undefined>('');
    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [showResultModal, setShowResultModal] = useState<boolean>(false);
    const [resMessage, setResMessage] = useState<string | null>(null);
    const [invoiceData, setInvoiceData] = useState<Invoice | null>(null);
    const [copyRecipientEmail, setCopyRecipientEmail] = useState<string>('');
    const [customPrice, setCustomPrice] = useState<number | string>(enabledEditMode ? inventoryData!.invoice.amount : inventoryData!.unit_amount);
    const [caughtEntity, setCaughtEntity] = useState<any>(null);

    const formInitial = {
        due_date: new Date(),
        external_reference: enabledEditMode ? inventoryData?.invoice.external_reference : '',
        customer_display_name: selectedCustomer?.display_name,
        stock_type: inventoryData?.stock_type,
        fulfillment_status: enabledEditMode ? inventoryData?.invoice?.fulfillment_status : '',
        invoice_status: enabledEditMode ? inventoryData?.invoice?.invoice_status : '',
        quantity: selectedQuantity || 0,
        public_notes: enabledEditMode ? inventoryData?.invoice?.public_notes : '',
        internal_notes: enabledEditMode ? inventoryData?.invoice?.internal_notes : '',
        cc_recipient: '',
        price: customPrice
    };

    const createCustomer = async () => {
        setShowCreateCustomer(true);
        getAllPossibleTags().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handelCreateCustomerClose = (customer_id?: number) => {
        setShowCreateCustomer(false);
        return customer_id ?? null;
    };

    const handleResultModal = () => {
        setShowResultModal(false);
    }

    const handleLowSeatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLowSeat = parseInt(event.target.value);
        const newHighSeat = selectedHighSeat!;

        if (newLowSeat > newHighSeat) {
            setSelectedHighSeat(newLowSeat);
        }

        setSelectedLowSeat(newLowSeat);
    };

    const handleHighSeatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newHighSeat = parseInt(event.target.value);
        const newLowSeat = selectedLowSeat!;

        if (newHighSeat < newLowSeat) {
            setSelectedLowSeat(newHighSeat);
        }

        setSelectedHighSeat(newHighSeat);
    };

    useEffect(() => {
        setSelectedQuantity(selectedHighSeat! - selectedLowSeat! + 1)
    }, [selectedHighSeat, selectedLowSeat]);

    useEffect(() => {
        setSelectedCustomer(customer ?? selectedCustomer);
    }, [selectedCustomer]);

    useEffect(() => {
        setSelectedCustomer(caughtEntity);
    }, [caughtEntity]);

    useEffect(() => {
        setSelectedPaymentMethod(enabledEditMode ? String(inventoryData?.invoice.payment_method) : selectedCustomer?.payment_method);
        setSelectedDeliveryMethod(enabledEditMode ? String(inventoryData?.invoice.delivery_method) : selectedCustomer?.delivery_method);
        setCustomerEmail(enabledEditMode ? customer?.email : selectedCustomer?.email);
    }, [selectedCustomer, enabledEditMode, customer]);

    const createNewInvoice = async (data: any) => {
        setIsLoading(true);

        data.price = customPrice;

        const customerInput = document.getElementById('customer_input') as HTMLInputElement;

        if (customerInput.dataset.entityId) {
            data.customer_id = Number(customerInput.dataset.entityId);
        }

        if (!enabledEditMode) {
            if (sendEmail) {
                data.send_email = true;
                data.recipient = customerEmail;
                data.cc_recipient = copyRecipientEmail;
            }

            const res = await createInvoice(data, inventoryData!);

            setIsLoading(false);

            if (res instanceof HttpSuccessResponse) {
                setInvoiceData(res.data.invoice);
                setResMessage(res.message);
            }

            setShowResultModal(true);
        }

        data.customer_email = selectedCustomer?.email;
        data.customer_name = selectedCustomer?.display_name;
        data.amount = Number(customPrice);

        if (sendEmail) {
            data.send_email = true;
            data.recipient = customerEmail;
            data.cc_recipient = copyRecipientEmail
        }

        const res = await updateInvoice(data, inventoryData!.invoice.id);

        setIsLoading(false);

        if (res instanceof HttpSuccessResponse) {
            setResMessage(res.message);
            setInvoiceData(res.data.invoice);
        }

        setShowResultModal(true);
    };

    const catchNewEntity = (item: any) => {
        setCaughtEntity(item);
        setCustomerEmail(item.email);
        setSelectedPaymentMethod(item.payment_method);
        setSelectedDeliveryMethod(Object.keys(DeliveryMethods)[Object.values(DeliveryMethods).indexOf(DeliveryMethods.EMAIL)]);
    };

    const handleNavigateBack = () => {
        navigate(-1);
    };

    const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newTotal = convert(selectedCurrency, e.target.value, Number(customPrice), currencies);

        setSelectedCurrency(e.target.value);
        setCustomPrice(newTotal);
    };

    return (
        <>
            <div className={'invoice-actions-container'}>
                <h1 className={'admin-users-page__title'}>
                    {enabledEditMode ? 'Edit Invoice' : 'New Invoice'}
                </h1>
                <div className={'invoice-info'}>
                    <Formik
                        validationSchema={InvoiceValidationSchema}
                        initialValues={formInitial}
                        onSubmit={async (values) => {
                            const data = {
                                ...values,
                                payment_method: selectedPaymentMethod,
                                delivery_method: selectedDeliveryMethod,
                                seats: selectedLowSeat + '-' + selectedHighSeat,
                                quantity: selectedQuantity,
                                currency: selectedCurrency,
                            }
                            await createNewInvoice(data)
                        }}
                    >
                        {({values}) =>
                            <Form className={'invoice-form'}>
                                <AppTable
                                    withAutoHeight={false}
                                    customTableWrapperClass={'no-radius-table-border'}
                                    columns={invoiceInfoHeaders}
                                >
                                    <tr>
                                        <td>{enabledEditMode ? inventoryData?.invoice.id : 'New'}</td>
                                        <td>{enabledEditMode ? dateToLocaleString(inventoryData!.invoice.date) : currentDate}</td>
                                        <td>{enabledEditMode ? inventoryData?.invoice?.owner?.name : ''}</td>
                                        <td className={'due-date-container'}>
                                            <AppDatePicker
                                                name='due_date'
                                                placeholder={''}
                                                dateFormat={'MM/dd/yyyy'}
                                            />
                                            <img src={calendarIcon} alt={'calendar-icon'}></img>
                                        </td>
                                        <td className={'error-relative'}>
                                            <Field name={'fulfillment_status'} as={'select'}>
                                                {!enabledEditMode && (<option></option>)}
                                                <option>Pending</option>
                                                <option>Completed</option>
                                            </Field>
                                            <ErrorMessage name="fulfillment_status" component="div"
                                                          className="invoice-custom-error error-message mt-0"/>
                                        </td>
                                        <td className={'error-relative'}>
                                            <Field name={'invoice_status'} as={'select'}>
                                                {!enabledEditMode && (<option></option>)}
                                                {Object.keys(InvoiceStatusesEnum).map((key, index) => (
                                                    <option key={index} value={key}>
                                                        {InvoiceStatusesEnum[key as keyof typeof InvoiceStatusesEnum]}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="invoice_status" component="div"
                                                          className="invoice-custom-error error-message mt-0"/>
                                        </td>
                                        <td>
                                            <DefaultFormikInput
                                                name="external_reference"
                                                type="string"
                                                autocomplete="off"
                                            />
                                        </td>
                                    </tr>
                                </AppTable>
                                <div className={'customer-block'}>
                                    <div className={'customer-block__customer-info'}>
                                        <p className={'customer-block__header'}>Customer</p>
                                        <div className={'customer-block__search'}>
                                            <AppSmartSearch name={'customer_display_name'} placeholder={'Customer name'}
                                                            inputId={'customer_input'}
                                                            showIcon={true}
                                                            initialInputItem={customer ? customer : selectedCustomer}
                                                            showErrorOnFocus={false}
                                                            requestFunction={customerSearchByDisplayName}
                                                            setDataFromRequest={setSelectedCustomer}
                                                            valuesKey={'display_name'}
                                                            customClass={'invoice-create_customer-input'}
                                            />
                                            <button className="app-button app-button-gray" type="button"
                                                    onClick={() => createCustomer()}>
                                                CREATE NEW CUSTOMER
                                            </button>
                                            <div className={'customer-block__selectors'}>
                                                <Field
                                                    className={'customer-block__selector'}
                                                    as={'select'}
                                                    name={'payment_method'}
                                                    value={selectedPaymentMethod}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSelectedPaymentMethod(event.target.value)}
                                                >
                                                    <option disabled>PAYMENT METHOD</option>
                                                    {Object.keys(PaymentMethods).map((key, index) => (
                                                        <option key={index} value={key} disabled={!selectedCustomer}>
                                                            {PaymentMethods[key as keyof typeof PaymentMethods]}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <Field
                                                    className={'customer-block__selector'}
                                                    as={'select'}
                                                    name={'delivery_method'}
                                                    value={selectedDeliveryMethod}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSelectedDeliveryMethod(event.target.value)}
                                                >
                                                    <option disabled>DELIVERY METHOD</option>
                                                    {Object.keys(DeliveryMethods).map((key, index) => (
                                                        <option key={index} value={key} disabled={!selectedCustomer}>
                                                            {DeliveryMethods[key as keyof typeof DeliveryMethods]}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'customer-block__billing-info'}>
                                        <p className={'customer-block__header'}>Billing info</p>
                                        <div className={'customer-block__billing-data'}>
                                            {selectedCustomer ? (
                                                <>
                                                    <p>Country: {selectedCustomer.country}</p>
                                                    <p>State: {selectedCustomer.state}</p>
                                                    <p>Street: {selectedCustomer.street_address}</p>
                                                    <p>Postal Code: {selectedCustomer.postal_code}</p>
                                                    <p>Email: {selectedCustomer.email}</p>
                                                    <p>Payment method:
                                                        {PaymentMethods[selectedCustomer.payment_method as keyof typeof PaymentMethods]
                                                        }
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className={'invoice-lines'}>
                                    <p className={'customer-block__header'}>Invoice lines</p>
                                    <AppTable
                                        withAutoHeight={false}
                                        customTableWrapperClass={'no-radius-table-border'}
                                        columns={invoiceLinesHeaders}
                                    >
                                        <>
                                            <tr>
                                                <td>{enabledEditMode ? inventoryData?.sb_inventory_id : inventoryData?.id}</td>
                                                <td>{eventDate}</td>
                                                <td>{eventTime}</td>
                                                <td>{inventoryData?.sb_event?.name}</td>
                                                <td>{inventoryData?.sb_event?.venue}</td>
                                                <td>{StockTypes[inventoryData?.stock_type as keyof typeof StockTypes]}</td>

                                                <td>
                                                    {enabledEditMode
                                                        ? inventoryData?.low_seat
                                                        :
                                                        <Field
                                                            name={'low_seat'}
                                                            as={'select'}
                                                            onChange={handleLowSeatChange}
                                                            value={selectedLowSeat}
                                                        >
                                                            {Array.from({length: inventoryData!.quantity}, (v, k) => inventoryData!.low_seat + k)
                                                                .map((seat, index) => (
                                                                    <option key={index} value={seat}>{seat}</option>
                                                                ))
                                                            }
                                                        </Field>}
                                                </td>
                                                <td>
                                                    {enabledEditMode
                                                        ? inventoryData?.high_seat
                                                        :
                                                        <Field
                                                            name={'high_seat'}
                                                            as={'select'}
                                                            onChange={handleHighSeatChange}
                                                            value={selectedHighSeat}
                                                        >
                                                            {Array.from({length: inventoryData!.quantity}, (v, k) => inventoryData!.low_seat + k)
                                                                .map((seat, index) => (
                                                                    <option key={index} value={seat}>{seat}</option>
                                                                ))
                                                            }
                                                        </Field>
                                                    }
                                                </td>
                                                <td>
                                                    {selectedQuantity}
                                                </td>
                                                <td>
                                                    {enabledEditMode
                                                        ? inventoryData!.invoice?.currency_code
                                                        :
                                                        <Field name={'currency'} as="select"
                                                               value={selectedCurrency}
                                                               onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                   handleCurrencyChange(e)
                                                               }}>
                                                            {currenciesArray.map(currency => (
                                                                <option key={currency} value={currency}>
                                                                    {currency}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    }
                                                </td>
                                                <td>
                                                    <div className={'d-flex'}>
                                                        <DefaultFormikInput
                                                            onInput={(e: React.FormEvent<HTMLInputElement>) => setCustomPrice((e.target as HTMLInputElement).value)}
                                                            value={customPrice}
                                                            name="price"
                                                            placeholder="Price"
                                                            autocomplete={'off'}
                                                            type={'number'}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                </td>
                                                <td>
                                                    {enabledEditMode
                                                        ? `${getCurrencySymbol(inventoryData!.invoice?.currency_code, currencies)} ${(Number(customPrice) * selectedQuantity!).toFixed(2)}`
                                                        : `${getCurrencySymbol(selectedCurrency, currencies)}${(Number(customPrice) * selectedQuantity!).toFixed(2)}`
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={invoiceLinesHeaders.length - 1}>
                                                    <p className={'invoice-total-line'}>
                                                        Invoice Total:
                                                    </p>
                                                </td>
                                                <td>
                                                    {enabledEditMode
                                                        ? `${getCurrencySymbol(inventoryData!.invoice?.currency_code, currencies)} ${(Number(customPrice) * selectedQuantity!).toFixed(2)}`
                                                        : `${getCurrencySymbol(selectedCurrency, currencies)}${(Number(customPrice) * selectedQuantity!).toFixed(2)}`
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    </AppTable>
                                </div>
                                <div className={'notes-block'}>
                                    <p className={'customer-block__header'}>Notes</p>
                                    <div className={'notes-block__container'}>
                                        <div className={'notes-block__container-item'}>
                                            <p>PUBLIC NOTES</p>
                                            <Field as={'textarea'} name={'public_notes'} placeholder={''}/>
                                        </div>
                                        <div className={'notes-block__container-item'}>
                                            <p>INTERNAL NOTES</p>
                                            <Field as={'textarea'} name={'internal_notes'} placeholder={''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'email-block'}>
                                    <p className={'customer-block__header'}>Send Invoice e-mail</p>
                                    <div className={'email-block__inputs'}>
                                        <div className={'email-block__item'}>
                                            <p>SEND TO</p>
                                            <DefaultFormikInput
                                                name={'recipient'}
                                                placeholder={'email@email.com'}
                                                value={customerEmail}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className={'email-block__item'}>
                                            <p>CC</p>
                                            <DefaultFormikInput
                                                name={'cc_recipient'}
                                                value={copyRecipientEmail}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCopyRecipientEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'invoice-buttons'}>
                                    <div className={'invoice-buttons__item'}>
                                        <AppButton text={enabledEditMode ? 'Update' : 'Create'} color={'red'}
                                                   type={"submit"} disabled={isLoading}/>
                                    </div>
                                    <div className={'invoice-buttons__item'} onClick={() => setSendEmail(true)}>
                                        <AppButton text={enabledEditMode ? 'Update and send' : 'Create and send'}
                                                   type={"submit"} color={'red'} disabled={isLoading}/>
                                    </div>
                                    <div className={'invoice-buttons__item'} onClick={handleNavigateBack}>
                                        <AppButton text={'Cancel'} color={'gray'} type="reset" disabled={isLoading}/>
                                    </div>
                                </div>
                            </Form>
                        }
                    </Formik>
                </div>
            </div>
            <AppModal title="Create Customer"
                      centered={true}
                      size={'lg'}
                      closeButton={true}
                      form={
                          <CustomerOrVendorForm
                              handleEntity={catchNewEntity}
                              tags={tags}
                              selectedTags={selectedTags}
                              customer={undefined}
                              setSelectedTags={setSelectedTags}
                              onClose={handelCreateCustomerClose}
                          />
                      }
                      show={showCreateCustomer} showHandle={handelCreateCustomerClose} onClose={() => {
            }}/>
            <AppModal
                size={'sm'}
                title={''}
                centered={true}
                headerStyle={{display: 'none'}}
                backdrop={'static'}
                keyboard={false}
                form={
                    <InvoiceResultModal
                        isEditMode={enabledEditMode}
                        invoiceData={invoiceData}
                        recipient={customerEmail}
                        cc_recipient={copyRecipientEmail}
                        resMessage={resMessage}
                        isEmailSent={sendEmail}
                    />
                }
                show={showResultModal}
                showHandle={handleResultModal}
                onClose={() => {
                }}/>
        </>
    )
}

export default InvoiceActionsPage;
