import {PaginationParams} from "../@interfaces/PaginationParams";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {LTGSection} from "../models/Section";
import {FormikValues} from "formik";
import {IHttpSuccessResponse} from "./Responses/HttpSuccessResponse";

export const ltgSectionsPaginateRequest = (params: PaginationParams<LTGSection>) => {
    params.perPage = 500;

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<LTGSection>>(
        axios.get('/integrations/ltg/sections', {
            params: params,
        })
    );
}

export const updateLTGSection = (id: number, data: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, IHttpSuccessResponse<GlobalStatusCodesEnum, LTGSection>>(
        axios.put(`/integrations/ltg/sections/${id}`, data),
    );
};
