import React, {FC} from "react";
import {Invoice} from "../../../models/Invoice";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import './InvoiceResultModal.scss'
import {useNavigate} from "react-router-dom";

interface Props {
    resMessage: string | null,
    isEmailSent?: boolean,
    recipient?: string,
    cc_recipient?: string
    invoiceData: Invoice | null,
    isEditMode?: boolean
}

const InvoiceResultModal: FC<Props> = (
    {
        resMessage,
        isEmailSent,
        invoiceData,
        recipient,
        cc_recipient,
        isEditMode
    }) => {

    const navigate = useNavigate();

    const handleNavigateForward = () => {
        navigate('/user/invoices')
    };

    const handleNavigateBack = () => {
        navigate(-1);
    };

    return (

        <div className={'invoice-status-modal'}>
            {resMessage === 'Success' ? (
                <>
                    <p>
                        Invoice №{invoiceData?.id} was
                        {isEditMode ? ' updated ' : ' created '}
                        successfully {isEmailSent && `and sent to ${recipient}${cc_recipient ? `, ${cc_recipient}!` : ''}`}
                    </p>
                    <div className={'invoice-status-modal__buttons'}>
                        <div className={'invoice-status-modal__button-item'} onClick={handleNavigateForward}>
                            <AppButton text={'View Invoice'} color={'red'}/>
                        </div>
                        <div className={'invoice-status-modal__button-item'} onClick={handleNavigateBack}>
                            <AppButton text={'Dismiss'} color={'gray'}/>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        Your invoice couldn't be {isEditMode ? 'updated' : 'generated'}. Please contact our support team
                        at
                        sales@vipeventsteam.com
                        for assistance.
                    </p>
                    <div className={'invoice-status-modal__buttons'}>
                        <div className={'invoice-status-modal__button-item'} onClick={handleNavigateBack}>
                            <AppButton text={'Dismiss'} color={'gray'}/>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default InvoiceResultModal;
