import requestWrapper from './RequestWrapper';
import RequestWrapper from './RequestWrapper';
import axios from 'axios';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {FormikValues} from "formik";
import {Inventory} from "../models/Inventory";
import {Inventory as SbInventory} from "../@interfaces/Skybox/Inventory"
import {PaginationParams} from "../@interfaces/PaginationParams";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import {TicomboParamsForMap} from "../@interfaces/TicomboParamsForMap";
import {LtgParamsForMap} from "../@interfaces/LtgParamsForMap";
import {QuoteBuilderData} from "../@interfaces/QuoteBuilderData";
import {SbHold} from "../models/SbHold";
import {SbEvent} from "../models/Event";

const urlPrefix = 'inventories';

export const sellInventory = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${id}/sell`, params),
    );
};

export const cancelInventory = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${id}/cancel`),
    );
};

export const splitInventory = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${id}/split`, params),
    );
};

export const createHold = (id: number, params: any) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/${id}/hold`, params),
    );
};

export const buy = (id: number, quantity: number, selectedCurrency: string, convertedCost: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { event: SbEvent, inventory: Inventory }>(
        axios.post(`${urlPrefix}/${id}/buy`, {quantity, selectedCurrency, convertedCost})
    )
};

export const mergeInventory = (ids: number[] | string[]) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/merge`, {ids}),
    );
};

export const loadFile = (file: FormData) => {
    const headers = {
        'content-type': 'multipart/form-data',
    };

    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/bulk-add`, file, {headers: headers}),
    );
};

export const duplicateInventory = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/` + id + '/duplicate', params),
    );
};

export const getInventoryById = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { inventory: Inventory }>(
        axios.get(`${urlPrefix}/` + id),
    );
}

export const setLtgSection = (id: number, data: { ltg_location_category_id?: number, ltg_section_id?: number }) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post(`${urlPrefix}/${id}/setLtgParams`, data),
    );
}

export const getSoldInventories = (params: PaginationParams<Inventory>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Inventory>>(
        axios.get(`${urlPrefix}/sold`, {
            params: {...data, ...params},
        }),
    );
};

export const getNeededInventory = (params: PaginationParams<SbInventory>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<SbInventory>>(
        axios.get(`${urlPrefix}/needed`, {
            params: {...data, ...params},
        }),
    );
};

export const mapped = (params: PaginationParams<Inventory>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Inventory>>(
        axios.get(`${urlPrefix}/mapped`, {
            params: {...params},
        }),
    );
};

export const mapInventory = (id: number, payload: { ticombo?: TicomboParamsForMap, ltg?: LtgParamsForMap }) => {
    return requestWrapper<GlobalStatusCodesEnum, Inventory>(
        axios.post(`${urlPrefix}/${id}/map/`, {
            ...payload
        }),
    );
};

export const postInventoriesDownload = (data: QuoteBuilderData[] | undefined, notes: string) => {
    return axios.post<Blob | MediaSource>(`${urlPrefix}/feed/download`, {data, notes}, {responseType: 'blob'});
};

export const getOccupiedSeats = (data: { eventId: number, section: string, row: string }) => {
    return requestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}/occupied-seats`, {
            params: {...data}
        }),
    );
};

export const getInventoriesForEvent = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Inventory>(
        axios.get(`${urlPrefix}/eventInventories`, {params: id})
    )
};

export const holdsPaginate = (params: PaginationParams<SbHold>) => {
    const data = paginationParamsForAxios(params);
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<SbHold>>(axios.get(`${urlPrefix}/paginated`, {
        params: {...data, ...params},
    }));
};

export const uploadTicketFile = (files: FormData) => {
    const headers = {
        'content-type': 'multipart/form-data',
    };

    return requestWrapper<GlobalStatusCodesEnum>(
        axios.post<{
            pageNumber: number,
            pdfUrl: string
        }[]>(`${urlPrefix}/uploadTicketFiles`, files, {headers: headers})
    );
};

export const getSoldLocalInventories = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { inventories: Inventory[] }>(
        axios.get(`${urlPrefix}/soldInventories`, {params: {'id': id}})
    );
};

export const getOwnLocalInventories = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { inventories: Inventory[] }>(
        axios.get(`${urlPrefix}/ownInventories`, {params: {'id': id}})
    );
};

export const getPurchasedInventories = (params: PaginationParams<Inventory>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}/purchased`, {
            params: params
        })
    );
};

export const getPaginatedTransferredInventories = (params: PaginationParams<Inventory>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}/transferred`, {
            params: params
        })
    );
};

export const approveTransfer = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, { inventory: Inventory }>(
        axios.post(`${urlPrefix}/${id}`)
    );
};

export const getInventoriesForExcel = () => {
    return RequestWrapper<GlobalStatusCodesEnum, { inventories: Inventory[] }>(
        axios.get(`${urlPrefix}/purchasedForExcelExport`)
    );
};

export const getInventoriesByEvent = (eventId?: number) => {
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}?event=${eventId || 'null'}`)
    );
};

export const makeOffer = (id: number, values: FormikValues) => {
    return RequestWrapper<GlobalStatusCodesEnum, { inventory: Inventory }>(
        axios.post(`${urlPrefix}/${id}/offerNeeded`, {...values})
    );
};

export const requestToAddEvent = (id: number, values: FormikValues) => {
    return RequestWrapper<GlobalStatusCodesEnum, { inventory: Inventory }>(
        axios.post(`${urlPrefix}/${id}/integration/request-to-add-event`, {...values})
    );
};

