import React, {FC} from "react";
import Table from "react-bootstrap/Table";
import {Bids, bidStatusToString} from "../../../../../models/Bids";
import './BidsTable.scss';
import {BidStatus} from "../../../../../enums/BidStatus";
import {heightTableStyle} from "../../../../../helpers/SpecificStyles";
import {convert, getCurrencySymbol} from "../../../../../helpers/CurrencyConverter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";
import AppLoader from "../../../../../ui/Loader/AppLoader/AppLoader";

interface Props {
    bids: Bids[] | undefined
    bidsRequest: () => void
    received?: boolean
    onReject?: (bid: Bids) => void;
    onAccept?: (bid: Bids) => void;
    btnDisabled: boolean
}

const BidsTable: FC<Props> = ({bids, received, onAccept, onReject}) => {
    const currencies = useSelector((state: RootState) => state.currency.currencies);

    return (
        <>

            {typeof bids !== 'undefined' ?
                <div className={'bids-table'} style={heightTableStyle('bids-table')}>
                    <Table className={'bids-table_main'}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Event</th>
                            <th>Section</th>
                            <th>Row</th>
                            <th>Stock Type</th>
                            <th>Notes</th>
                            <th>Original Price</th>
                            <th>Bid</th>
                            <th>Quantity</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {bids && bids.length ? bids.map((bid) => (
                                <tr>
                                    <td>{bid.id}</td>
                                    <td>{bidStatusToString(bid.status)}</td>
                                    <td>{bid.sb_inventories.sb_event.name}</td>
                                    <td>{bid.sb_inventories.section}</td>
                                    <td>{bid.sb_inventories.row}</td>
                                    <td>{bid.sb_inventories.stock_type}</td>
                                    <td>{bid.notes || ''}</td>
                                    <td>{bid.sb_inventories.currency_icon} {bid.sb_inventories.unit_amount}</td>
                                    <td>
                                        {getCurrencySymbol(bid.currency, currencies) || bid.sb_inventories?.currency_symbol}
                                        {convert(bid.sb_inventories.currency_symbol, bid.currency, bid.price, currencies)}
                                    </td>
                                    <td>{bid.quantity}</td>
                                    <td>
                                        {getCurrencySymbol(bid.currency, currencies) || bid.sb_inventories?.currency_symbol}
                                        {bid.quantity * bid.price}
                                    </td>
                                    {received && bid.status === BidStatus.Active ?
                                        <td>
                                            <div className={'actions-container'}>
                                                <button type={"button"} onClick={() => onAccept && onAccept(bid)}
                                                        className={'actions-container_action actions-container_action-accept'}>Accept
                                                </button>
                                                <button type={"button"} onClick={() => onReject && onReject(bid)}
                                                        className={'actions-container_action actions-container_action-reject'}>Reject
                                                </button>
                                            </div>
                                        </td>
                                        :
                                        <td></td>
                                    }
                                </tr>
                            ))
                            : <tr>
                                <td colSpan={100}>No bids</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </div>
                :
                <AppLoader/>
            }
        </>
    )
}

export default BidsTable;
