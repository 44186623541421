import {FC, useEffect, useState} from "react";
import {LTGEvent} from "../../../models/Event";
import usePagination from "../../../helpers/UsePagination";
import {LTGSection} from "../../../models/Section";
import {LTGLocationCategory} from "../../../models/Category";
import {ltgEventsPaginateRequest} from "../../../@api/Event";
import {ltgSectionsPaginateRequest, updateLTGSection} from "../../../@api/Section";
import {ltgLocationCategoriesPaginateRequest, updateLTGLocationCategory} from "../../../@api/Categories";
import {dateToLocaleString} from "../../../helpers/DateFormatter";

interface LTGDeliveryFormProps {
    sbEventId?: number;
    alias: string;
    isSubmitting: boolean;
}

const LTGDeliveryForm: FC<LTGDeliveryFormProps> = ({sbEventId, alias, isSubmitting}) => {
    const paginationEvent = usePagination<LTGEvent>(ltgEventsPaginateRequest);
    const paginationSection = usePagination<LTGSection>(ltgSectionsPaginateRequest);
    const paginationLocationCategory = usePagination<LTGLocationCategory>(ltgLocationCategoriesPaginateRequest);

    const [ltgLocationCategoryId, setLTGlocationCategoryId] = useState<number | null>();
    const [ltgSectionId, setLTGSectionId] = useState<number | null>();

    const getSections = (ltgLocationCategoryId: number) => {
        setLTGlocationCategoryId(ltgLocationCategoryId);
        setLTGSectionId(null);

        if (!ltgLocationCategoryId) {
            paginationSection.setItems([]);
            return;
        }

        paginationSection.paginate({
            ltg_venue_id: paginationEvent.items[0].ltg_venue.id,
            ltg_location_category_id: ltgLocationCategoryId,
        });
    };

    const getNewAlias = (currentAlias?: string) => {
        return currentAlias ? currentAlias + '###' + alias : alias
    }

    useEffect(() => {
        paginationEvent.setItems([]);
        paginationEvent.paginate({sb_event_id: sbEventId});
    }, [sbEventId]);

    useEffect(() => {
        if (paginationEvent.items.length) {
            paginationLocationCategory.paginate({
                ltg_venue_id: paginationEvent.items[0].ltg_venue.id
            });
        }
    }, [paginationEvent.items.length]);

    useEffect(() => {
        if (!isSubmitting) {
            return;
        }

        if (ltgSectionId) {
            const section = paginationSection.items.find(i => i.id === ltgSectionId);
            const newSectionAlias = getNewAlias(section?.alias)

            updateLTGSection(ltgSectionId, {alias: newSectionAlias});
        }

        if (ltgLocationCategoryId) {
            const category = paginationLocationCategory.items.find(i => i.id === ltgLocationCategoryId);
            const newCategoryAlias = getNewAlias(category?.alias);

            updateLTGLocationCategory(ltgLocationCategoryId, {alias: newCategoryAlias});
        }
    }, [isSubmitting]);

    if (!paginationEvent.items?.length) {
        return null;
    }

    return <div className="w-100">
        <h3 className="add-inventory-event-title">Live Ticket Group Delivery</h3>

        <form className="add-inventory-form">
            <div className="d-flex flex-column w-100">
                <label className="add-inventory-form-label">Event</label>
                <select className={'add-inventory-form-input d-block'}>
                    <option value="">
                        {paginationEvent.items[0].name}
                    </option>
                </select>

                <label className="add-inventory-form-label mt-3">Venue</label>
                <select className={'add-inventory-form-input d-block'}>
                    <option value="">
                        {paginationEvent.items[0].ltg_venue.name} {dateToLocaleString(paginationEvent.items[0].start_date_time)}
                    </option>
                </select>
            </div>

            <div className="d-flex flex-column flex-grow-1">
                <label className="add-inventory-form-label">Location category</label>
                <select name={'ltg_location_category_id'}
                    style={{display: 'block', backgroundColor: 'white'}}
                    className={'add-inventory-form-input'}
                    onChange={(e) => getSections(Number(e.target.value))}
                >
                    <option></option>
                    {
                        paginationLocationCategory.items?.map(item => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                </select>
            </div>

            <div className="d-flex flex-column flex-grow-1">
                <label className="add-inventory-form-label">Section</label>
                <select name={'ltg_section_id'}
                    style={{display: 'block', backgroundColor: 'white'}}
                    className={'add-inventory-form-input'}
                    onChange={(e) => setLTGSectionId(Number(e.target.value))}
                >
                    <option value=""></option>
                    {
                        paginationSection.items?.map(item => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                </select>
            </div>
        </form>
    </div>
}

export default LTGDeliveryForm;