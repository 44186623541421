import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import DefaultFormikInput from '../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import './ApiKeys.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/Store';
import { updateApiKeys } from '../../../../../@api/User';
import { HttpSuccessResponse } from '../../../../../@api/Responses/HttpSuccessResponse';
import { updateUserApiKeys } from '../../../../../store/Reducers/Auth';
import { integrations } from '../../../../../constants/Integrations';
import { IIntegration } from '../../../../../@interfaces/IIntegration';
import { IntegrationsEnum } from '../../../../../enums/IntegrationsEnum';

const ApiKeys: FC = () => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const [formValues, setFormValues] = useState<IIntegration[]>([]);
    const dispatch = useDispatch();

    const initFormValues = () => {
        const activeIntegrations = integrations.filter((integration: IIntegration) => {
            const hasOwnTab = [IntegrationsEnum.Ticombo].includes(integration.key);
            return !hasOwnTab && (loggedUser!.active_integrations ? loggedUser!.active_integrations.includes(integration.key) : false);
        });

        setFormValues(activeIntegrations);
    };

    useEffect(() => {
        initFormValues();
    }, []);

    const paymentValues = [
        {name: 'paypal_client_id', placeholder: 'Paypal Client ID'},
        {name: 'paypal_secret', placeholder: 'Paypal Secret'},
        {name: 'stripe_client_id', placeholder: 'Stripe Client ID'},
        {name: 'stripe_secret', placeholder: 'Stripe Secret'},
    ];

    const formInitial = {
        viagogo: loggedUser!.api_keys?.viagogo || '',
        stubhub: loggedUser!.api_keys?.stubhub || '',
        seatsnet: loggedUser!.api_keys?.seatsnet || '',
        paypal_client_id: loggedUser!.api_keys?.paypal_client_id || '',
        paypal_secret: loggedUser!.api_keys?.paypal_secret || '',
        stripe_client_id: loggedUser!.api_keys?.stripe_client_id || '',
        stripe_secret: loggedUser!.api_keys?.stripe_secret || '',
    };

    const updateKeys = async (userId: number, values: FormikValues) => {
        try {
            const res = await updateApiKeys(values);

            if (res instanceof HttpSuccessResponse) {
                dispatch(updateUserApiKeys({
                    api_keys: res.data
                }));

                alert('Update Successful');
            }
        } catch (error) {
            console.error('Error updating API keys:', error);
        }
    };

    return (
        <div className={'api-keys settings-tab-width-api-keys'}>
            <Formik
                initialValues={formInitial}
                onSubmit={(values) => {
                    updateKeys(loggedUser!.id, values);
                }}
            >
                <Form className="filters-form ">
                    <div className={'form-fields'}>
                        <div className={'field'}>
                            <h5>Payment Keys</h5>
                            {
                                paymentValues.map(value => (
                                    <>
                                        <div className={'api-input'}>
                                            <p className={'api-keys_input-name'}>{value.placeholder}:</p>
                                            <DefaultFormikInput
                                                name={value.name}
                                                placeholder={value.placeholder}
                                                autocomplete={'off'}
                                                class={'filters-search'}
                                            ></DefaultFormikInput>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                        {!!formValues.length && (
                            <div className={'field'}>
                                <h5>API Keys</h5>
                                {
                                    formValues.map((formValue, i) => (
                                        <React.Fragment key={`api-key-${i}-${formValue.key}`}>
                                            <div className={'api-input'}>
                                                <p className={'api-keys_input-name'}>{formValue.inputName}:</p>
                                                <DefaultFormikInput
                                                    name={formValue.key}
                                                    placeholder={formValue.placeholder}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                    <button type={'submit'} className="auth-wrapper__submit-btn self-align">Update</button>
                </Form>
            </Formik>
        </div>
    );
};

export default ApiKeys;
