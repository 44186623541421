import * as Yup from "yup";
import {appSmartSearchRule} from "../../../helpers/YupRules";

export const holdFormValidation = Yup.object().shape({
    quantity: Yup.string().required('Quantity is required'),
    customer_name: Yup.string().required('Customer is required').test(appSmartSearchRule('customer_input')).nullable(),
    expiry_date: Yup.date()
        .min(new Date(), 'Invalid date or time selected. Please choose a valid date and time. ')
        .required('Expiry date is required'),
});

export const holdEditFormValidation = Yup.object().shape({
    customer_name: Yup.string().required('Customer is required').test(appSmartSearchRule('customer_input')).nullable(),
    expiry_date: Yup.date()
        .min(new Date(), 'Invalid date or time selected. Please choose a valid date and time. ')
        .required('Expiry date is required'),
});
