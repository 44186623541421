export enum IntegrationInvoicesEnum {
    ticombo = 'ticomboInvoice',
    ltg = 'ltgInvoices',
    viagogo = 'viagogoInvoice'
}

export enum IntegrationInvoicesTitleEnum {
    ticomboInvoice = 'Ticombo',
    ltgInvoice = 'Ltg',
    viagogoInvoice = 'Viagogo'
}
