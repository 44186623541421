import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUserTicomboAccount} from "../../models/IUserTicomboAccount";

export interface TicomboState {
    account: IUserTicomboAccount;
}

const initialState: TicomboState = {
    account: {
        api_key: '',
        is_valid: false
    }
};

export const ticomboAccountSlice = createSlice({
    name: 'ticombo',
    initialState,
    reducers: {
        setUserTicomboAccount: (state, action: PayloadAction<IUserTicomboAccount>) => {
            state.account = action.payload;
        },
        resetUserTicomboAccount: (state) => {
            state.account = initialState.account;
        },
    },
});

export const {setUserTicomboAccount, resetUserTicomboAccount} = ticomboAccountSlice.actions;

export default ticomboAccountSlice.reducer;
