import React, {FC} from "react";
import {User} from "../models/User";
import {Navigate} from "react-router-dom";
import {RolesEnum} from "../enums/RolesEnum";

type Props = {
    user: User | undefined,
    children: JSX.Element,
};

const EuroRoute: FC<Props> = ({children, user}) => {
    if (user?.role_id === RolesEnum.Admin || user?.role_id === RolesEnum.SuperAdmin) {
        return children;
    }

    if (!user?.sb_vendor?.euro_vendor || user?.sb_vendor?.euro_vendor?.pause) {
        Navigate({to: '/'});
        return (<></>);
    }

    return children;
};

export default EuroRoute;
