import {FC, useEffect, useState} from "react";
import {Inventory} from "../../../../models/Inventory";
import {getPurchasesNotPaginated} from "../../../../@api/PurchaseOrders";
import './SelectPurchaseOrdersForm.scss';
import {dateToLocaleDateString} from "../../../../helpers/DateFormatter";

interface Props {
    onOrderIdClick: (orderId: number) => void;
}

const SelectPurchaseOrderForm: FC<Props> = ({onOrderIdClick}) => {
    const [orders, setOrders] = useState<Inventory[] | null>(null)

    useEffect(() => {
        fetchOrders()
    }, []);

    const handleOrderIdClick = (orderId: number) => {
        onOrderIdClick(orderId);
    };

    const fetchOrders = async () => {
        await getPurchasesNotPaginated().then((res) => {
            setOrders(res.data.orders);
        });
    };

    return (
        <>
            <div className={'purchase-orders__container'}>
                {orders && orders.length ?
                    orders.filter(
                        (inventory, index, self) =>
                            index === self.findIndex((inv) => inv.purchase_orders?.id === inventory.purchase_orders?.id)
                    ).map((order, index) => (
                        <>
                            <div
                                onClick={() => handleOrderIdClick(order.purchase_orders?.id)}
                                key={order.id + index}
                                className={'purchase-orders__item'}
                            >
                                <div className='purchase-orders__info-container'>
                                    <div className={'purchase-orders__info-block'}>
                                        <p className={'purchase-orders__info-header'}>ID</p>
                                        <p>{order.purchase_orders?.id || '-'}</p>
                                    </div>
                                    <div className={'purchase-orders__info-block'}>
                                        <p className={'purchase-orders__info-header'}>DUE DATE</p>
                                        <p>{dateToLocaleDateString(order.purchase_orders?.due_date) || '-'}</p>
                                    </div>
                                    <div className={'purchase-orders__info-block'}>
                                        <p className={'purchase-orders__info-header'}>VENDOR</p>
                                        <p>{order.purchase_orders?.user_vendor?.display_name || '-'}</p>
                                    </div>
                                    <div className={'purchase-orders__info-block'}>
                                        <p className={'purchase-orders__info-header'}>CREATED BY</p>
                                        <p>{order.purchase_orders?.user?.name || '-'}</p>
                                    </div>
                                    <div className={'purchase-orders__info-block'}>
                                        <p className={'purchase-orders__info-header'}>Public notes</p>
                                        <p>{order.public_notes || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                    : <h4>No purchase orders</h4>
                }
            </div>
        </>
    )
}

export default SelectPurchaseOrderForm;
