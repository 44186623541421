import {FC} from "react";
import {Container, Tab, Tabs} from "react-bootstrap";
import "./VenuesPage.scss";
import LTGVenuesTab from "../../../components/VenuesPage/LTGVenuesTab/LTGVenuesTab";

const VenuesPage: FC = () => {
    return (
        <Container className={'admin-users-page venues'}>
            <h1 className={'admin-users-page__title'}>Venues</h1>
            <Tabs defaultActiveKey={'ltg_venues'}>
                <Tab title={'LiveTicketGroup'} eventKey={'ltg_venues'}>
                    <LTGVenuesTab />
                </Tab>
            </Tabs>
        </Container>
    )
}

export default VenuesPage;