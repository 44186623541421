import {PaginationParams} from "../@interfaces/PaginationParams";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {Vendor, VendorEdit} from "../models/Vendor";

const urlPrefix = 'vendors';

export const vendorsPaginateRequest = (params: PaginationParams<Vendor>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Vendor>>(axios.get(`${urlPrefix}`, {
        params: {...data, ...params},
    }));
};

export const vendorsSetPercentRequest = (id: number, params: any) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${id}/edit`, params),
    );
};

export const getVendorsByPartOfName = (params: { sb_vendor: string }) => {
    return requestWrapper<GlobalStatusCodesEnum, Vendor>(axios.get(`${urlPrefix}/get-vendors-by-name`, {
        params: params
    }))
};

export const vendorsNotPaginated = (name: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Vendor[]>(
        axios.get(`${urlPrefix}/not-paginated`, {
            params: {
                name
            }
        })
    );
};

export const updateVendor = (values: VendorEdit, id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Vendor>(
        axios.patch(`${urlPrefix}/${id}`, {...values, id})
    )
}

export const citiesNotPaginated = (city: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Vendor[]>(
        axios.get(`${urlPrefix}/searchCity`, {
            params: {
                city
            }
        })
    );
};

