export const dateToLocaleString = (date: string | null | undefined) => {
    if (!date) return '';

    return date ? new Date(date).toLocaleString() : '';
}

export const dateToLocaleDateString = (date: string) => {
    return date ? new Date(date).toLocaleDateString() : '';
}

export const getTimeWithoutSeconds = (date: string) => {
    if (!date) return '';

    const eventTimeDate = new Date(date);
    const eventHours = eventTimeDate.getHours();
    const eventMinutes = eventTimeDate.getMinutes();
    const eventAmPm = eventHours >= 12 ? 'PM' : 'AM';
    const eventHours12 = eventHours % 12 || 12;

    return `${eventHours12}:${eventMinutes < 10 ? '0' : ''}${eventMinutes} ${eventAmPm}`;
}
