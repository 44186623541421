import React, {FC, FormEvent, useEffect, useState} from "react";
import {Accordion} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import useWindowWidth from "../../../hooks/useHandleWindowResize";
import {EuroEvent} from "../../../models/Euro/EuroEvent";
import {EuroCategory} from "../../../models/Euro/EuroCategory";
import {
    getEuroCalendarData,
    getEuroStaticData,
} from "../../../@api/Euro/Euro";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {EuroQuantityCombination} from "../../../models/Euro/EuroQuantityCombination";
import Calendar from "./components/Calendar/Calendar";
import "./CalendarPage.scss";
import {QUANTITY_COMBINATIONS} from "../../../constants/Euro";
import {CalendarResponseDataType} from "../../../@interfaces/Euro/CalendarResponseDataType";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";


const CalendarPage: FC = () => {
    const formInitial = {
        event_id: '',
        category_id: '',
        inventory_id: '',
        quantity: '',
        price: '',
        seats: ''
    };

    const windowWidth = useWindowWidth();

    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [euroEvents, setEuroEvents] = useState<EuroEvent[]>([]);
    const [euroCategories, setEuroCategories] = useState<EuroCategory[]>([]);
    const [quantityCombination, setQuantityCombination] = useState<EuroQuantityCombination[]>([]);
    const [calendarData, setCalendarData] = useState<CalendarResponseDataType | null>(null);
    const [filterValue, setFilterValue] = useState<{ event: string, category: string, quantity: string }>({
        event: '',
        category: '',
        quantity: ''
    })

    useEffect(() => {
        getStaticData();
        getCalendarData();
    }, [])

    const getStaticData = async () => {
        let params = {only_available_events: loggedUser!.role_id === 3}
        const response = await getEuroStaticData(params);

        if (response instanceof HttpSuccessResponse) {
            setEuroEvents(response.data.events);
            setEuroCategories(response.data.categories);
            setQuantityCombination(response.data.quantity_combinations);
        }
    }

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const changeFilter = async (event: FormEvent<HTMLInputElement>, type: string) => {
        const target = event.target as HTMLInputElement;

        (filterValue as any)[type] = target.value;
        setFilterValue({...filterValue})
        await getCalendarData();
    }

    const getCalendarData = async () => {
        let queryParams = {
            event_id: filterValue.event,
            category: filterValue.category,
            quantity_combination: filterValue.quantity
        }

        const response = await getEuroCalendarData(queryParams);

        if (response instanceof HttpSuccessResponse) {
            setCalendarData(response.data.calendar)
        }

        return;
    }


    return (
        <>
            {
                isOpenSidebar && windowWidth <= 1024
                    ? (
                        <div className="sidebar-overlay"></div>
                    )
                    : null
            }
            <div className="filters-sidebar">
                <div className="filters-sidebar-toggle" onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                    {isOpenSidebar ? <span>&#171;</span> : <span>&#187;</span>}
                </div>
                <div className={isOpenSidebar ? 'filters filters-expand' : 'filters-collapse'}>
                    {
                        isOpenSidebar
                            ? <>
                                <Accordion defaultActiveKey="0" onSelect={toggleAccordion}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="filters-accordion-header-euro-calendar">
                                            Filters
                                        </Accordion.Header>
                                        <hr/>
                                        <Accordion.Body className="filters-accordion-body">
                                            <Formik initialValues={formInitial}
                                                    onSubmit={() => {
                                                    }}>
                                                <Form className={'invoice-form-viagogo'}>
                                                    <div className={'invoice-form-viagogo_container w-100'}>
                                                        <div className={'form-viagogo-inputs_item invoice-input'}>
                                                            <Field name={'event_id'} as="select"
                                                                   onChange={(event: any) => changeFilter(event, 'event')}
                                                                   defaultValue={filterValue.event}
                                                                   value={filterValue.event}
                                                            >
                                                                <option value={''}>Choose event</option>
                                                                {
                                                                    euroEvents.map(event => (
                                                                        <option key={event.id}
                                                                                value={event.id}>{event.sb_event.name}</option>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'form-viagogo-inputs_item invoice-input'}>
                                                            <Field name={'category_id'} as="select"
                                                                   onChange={(event: any) => changeFilter(event, 'category')}
                                                                   value={filterValue.category}
                                                            >
                                                                <option value={''}>Choose category</option>

                                                                {
                                                                    euroCategories.map(category => (
                                                                        <option key={category.id}
                                                                                value={category.id}>{category.name}</option>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </div>

                                                        <div className={'form-viagogo-inputs_item invoice-input'}>
                                                            <Field name={'category_id'} as="select"
                                                                   onChange={(event: any) => changeFilter(event, 'quantity')}
                                                                   value={filterValue.quantity}
                                                            >
                                                                <option value={''}>Choose quantity of tickets</option>

                                                                {
                                                                    quantityCombination.map((combination, index) => (
                                                                        <option key={combination.id}
                                                                                value={combination.id}>{QUANTITY_COMBINATIONS[index]}</option>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Formik>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </>
                            : <></>
                    }
                </div>
            </div>
            <div className={'calendar-container'}>
                {
                    calendarData !== null ?
                        <Calendar data={calendarData}/>
                        : null
                }
            </div>
        </>
    );
};


export default CalendarPage;
