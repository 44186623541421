import React, {FC, FormEvent, useEffect, useRef, useState} from "react";
import {Inventory} from "../../../../../models/Inventory";
import {HttpSuccessResponse} from "../../../../../@api/Responses/HttpSuccessResponse";
import {ErrorMessage, Field, Form, Formik} from "formik";
import './CreateInvoiceForViagogoForm.scss'
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {EuroEvent} from "../../../../../models/Euro/EuroEvent";
import {getEuroStaticData, getInventoriesForViagogoInvoice} from "../../../../../@api/Euro/Euro";
import {EuroCategory} from "../../../../../models/Euro/EuroCategory";
import {ViagogoInvoiceValidationSchema} from "./ViagogoInvoiceValidationSchema";
import {createViagogoInvoice} from "../../../../../@api/Invoice";
import {setSeatsOptions} from "../../../../../helpers/InvoiceHelper";

interface Props {
    inventory?: Inventory
    handleClose: () => void;
}

const CreateInvoiceForViagogoForm: FC<Props> = ({inventory, handleClose}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [wantedQuantity, setWantedQuantity] = useState<Number>(0);
    const [euroEvents, setEuroEvents] = useState<EuroEvent[]>([]);
    const [euroCategories, setEuroCategories] = useState<EuroCategory[]>([]);
    const [sbInventories, setSbInventories] = useState<Inventory[]>([]);
    const [params, setParams] = useState<{ event: string, category: string, price: string }>({
        event: '',
        category: '',
        price: ''
    })
    const [chosenInventory, setChosenInventory] = useState<string>('')


    const formInitial = {
        event_id: '',
        category_id: '',
        inventory_id: '',
        quantity: '',
        price: '',
        seats: ''
    };

    useEffect(() => {
        getStaticData();
    }, [])

    const getStaticData = async () => {
        const response = await getEuroStaticData();

        if (response instanceof HttpSuccessResponse) {
            setEuroEvents(response.data.events);
            setEuroCategories(response.data.categories)
        }
    }

    const createNewViagogoInvoice = async (values: any) => {
        const inventory = sbInventories.filter((inventory: Inventory) => {
            return inventory.id == Number(chosenInventory);
        })[0];

        const data = {
            quantity: values.quantity,
            seats: values.seats,
            price: values.price,
            viagogo_invoice: true,
        }

        setIsLoading(true);

        await createViagogoInvoice(data, inventory);

        setIsLoading(false);
        handleClose();
    };

    const chooseInventory = async (event: FormEvent<HTMLSelectElement>) => {
        const target = event.target as HTMLSelectElement;
        setChosenInventory(target.value);

        let quantityInput = document.getElementsByName("quantity")[0] as HTMLInputElement;
        setSeatsForSplit(quantityInput, target.value);
    }
    const changeEvent = async (event: FormEvent<HTMLInputElement>, type: string) => {
        const target = event.target as HTMLInputElement;

        (params as any)[type] = target.value;
        setParams({...params})
        await getInventories();
    }

    const getInventories = async () => {
        if (!params.event || !params.category || !params.price) {
            return;
        }

        let queryParams = {
            event_id: params.event,
            category: params.category,
            price: params.price
        }

        const response = await getInventoriesForViagogoInvoice(queryParams);

        if (response instanceof HttpSuccessResponse) {
            setChosenInventory('');
            setWantedQuantity(0);
            setSbInventories(response.data.inventories)
        }
    }


    const onQuantityChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const quantityInput = document.getElementsByName("quantity")[0] as HTMLInputElement;
        let quantityValue = Number(target.value);

        const chosenInv = sbInventories.find(inventory => inventory.id === Number(wantedQuantity));

        if (quantityValue < 0) {
            quantityValue = 1;
        } else if (chosenInv && quantityValue > chosenInv.quantity) {
            quantityValue = chosenInv.quantity
        }

        quantityInput.value = String(quantityValue);

        setWantedQuantity(quantityValue)
        setSeatsForSplit(target, chosenInventory);
    }

    const setSeatsForSplit = (wantedQuantityInput: HTMLInputElement, inventoryId: string) => {
        let quantityValue = Number(wantedQuantityInput.value);

        const seatsSelectHTML = document.getElementsByName("seats")[0] as HTMLSelectElement;
        while (seatsSelectHTML.options.length > 0) {
            seatsSelectHTML.remove(0);
        }

        if (!inventoryId || !quantityValue) {
            return;
        }

        const inventory = sbInventories.find((inventory: Inventory) => {
            return inventory.id == Number(inventoryId);
        });

        if (!inventory) {
            return
        }

        const quantity = (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity) ? inventory.shown_quantity : inventory.quantity;
        seatsSelectHTML.add(new Option("", ""));

        if (quantityValue < 1) {
            wantedQuantityInput.value = "1";
            quantityValue = 1;
        } else if (quantityValue > quantity) {
            wantedQuantityInput.value = String(quantity);
            quantityValue = quantity;
            setWantedQuantity(quantityValue)
        }

        setSeatsOptions(quantityValue, inventory, seatsSelectHTML);
    }

    return (
        <>
            <Formik initialValues={formInitial}
                    validationSchema={ViagogoInvoiceValidationSchema}
                    onSubmit={(values) => {
                        createNewViagogoInvoice({...values});
                    }}>
                <Form className={'invoice-form-viagogo'}>
                    <div className={'invoice-form-viagogo_container'}>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Events</p>
                            <Field name={'event_id'} as="select"
                                   onChange={(event: any) => changeEvent(event, 'event')}
                                   defaultValue={params.event}
                                   value={params.event}
                            >
                                <option value={''} disabled hidden>Event</option>
                                {
                                    euroEvents.map(event => (
                                        <option key={event.id}
                                                value={event.sb_event_id}>{event.sb_event.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Categories</p>
                            <Field name={'category_id'} as="select"
                                   onChange={(event: any) => changeEvent(event, 'category')}
                                   value={params.category}
                            >
                                <option value={''} disabled hidden>Category</option>

                                {
                                    euroCategories.map(category => (
                                        <option key={category.id}
                                                value={category.name}>{category.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Price</p>
                            <div className={'form-viagogo-inputs_item invoice-input'}>
                                <DefaultFormikInput
                                    name="price"
                                    style={{width: '240px'}}
                                    type="number"
                                    placeholder="Price"
                                    autocomplete="off"
                                    onInput={(event: any) => changeEvent(event, 'price')}
                                ></DefaultFormikInput>
                            </div>
                        </div>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Inventories</p>
                            <Field name={'inventory_id'} as="select"
                                   onChange={(event: any) => chooseInventory(event)}
                                   value={chosenInventory}
                            >
                                <option value={''} disabled hidden>Inventory</option>
                                {
                                    sbInventories.map(inventory => (
                                        <option key={inventory.id} value={inventory.id}>
                                            {'ID: ' + inventory.id + ' - Qt: ' + inventory.quantity + ' - ' + inventory.internal_notes}
                                        </option>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="inventory_id"
                                          render={msg => <div className={'error-message mt-1'}>{msg}</div>}/>
                        </div>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Wanted Quantity</p>
                            <div className={'form-viagogo-inputs_item invoice-input'}>
                                <DefaultFormikInput
                                    name="quantity"
                                    style={{width: '240px'}}
                                    type="number"
                                    placeholder="Enter wanted quantity"
                                    autocomplete="off"
                                    onInput={onQuantityChange}
                                    value={wantedQuantity}
                                ></DefaultFormikInput>
                            </div>
                        </div>
                        <div className={'form-viagogo-inputs_item invoice-input'}>
                            <p className={'form-inputs_item-header'}>Seats</p>
                            <Field as="select" className="form-control" name="seats"></Field>
                            <ErrorMessage name="seats"
                                          render={msg => <div className={'error-message mt-1'}>{msg}</div>}/>
                        </div>
                    </div>
                    <button disabled={isLoading}
                            type={"submit"} className={'filters-button-apply invoice-btn mt-3'}>Create Invoice
                    </button>
                </Form>
            </Formik>
        </>
    );
}

export default CreateInvoiceForViagogoForm;
