import React, {FC, useEffect, useState} from "react";
import {deletePool, getPools} from "../../../../@api/Pools";
import {Pool} from "../../../../models/Pool";
import './PoolsControl.scss';
import {HttpSuccessResponse} from "../../../../@api/Responses/HttpSuccessResponse";

const PoolsControl: FC = () => {

    const [pools, setPools] = useState<Pool[]>([]);
    const [selectedPool, setSelectedPool] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchPools()
    }, []);

    const fetchPools = async () => {
        const res = await getPools();
        setPools(res.data);
    }

    const deleteSelectedPool = async (poolId: number) => {
        setSelectedPool(poolId)
        setIsLoading(true);
        const res = await deletePool(poolId);
        setIsLoading(false)

        if (res instanceof HttpSuccessResponse) {
            await fetchPools();
        }
    }

    return (
        <div className={'pool-container'}>
            {pools.map((pool) => (
                <>
                    <div className={'pool-control'}>
                        <p>{pool.name}</p>
                        <button
                            className={'pool-control_button'}
                            onClick={() => deleteSelectedPool(pool.id)}
                            disabled={isLoading}>
                            Delete Pool
                        </button>
                    </div>
                </>
            ))}
        </div>
    )
}

export default PoolsControl
