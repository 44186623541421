import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {Customer} from "../models/Customer";

const urlPrefix = 'tags'
export const getAllPossibleTags = () => {
    return RequestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}`)
    );
};

export const getCustomerTags = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}/${id}`)
    );
};

export const setCustomerTags = ( customer_tags: string[], id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, Customer>(
        axios.put(`${urlPrefix}/${id}`, {customer_tags})
    )
}
