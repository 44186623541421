import React from 'react';
import {Inventory} from '../../../../../../models/Inventory';
import './EventIntegrations.scss';
import {EventIntegration} from './EventIntegration';
import {IntegrationsEnum} from '../../../../../../enums/IntegrationsEnum';
import {EventIntegrationCustomPrice} from '../../../../../../models/EventIntegrationCustomPrice';

type Props = {
    inventory: Inventory;
    integrationType?: IntegrationsEnum;
    onUpdatedCallback?: () => void;
}
export const EventIntegrations: React.FC<Props> = ({inventory, integrationType, onUpdatedCallback}) => {
    const integrationCustomPrices: EventIntegrationCustomPrice[] = inventory.integration_custom_price ?? [];

    if (!inventory.id) {
        return null;
    }

    if (!integrationCustomPrices.length) {
        return (<>Listing is processing.</>);
    }

    return (
        <>
            {integrationCustomPrices
                .filter(i => !integrationType || i.integration_type === integrationType)
                .map(integration=> (
                <EventIntegration
                    showLabel={!integrationType}
                    key={`${inventory.id}-integration-form-${integration.integration_type}`}
                    inventory={inventory}
                    currency={integration.currency || 'USD'}
                    customPrice={integration.custom_price}
                    integrationPrice={integration.integration_price}
                    integrationType={integration.integration_type}
                    integrationStatus={integration.status}
                    onUpdatedCallback={onUpdatedCallback}
                ></EventIntegration>
                ))
            }
        </>
    );
};
