import React, {FC, useEffect, useState} from "react";
import {Inventory} from "../../../../../../@interfaces/Skybox/Inventory";
import './NeedListForm.scss';
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import AppButton from "../../../../../../ui/Buttons/AppButton/AppButton";
import {NeedListFormValidation} from "./NeedListFormValidation";
import {makeOffer} from "../../../../../../@api/Inventory";
import {HttpSuccessResponse} from "../../../../../../@api/Responses/HttpSuccessResponse";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store/Store";
import DefaultFormikInput from "../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";

interface Props {
    inventory: Inventory | null;
    onClose: () => void;
}

const NeedListForm: FC<Props> = ({inventory, onClose}) => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);

    const [isUserLogged, setIsUserLogged] = useState<boolean>(false);

    useEffect(() => {
        if (loggedUser !== undefined) {
            setIsUserLogged(true);
        }
    }, [loggedUser]);

    const formInitial = {
        message: '',
        name: '',
        email: '',
        phone: ''
    };

    const handleSubmit = async (values: FormikValues) => {
        const res = await makeOffer(inventory!.id, values);

        if (res instanceof HttpSuccessResponse) {
            alert('Your offer to the VIP Events Team has been successfully sent.')
            onClose();
        } else {
            alert(res.message)
        }
    };

    return (
        <>
            {inventory && (
                <div className={'needed-inventory__modal'} key={inventory.id + inventory.event.id}>
                    <div className={'needed-inventory__header'}>
                        <div className={'needed-inventory__header-row'}>
                            <p>Event</p>
                            <p>Date</p>
                            <p>Section</p>
                            <p>Quantity</p>
                        </div>
                        <div className={'needed-inventory__header-row'}>
                            <p>{inventory.event.name}</p>
                            <p>{inventory.event.date.split('T')[0]} {inventory.event.date.split('T')[1]}</p>
                            <p>{inventory.section}</p>
                            <p>{inventory.quantity}</p>
                        </div>
                        <p>Please describe your offer below, including desired price:</p>
                    </div>
                    <Formik
                        validationSchema={NeedListFormValidation(isUserLogged)}
                        initialValues={formInitial}
                        onSubmit={(values) => handleSubmit(values)}
                    >
                        {({isSubmitting, values}) =>
                            <Form className={'needed-inventory__form'}>
                                <Field as="textarea" name={'message'} placeholder={''}/>
                                <ErrorMessage name={'message'} component="div"
                                              className="error-message"/>

                                {!loggedUser && (
                                    <div className={'unauthorized-fields'}>
                                        <div>
                                            <label>Name</label>
                                            <DefaultFormikInput
                                                name={'name'}
                                                type={'text'}
                                            />
                                        </div>
                                        <div>
                                            <label>Email</label>
                                            <DefaultFormikInput
                                                name={'email'}
                                                type={'text'}
                                            />
                                        </div>
                                        <div>
                                            <label>Phone/WhatsApp</label>
                                            <DefaultFormikInput
                                                name={'phone'}
                                                type={'text'}
                                            />
                                        </div>
                                    </div>
                                )}
                                < AppButton type={'submit'} text={'Send an offer'} color={'red'}
                                            disabled={isSubmitting}/>
                            </Form>
                        }
                    </Formik>
                </div>
            )}
        </>
    )
}

export default NeedListForm;
