export const addPendoScriptsToHead = () => {
    if (process.env.REACT_APP_ENV !== 'production' || !process.env.REACT_APP_PENDO_API_KEY) {
        return;
    }

    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.innerHTML = `
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[]; v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${process.env.REACT_APP_PENDO_API_KEY}');
    `
    head.insertBefore(script, head.firstChild);
    initializePendo();
}


export const initializePendo = (params: any = {visitor: {id: 'VISITOR-UNIQUE-ID'}}) => {
    if (process.env.REACT_APP_ENV !== 'production' || !process.env.REACT_APP_PENDO_API_KEY) {
        return;
    }

    try {
        (<any>window).pendo.initialize(params);
    } catch (e) {
        console.error(e);
    }
}

export const identifyPendo = (params: any = {visitor: {id: 'VISITOR-UNIQUE-ID'}}) => {
    if (process.env.REACT_APP_ENV !== 'production' || !process.env.REACT_APP_PENDO_API_KEY) {
        return;
    }

    try {
        (<any>window).pendo.identify(params);
    } catch (e) {
        console.error(e);
    }
}
