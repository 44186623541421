import './DefaultSpinner.scss';
import React, {FC} from "react";

// size - 10px, 2rem etc
interface Props {
  size?: string | number;
  thickness?: string | number;
}

const DefaultSpinner: FC<Props> = ({size, thickness = 5}) => {
    if (Number.isFinite(size)) {
      size = `${size}px`;
    }
    if (Number.isFinite(thickness)) {
      thickness = `${thickness}px`;
    }

    const height = size ?? '40px';
    const width = size ??'40px';
    const borderWidth = thickness ??'5px';

    return (
        <div className="default-spinner">
            <span className="default-spinner__spinner"
                  style={{
                      borderWidth,
                      height,
                      width,
            }}
            ></span>
        </div>
    );
}

export default DefaultSpinner;
