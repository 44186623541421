import getFirstStringFromArray from './GetFirstStringFromArray';

const normalizeValidationResponse = (validation: any, validationMapper?: Record<string, string>): Record<string, string> | undefined => {
  if (typeof validation !== 'object' || validation === null || Array.isArray(validation)) {
    return undefined;
  }

  return Object.entries(validation).reduce((acc, [key, value]) => {
    key = (validationMapper as any)?.[key] ?? key;

    const valueType = typeof value;

    if (valueType === 'string') {
      (acc as any)[key] = value;
      return acc;
    }

    if (Array.isArray(value)) {
      (acc as any)[key] = getFirstStringFromArray(value);
    }

    return acc;
  }, {});
};

export default normalizeValidationResponse;
