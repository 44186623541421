import React, {FC, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import "./BuyPage.scss";
import {clearInventory, InventoryData, setInventory} from "../../../store/Reducers/Inventory";
import {clearFilter} from "../../../store/Reducers/Filters";
import {Tab, Tabs} from "react-bootstrap";
import {Form, Formik} from "formik";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import TableEventBlock from "../SellPage/components/TableEventBlock/TableEventBlock";
import WantedTickets from "./components/WantedTickets/WantedTickets";
import {getInventoriesForEvent} from "../../../@api/Inventory";
import useWindowWidth from "../../../hooks/useHandleWindowResize";
import {heightTableStyle} from "../../../helpers/SpecificStyles";
import {MOBILE_WIDTH} from "../../../constants/Layout";
import MatchCard from "../SellPage/components/MatchCard/MatchCard";
import useHandleSbEventsPaginationScroll from "../../../hooks/useHandleSbEventsPaginationScroll";
import {useDynamicSortOptions} from "../../../hooks/useDynamicSortOptions";
import AppDatePicker from "../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {SbEvent} from "../../../models/Event";
import {EventGroupType, SbEventsFilters} from "../../../@interfaces/SbEventsFilters";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {performersNotPaginated} from "../../../@api/Performer";
import {omit} from "lodash";
import {venuesNotPaginated} from "../../../@api/Venue";
import {setPreferredCurrencySetting} from "../../../@api/User";
import {updateSettings} from "../../../store/Reducers/Auth";
import {RolesEnum} from "../../../enums/RolesEnum";
import {Inventory} from "../../../models/Inventory";
import NeedList from "./components/NeedList/NeedList";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

type ButtonTicketGroupStatuses = 'All' | 'My';

const BuyPage: FC = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        dispatch(clearInventory())
    }, [dispatch]);

    const inventoryData = useSelector((state: RootState) => state.inventory.data);
    const windowWidth = useWindowWidth();

    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const [toggleMatches, setToggleMatches] = useState<any>([]);
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');
    const [activeTicketGroupButton, setActiveTicketGroupButton] = useState<ButtonTicketGroupStatuses>('All')
    const [activeEventGroupButton, setActiveEventGroupButton] = useState<EventGroupType>('All')
    const [filteredInventoryData, setFilteredInventoryData] = useState<InventoryData[]>([]);

    useEffect(() => {
        if (windowWidth <= 1250) {
            setIsOpenSidebar(false);
        } else {
            setIsOpenSidebar(true);
        }
    }, [windowWidth]);

    useEffect(() => {
        if (loggedUser!.settings?.preferred_currency) {
            setSelectedCurrency(loggedUser!.settings.preferred_currency)
        }
    }, []);

    const filterInitial: SbEventsFilters = {
        name: '',
        from_date: new Date(),
        to_date: undefined,
        city: '',
        venue: '',
        eventGroup: activeEventGroupButton,
        performer: ''
    };

    const [validatedFormData, setValidatedFormData] = useState<SbEventsFilters>(filterInitial)

    const resetFiltersForm = async () => {
        dispatch(clearFilter());
        await handleScroll.resetEvents();
    };

    const ref = useRef<null | any>(null);
    const handleScroll = useHandleSbEventsPaginationScroll(ref, filterInitial);
    const {sortFunction} = useDynamicSortOptions();
    const [currentCardId, setCurrentCardId] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const toggleMatch = async (event: SbEvent) => {
        if (event && event.id) {
            setIsLoading(true)
            setCurrentCardId(event.id);

            const {data} = await getInventoriesForEvent(event.id);

            dispatch(setInventory(data?.data));
            setInventoryByGroup(data?.data, activeTicketGroupButton);

            setIsLoading(false);

            if (windowWidth <= 1024 && isOpenSidebar) {
                setIsOpenSidebar(false);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await searchMatch();
        };

        fetchData();
    }, [validatedFormData]);

    const searchMatch = () => {
        handleScroll.filterEvents(validatedFormData)
    };

    const eventSearchInputs = [
        {
            name: 'name',
            placeholder: 'Search events by name',
        },
        {
            name: 'city',
            placeholder: 'Search events by city',
        },
    ];

    const currencyOptions = [
        {name: 'EUR', value: 'EUR'},
        {name: 'GBP', value: 'GBP'},
        {name: 'USD', value: 'USD'},
    ];

    const handleTicketGroup = (button: ButtonTicketGroupStatuses) => {
        setActiveTicketGroupButton(button);
        setInventoryByGroup(inventoryData, button);
    }

    const setInventoryByGroup = (inventoryData: InventoryData[], filterGroup: ButtonTicketGroupStatuses) => {
        if (filterGroup === 'All') {
            setFilteredInventoryData(inventoryData);
        } else {
            const data = inventoryData.map(
                (inventoryDataItem) => ({
                    event: inventoryDataItem.event,
                    inventories: inventoryDataItem
                        .inventories
                        .filter((inventory) => inventory.sb_vendor_id === loggedUser!.vendor_id)
                })
            );

            setFilteredInventoryData((data[0]?.inventories?.length) ? data : []);
        }
    };

    const handleSuccessBuy = (event: SbEvent, newInventory: Inventory) => {
        const buyResultInventories = inventoryData.map((eventInventoryData) => {
            if (eventInventoryData.event.id === event.id) {
                const restEventInventories = eventInventoryData.inventories.filter((inventory) => inventory.id !== newInventory.id);

                const checkActualInventories = isEventHaveActualInventories(newInventory) ? [
                    ...restEventInventories,
                    newInventory
                ] : restEventInventories;

                const haveAnyActualInventories = !!checkActualInventories.find((actualInventory) => isEventHaveActualInventories(actualInventory));

                return haveAnyActualInventories ? {
                    event: eventInventoryData.event,
                    inventories: checkActualInventories
                } : null;
            }

            return eventInventoryData;
        });

        setFilteredInventoryData(buyResultInventories.filter(resultInventories => resultInventories) as any);
    }

    const isEventHaveActualInventories = (newInventory: Inventory) => {
        return newInventory.quantity > 0
    };

    const handleEventGroup = async (buttonValue: ButtonTicketGroupStatuses) => {
        setActiveEventGroupButton(buttonValue);
        validatedFormData.eventGroup = buttonValue;
        await searchMatch();
    };

    const handeSelectCurrency = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCurrency(e.target.value);

        dispatch(updateSettings({
            id: loggedUser!.settings.id,
            user_id: loggedUser!.settings.user_id,
            preferred_currency: e.target.value
        }));

        await setPreferredCurrencySetting(e.target.value);
    };

    const sidebar = () => {
        return (
            <div className="matches matches-add-inventory">
                <div className="matches-filters">
                    <div className="matches-header matches-header-add-inventory">
                        {loggedUser!.role_id === RolesEnum.User && (
                            <div className={'event-groups'}>
                                <button
                                    onClick={() => handleEventGroup('My')}
                                    className={activeEventGroupButton === 'My'
                                        ? 'ticket-groups-button_active'
                                        : 'ticket-groups-button'}
                                    type={"button"}
                                >
                                    My Events
                                </button>
                                <button onClick={() => handleEventGroup('All')}
                                        className={activeEventGroupButton === 'All'
                                            ? 'ticket-groups-button_active'
                                            : 'ticket-groups-button'}
                                        type={"button"}>
                                    All Events
                                </button>
                            </div>
                        )}
                    </div>
                    <hr/>
                    <Formik
                        initialValues={filterInitial}
                        onSubmit={async (values) => {
                            setValidatedFormData({...validatedFormData, ...omit(values, 'eventGroup')})
                        }}
                        innerRef={ref}
                    >
                        {({isSubmitting, submitForm, values, setValues}) =>
                            <Form className="filters-form">
                                {
                                    eventSearchInputs.map((eventInput, inputIndex) => (
                                        <DefaultFormikInput
                                            key={`${eventInput.name}-${inputIndex}`}
                                            name={eventInput.name}
                                            placeholder={eventInput.placeholder}
                                            autocomplete={'on'}
                                            class={'filters-search'}
                                        ></DefaultFormikInput>
                                    ))
                                }
                                <AppSmartSearch
                                    name={'venue'}
                                    placeholder={'Search by venue'}
                                    requestFunction={venuesNotPaginated}
                                    valuesKey={'venue'}
                                />
                                <AppSmartSearch
                                    name={'performer'}
                                    placeholder={'Search by performer'}
                                    requestFunction={performersNotPaginated}
                                    valuesKey={'name'}
                                />
                                <div className="filters-date">
                                    <AppDatePicker name={'from_date'}
                                                   placeholder={'From date'}
                                                   sendDate={(date) =>
                                                       setValues({...values, from_date: date})
                                                   }
                                    />
                                    <AppDatePicker name={'to_date'}
                                                   placeholder={'To date'}
                                                   sendDate={(date) =>
                                                       setValues({...values, to_date: date})
                                                   }
                                    />
                                </div>
                                <FiltersFormButtons
                                    className={'col-12'}
                                    resetForm={() => resetFiltersForm()}
                                    isSubmitting={isSubmitting}
                                    filterInitial={filterInitial}
                                    submitForm={submitForm}
                                ></FiltersFormButtons>
                            </Form>
                        }
                    </Formik>
                </div>
                <div
                    className="matches-list "
                    onScroll={handleScroll.handleChange}>
                    {
                        handleScroll.events ? handleScroll.events.slice().sort(sortFunction).map((data) =>
                            <MatchCard key={data.event_id} event={data}
                                       currentEventId={currentCardId}
                                       clickFunc={() => toggleMatch(data)}
                            />
                        ) : <></>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {isOpenSidebar && windowWidth <= 1024 ? (
                <div className="buy-page-overlay"></div>
            ) : null}
            <div className={'buy-page-tabs'}>
                <Tabs defaultActiveKey={'all-matches'} className={'page-tabs-margin'}>
                    <Tab title={'All inventories'} eventKey={'all-matches'}>
                        <div className={'buy-page-container'}>
                            {isOpenSidebar && windowWidth <= 1024 ? (
                                <div className="buy-page-overlay"></div>
                            ) : null}
                            <div className="find-events-sidebar">
                                <div className="find-events-sidebar-toggle"
                                     style={loggedUser!.role_id === RolesEnum.User ? {} : {top: '0'}}
                                     onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                                    {isOpenSidebar ? <span>&#171;</span> : <span>&#187;</span>}
                                </div>
                                <div
                                    className={isOpenSidebar ? 'find-events find-events-expand' : 'find-events-collapse'}>
                                    {
                                        isOpenSidebar
                                            ?
                                            sidebar()
                                            : <></>
                                    }
                                </div>
                            </div>
                            <div className={'user-sell-page no-top-margin buy-page-table-margin'}>
                                {windowWidth <= MOBILE_WIDTH && sidebar()}
                                <div className="user-sell-page__table-block">
                                    <div className="user-sell-page__header">
                                        {loggedUser!.role_id === RolesEnum.User && (
                                            <div className={'ticket-groups'}>
                                                <button
                                                    onClick={() => handleTicketGroup('My')}
                                                    className={activeTicketGroupButton === 'My'
                                                        ? 'ticket-groups-button_active'
                                                        : 'ticket-groups-button'}
                                                    type={"button"}
                                                >
                                                    My Ticket Groups
                                                </button>
                                                <button onClick={() => handleTicketGroup('All')}
                                                        className={activeTicketGroupButton === 'All'
                                                            ? 'ticket-groups-button_active'
                                                            : 'ticket-groups-button'}
                                                        type={"button"}>
                                                    All Ticket Groups
                                                </button>
                                            </div>
                                        )}
                                        <div className={'currency-container'}>
                                            <p>Currency</p>
                                            <select
                                                value={selectedCurrency}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handeSelectCurrency(e);
                                                }}
                                            >
                                                {
                                                    currencyOptions.map((option: { name: string, value: string }) => (
                                                        <option key={option.name} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="user-sell-page__table-wrapper"
                                         style={heightTableStyle('user-sell-page__table-wrapper')}>
                                        {!isLoading ?
                                            <table className="user-sell-page__table">
                                                <thead className="user-sell-page__table-head-text sticky-header">
                                                <tr>
                                                    <th></th>
                                                    {loggedUser!.role_id === RolesEnum.SuperAdmin && (
                                                        <>
                                                            <th>Vendor ID</th>
                                                            <th>Vendor Name</th>
                                                            <th>Inventory ID</th>
                                                            <th>User`s Broker Pools</th>
                                                        </>
                                                    )}
                                                    <th>Qty</th>
                                                    <th>Section</th>
                                                    <th>Row</th>
                                                    <th>Unit price</th>
                                                    <th>Public Notes</th>
                                                    <th>Seat type</th>
                                                    <th>Stock type</th>
                                                    <th>In-hand date</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    filteredInventoryData?.length
                                                        ? filteredInventoryData
                                                            .filter((data: any) => toggleMatches.length ? toggleMatches.includes(data.event.id) : true)
                                                            .map((data: any) =>
                                                                <TableEventBlock key={data.event.event_id} {...data}
                                                                                 handleSuccessBuy={handleSuccessBuy}
                                                                                 selectedCurrency={selectedCurrency}
                                                                                 getData={toggleMatch}

                                                                />
                                                            )
                                                        : <tr>
                                                            <td colSpan={11}>No inventory available</td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <AppLoader/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {loggedUser!.role_id === RolesEnum.User && (
                        <Tab
                            title={'Wanted tickets'}
                            eventKey={'Wanted tickets'}
                            mountOnEnter={true}
                        >
                            <WantedTickets/>
                        </Tab>
                    )}
                    {loggedUser!.role_id === RolesEnum.User && (
                        <Tab
                            title={'Needed by VIP Events Team'}
                            eventKey={'Needed by VIP Events Team'}
                            mountOnEnter={true}
                        >
                            <NeedList/>
                        </Tab>
                    )}
                </Tabs>
            </div>
        </>
    )
        ;
};

export default BuyPage;
