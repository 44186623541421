import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {dateToLocaleString} from "../../../../../../helpers/DateFormatter";
import {EventState} from "../../../../../../store/Reducers/Event";
import "./UploadPdfForm.scss";
import {uploadTicketFile} from "../../../../../../@api/Inventory";
import {HttpSuccessResponse} from "../../../../../../@api/Responses/HttpSuccessResponse";
import {SbEvent} from "../../../../../../models/Event";

interface Props {
    quantity: string,
    section: string,
    row: string,
    lowSeat: string,
    filesLinks: string[],
    setFilesLinks: React.Dispatch<React.SetStateAction<string[]>>,
    barcodes: string[],
    setBarcodes: React.Dispatch<React.SetStateAction<string[]>>,
    showHandle: () => void,
    event?: EventState
    inventoryEvent?: SbEvent,
    isSecondModal?: boolean
}

const UploadPdfForm: FC<Props> = ({
                                      quantity,
                                      lowSeat,
                                      row,
                                      section,
                                      filesLinks,
                                      setFilesLinks,
                                      barcodes,
                                      setBarcodes,
                                      showHandle,
                                      event = undefined,
                                      inventoryEvent = undefined,
    isSecondModal= false
                                  }) => {
    const ticketsData = [
        {name: 'Section', value: section},
        {name: 'Row', value: row},
        {name: 'Seats', value: lowSeat + ' - ' + (Number(lowSeat) + Number(quantity) - 1)},
    ];
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(true);
    let [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        if (!files.length) {
            setFiles(Array(Number(quantity)).fill(''));
        }

        if (!barcodes.length) {
            setBarcodes(Array(Number(quantity)).fill(''));
        }
    }, [barcodes, files, quantity, setBarcodes, setFiles]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, item: number) => {
        if (e.target.files) {
            files[item - Number(lowSeat)] = e.target.files[0];
        }
    };

    const handleBarcodeChange = (e: ChangeEvent<HTMLInputElement>, item: number) => {
        if (e.target.value) {
            barcodes[item - Number(lowSeat)] = e.target.value;
        }
    };

    const uploadAvailability = () => {
        for (const barcode of barcodes) {
            if (!barcode) {
                setUploadDisabled(true);
                return;
            }
        }

        for (const file of files) {
            if (!file) {
                setUploadDisabled(true);
                return;
            }
        }

        setUploadDisabled(false);
    };

    const handleSendFiles = async () => {
        const links: string[] = [];

        for (const file of files) {
            const formData = new FormData();

            formData.append('file', file);
            const link = await uploadTicketFile(formData);

            if (link instanceof HttpSuccessResponse) {
                links.push(link.data[0].pdfUrl);
            } else {
                alert(link.message)
            }
        }

        setFilesLinks(links);
        showHandle();
    };

    return (
        <>
            <div className={`upload-form-container ${isSecondModal ? 'modal-open' : ''}`}>
            <div className="upload-form_title">
                {event
                    ? <div className="upload-form_event">
                        <div
                            className="upload-form_event_data">{event?.event_venue + ' | ' + event?.event_city + ', ' + dateToLocaleString(event?.date ?? '')}</div>
                        <div className="upload-form_event_name">{event?.event}</div>
                    </div>
                    : inventoryEvent
                        ? <div className="upload-form_event">
                            <div
                                className="upload-form_event_data">{inventoryEvent?.venue + ' | ' + inventoryEvent?.city + ', ' + dateToLocaleString(inventoryEvent?.occurs_at ?? '')}</div>
                            <div className="upload-form_event_name">{inventoryEvent?.name}</div>
                        </div>
                        : null
                }
                <div className="upload-form_inventory">
                    {ticketsData.map((item) => (
                        <div key={item.name} className="upload-form_inventory_item">
                            <span className="upload-form_inventory_item_title">{item.name}</span>
                            <span>{item.value}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="upload-form_inputs">
                <h5>PDFs and Barcodes</h5>
                {Array.from({length: Number(quantity)}, (_, i) => i + Number(lowSeat)).map(
                    (item) => (
                        <div key={item} className="upload-form_input">
                            <div className="upload-form_barcode">
                                <label htmlFor={'barcode' + item}>Seat {item}</label>
                                <input
                                    id={'barcode' + item}
                                    className="form-control add-inventory-form-input"
                                    type="text"
                                    placeholder="Barcode"
                                    defaultValue={barcodes[item - Number(lowSeat)]}
                                    onChange={(event) => {
                                        handleBarcodeChange(event, item);
                                        uploadAvailability();
                                    }}
                                />
                            </div>
                            <input
                                className="import-file__input"
                                type="file"
                                accept=".pdf"
                                onChange={(event) => {
                                    handleFileChange(event, item);
                                    uploadAvailability();
                                }}
                            />
                        </div>
                    )
                )}
                <button
                    className="upload-form_upload filters-button-apply"
                    type="button"
                    disabled={uploadDisabled}
                    onClick={handleSendFiles}
                >
                    Upload
                </button>
            </div>
            </div>
        </>
    );
};

export default UploadPdfForm;
