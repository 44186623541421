import * as Yup from "yup";

export const EditInvoiceFormValidationSchema = Yup.object().shape({
    customer_email: Yup.string().email('Enter valid email').required('Required'),
    customer_name: Yup.string().required('Required'),
    quantity: Yup.number().required().typeError('Must be a number'),
    amount: Yup.number().required().typeError('Must be a number'),
    external_reference: Yup.string().required('Required'),
    delivery_method: Yup.string().notOneOf([''], 'Please select delivery method').required('Required'),
    payment_method: Yup.string().notOneOf([''], 'Please select payment method').required('Required'),
    recipient: Yup.string().email('Enter valid email').required('Required')
});
