import React, {FC, useEffect, useState} from "react";
import ActionIconDark from "../../../assets/images/other/action-icon-dark.png";
import {ltgVenuesRequest} from "../../../@api/Venue";
import {LTGVenue} from "../../../models/Venue";
import {Container, Dropdown} from "react-bootstrap";
import {Formik, Form, FormikState} from "formik";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import {Link} from "react-router-dom";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import FiltersFormButtons from "../../Forms/FiltersFormButtons";

interface FilterProps {
    name: string;
    country: string;
    city: string;
}

const LTGVenuesTab: FC = () => {
    const tableHeaders = [
        'Name',
        'Country',
        'City',
        '',
    ];

    const filterInitial: FilterProps = {
        name: '',
        country: '',
        city: '',
    };

    const filtersValues = [
        {name: 'name', placeholder: 'Name'},
        {name: 'country', placeholder: 'Country'},
        {name: 'city', placeholder: 'City'},
    ];

    const [ltgVenues, setLTGVenues] = useState<LTGVenue[]>([]);

    const getVenues = async (values: FilterProps) => {
        const response = await ltgVenuesRequest(values);

        setLTGVenues(response.data);
    };

    const handleReset = async (resetForm: (nextState?: Partial<FormikState<FilterProps>>) => void) => {
        resetForm();
        await getVenues(filterInitial);
    };

    useEffect(() => {
        getVenues(filterInitial);
    }, []);

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>LiveTicket Group Venues</h1>

            <div className="mb-3">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values) => {
                        await getVenues(values);
                    }}
                >
                    {({resetForm, submitForm, isSubmitting}) => {
                        return (
                            <Form className="table__filters">
                                <div className="table__filters__wrapper">
                                    {filtersValues.map((item) => (
                                        <DefaultFormikInput
                                            key={item.name + 'filter'}
                                            name={item.name}
                                            placeholder={item.placeholder}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                    ))}
                                    <FiltersFormButtons
                                        resetForm={() => handleReset(resetForm)}
                                        isSubmitting={isSubmitting}
                                        filterInitial={filterInitial}
                                        submitForm={submitForm}
                                    ></FiltersFormButtons>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <AppTable columns={tableHeaders}>
                <>
                    {ltgVenues.length ?
                        ltgVenues.map((venue, index) =>
                            <tr key={'Events' + index} className={"ltg-events-page__tr"}>
                                <td className={"ltg-events-page__td"}>{venue.name}</td>
                                <td className={"ltg-events-page__td"}>{venue.country}</td>
                                <td className={"ltg-events-page__td"}>{venue.city}</td>
                                <td className={"ltg-events-page__td"}>
                                    <Dropdown>
                                        <Dropdown.Toggle className="user-sell-page__action-button">
                                            <img src={ActionIconDark}
                                                 alt="action icon"
                                                 className="action-icon dropdown-toggle"
                                                 draggable="false"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item className="user-sell-page__dropdown-item"
                                                           as={Link} to={'/admin/venues/ltg/' + venue.id + '/aliases'}>
                                                Aliases
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>}
                </>
            </AppTable>
        </Container>
    )
}

export default LTGVenuesTab;
