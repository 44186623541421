export enum StockTypes {
    Barcode = 'BARCODE',
    Pdf = 'PDF',
    Mobile = 'MOBILE',
    MobileTransfer = 'MOBILETRANSFER',
    FlashSeat = 'FLASHSEAT',
    Paper = 'PAPER',
    EventCard = 'EVENTCARD',
    SeasonCard = 'SEASONCARD',
    RfId = 'RFID',
    GuestList = 'GUESTLIST',
    WillCall = 'WILLCALL',
    LocalDelivery = 'LOCALDELIVERY'
}
