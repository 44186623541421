import * as Yup from 'yup';

export class ResetData {
  token: string = '';
  password: string = '';
  passwordConfirm: string = '';
}

export const ResetFormValidationSchema = Yup.object().shape({
  token: Yup.string().required('Required'),
  password: Yup.string().min(6).required('Required'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
});
