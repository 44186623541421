import React, {FC, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {acMilanEventsPatch} from "../../../@api/Event";
import ActionIconDark from "../../../assets/images/other/action-icon-dark.png";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import EditEventForm from "../EditP1EventForm/EditEventForm";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {AcMilanEvent} from "../../../models/AcMilanEvent";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {Pagination} from "../../../helpers/UsePagination";

interface AcMilanTableProps {
    pagination: Pagination<AcMilanEvent>;
}

const AcMilanEventsTable: FC<AcMilanTableProps> = ({pagination}) => {
    const [showEdit, setShowEdit] = useState<number | null>(null);

    const edit = (id: number) => {
        setShowEdit(id);
    };

    const handleEditClose = () => {
        pagination.paginate();
        setShowEdit(null);
    };

    const handleToMap = (event_id: number, checked: boolean) => {
        acMilanEventsPatch(event_id, {'to_map': checked}).then(res => {
            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        });
    };

    const tableHeaders = [
        'ID',
        'Name',
        'Venue',
        'City',
        'Date',
        'To map',
        ''
    ];

    return (
        <AppTable columns={tableHeaders} pagination={pagination} noRecordsMessage='No events' >
            <>
                {pagination.items.length >0 && pagination.items?.map((event, index) =>
                        <React.Fragment key={'P1Events' + index}>
                            <tr data-row={index}>
                                <td>
                                    <div className={"app-paginated-table_td-id"}>
                                        {event.code}
                                        <span className={"app-paginated-table_td-id__tooltip"}>{event.code}</span>
                                    </div>
                                </td>
                                <td>{event.name}</td>
                                <td>{event.venue}</td>
                                <td>{event.city}</td>
                                <td>{dateToLocaleString(event.occurs_at)}</td>
                                <td>
                                    <input key={'map' + event.id} name={'to_map'} type='checkbox'
                                           defaultChecked={event.to_map}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleToMap(event.id, e.target.checked)}/>
                                </td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle className="user-sell-page__action-button">
                                            <img src={ActionIconDark}
                                                 alt="action icon"
                                                 className="action-icon dropdown-toggle"
                                                 draggable="false"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item className="user-sell-page__dropdown-item"
                                                           onClick={() => edit(event.id)}>
                                                Map
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <AppModal key={'editModal' + event.id} show={showEdit === event.id}
                                      showHandle={() => setShowEdit(null)} title={'Select Event'} form={
                                <EditEventForm
                                    showHandle={handleEditClose}
                                    event_id={event.id}
                                    sb_event={event.sb_event}
                                    name={event.name}
                                    venue={event.venue}
                                    city={event.city}
                                    date={event.occurs_at}
                                    integration_type={'ac_milan'}
                                />
                            } onClose={() => {
                            }}/>
                        </React.Fragment>
                    )
                }
            </>
        </AppTable>
    )
}

export default AcMilanEventsTable;
