import {FC, useEffect, useState} from "react";
import "./Calendar.scss";
import leftArrow from "../../../../../assets/images/other/leftarrow.svg";
import rightArrow from "../../../../../assets/images/other/rightarrow.svg";
import CalendarItem from "../CalendarItem/CalendarItem";
import {CalendarResponseDataType} from "../../../../../@interfaces/Euro/CalendarResponseDataType";

interface CalendarProps {
    data: CalendarResponseDataType;
}

const Calendar: FC<CalendarProps> = ({data}) => {
    const [monthNumber, setMonthNumber] = useState<number>(1);
    const [calendars, setCalendars] = useState<string[] | null>(null);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        let today = new Date();
        let currentMonth = today.getMonth()
        setMonthNumber(currentMonth);
    }, []);

    useEffect(() => {
        setCalendars(Object.keys(data));
    }, [data]);

    const changeMonth = (direction: boolean = false): void => {
        setMonthNumber(direction
            ? monthNumber + 1 <= 11 ? monthNumber + 1 : 0
            : monthNumber - 1 >= 0 ? monthNumber - 1 : 11
        );
    }

    return (
        <>
            <div className={"month-container"}>
                <img className={"pointer"} src={leftArrow} alt={""} onClick={() => changeMonth()}/>
                <p className={"month-title"}>{monthNames[monthNumber]}</p>
                <img className={"pointer"} src={rightArrow} alt={""} onClick={() => changeMonth(true)}/>
            </div>
            <div className={"calendar-items-container"}>
                {
                    calendars
                        ? (
                            calendars.length > 0
                                ? calendars.map(calendar => (
                                    <CalendarItem data={data} month={monthNumber} calendarNumber={calendar}/>
                                ))
                                :
                                <CalendarItem data={data} month={monthNumber} empty={true}/>
                        )
                        : null
                }
            </div>
        </>
    );
}

export default Calendar;
