import React, {ChangeEvent, FC, useRef} from "react";
import {LTGEvent} from "../../../../../../models/Event";
import {StubhubEvent} from "../../../../../../models/StubhubEvent";
import {TicomboEvent} from "../../../../../../models/TicomboEvent";

export type PossibleEvent = LTGEvent | StubhubEvent | TicomboEvent;

type Props = {
    isLoading: boolean;
    mappedEvents?: PossibleEvent[];
    events?: PossibleEvent[];
    hasAlreadyMappedEvents: boolean;
    integrationEventKey: string;
    handleCheck: (event: ChangeEvent<HTMLInputElement>) => void;
    labelRenderer: (event: unknown) => React.ReactNode;
    noResultsHandler: (hasExisting: boolean) => React.ReactNode;
};

const MapEventGroup: FC<Props> = ({
                                      isLoading,
                                      mappedEvents,
                                      events,
                                      hasAlreadyMappedEvents,
                                      integrationEventKey,
                                      handleCheck,
                                      labelRenderer,
                                      noResultsHandler
                                  }) => {
    const groupRef = useRef<HTMLDivElement>(null);

    const handleEventSelected = (event: ChangeEvent<HTMLInputElement>, isMapped: boolean) => {
        !hasAlreadyMappedEvents && handleCheck(event);
        if (!isMapped) {
            groupRef.current?.scroll({top: 0, behavior: 'smooth'} as ScrollOptions);
        }
    }

    return (
        <div className="map__events_group" ref={groupRef}>
            {!!mappedEvents?.length && mappedEvents.map((mappedEvent) => {
                const itemKey = `mapped-${integrationEventKey}${mappedEvent?.id}`;
                return mappedEvent && (
                    <div key={itemKey} className="map__events_item">
                        <input id={itemKey}
                               type="checkbox"
                               checked={true}
                               name={integrationEventKey}
                               value={mappedEvent.id}
                               className="map__events_item_input"
                               readOnly={hasAlreadyMappedEvents}
                               onChange={(e) => handleEventSelected(e, true)}/>
                        <label className="map__events_item_label px-1"
                               htmlFor={itemKey}>{labelRenderer(mappedEvent)}</label>
                    </div>
                )
            })}
            {
                !hasAlreadyMappedEvents && !!events?.length && !isLoading
                    ? events?.map((event) => {
                        const itemKey = `event-${integrationEventKey}${event?.id}`;
                        return event && (
                            <div key={itemKey} className="map__events_item">
                                <input id={itemKey}
                                       type="checkbox"
                                       name={integrationEventKey}
                                       value={event.id}
                                       className="map__events_item_input"
                                       onChange={(e) => handleEventSelected(e, false)}/>
                                <label className="map__events_item_label px-1"
                                       htmlFor={itemKey}>{labelRenderer(event)}</label>
                            </div>
                        )})
                    : noResultsHandler(hasAlreadyMappedEvents)
            }
        </div>
    );
}

export default MapEventGroup;
