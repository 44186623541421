import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {CreatedWantedEvent, WantedEvent} from "../models/WantedEvent";
import axios from "axios";
import {Inventory} from "../models/Inventory";

type CreateWantedTicket = Omit<WantedEvent, 'id' | 'created_at' | 'sb_event' | 'updated_at' | 'user_id' | 'has_offer'>

const urlPrefix = 'wanted-events';

export const createWantedEvent = (data: CreateWantedTicket) => {
    return requestWrapper<GlobalStatusCodesEnum, CreatedWantedEvent>(
        axios.post(`${urlPrefix}`, data)
    )
}

export const getWantedEvents = () => {
    return requestWrapper<GlobalStatusCodesEnum, WantedEvent>(
        axios.get(`${urlPrefix}`)
    )
}

export const deleteEvent = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, WantedEvent>(
        axios.delete(`${urlPrefix}/${id}`)
    )
}

export const buy = (id: number, inventory_id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, WantedEvent>(
        axios.post(`${urlPrefix}/${id}`, {inventory_id})
    )
}

export const getSuitableInventories = (eventId: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Inventory[]>(
        axios.get(`${urlPrefix}/${eventId}/suitable-inventories`)
    )
}