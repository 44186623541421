import React, {FC, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import './PaymentModal.scss';
import StripeForm from "./PaymentForm/PaymentForm";

interface PaymentModalProps {
    visible: boolean;
    onClose: React.Dispatch<void>
}

const PaymentModal: FC<PaymentModalProps> = ({visible, onClose}) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

    const [show, setShow] = useState(visible);

    const handleSubmit = () => {
        handleClose()
    }

    const handleClose = () => {
        onClose();
        setShow(false);
    };

    useEffect(() => {
        setShow(visible);
    }, [visible])

    return (
        <Elements stripe={stripePromise}>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Make Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StripeForm onSubmit={handleSubmit}/>
                </Modal.Body>
            </Modal>
        </Elements>
    )
}

export default PaymentModal;