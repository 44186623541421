import React, {FC, useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import BidsTable from "./components/BidsTable/BidsTable";
import {acceptBid, getPlacedBids, getReceivedBids, rejectBid} from "../../../@api/Bids";
import {Bids} from "../../../models/Bids";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";

const BidsPage: FC = () => {

    const [placedBids, setPlacedBids] = useState<Bids[] | undefined>(undefined)
    const [receivedBids, setReceivedBids] = useState<Bids[] | undefined>(undefined)
    const [selectedBid, setSelectedBid] = useState<number | null>(null)
    const [btnDisabled, setBtnDisabled] = useState(false)

    useEffect(() => {
        fetchPlacedBids()
        fetchReceivedBids()
    }, [])

    const fetchPlacedBids = async () => {
        const data = await getPlacedBids();
        setPlacedBids(data.data)
    }

    const fetchReceivedBids = async () => {
        const data = await getReceivedBids();
        setReceivedBids(data.data)
    }

    const handleRejectBid = async (bid: Bids) => {
        setBtnDisabled(true)
        setSelectedBid(bid.id);
        await rejectBid(bid.id);
        setBtnDisabled(false)
        await fetchReceivedBids();
    }

    const handleAcceptBid = async (bid: Bids) => {
        setBtnDisabled(true)
        setSelectedBid(bid.id);

        const res = await acceptBid(bid.id);

        if (res instanceof HttpFailureResponse) {
            alert(res.message);
        }

        setBtnDisabled(true)

        await fetchReceivedBids();
    }

    return (
        <div className={'buy-page-tabs'}>
            <Tabs defaultActiveKey={'Placed bids'} className={'ms-2'}>
                <Tab title={'Placed bids'} eventKey={'Placed bids'}>
                    <BidsTable btnDisabled={btnDisabled} bids={placedBids} bidsRequest={fetchPlacedBids}
                    ></BidsTable>
                </Tab>

                <Tab title={'Received bids'} eventKey={'Received bids'}>
                    <BidsTable btnDisabled={btnDisabled} bids={receivedBids} bidsRequest={fetchReceivedBids}
                               onReject={handleRejectBid} onAccept={handleAcceptBid} received={true}></BidsTable>
                </Tab>
            </Tabs>
        </div>
    )
}

export default BidsPage;
