import {Invoice} from "./Invoice";

export interface Customer {
    id: number,
    user_id: number,
    share: boolean,
    display_name: string,
    email: string,
    phone: string,
    type: string,
    last_name: string,
    first_name: string,
    company: string,
    street_address: string,
    apartment_floor: string,
    city: string,
    state: string,
    country: string,
    postal_code: string,
    customer_credit: string,
    customer_tags: string[]
    payment_method: string,
    telegram: string,
    instagram: string,
    invoices: Invoice[] | null,
    delivery_method: string
}

export interface CustomerCreation extends Omit<Customer, 'phone' | 'user_id' | 'id'> {
    phone: {
        phone_number: string
    },
}

export type CreateCustomerValuesTypes = Pick<Customer, 'phone' | 'email' | 'display_name'>;

export const createCustomerCreation = (customer?: Omit<Customer, 'user_id' | 'id'>): CustomerCreation => {
    return {
        share: customer?.share || false,
        display_name: customer?.display_name || '',
        email: customer?.email || '',
        phone: {
            phone_number: customer?.phone || '',
        },
        type: customer?.type || '',
        last_name: customer?.last_name || '',
        first_name: customer?.first_name || '',
        company: customer?.company || '',
        street_address: customer?.street_address || '',
        apartment_floor: customer?.apartment_floor || '',
        city: customer?.city || '',
        state: customer?.state || '',
        postal_code: customer?.postal_code || '',
        customer_credit: customer?.customer_credit || '',
        country: customer?.country || '',
        customer_tags: customer?.customer_tags || [],
        payment_method: customer?.payment_method || '',
        instagram: customer?.instagram || '',
        telegram: customer?.telegram || '',
        invoices: customer?.invoices || null,
        delivery_method: customer?.delivery_method || ''
    }
}
