import React from "react";
import AppButton from "../../Buttons/AppButton/AppButton";
import './ConfirmationModal.scss';

interface ButtonLabels {
    no: string;
    yes: string;
}

interface Props {
    onClose: (isConfirmed: boolean) => void;
    children: React.ReactNode,
    labels?: ButtonLabels
}

const ConfirmationModal: React.FC<Props> = ({onClose, children, labels}) => {
    const buttonLabels: ButtonLabels = {
        no: labels?.no ?? 'No',
        yes: labels?.yes ?? 'Yes',
    }

    return (
        <>
            <div className={'confirmation-modal'}>
                <div className={'confirmation-modal__text'}>
                    {children}
                </div>
                <div className={'confirmation-modal__buttons'}>
                    <div onClick={() => onClose(false)}>
                        <AppButton text={buttonLabels.no} color={'gray'}/>
                    </div>
                    <div onClick={() => onClose(true)}>
                        <AppButton text={buttonLabels.yes} color={'red'}/>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ConfirmationModal;
