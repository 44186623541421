import React, {FC, useState} from 'react';
import './OtpVerificationPage.scss';
import Form from 'react-bootstrap/Form';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/Store';
import {Navigate, useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {HttpSuccessResponse} from '../../../@api/Responses/HttpSuccessResponse';
import {GlobalStatusCodesEnum} from '../../../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {VerifyOtpSuccessResponse} from '../../../@interfaces/Response/Auth';
import {login} from '../../../store/Reducers/Auth';
import {HttpFailureResponse} from '../../../@api/Responses/HttpFailureResponse';
import {AuthResponseStatusesEnum} from '../../../enums/ResponseStatuses/AuthResponseStatusesEnum';
import {verifyOtpRequest} from '../../../@api/Auth';

const OtpVerificationPage: FC = () => {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const otpToken = useSelector((state: RootState) => state.auth.otpToken);
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (!otpToken) {
        Navigate({to: '/'});
        return (<></>);
    }

    const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum, VerifyOtpSuccessResponse>) => {
        if (res.statusCode === AuthResponseStatusesEnum.VerifyOtpFormNeedAdminVerification) {
            alert(res.message);
            navigateTo('/');
            return;
        }

        dispatch(login({
            user: res.data.user,
            authToken: res.data.auth_token,
            pools: res.data.user.pools,
            hidden_users: res.data.user.hidden_users,
            settings: res.data.user.settings
        }));
        navigateTo('/user');
    };

    const failureResponse = (res: HttpFailureResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum>) => {
        setError(res.message);
    };

    const submit = async () => {
        setError(undefined);

        setIsSubmitting(true);

        const res = await verifyOtpRequest(otpToken as string, code);

        setIsSubmitting(false);

        return res instanceof HttpSuccessResponse ? successResponse(res) : failureResponse(res);
    };

    return (
        <AuthPageWrapper>
            <Form className={'otp-form'}>
                <div>
                    <p className="otp-form__verification-title">Otp verification</p>
                    <p className="otp-form__verification-sub-title">Enter otp</p>
                </div>
                <Form.Group className="mb-4" controlId="form-basic-email">
                    <div>
                        <Form.Control type="text"
                                      className="form-control auth-wrapper__input"
                                      placeholder="Enter otp code"
                                      value={code}
                                      onChange={(e) => setCode(e.target.value)}
                        />
                        {error
                            ? <div className="position-absolute otp-form__error-block"><p>{error}</p></div>
                            : ''
                        }
                    </div>
                </Form.Group>

                <Button className="auth-wrapper__submit-btn" onClick={() => submit()} disabled={isSubmitting}>
                    Verify
                </Button>

                <div className="otp-form__resend-code-block">
                    <div>
                        <span className="clickable-word">Resend verification code?</span>
                    </div>
                </div>
            </Form>
        </AuthPageWrapper>
    );
};

export default OtpVerificationPage;
