import {LTGEvent, SbEvent} from "../../../../../models/Event";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../../../../components/Inputs/FormikDateInput/FormikDateInput";
import "./MapForm.scss";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {getEventsToMap, getMappedEvents, patchMappedEvents} from "../../../../../@api/Event";
import {EventsFilters} from "../../../../../@interfaces/EventsFilters";
import {HttpFailureResponse} from "../../../../../@api/Responses/HttpFailureResponse";
import {TicomboEvent} from "../../../../../models/TicomboEvent";
import {StubhubEvent} from "../../../../../models/StubhubEvent";
import DefaultSpinner from "../../../../../ui/Spinners/DefaultSpinner/DefaultSpinner";
import FiltersFormButtons from "../../../../../components/Forms/FiltersFormButtons";
import MapEventGroup, {PossibleEvent} from "./components/MapEventGroup";

interface Props {
    sbEvent: SbEvent;
    showHandle: (ltgEvents: boolean, tcEvents: boolean, shEvents: boolean) => void;
}

interface EventsArray {
    ltgEvents?: LTGEvent[],
    tcEvents?: TicomboEvent[],
    shEvents?: StubhubEvent[]
}

type Option = 'add' | 'delete';

const MapForm: FC<Props> = ({sbEvent, showHandle}) => {
    const formValues = [
        {key: 'name', value: 'Search events by name'},
        {key: 'venue', value: 'Search events by venue'},
        {key: 'city', value: 'Search events by city'},
    ];

    const todayDate = () => {
        const date = new Date();
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;

        return new Date(date.getTime() - userTimezoneOffset);
    };

    const filterInitial = {name: '', venue: '', city: '', category: '', from_date: todayDate(), to_date: null};
    const [filterValues, setFilterValues] = useState(filterInitial);
    const [events, setEvents] = useState<EventsArray>({ltgEvents: [], tcEvents: [], shEvents: []});
    const [mappedEvents, setMappedEvents] = useState<EventsArray>({ltgEvents: [], tcEvents: [], shEvents: []});
    const [isEventsMapToLoading, setIsEventsMapToLoading] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);
    const [hasAlreadyMappedEvents, setHasAlreadyMappedEvents] = useState({ltgEvents: false, tcEvents: false, shEvents: false});

    useEffect(() => {
        if (sbEvent?.id) {
            const modal = document.getElementsByClassName('modal-dialog')[0];

            modal.classList.add('map');

            getMappedEvents(sbEvent.id)
                .then((res) => {
                    if (res instanceof HttpFailureResponse) {
                        alert(res?.message);
                    } else {
                        const resEvents = res.data;
                        setMappedEvents({
                            ltgEvents: resEvents.ltgEvents || [],
                            tcEvents: resEvents.tcEvents || [],
                            shEvents: resEvents.shEvents || [],
                        });
                        setHasAlreadyMappedEvents({
                            ltgEvents: !!resEvents?.ltgEvents?.length,
                            tcEvents: !!resEvents?.tcEvents?.length,
                            shEvents: !!resEvents?.shEvents?.length,
                        });
                    }
                });
        }
    }, [sbEvent.id]);

    const handleOnDatesChange = (date: Date | null, name: string) => {
        (filterValues as any)[name] = date;
        setFilterValues(filterValues);
    };

    const handleOnFiltersChange = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;

        (filterValues as any)[target.name] = target.value;
        setFilterValues(filterValues);
    };

    const handleFiltersSubmit = async (values: EventsFilters) => {
        setIsEventsMapToLoading(true);
        setSearchStatus(true);

        const res = await getEventsToMap(values);

        setIsEventsMapToLoading(false);

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
        } else {
            setEvents(res.data);
        }
    };

    function switchChecked<T extends { id: string | number }>
    (id: string, from: T[] | undefined, to: T[] | undefined, option: Option) {
        const searchingEvent = from?.findIndex((key) => {
            return `${key.id}` === `${id}`;
        });

        if (searchingEvent === undefined || from === undefined) {
            return;
        }

        if (option === 'add') {
            if (to && to.length > 0) {
                from.push(to[0]);
            }
            to?.splice(0, to.length);
        }
        to?.push(from[searchingEvent]);
        from?.splice(searchingEvent, 1);
    }

    const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
        let localEvents;
        let localMappedEvents;
        const integrationKey = `${event.target.name}s` as keyof EventsArray;
        switch (integrationKey) {
            case 'ltgEvents':
                localEvents = events.ltgEvents;
                localMappedEvents = mappedEvents.ltgEvents;
                break;
            case 'tcEvents':
                localEvents = events.tcEvents;
                localMappedEvents = mappedEvents.tcEvents;
                break;
            case 'shEvents':
                localEvents = events.shEvents;
                localMappedEvents = mappedEvents.shEvents;
                break;
        }
        (event.target.checked)
            ? switchChecked<PossibleEvent>(event.target.value, localEvents, localMappedEvents, 'add')
            : switchChecked<PossibleEvent>(event.target.value, localMappedEvents, localEvents, 'delete');

        setMappedEvents({...mappedEvents, [integrationKey]: localMappedEvents});
        setEvents({...events, [integrationKey]: localEvents});
    };

    const handleSubmit = () => {
        // send only added mappings. Do not send previous ones
        patchMappedEvents(sbEvent.id, {
            ltgEvents: !hasAlreadyMappedEvents.ltgEvents ? mappedEvents.ltgEvents : undefined,
            tcEvents: !hasAlreadyMappedEvents.tcEvents ? mappedEvents.tcEvents : undefined,
            shEvents: !hasAlreadyMappedEvents.shEvents ? mappedEvents.shEvents : undefined,
        })
            .then((res) => {
                if (res instanceof HttpFailureResponse) {
                    alert(res.message);
                } else {
                    showHandle(res.data.ltgEvents, res.data.tcEvents, res.data.shEvents);
                }
            });
    };

    const eventsLoadingOrEmpty = (alreadyMapped?: boolean) => {
        if (alreadyMapped === true) {
            return <span className="map__events_item map__events_item-no">Already mapped</span>
        }

        return (isEventsMapToLoading
                ? <DefaultSpinner></DefaultSpinner>
                : (
                    searchStatus
                        ? <span className="map__events_item map__events_item-no">No events were found</span>
                        : <span className="map__events_item map__events_item-no">No Events</span>
                )
        );
    };

    const allMapped = hasAlreadyMappedEvents.ltgEvents && hasAlreadyMappedEvents.tcEvents && hasAlreadyMappedEvents.shEvents;

    return (
        <>
            <div className="map__event-info">
                <h5>{sbEvent.name}, {sbEvent.venue}, {sbEvent.city}, {dateToLocaleString(sbEvent.occurs_at)}</h5>
            </div>
            {!allMapped ? (
                <div className="map__filters">
                    <Formik
                        initialValues={filterInitial}
                        onSubmit={async (values: EventsFilters) => {
                            await handleFiltersSubmit(values);
                        }}
                    >
                        {({resetForm, submitForm, isSubmitting}) => {
                            return (
                                <Form className="map__filters_form" onChange={handleOnFiltersChange}>
                                    <div className="map__filters_form_wrapper">
                                        {formValues.map(formValue => (
                                            <DefaultFormikInput
                                                key={formValue.key}
                                                name={formValue.key}
                                                placeholder={formValue.value}
                                                autocomplete={'off'}
                                                class={'filters-search'}
                                            ></DefaultFormikInput>
                                        ))}
                                    </div>
                                    <div className="map__filters_form_wrapper">
                                        <div className={'flex-row col'}>
                                            <FormikDateInput
                                                name={'from_date'}
                                                placeholderText={'From date'}
                                                showTimeSelect
                                                autoComplete="off"
                                                sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                                            />
                                            <FormikDateInput
                                                name={'to_date'}
                                                placeholderText={'To date'}
                                                showTimeSelect
                                                autoComplete="off"
                                                sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                                            />
                                        </div>
                                        <FiltersFormButtons
                                            className={"col-12 col-sm-6 col-md-4 col-lg-3"}
                                            filterInitial={filterInitial}
                                            submitForm={submitForm}
                                            resetForm={() => resetForm({values: filterInitial})}
                                            isSubmitting={isSubmitting}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            ) : (
                <h6>You can not modify this mapping.</h6>
            )}
            <div className="map__events">
                <div className="map__events_block">
                    <div>Live Ticket Group</div>
                    <MapEventGroup
                        events={events.ltgEvents}
                        mappedEvents={mappedEvents.ltgEvents}
                        hasAlreadyMappedEvents={hasAlreadyMappedEvents.ltgEvents}
                        integrationEventKey={'ltgEvent'}
                        isLoading={isEventsMapToLoading}
                        handleCheck={handleCheck}
                        noResultsHandler={eventsLoadingOrEmpty}
                        labelRenderer={(event) => {
                            const ltgEvent = event as LTGEvent;
                            return <>{ltgEvent.name}, {ltgEvent.ltg_venue?.name}, {ltgEvent.ltg_venue?.city}, {dateToLocaleString(ltgEvent.start_date_time)}</>
                        }}
                    ></MapEventGroup>
                </div>

                <div className="map__events_block">
                    <div>Ticombo</div>
                    <MapEventGroup
                        events={events.tcEvents}
                        mappedEvents={mappedEvents.tcEvents}
                        hasAlreadyMappedEvents={hasAlreadyMappedEvents.tcEvents}
                        integrationEventKey={'tcEvent'}
                        isLoading={isEventsMapToLoading}
                        handleCheck={handleCheck}
                        noResultsHandler={eventsLoadingOrEmpty}
                        labelRenderer={(event) => {
                            const tcEvent = event as TicomboEvent;
                            return <>{tcEvent.name}, {tcEvent.venue.name}, {tcEvent.venue.location?.city}, {dateToLocaleString(tcEvent.date.start)}</>
                        }}
                    ></MapEventGroup>
                </div>

                <div className="map__events_block">
                    <div>Stubhub</div>
                    <MapEventGroup
                        events={events.shEvents}
                        mappedEvents={mappedEvents.shEvents}
                        hasAlreadyMappedEvents={hasAlreadyMappedEvents.shEvents}
                        integrationEventKey={'shEvent'}
                        isLoading={isEventsMapToLoading}
                        handleCheck={handleCheck}
                        noResultsHandler={eventsLoadingOrEmpty}
                        labelRenderer={(event) => {
                            const shEvent = event as StubhubEvent;
                            return <>{shEvent.name}, {shEvent.venue.name}, {shEvent.venue.city}, {dateToLocaleString(shEvent.event_date_local)}</>
                        }}
                    ></MapEventGroup>
                </div>
            </div>
            {allMapped ? (
                <button
                    className="map__submit events-page__filters__button filters-button-apply"
                    type="button" onClick={() => showHandle(false, false, false)}>
                    Close
                </button>
            ) : (
                <button
                    className="map__submit events-page__filters__button filters-button-apply"
                    type="button" onClick={handleSubmit}>
                    Save
                </button>
            )}
        </>
    );
};

export default MapForm;
