import React, {FC, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LandingPage from '../pages/LandingPage/LandingPage';
import SellPage from '../pages/User/SellPage/SellPage';
import BuyPage from '../pages/User/BuyPage/BuyPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import checkUserLogin from '../helpers/CheckUserLogin';
import {User} from '../models/User';
import {login, logout} from '../store/Reducers/Auth';
import {useDispatch, useSelector} from 'react-redux';
import LoadingRoute from './LoadingRoute';
import NotLoginRoute from './NotLoginRoute';
import LoginPage from '../pages/Auth/LoginPage/LoginPage';
import RegistrationPage from '../pages/Auth/RegistrationPage/RegistrationPage';
import OtpVerificationPage from '../pages/Auth/OtpVerificationPage/OtpVerificationPage';
import MainLayout from '../layouts/MainInterface/MainLayout/MainLayout';
import UsersPage from '../pages/Admin/UsersPage/UsersPage';
import PrivateRoute from './PrivateRoute';
import {RootState} from '../store/Store';
import {RolesEnum} from '../enums/RolesEnum';
import SellTicketPage from "../pages/User/SellPage/SellTicketPage/SellTicketPage";
import EditTicketPage from "../pages/User/SellPage/EditTicketPage/EditTicketPage";
import SettingPage from "../pages/User/SettingPage/SettingPage";
import ScrollToTop from '../components/ScrollToTop';
import VerifyEmailPage from '../pages/Auth/VerifyEmailPage/VerifyEmailPage';
import ResetPasswordRequestPage from '../pages/Auth/ResetPasswordRequestPage/ResetPasswordRequestPage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage/ResetPasswordPage';
import PerformersPage from "../pages/Admin/PerformersPage/PerformersPage";
import VendorsPage from "../pages/Admin/VendorsPage/VendorsPage";
import InvoiceSectionPage from "../pages/InvoiceSectionPage/InvoiceSectionPage";
import UserEditPage from "../pages/Admin/UserEditPage/UserEditPage";
import EventsPage from "../pages/Admin/EventsPage/EventsPage";
import TeammatesPage from "../pages/User/TeammatesPage/TeammatesPage";
import CategoriesPage from "../pages/Admin/CategoriesPage/CategoriesPage";
import VenuesPage from '../pages/Admin/VenuesPage/VenuesPage';
import LTGAliasesPage from '../pages/Admin/LTGAliasesPage/LTGAliasesPage';
import BidsPage from "../pages/User/BidsPage/BidsPage";
import {HiddenUser} from "../models/HiddenUser";
import SoldInventoryPage from "../pages/User/SoldInventoryPage/SoldInventoryPage";
import CustomersPage from "../pages/User/CustomersPage/CustomersPage";
import P1CategoriesPage from "../pages/Admin/P1CategoriesPage/P1Categories";
import ReportsPage from "../pages/Admin/ReportsPage/ReportsPage";
import HoldsPage from "../pages/User/HoldsPage/HoldsPage";
import TicketExchangePurchaseHistory from "../pages/Admin/TicketExchangePurchaseHistory/TicketExchangePurchaseHistory";
import UserEventsPage from "../pages/User/UserEventsPage/UserEventsPage";
import useCurrenciesGet from "../hooks/useCurrenciesGet";
import {UserSettings} from "../models/UserSettings";
import UserVendorsPage from "../pages/User/UserVendorsPage/UserVendorsPage";
import EditPurchaseOrderPage from "../pages/User/EditPurchaseOrderPage/EditPurchaseOrderPage";
import PurchasesPage from "../pages/User/PurchasesPage/PurchasesPage";
import PurchasedInventoryPage from "../pages/User/PurchasedInventoryPage/PurchasedInventoryPage";
import InvoicesPage from "../pages/Admin/InvoicesPage/InvoicesPage";
import ViagogoInvoicesPage from "../pages/Admin/ViagogoInvoicesPage/ViagogoInvoicesPage";
import TransfersPage from "../pages/User/TransfersPage/TransfersPage";
import CalendarPage from "../pages/Euro/Calendar/CalendarPage";
import EuroVendorPausePage from "../pages/Admin/EuroVendorPausePage/EuroVendorPausePage";
import EuroRoute from "./EuroRoute";
import {identifyPendo} from "../helpers/Pendo";
import InvoiceActionsPage from "../pages/InvoiceActionsPage/InvoiceActionsPage";
import {identify} from "../helpers/Analytics";
import {Pool} from "../models/Pool";
import ProtectedRoute from "./ProtectedRoute";
import NeedsList from "../pages/Unauthorized/NeedsList/NeedsList";

const Router: FC = () => {
    const [isUserChecking, setIsUserChecking] = useState(true);
    const user = useSelector((state: RootState) => state.auth.user);
    const currenciesSet = useCurrenciesGet();

    const dispatch = useDispatch();

    const setUser = (user: User, authToken: string, pools: Pool[], hidden_users: HiddenUser[], settings: UserSettings) => {
        dispatch(login({user, authToken, pools, hidden_users, settings}));
        identify(user);
    };

    const setUserAsNotLogged = () => {
        dispatch(logout());
        identifyPendo();
    }

    useEffect(() => {
        checkUserLogin(setIsUserChecking, setUser, setUserAsNotLogged).catch(() => {
            setIsUserChecking(false);
            setUserAsNotLogged();
        });
        currenciesSet();
    }, []);

    return (
        <>
            <ScrollToTop/>
            <Routes>
                <Route path={'/'} element={<LandingPage/>}/>
                <Route path={'/needs-list'} element={<NeedsList/>}/>

                <Route path={'/login'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <LoginPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'/registration'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <RegistrationPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'/verify-email/:token'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <VerifyEmailPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'/reset-password-request'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <ResetPasswordRequestPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'/reset-password/:token'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <ResetPasswordPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'/otp-verification'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <NotLoginRoute>
                            <OtpVerificationPage/>
                        </NotLoginRoute>
                    </LoadingRoute>
                }/>

                <Route path={'user'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <PrivateRoute user={user}
                                      roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.User]}
                        >
                            <MainLayout/>
                        </PrivateRoute>
                    </LoadingRoute>
                }>
                    <Route index element={<Navigate to="sell" replace/>}/>
                    <Route path={'sell'} element={<SellPage/>}/>
                    <Route path={'sell/sell-ticket'} element={<SellTicketPage/>}/>
                    <Route path={'sell/edit-ticket/:inventoryId'} element={<EditTicketPage/>}/>
                    <Route path={'sell/edit-purchase-order/:purchaseOrderId'} element={<EditPurchaseOrderPage/>}/>
                    <Route path={'bids'} element={<ProtectedRoute user={user}>
                        <BidsPage/>
                    </ProtectedRoute>}/>
                    <Route path={'setting'} element={<SettingPage/>}/>
                    <Route path={'invoices'} element={<InvoiceSectionPage/>}/>
                    <Route path={'teammates'} element={<TeammatesPage/>}/>
                    <Route path={'purchases'} element={<PurchasesPage/>}/>
                    <Route path={'invoices/:id'} element={<InvoiceActionsPage/>}/>
                    <Route path={'purchased-inventory'} element={<PurchasedInventoryPage/>}/>
                    <Route path={'sold-inventory'} element={<SoldInventoryPage/>}/>
                    <Route path={'customers'} element={<CustomersPage/>}/>
                    <Route path={'holds'} element={<HoldsPage/>}/>
                    <Route path={'events'} element={<UserEventsPage/>}/>
                    <Route path={'vendors'} element={<UserVendorsPage/>}/>
                    <Route path={'transfers'} element={
                        <ProtectedRoute user={user}>
                            <TransfersPage/>
                        </ProtectedRoute>
                    }/>
                    <Route path={'buy'} element={
                        <ProtectedRoute user={user}>
                            <BuyPage/>
                        </ProtectedRoute>
                    }>
                    </Route>
                    <Route path={'euro2024/calendar'} element={
                        <EuroRoute user={user}>
                            <CalendarPage/>
                        </EuroRoute>
                    }/>
                </Route>

                <Route path={'admin'} element={
                    <LoadingRoute isLoading={isUserChecking}>
                        <PrivateRoute user={user}
                                      roles={[RolesEnum.SuperAdmin, RolesEnum.Admin]}
                        >
                            <MainLayout/>
                        </PrivateRoute>
                    </LoadingRoute>
                }>
                    <Route index element={<Navigate to="users" replace/>}/>
                    <Route path={'users'} element={<UsersPage/>}/>
                    <Route path={'users/:userId'} element={<UserEditPage/>}/>
                    <Route path={'performers'} element={<PerformersPage/>}/>
                    <Route path={'vendors'} element={<VendorsPage/>}/>
                    <Route path={'events'} element={<EventsPage/>}/>
                    <Route path={'venues'} element={<VenuesPage/>}/>
                    <Route path={'venues/ltg/:venueId/aliases'} element={<LTGAliasesPage/>}/>
                    <Route path={'categories'} element={<CategoriesPage/>}/>
                    <Route path={'p1-categories'} element={<P1CategoriesPage/>}/>
                    <Route path={'reports'} element={<ReportsPage/>}/>
                    <Route path={'ticket-exchange-purchase-history'} element={<TicketExchangePurchaseHistory/>}/>
                    <Route path={'invoices'} element={<InvoicesPage/>}/>
                    <Route path={'viagogo-invoices'} element={<ViagogoInvoicesPage/>}/>
                    <Route path={'euro2024/calendar'} element={<CalendarPage/>}/>
                    <Route path={'euro2024/vendors'} element={<EuroVendorPausePage/>}/>
                </Route>

                <Route path={'/*'} element={<ErrorPage/>}/>
            </Routes>
        </>
    );
};

export default Router;
