import "./AppCheckbox.scss";
import React, {FC} from "react";
import {Form} from "react-bootstrap";
import {Field} from "formik";

interface Props {
    label: string;
    id: string;
    value: boolean;
    name?: string;
    dispatchValue?: React.Dispatch<boolean>;
    disabled?: boolean
}

const AppCheckbox: FC<Props> = ({label, id, value, name, dispatchValue, disabled = false}) => {
    return (
        <>
            {dispatchValue
                ? <Form.Check type="checkbox" id={id} className="app-check">
                    <Form.Check.Label className="app-check-label me-2">{label}</Form.Check.Label>
                    <Form.Check.Input
                        className="app-check-input"
                        type="checkbox"
                        checked={value}
                        disabled={disabled}
                        onChange={(e) => dispatchValue(e.target.checked)}
                    />
                </Form.Check>
                : <div className="app-check">
                    <label className="app-check-label me-2" htmlFor={id}>{label}</label>
                    <Field className="app-check-input form-check-input" id={id} name={name} type='checkbox'></Field>
                </div>
            }
        </>
    )
}

export default AppCheckbox;
