import {createSlice, PayloadAction, current} from '@reduxjs/toolkit';


export interface FilterState {
    section: string,
    fromDate: string,
    toDate: string,
    name:string,
    row:string,
    inventory_id:string,
    city:string,
    venue:string,
    performer: string
}

const initialState: FilterState = {
    section: '',
    fromDate: '',
    toDate: '',
    name:'',
    row:'',
    inventory_id:'',
    city:'',
    venue:'',
    performer: ''
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<any>) => {
            let userTimezoneOffset = (new Date).getTimezoneOffset() * 60000;
            return {
                section: action.payload.section,
                name: action.payload.name,
                row: action.payload.row,
                inventory_id: action.payload.inventory_id,
                fromDate: (new Date(action.payload.from_date- userTimezoneOffset)).toJSON(),
                toDate: (new Date(action.payload.to_date- userTimezoneOffset)).toJSON(),
                city: action.payload.city,
                venue:action.payload.venue,
                performer: action.payload.performer
            }
        },
        clearFilter: (state) => {
            current(state);
            return initialState;
        }
    },
});

export const {setFilter, clearFilter} = filterSlice.actions;

export default filterSlice.reducer;
