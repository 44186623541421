import {Currency} from "../models/Currency";

export const getCurrencySymbol = (code: string, currencies: Currency[]) => {
    if (currencies) {
        return currencies?.find((item) => {
            return item.code === code;
        })?.currency_symbol;
    }

    return '';
};

export const convert = (from: string, to: string, amount: number, currencies: Currency[]) => {
    const fromValue = currencies.find((item) => {
        return item.code === from;
    })?.value;

    const toValue = currencies.find((item) => {
        return item.code === to;
    })?.value;

    if (fromValue && toValue) {
        return Math.round(toValue * amount / fromValue * 100) / 100;
    }

    return -1;
};
