export enum StateTypes {
    DE = 'Delaware',
    HI = 'Hawaii',
    PR = 'Puerto Rico',
    TX = 'Texas',
    QC = 'Quebec',
    MA = 'Massachusetts',
    MB = 'Manitoba',
    MD = 'Maryland',
    IA = 'Iowa',
    ME = 'Maine',
    ID = 'Idaho',
    YT = 'Yukon Territory',
    MI = 'Michigan',
    UT = 'Utah',
    MN = 'Minnesota',
    AB = 'Alberta',
    MO = 'Missouri',
    IL = 'Illinois',
    MP = 'Northern Mariana Islands',
    IN = 'Indiana',
    MS = 'Mississippi',
    MT = 'Montana',
    AK = 'Alaska',
    AL = 'Alabama',
    VA = 'Virginia',
    AR = 'Arkansas',
    AS = 'American Samoa',
    VI = 'Virgin Islands',
    NB = 'New Brunswick',
    NC = 'North Carolina',
    ND = 'North Dakota',
    NE = 'Nebraska',
    RI = 'Rhode Island',
    AZ = 'Arizona',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    VT = 'Vermont',
    NL = 'Newfoundland and Labrador',
    NM = 'New Mexico',
    BC = 'British Columbia',
    NS = 'Nova Scotia',
    FL = 'Florida',
    NT = 'Northwest Territories',
    NU = 'Nunavut',
    NV = 'Nevada',
    WA = 'Washington',
    NY = 'New York',
    SC = 'South Carolina',
    SD = 'South Dakota',
    WI = 'Wisconsin',
    SK = 'Saskatchewan',
    OH = 'Ohio',
    GA = 'Georgia',
    OK = 'Oklahoma',
    CA = 'California',
    WV = 'West Virginia',
    ON = 'Ontario',
    WY = 'Wyoming',
    OR = 'Oregon',
    KS = 'Kansas',
    CO = 'Colorado',
    GU = 'Guam',
    KY = 'Kentucky',
    CT = 'Connecticut',
    PA = 'Pennsylvania',
    LA = 'Louisiana',
    PE = 'Prince Edward Island',
    TN = 'Tennessee',
    DC = 'District of Columbia'
}
