import React, {FC, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import renderPhoneInput from "../../../../adapters/Formik/DefaultFormikPhoneInputAdapter";
import {PhoneNumberData, PhoneNumberValidationSchema} from './Data';
import {vendorsSetPercentRequest} from "../../../../@api/Vendor";

interface PhoneNumberModalProps {
    visible: boolean;
    vendor: any;
    onClose: () => void
}

const PhoneNumberModal: FC<PhoneNumberModalProps> = ({visible, vendor, onClose}) => {
    const [show, setShow] = useState(visible);

    const handleClose = () => {
        onClose();
        setShow(false);
    };

    const phoneNumber = async (values: FormikValues) => {
        const res = await vendorsSetPercentRequest(vendor.id, values);

        alert(res?.message);

        if (res?.status) {
            handleClose();
            window.location.reload();
        }
    }

    useEffect(() => {
        setShow(visible);
    }, [visible])

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vendor phone number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={PhoneNumberData}
                        validationSchema={PhoneNumberValidationSchema}
                        onSubmit={async (values, {setErrors}) => {
                            const data = {
                                ...values,
                            }
                            await phoneNumber(data)
                        }}
                    >
                        {({isSubmitting}) =>
                            <Form>
                                <DefaultFormikInput name={'phoneNumber'}
                                                    placeholder={'Phone Number'}
                                                    render={renderPhoneInput}
                                                    autocomplete={'off'}
                                                    class={'auth-wrapper__input'}
                                ></DefaultFormikInput>

                                <button className="auth-wrapper__submit-btn mt-3" type="submit"
                                        disabled={isSubmitting}>
                                    Add Vendor
                                </button>
                            </Form>
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PhoneNumberModal;
