import React, {FC} from 'react';
import './MainLayout.scss';
import {Outlet} from 'react-router-dom';
import MainHeader from '../MainHeader/MainHeader';
import '../../../assets/styles/variables.scss'

const MainLayout: FC = () => {
    return (
        <>
            <MainHeader></MainHeader>
            <div className="main-interface">
                <div className="main-interface__content">
                    <Outlet/>
                </div>
            </div>
        </>
    );
};

export default MainLayout;
