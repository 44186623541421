import {RolesEnum} from "../../../enums/RolesEnum";

export interface NavigationItem {
    title: string;
    isOpen: boolean;
    itemId: number;
    items: {
        itemTitle: string;
        path: string;
        condition?: () => boolean;
    }[];
}

export const getNavigationItems = (euroVendorStatus: () => boolean, role_id: number, hasPools: boolean, paidAccess: boolean): NavigationItem[] => {
    if (role_id === RolesEnum.User) {
        return [
            {
                title: 'Sales',
                isOpen: false,
                itemId: 0,
                items: [
                    {
                        itemTitle: 'Holds',
                        path: '/user/holds'
                    },
                    {
                        itemTitle: 'Invoices',
                        path: '/user/invoices'
                    },
                    {
                        itemTitle: 'Customers',
                        path: '/user/customers'
                    },
                    {
                        itemTitle: 'Sold Inventory',
                        path: '/user/sold-inventory'
                    },
                    {
                        itemTitle: 'Euro 2024 Calendar',
                        path: 'euro2024/calendar',
                        condition: euroVendorStatus
                    },
                ]
            },
            {
                title: 'Purchases',
                isOpen: false,
                itemId: 1,
                items: [
                    {
                        itemTitle: 'Purchases',
                        path: '/user/purchases'
                    },
                    {
                        itemTitle: 'Vendors',
                        path: '/user/vendors'
                    },
                    {
                        itemTitle: 'Purchased Inventory',
                        path: '/user/purchased-inventory'
                    },
                    {
                        itemTitle: 'Events',
                        path: '/user/events'
                    },
                ]
            },
            hasPools || paidAccess ? {
                title: 'Exchange',
                isOpen: false,
                itemId: 2,
                items: [
                    {
                        itemTitle: 'Ticket Exchange',
                        path: '/user/buy'
                    },
                    {
                        itemTitle: 'Transfers',
                        path: '/user/transfers'
                    },
                    {
                        itemTitle: 'Bids',
                        path: '/user/bids'
                    },
                ]
            } : null,
        ].filter(item => item !== null) as NavigationItem[];
    } else {
        return [
            {
                title: 'Mapping',
                isOpen: false,
                itemId: 0,
                items: [
                    {
                        itemTitle: 'Events',
                        path: '/admin/events'
                    },
                    {
                        itemTitle: 'Categories',
                        path: '/admin/categories'
                    },
                    {
                        itemTitle: 'P1 categories',
                        path: '/admin/p1-categories'
                    },
                    {
                        itemTitle: 'Reports',
                        path: '/admin/reports'
                    },
                    {
                        itemTitle: 'Venues',
                        path: '/admin/venues',
                        condition: euroVendorStatus
                    },
                ]
            },
            {
                title: 'Configurations',
                isOpen: false,
                itemId: 1,
                items: [
                    {
                        itemTitle: 'Users',
                        path: '/admin/users'
                    },
                    {
                        itemTitle: 'Vendors',
                        path: '/admin/vendors'
                    },
                    {
                        itemTitle: 'Performers',
                        path: '/admin/performers'
                    },
                ]
            },
            {
                title: 'Ticket Exchange',
                isOpen: false,
                itemId: 2,
                items: [
                    {
                        itemTitle: 'Ticket Exchange',
                        path: '/user/buy'
                    },
                    {
                        itemTitle: 'TE Purchase History',
                        path: '/admin/ticket-exchange-purchase-history'
                    },
                    {
                        itemTitle: 'Invoices',
                        path: '/admin/invoices'
                    },
                ]
            },
            {
                title: 'Euro 2024',
                isOpen: false,
                itemId: 3,
                items: [
                    {
                        itemTitle: 'Viagogo Invoices',
                        path: '/admin/viagogo-invoices'
                    },
                    {
                        itemTitle: 'Euro 2024 Calendar',
                        path: '/admin/euro2024/calendar'
                    },
                    {
                        itemTitle: 'Euro 2024 Vendors',
                        path: '/admin/euro2024/vendors'
                    },
                ]
            },
        ]
    }

};
