import React, {FC, useEffect, useState} from "react";
import {Inventory} from "../../../../../models/Inventory";
import {Field, Form, Formik} from "formik";
import {BuyTicketValidationSchema} from "../BuyTicketValidationSchema";
import {SbEvent} from "../../../../../models/Event";
import './BuyTicketForm.scss';
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {getInventoryAmount} from "../../../../../helpers/InventoryHelper";
import {StockTypes} from "../../../../../enums/Skybox/StockTypes";
import {convert, getCurrencySymbol} from "../../../../../helpers/CurrencyConverter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";
import {buy} from "../../../../../@api/Inventory";
import {HttpFailureResponse} from "../../../../../@api/Responses/HttpFailureResponse";
import AppButton from "../../../../../ui/Buttons/AppButton/AppButton";

interface BuyFormValues {
    quantity: number
}

interface Props {
    inventory: Inventory,
    event: SbEvent,
    isSubmitting: boolean,
    quantityOptions: { name: string, value: string }[],
    selectedCurrency: string,
    showHandle: (event?: SbEvent, inventory?: Inventory) => void
}

const BuyTicketForm: FC<Props> = ({inventory, event, isSubmitting, quantityOptions, selectedCurrency, showHandle}) => {
    const formInitial = {quantity: 0};
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [selectedQuantity, setSelectedQuantity] = useState<number>(0);

    const convertedCost = (inventory: Inventory) => {
        const inventoryCost = getInventoryAmount(inventory);
        return Math.ceil(convert(inventory.currency_symbol, selectedCurrency, inventoryCost, currencies));
    };

    const handleQuantityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedQuantity(Number(e.target.value));
    };

    const handleSubmitBuyTicketModal = async (values: BuyFormValues) => {
        const res = await buy(inventory.id, values.quantity, selectedCurrency, convertedCost(inventory));

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
            return
        }

        showHandle(res.data.event, res.data.inventory);
    };

    return (
        <>
            <div>
                <div className={'buy-inventory__info'}>
                    <div className={'buy-inventory__event'}>
                        <p>{inventory.sb_event.name}</p>
                        <p>{event.venue}, {inventory.sb_event.city}</p>
                    </div>
                    <div className={'buy-inventory__event-time'}>
                        <p>{dateToLocaleString(inventory.sb_event.occurs_at)}</p>
                    </div>
                </div>
                <div className={'buy-inventory-container'}>
                    <div className={'buy-inventory__listing'}>
                        <div className={'buy-inventory__listing-header'}>
                            <p>QUANTITY</p>
                            <p>SECTION</p>
                            <p>ROW</p>
                        </div>
                        <div className={'buy-inventory__listing-data'}>
                            <p>{inventory.quantity}</p>
                            <p>{inventory.section}</p>
                            <p>{inventory.row}</p>
                        </div>
                    </div>
                    <div className={'buy-inventory__listing'}>
                        <div className={'buy-inventory__listing-header buy-inventory__listing-header_second'}>
                            <p></p>
                            <p>PRICE</p>
                            <p>TOTAL COST</p>
                        </div>
                        <div className={'buy-inventory__listing-data buy-inventory__listing-data_second'}>
                            <p></p>
                            <p>{getCurrencySymbol(selectedCurrency, currencies)}{convertedCost(inventory)}</p>
                            <p>{getCurrencySymbol(selectedCurrency, currencies)}{convertedCost(inventory)}</p>
                        </div>
                    </div>
                </div>
                <div className={'buy-inventory-container'}>
                    <div className={'buy-inventory__listing-info buy-inventory__listing-info_gray'}>
                        <p className={'buy-inventory-container__text'}>IN-HAND DATE</p>
                        <p>{dateToLocaleString(inventory.in_hand_date)}</p>
                    </div>
                    <div className={'buy-inventory__listing-info'}>
                        <p className={'buy-inventory-container__text'}>STOCK TYPE</p>
                        <p>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</p>
                    </div>
                    <div className={'buy-inventory__listing-info buy-inventory__listing-info_gray'}>
                        <p className={'buy-inventory-container__text'}>PUBLIC NOTES</p>
                        <p>{inventory.public_notes}</p>
                    </div>
                </div>
            </div>
            <Formik initialValues={formInitial}
                    onSubmit={async (values: BuyFormValues) => {
                        const data = {
                            quantity: selectedQuantity
                        };

                        await handleSubmitBuyTicketModal(data)
                    }}
                    validationSchema={BuyTicketValidationSchema}>
                {({isSubmitting, values}) =>
                    <Form className={'buy-inventory-form'}>
                        <div className={'buy-inventory-form__block'}>
                            <p>CHOOSE QUANTITY</p>
                            <Field as="select" className={'buy-form-select'} name={'quantity'}
                                   onClick={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                       handleQuantityChange(e)
                                   }}>
                                {
                                    quantityOptions.map((option: {
                                        name: string,
                                        value: string
                                    }) => (
                                        <option key={option.name} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className={'buy-inventory__buttons'}>
                            <div className={'buy-inventory__buttons-item'}>
                                <AppButton disabled={!values.quantity || isSubmitting} text={'Buy Inventory'}
                                           type={'submit'} color={'red'}/>
                            </div>
                            <div onClick={() => showHandle()} className={'buy-inventory__buttons-item'}>
                                <AppButton text={'Cancel'} type={'button'} color={'gray'}/>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </>
    )
}


export default BuyTicketForm;
