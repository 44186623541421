import * as Yup from "yup";

export const UserVendorFormValidation = Yup.object().shape({
    display_name: Yup.string().required('Required'),
    email: Yup.string().email('Enter valid email').required('Required').matches(/@[^.]*\./),
    street_address: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postal_code: Yup.string().required('Required'),
    first_name: Yup.string().required('Required').max(255, 'Too long').matches(
        /^[a-zA\-\[\]]*$/gi,
        'First name can only contain latin letters.'
    ).trim(),
    last_name: Yup.string().required('Required').max(255, 'Too long').matches(
        /^[a-zA\-\[\]]*$/gi,
        'Last name can only contain latin letters.'
    ).trim(),
    company: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
})
