import React, {FC, useEffect} from "react";
import {Vendor, VendorEdit} from "../../../../models/Vendor";
import {ErrorMessage, Field, Form, Formik} from "formik";
import DefaultFormikInput from "../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import Select from "react-select";
import {Tag, TagPicker} from "rsuite";
import renderPhoneInput from "../../../../adapters/Formik/DefaultFormikPhoneInputAdapter";
import './EditVendorForm.scss';
import {VendorTypes} from "../../../../enums/Skybox/VendorTypes";
import {EditVendorFormValidationSchema} from "./EditVendorFormValidation";


interface Props {
    vendor: Vendor,
    onSubmit: (values: VendorEdit) => void;
}

const EditVendorForm: FC<Props> = ({vendor, onSubmit}) => {
    const formInputsFirst = [
        {
            name: 'name',
            placeholder: 'Enter Name',
            inputName: 'Name'
        },
        {
            name: 'email',
            placeholder: 'Enter email',
            inputName: 'Email'
        },
        {
            name: 'company_name',
            placeholder: 'Enter company',
            inputName: 'Company'
        },
    ];

    const formInputsSecond = [
        {
            name: 'city',
            placeholder: 'Enter city',
            inputName: 'City'
        },
        {
            name: 'state',
            placeholder: 'Enter state',
            inputName: 'State'
        },
        {
            name: 'company_address',
            placeholder: 'Enter company address',
            inputName: 'Company address'
        },
    ];

    const formInitial: VendorEdit = {
        name: vendor.name,
        email: vendor.email,
        company_name: vendor.company_name,
        company_address: vendor.company_address,
        phone: {phone_number: vendor.phone_number},
        city: vendor.city,
        state: vendor.state
    }

    return (
        <>
            <Formik initialValues={formInitial} onSubmit={(values) => onSubmit(values)}
                    validationSchema={EditVendorFormValidationSchema}
            >
                <Form className={'customer-form'}>
                    <div className={'inputs-container'}>
                        <div className={'form-inputs w-100'}>
                            {formInputsFirst.map((formInput, index) => (
                                <>
                                    <div className={'form-inputs_item item-gap'}
                                         key={index + formInput.inputName}
                                    >
                                        <p className={'form-inputs_item-header'}>{formInput.inputName}</p>
                                        <DefaultFormikInput
                                            name={formInput.name}
                                            noLabel={true}
                                            placeholder={formInput.placeholder}
                                            autocomplete={'off'}
                                            type={'text'}
                                        ></DefaultFormikInput>
                                    </div>
                                </>
                            ))
                            }
                        </div>
                        <div className={'form-inputs w-100'}>
                            {formInputsSecond.map((formInput, index) => (
                                <>
                                    <div className={'form-inputs_item item-gap'}
                                         key={index + formInput.inputName}
                                    >
                                        <p className={'form-inputs_item-header'}>{formInput.inputName}</p>
                                        <DefaultFormikInput
                                            name={formInput.name}
                                            noLabel={true}
                                            placeholder={formInput.placeholder}
                                            autocomplete={'off'}
                                            type={'text'}
                                        ></DefaultFormikInput>
                                    </div>
                                </>
                            ))
                            }
                        </div>
                        <div className={'form-inputs w-100'}>
                            <div className={'form-inputs_item item-gap'}>
                                <p className={'form-inputs_item-header '}>Phone</p>
                                <DefaultFormikInput name={'phone'}
                                                    noLabel={true}
                                                    render={renderPhoneInput}
                                                    placeholder={'Phone'}
                                                    autocomplete={'off'}
                                ></DefaultFormikInput>
                            </div>
                            <div className={'form-inputs_item item-gap'}>
                                <p className={'form-inputs_item-header'}>Select Type</p>
                                <div className="form-inputs_item__field-error">
                                    <Field name={'type'} as="select">
                                        <option value="">Type</option>
                                        {Object.entries(VendorTypes).map((key) => (
                                            <option key={key[0]} value={key[0]}>{key[1]}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="type" component="div" className="error-message"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className={'filters-button-apply mt-3'}
                            type={'submit'}>Edit vendor
                    </button>
                </Form>
            </Formik>
        </>
    )
}

export default EditVendorForm;
