import React, {FC} from "react";
import * as Yup from "yup";
import {Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../Inputs/DefaultFormikInput/DefaultFormikInput";
import renderPhoneInput from "../../adapters/Formik/DefaultFormikPhoneInputAdapter";
import {addTeammate} from "../../@api/User";
import {HttpFailureResponse} from "../../@api/Responses/HttpFailureResponse";

interface AddTeammateFormProps {
    showHandle: () => void
}
const AddTeammateForm: FC<AddTeammateFormProps> = ({showHandle}) => {
    const add = async (values: FormikValues) => {
        const res = await addTeammate(values);

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
        } else {
            showHandle();
        }
    }

    const formValues = [
        {key: "name", value: "Name"},
        {key: "email", value: "Email"},
    ];

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phoneNumber: '',
            }}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string().required('Required'),
                    email: Yup.string().email('Invalid Email').required('Required'),
                    phoneNumber: Yup.mixed().required('Required'),
                })
            }
            onSubmit={async (values) => {
                await add(values)
            }}>
            <Form>
                <div role={"group"}>
                    {
                        formValues.map(formValue => (
                            <>
                                <label htmlFor="">{formValue.value}</label>
                                <DefaultFormikInput
                                    name={formValue.key}
                                    autocomplete={'off'}
                                    class={'auth-wrapper__input'}
                                    noLabel={true}
                                ></DefaultFormikInput>
                            </>
                        ))
                    }
                    <label htmlFor="">Phone Number</label>
                    <DefaultFormikInput name={'phoneNumber'}
                                        render={renderPhoneInput}
                                        autocomplete={'off'}
                                        class={'auth-wrapper__input'}
                                        noLabel={true}
                    ></DefaultFormikInput>
                </div>
                <button className="auth-wrapper__submit-btn mt-3" type="submit">
                    Add
                </button>
            </Form>
        </Formik>
    );
}

export default AddTeammateForm;
