import React, { FC, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { User } from '../../models/User';
import AppCheckbox from '../../ui/Inputs/AppCheckbox/AppCheckbox';
import { integrations } from '../../constants/Integrations';
import { updateUserShowingIntegrations } from '../../@api/User';
import { ApiKey } from '../../models/ApiKey';
import { IntegrationsEnum } from '../../enums/IntegrationsEnum';

interface IntegrationProps {
    user: User;
}

interface integrationShowingStatusInterface {
    name: string,
    key: string,
    showing_status: boolean,
    api_key_exists: boolean
}

const Integration: FC<IntegrationProps> = ({user}) => {

    const [integrationShowingStatus, setIntegrationShowingStatus] = useState<integrationShowingStatusInterface[]>([]);

    useEffect(() => {
        initIntegrationShowingStatus();
    }, []);

    const initIntegrationShowingStatus = () => {
        let initIntegrationShowingStatusData = [];
        for (let integration of integrations) {

            // do not display these on same page with other integrations
            const hasOwnTab = [IntegrationsEnum.Ticombo, IntegrationsEnum.Ltg].includes(integration.key);
            if (hasOwnTab) {
                continue;
            }

            let apiKey: string = '';
            if (user.api_keys) {
                apiKey = user.api_keys[integration.key as keyof ApiKey];
            }

            initIntegrationShowingStatusData.push({
                'name': integration.name,
                'key': integration.key,
                'showing_status': !!(user.active_integrations?.includes(integration.key)),
                'api_key_exists': !!apiKey,
            });
        }

        setIntegrationShowingStatus(initIntegrationShowingStatusData);
    };

    const handleSubmitShowingIntegrations = async () => {
        const showingIntegrationKeys = integrationShowingStatus.filter((integration: integrationShowingStatusInterface) => {
            return integration.showing_status;
        }).map((integration => integration.key));
        const res = await updateUserShowingIntegrations(user.id, showingIntegrationKeys);
        alert(res?.message);
    };

    const setShowingStatus = (key: string, checked: boolean) => {
        let updatedStatuses = [...integrationShowingStatus];
        const searchingIntegration = integrationShowingStatus?.findIndex((integration) => {
            return integration.key === key;
        });
        updatedStatuses[searchingIntegration].showing_status = !updatedStatuses[searchingIntegration].showing_status;
        setIntegrationShowingStatus(updatedStatuses);
    };

    return (
        <div className={'settings-tab-width'}>
            <Formik
                initialValues={{}}
                onSubmit={handleSubmitShowingIntegrations}
            >
                <Form>
                    {
                        integrationShowingStatus.map((integration: integrationShowingStatusInterface) => (
                            <AppCheckbox
                                label={integration.name}
                                key={integration.key}
                                id={integration.key}
                                value={integration.showing_status}
                                disabled={integration.api_key_exists}
                                dispatchValue={(checked) => setShowingStatus(integration.key, checked)}
                            />
                        ))
                    }
                    <button className="auth-wrapper__submit-btn" type="submit">Update</button>
                </Form>
            </Formik>
        </div>
    );
};

export default Integration;
