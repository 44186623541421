import {DefaultFormikFieldRenderParams} from '../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import PhoneInput, {CountryData} from 'react-phone-input-2';

const renderPhoneInput = (renderParams: DefaultFormikFieldRenderParams): JSX.Element => {
    const setValue = (value: string, data: CountryData) => {
        if (value === undefined || value.trim() === '') {
            renderParams.helpers.setValue(undefined);
            return;
        }

        renderParams.helpers.setValue({
            phone_number: value,
            alphabet_country_code: data.countryCode,
        });
    };

    return (
        <PhoneInput specialLabel={''}
                    inputProps={{
                        name: renderParams.field.name,
                        autoComplete: renderParams.inputAttributes.autocomplete
                    }}
                    inputClass={renderParams.inputAttributes.class}
                    value={renderParams.field.value?.phone_number ?? ''}
                    onClick={() => {
                        renderParams.helpers.setTouched(true);
                    }}
                    onChange={setValue}
                    onBlur={renderParams.field.onBlur}
                    placeholder={''}
        />
    );
};

export default renderPhoneInput;
