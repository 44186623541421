import {GlobalStatusCodesEnum} from '../../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {AxiosResponse} from 'axios';
import {HttpFailureResponse} from './HttpFailureResponse';
import isObject from '../../helpers/IsObject';

export interface IHttpSuccessResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, R extends Record<string, any> = Record<string, any>> {
  status: boolean,
  statusCode: Status,
  message: string,
  data: R,
}

export class HttpSuccessResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, R extends Record<string, any> = Record<string, any>>
  implements IHttpSuccessResponse {

  status: boolean;
  statusCode: Status;
  message: string;
  data: R;

  private constructor({status, statusCode, message, data}: IHttpSuccessResponse<Status, R>) {
    this.status = status;
    this.statusCode = statusCode;
    this.message = message;
    this.data = data;
  }

  static createByAxiosResponse<Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, SR = any, FR = any>(
    res: AxiosResponse,
    validationMapper?: Record<string, string>,
  ): HttpSuccessResponse<Status, SR> | HttpFailureResponse<Status, FR> {
    const status = res.data?.status ?? false;

    if (status) {
      const dataExists = isObject(res.data?.data) ? res.data?.data : {};

      return new HttpSuccessResponse({
        status,
        message: res.data?.message ?? 'Success',
        statusCode: res.data?.statusCode ?? GlobalStatusCodesEnum.Success,
        data: dataExists ? res.data?.data : {},
      });
    }

    return HttpFailureResponse.createByAxiosResponse<Status, SR, FR>(res, validationMapper);
  }
}
