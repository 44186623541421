export enum InvoiceStatusesEnum {
    COMPLETED = "Completed",
    ORDER_VOID_CANCELLED = "Order Void/Cancelled",
    ORDER_PARTIALLY_VOID_CANCELLED = "Order Partially Void/Cancelled",
    WAITING_FOR_RESPONSE = "Waiting for Response",
    MANAGER_REVIEW = "Manager Review",
    INVESTIGATION_CLOSED = "Invalid Claim Investigation – Closed",
    DELIVERY_FAILURE = "Delivery Failure",
    EVENT_CANCELLED = "Event Cancelled",
    EVENT_RESCHEDULED = "Event Rescheduled - Order Void/Cancelled",
    INVESTIGATION_CLOSED_SELLER_FAVOR = "Invalid Claim Investigation – Closed in Seller's Favor",
    INVESTIGATION_PENDING = "Invalid Claim Investigation – Pending",
    INVESTIGATION_CLOSED_BUYER_FAVOR = "Invalid Claim Investigation – Closed in Buyer's Favor",
    CONTACT_NEEDED = "Contact Needed",
}
