import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form, Formik, FormikState, FormikValues} from "formik";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import './EditPurchaseOrderPage.scss'
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {userVendorSearchByDisplayName} from "../../../@api/UserVendors";
import {getAllPossibleTags} from "../../../@api/CustomTags";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import {editPurchaseOrderRequest, getPurchaseOrderbyIdRequest} from "../../../@api/PurchaseOrders";
import {PurchaseOrder} from "../../../models/PurchaseOrder";
import {EditPOValidationSchema} from "./EditPOValidationSchema";
import AppDatePicker from "../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {setPurchaseOrder, updatePurchaseOrder} from "../../../store/Reducers/PurchaseOrder";
import {SeatTypes} from "../../../enums/Skybox/SeatTypes";
import {BsSplitTypes} from "../../../enums/BsSplitTypes";
import CustomerOrVendorForm from "../CustomersPage/CustomerOrVendorForm/CustomerOrVendorForm";

const EditPurchaseOrderPage: FC = () => {
    const {purchaseOrderId} = useParams();
    const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrder | null>(null);
    const [selectedVendorTags, setSelectedVendorTags] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const purchaseOrder = useSelector((state: RootState) => state.purchaseOrder.purchaseOrder);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (purchaseOrderData !== null) {
            dispatch(setPurchaseOrder(purchaseOrderData))
        }

    }, [purchaseOrderData, purchaseOrderId]);

    useEffect(() => {
        getPurchaseOrderbyIdRequest(Number(purchaseOrderId)).then((data) => {
            setPurchaseOrderData(data.data.purchase_order)
        })
    }, [purchaseOrderId]);

    const handleVendorModal = async () => {
        setIsModalOpen(!isModalOpen);

        getAllPossibleTags().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handleSubmit = async (values: FormikValues, resetForm: (nextState?: (Partial<FormikState<any>> | undefined)) => void) => {
        const vendorInput = document.getElementById('vendor_input') as HTMLInputElement;
        const {internal_notes, public_notes, external_reference, due_date} = values;

        const data = {
            public_notes: public_notes || purchaseOrder?.public_notes || '',
            internal_notes: internal_notes || purchaseOrder?.internal_notes || '',
            external_reference: external_reference || '',
            due_date: due_date || purchaseOrder?.due_date,
        } as any;

        if (vendorInput.dataset.entityId) {
            data.user_vendor_id = Number(vendorInput.dataset.entityId)
        }

        const res = await editPurchaseOrderRequest(Number(purchaseOrderId), data);

        if (res instanceof HttpSuccessResponse) {
            alert('Success!')
            dispatch(updatePurchaseOrder({
                public_notes: data.public_notes,
                internal_notes: data.internal_notes,
                due_date: data.due_date,
                external_reference: data.external_reference,
                user_vendor_id: data.user_vendor_id
            }));

            resetForm();
        }
    };

    const getOrderTotalAmount = (purchaseOrder: PurchaseOrder) => {
        let totalAmount = 0;

        purchaseOrderData?.inventories.forEach((inventory) => {
            totalAmount += Number(inventory?.unit_amount)
        })

        return totalAmount;
    };

    const getOrderTotalFaceValue = (purchaseOrder: PurchaseOrder) => {
        let totalFaceValue = 0;

        purchaseOrderData?.inventories.forEach((inventory) => {
            totalFaceValue += Number(inventory?.face_value)
        })

        return totalFaceValue;
    }

    return (
        <div className={'inventory-data'}>
            <h4>Edit Purchase Order</h4>
            <div className={'inventory-data-info'}>
                <div className={'data-text'}>
                    <h6>P.O. Number</h6>
                    <p>{purchaseOrderData?.id}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Date Created</h6>
                    <p>{purchaseOrderData?.inventories[0] && dateToLocaleString(purchaseOrderData.created_at)}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Created By</h6>
                    <p>{purchaseOrderData?.user?.name}</p>
                </div>
            </div>
            <div className={'inventory-data-info'}>
                <div className={'data-text'}>
                    <h6>Quantity</h6>
                    <p>{purchaseOrderData?.inventories[0]?.quantity}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Section</h6>
                    <p>{purchaseOrderData?.inventories[0]?.section}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Row</h6>
                    <p>{purchaseOrderData?.inventories[0]?.row}</p>
                </div>
            </div>
            <div className={'inventory-data-info'}>
                <div className={'data-text'}>
                    <h6>Unit price</h6>
                    <p>{getOrderTotalAmount(purchaseOrderData!)}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Face value</h6>
                    <p>{getOrderTotalFaceValue(purchaseOrderData!)}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Currency</h6>
                    <p>{purchaseOrderData?.inventories[0]?.currency_symbol}</p>
                </div>
            </div>
            <div className={'inventory-data-info'}>
                <div className={'data-text'}>
                    <h6>Seat type</h6>
                    <p>{SeatTypes[purchaseOrderData?.inventories[0]?.seat_type as keyof typeof SeatTypes]}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Split type</h6>
                    <p>{BsSplitTypes[purchaseOrderData?.inventories[0]?.split_type as keyof typeof BsSplitTypes]}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Current Vendor</h6>
                    {purchaseOrder &&
                        <p>{purchaseOrder!.user_vendor?.display_name}</p>
                    }
                </div>
            </div>
            <div className={'inventory-data-info'}>
                <div className={'data-text'}>
                    <h6>Public notes</h6>
                    <p>{purchaseOrderData?.inventories[0]?.public_notes || '-'}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Internal notes</h6>
                    <p>{purchaseOrderData?.inventories[0]?.internal_notes || '-'}</p>
                </div>
                <div className={'data-text'}>
                    <h6>Items</h6>
                    <p>{purchaseOrderData?.inventories?.length}</p>
                </div>
            </div>
            {purchaseOrder &&
                <>
                    <div className={'inventory-data-info'}>
                        <div className={'data-text'}>
                            <h6>P.O. public notes</h6>
                            <p>{purchaseOrder!.public_notes || '-'}</p>
                        </div>
                        <div className={'data-text'}>
                            <h6>P.O. internal notes</h6>
                            <p>{purchaseOrder!.internal_notes || '-'}</p>
                        </div>
                        <div className={'data-text'}>
                            <h6>Due date</h6>
                            <p>{dateToLocaleString(purchaseOrder!.due_date)}</p>
                        </div>
                    </div>
                    <div className={'inventory-data-info'}>
                        <div className={'data-text'}>
                            <h6>P.O. external reference</h6>
                            <p>{purchaseOrder!.external_reference || '-'}</p>
                        </div>
                        <div className={'data-text'}>
                            <h6>P.O. currency</h6>
                            <p>{purchaseOrder!.currency || '-'}</p>
                        </div>
                    </div>
                </>
            }
            <Formik
                initialValues={{
                    public_notes: '',
                    external_reference: '',
                    due_date: '',
                    internal_notes: ''
                }}
                validationSchema={EditPOValidationSchema}
                onSubmit={async (values, {resetForm}) => {
                    await handleSubmit(values, resetForm);
                }}
            >
                {({isSubmitting, resetForm}) =>
                    <Form className="change-po-form">
                        <h5>Change P.O. data</h5>
                        <div className={'edit-fields'}>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Public notes</label>
                                <DefaultFormikInput name={'public_notes'}
                                                    placeholder={'Public notes'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Internal notes</label>
                                <DefaultFormikInput name={'internal_notes'}
                                                    placeholder={'Internal notes'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">External reference</label>
                                <DefaultFormikInput name={'external_reference'}
                                                    placeholder={'External reference'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Due date</label>
                                <AppDatePicker name={'due_date'} placeholder={'Due date'}
                                               customClass={'add-inventory-form-input w-100'}/>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Vendor*</label>
                                <AppSmartSearch
                                    inputId={'vendor_input'}
                                    name={'user_vendor_id'}
                                    placeholder={'Vendor'}
                                    customClass={'add-inventory-form-input'}
                                    requestFunction={userVendorSearchByDisplayName}
                                    valuesKey={'display_name'}
                                    showErrorOnFocus={false}
                                />
                                <button className="hold__form_customer-button mb-2" type="button"
                                        onClick={() => handleVendorModal()}>Create
                                </button>
                            </div>
                        </div>
                        <div className={'buttons-container mt-3'}>
                            <button
                                className="add-inventory-form-submit-btn button-gray"
                                type="button"
                                onClick={() => navigate(-1)}
                            >
                                Dismiss
                            </button>
                            <button
                                className="add-inventory-form-submit-btn" type="submit"
                                disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
            <AppModal
                headerClassName={'customers-modal-header'}
                headerTitleClassName={'customers-modal-header__title'}
                size={'lg'}
                backdrop={'static'}
                keyboard={false}
                title={'Create new vendor'}
                form={
                    <CustomerOrVendorForm
                        customerFormMode={false}
                        onClose={() => handleVendorModal()}
                        tags={tags}
                        selectedTags={selectedVendorTags}
                        setSelectedTags={setSelectedVendorTags}
                        vendor={undefined}
                        editMode={false}
                    />
                }
                show={isModalOpen}
                showHandle={handleVendorModal}
                onClose={() => handleVendorModal()}
            />
        </div>
    )
}

export default EditPurchaseOrderPage;
