import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {User} from '../models/User';
import {RolesEnum} from '../enums/RolesEnum';

type Props = {
  children: JSX.Element,
  user: User | undefined,
  roles?: RolesEnum[]
  redirectPath?: string
};

const PrivateRoute: FC<Props> = ({children, user, roles, redirectPath}) => {
  redirectPath ??= '/';

  if (!user) {
    Navigate({to: '/login'});
    return (<></>);
  }

  if (roles && !roles.includes(user.role_id)) {
    Navigate({to: redirectPath});
    return (<></>);
  }

  return children;
};

export default PrivateRoute;
