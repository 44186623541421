import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PurchaseOrder} from "../../models/PurchaseOrder";

interface PurchaseOrderState {
    purchaseOrder: PurchaseOrder | undefined
}

const initialState: PurchaseOrderState = {
    purchaseOrder: undefined
};

export const purchaseOrder = createSlice({
    name: 'purchaseOrder',
    initialState,
    reducers: {
        setPurchaseOrder: (state: PurchaseOrderState, action: PayloadAction<PurchaseOrder>) => {
            state.purchaseOrder = action.payload
        },

        updatePurchaseOrder: (state, action: PayloadAction<{ public_notes: string, internal_notes: string, due_date: string, external_reference: string, user_vendor_id: number }>) => {
            if (state.purchaseOrder) {
                state.purchaseOrder.public_notes = action.payload.public_notes;
                state.purchaseOrder.internal_notes = action.payload.internal_notes;
                state.purchaseOrder.due_date = action.payload.due_date;
                state.purchaseOrder.user_vendor_id = action.payload.user_vendor_id;
                state.purchaseOrder.external_reference = action.payload.external_reference
            }
        }
    },
});

export const {setPurchaseOrder, updatePurchaseOrder} = purchaseOrder.actions;


export default purchaseOrder.reducer;
