import React, {FC} from "react";
import {ErrorMessage, Form, Formik} from "formik";
import DefaultFormikInput from "../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {venuesNotPaginated} from "../../../../../../@api/Venue";
import AppSmartSearch from "../../../../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import './RequestEventForm.scss';
import AppDatePicker from "../../../../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {RequestEventFormValidation} from "./RequestEventFormValidation";
import {RequestNewEvent} from "../../../../../../@interfaces/RequestNewEvent";
import {requestNewEvent} from "../../../../../../@api/Event";
import {HttpSuccessResponse} from "../../../../../../@api/Responses/HttpSuccessResponse";

interface Props {
    close: () => void;
}

const RequestEventForm: FC<Props> = ({close}) => {
    const formInitial: RequestNewEvent = {
        event_name: '',
        venue: '',
        date: undefined,
        link_to_primary: ''
    };

    const handleSubmit = async (values: RequestNewEvent) => {
        const res = await requestNewEvent(values)
        if (res instanceof HttpSuccessResponse) {
            close();
        } else {
            alert(res.message)
        }
    };

    return (
        <>
            <>
                <Formik initialValues={formInitial} onSubmit={handleSubmit}
                        validationSchema={RequestEventFormValidation}
                >
                    {({isValid, isSubmitting}) =>
                        <Form className={'request-event-form'}>

                            <div className={'request-event-form_inputs'}>

                                <p className={'form-inputs_item-header'}>Event name:</p>
                                <div className={'input-width'}>
                                    <DefaultFormikInput
                                        style={{width: '100%'}}
                                        name={'event_name'}
                                        placeholder={'Event Name'}
                                        autocomplete={'off'}
                                        type={'text'}
                                    ></DefaultFormikInput>
                                </div>
                            </div>

                            <div className={'request-event-form_inputs'}>
                                <p className={'form-inputs_item-header'}>Venue:</p>
                                <AppSmartSearch
                                    inputId={'request-event-venue'}
                                    showErrorOnFocus={false}
                                    name={'venue'}
                                    placeholder={'Start typing'}
                                    requestFunction={venuesNotPaginated}
                                    valuesKey={'venue'}
                                />
                            </div>
                            <div className="request-event-form_inputs">
                                <p className={'form-inputs_item-header'}>Date:</p>
                                <div className={'input-width'}>
                                    <div className="form-inputs_item__field-error">
                                        <AppDatePicker
                                            name={'date'}
                                            placeholder={'Date'}
                                        />
                                        <ErrorMessage name="date" component="div" className="error-message"/>
                                    </div>
                                </div>
                            </div>
                            <div className={'request-event-form_inputs'}>
                                <p className={'form-inputs_item-header'}>Link to primary:</p>
                                <div className={'input-width'}>
                                    <DefaultFormikInput
                                        name={'link_to_primary'}
                                        placeholder={'Link to primary'}
                                        autocomplete={'off'}
                                        type={'text'}
                                    ></DefaultFormikInput>
                                </div>
                            </div>
                            <span>The 'Link to Primary' is the URL from which the tickets were originally purchased (i.e. the primary market). Examples of this may include TicketMaster, AXS, Tickets.com, etc.</span>
                            <button disabled={!isValid || isSubmitting}
                                    className={'filters-button-apply mt-3 self-align'}
                                    type={'submit'}>Request
                            </button>
                        </Form>
                    }
                </Formik>
            </>
        </>
    )
}

export default RequestEventForm;
