import React, {FC, useEffect, useState} from 'react';
import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/Store';
import {logout} from '../../../store/Reducers/Auth';
import './LandingHeader.scss';
import bell from "../../../assets/images/other/bell.svg";
import {PortalNotification} from "../../../models/PortalNotification";
import {
    getLastReleaseNotes,
} from "../../../@api/PortalNotification";
import {PortalNotificationEnum} from "../../../enums/PortalNotificationEnum";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {logoutRequest} from "../../../@api/Auth";
import {identifyPendo} from "../../../helpers/Pendo";

const LandingHeader: FC = () => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const location = useLocation();

    const logoutClick = async () => {
        await logoutRequest();
        dispatch(logout());
        identifyPendo();
    }

    const [checkStatus, setCheckStatus] = useState<boolean>(true);
    const [items, setItems] = useState<PortalNotification[]>([]);

    useEffect(() => {
        getLastReleaseNotes({
            type: PortalNotificationEnum.release_note,
            limit: 2,
            order_direction: "DESC"
        })
            .then((res) => {
                if (res instanceof HttpSuccessResponse) {
                    setCheckStatus(res.data.viewedStatus)
                    setItems(res.data.items);
                }
            })
    }, []);

    return (
        <HeaderWrapper>
            <div className="landing-header">
                <div className={'header-logo-wrapper'}>
                    <Link className="header-logo" to="/">Broker B2B</Link>
                    <button className={'release-note-button'} id="olvy-whats-new">
                        <img className={'pointer'} src={bell} alt="bell" draggable="false"/>
                    </button>
                </div>

                <div className={'landing-header__navbar-left-block'}>
                    {loggedUser
                        ? <>
                            <Button variant="light" className="landing-header__navbar-login-button"
                                    onClick={logoutClick}>Logout</Button>
                        </>
                        :
                        <>
                            {location.pathname !== '/needs-list' &&
                                (
                                    <NavLink to="/needs-list" className={'needs-list-link'}>
                                        Needs list
                                    </NavLink>
                                )}
                            < NavLink to="/login">
                                <Button variant="light" className="landing-header__navbar-login-button">
                                    Login / Register
                                </Button>
                            </NavLink>
                        </>
                    }
                </div>
            </div>
        </HeaderWrapper>
    );
};

export default LandingHeader;
