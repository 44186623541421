import React, {FC} from "react";
import {Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../Inputs/DefaultFormikInput/DefaultFormikInput";
import {setInvoiceUnitCostRequest} from "../../@api/Invoice";
import {Invoice} from "../../models/Invoice";

interface CostFormProps {
    invoice: Invoice;
    showHandle: () => void;
}

const AmountForm: FC<CostFormProps> = ({invoice, showHandle}) => {
    const setCost = async (values: FormikValues) => {
        const res = await setInvoiceUnitCostRequest(invoice.id, values);

        alert(res?.message);
        showHandle();
    };

    return (
        <Formik
            initialValues={{
                amount: invoice.amount,
            }}
            onSubmit={async (values) => {
                const data = {
                    ...values
                };

                await setCost(data);
            }}
        >
            {({isSubmitting}) =>
                <Form>
                    <label htmlFor="amount">Amount</label>
                    <DefaultFormikInput
                        id='amount'
                        name='amount'
                        type='number'
                        placeholder='Amount'
                        autocomplete={'off'}
                        class={'auth-wrapper__input'}
                    ></DefaultFormikInput>
                    <button className="auth-wrapper__submit-btn mt-3" type="submit"
                            disabled={isSubmitting}>
                        Set
                    </button>
                </Form>
            }
        </Formik>
    )
}

export default AmountForm;
