import {PaginationParams} from '../@interfaces/PaginationParams';
import {User} from '../models/User';
import requestWrapper from './RequestWrapper';
import axios from 'axios';
import {paginationParamsForAxios} from '../helpers/PaginationParamsForAxios';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {PaginationResponseData} from '../@interfaces/Response/PaginationResponseData';
import {UserStatusesEnum} from '../enums/UserStatusesEnum';
import {TicketData} from "../pages/User/SellPage/SellTicketPage/Data";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {FormikValues} from "formik";
import {Inventory} from "../models/Inventory";
import {ApiKey} from "../models/ApiKey";
import {UserSettingsResponse} from "../@interfaces/Response/User";
import { IntegrationsEnum } from '../enums/IntegrationsEnum';
import {EventIntegrationCustomPrice} from "../models/EventIntegrationCustomPrice";

const urlPrefix = 'users';

export const usersPaginateRequest = (params: PaginationParams<User>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<User>>(axios.get(`${urlPrefix}`, {
        params: {...data, ...params},
    }));
};

export const getUserRequest = (usesId: string | undefined) => {
    return requestWrapper<GlobalStatusCodesEnum, User>(
        axios.get(`${urlPrefix}/` + usesId),
    );
};

export const updateUserStatusRequest = (usesId: number, status: UserStatusesEnum) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.patch(`${urlPrefix}/` + usesId + '/status', {status}),
    );
};

export const updateUserVendorIdRequest = (usesId: number, newVendorId: number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.patch(`${urlPrefix}/` + usesId + '/vendor_id', {newVendorId}),
    );
};

export const updateUserShowingIntegrations = (userId: number, userUpdateData: string[]) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.patch(`${urlPrefix}/` + userId + '/integrationShowingStatuses', {active_integrations: userUpdateData})
    );
}

export const deleteUserRequest = (usesId: number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${urlPrefix}/` + usesId),
    );
};

export const updateUserRequest = (userId: number, userUpdateData: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${userId}`, userUpdateData),
    );
};

export const updateUserLogoRequest = (userId: number, file: FormData) => {
    const headers = {
        'content-type': 'multipart/form-data',
    };

    return requestWrapper<GlobalStatusCodesEnum, User>(
        axios.post(`${urlPrefix}/${userId}/logo`, file, {headers: headers}),
    );
}

export const updatePasswordUserRequest = (userId: number, userUpdateData: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.patch(`${urlPrefix}/${userId}/password`, userUpdateData),
    );
};

export const sendTicketRequest = (ticketData: TicketData) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post('/inventories', ticketData),
    );
};

export const editTicketRequest = (inventoryId: number, ticketData: TicketData | FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, Inventory>(
        axios.put(`/inventories/${inventoryId}/edit`, ticketData),
    );
};

export const getInventoryCustomPriceRequest = (inventoryId: number) => {
    return requestWrapper<GlobalStatusCodesEnum, EventIntegrationCustomPrice[]>(
        axios.get(`/inventories/${inventoryId}/integration/custom-price`),
    );
};


export const setInventoryCustomPriceRequest = (inventoryId: number, integrationType: IntegrationsEnum, customPrice: number | null) => {
    return requestWrapper<GlobalStatusCodesEnum, Inventory>(
      axios.put(`/inventories/${inventoryId}/integration/custom-price`, {integration_type: integrationType, custom_price: customPrice}),
    );
};

export const getTeammatesRequest = () => {
    return requestWrapper<GlobalStatusCodesEnum, User[]>(
        axios.get(`${urlPrefix}/teammates`)
    );
};

export const deleteTeammate = (userId: number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${urlPrefix}/teammates/` + userId)
    );
};

export const addTeammate = (values: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, User>(
        axios.post(`${urlPrefix}/teammates/add`, values)
    );
};

export const getApiKeys = () => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}/api-keys`)
    );
}

export const updateApiKeys = (params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, ApiKey>(
        axios.put(`${urlPrefix}/api-keys`, {...params})
    );
}

export const getUserLogoAsBase64 = (userId: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { logo_as_base64: string }>(
        axios.get(`${urlPrefix}/${userId}/logo-as-base64`)
    );
}


export const getUserSettings = () => {
    return requestWrapper<GlobalStatusCodesEnum, UserSettingsResponse>(
        axios.get(`${urlPrefix}/settings`)
    );
}

export const setPreferredCurrencySetting = (currency: string) => {
    return requestWrapper<GlobalStatusCodesEnum, UserSettingsResponse>(
        axios.post(`${urlPrefix}/settings`, {preferred_currency: currency})
    );
};
