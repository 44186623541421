import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";

const urlPrefix = 'p1_series';

export const getSeries = (categoryId?: number) => {
    return requestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}`, categoryId ? {
            params: {
                category_id: categoryId
            }
        } : undefined)
    );
};
