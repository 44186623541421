import {CalendarDisplayData} from "../../@interfaces/Euro/CalendarDisplayData";

export const getDatesForMonth = (month: number, year: number = 2024): CalendarDisplayData[] => {
    let resultArray = [];
    let firstDate = new Date(year, month, 1);
    let dayOfWeek = firstDate.getDay();
    firstDate.setDate(firstDate.getDate() - dayOfWeek);

    for (let i = 0; i < dayOfWeek; i++) {
        resultArray.push(
            {
                month: firstDate.getMonth(),
                date: firstDate.getDate(),
                active: false,
                vendor: null
            }
        );
        firstDate.setDate(firstDate.getDate() + 1);
    }

    let currentMonth = month;
    while (currentMonth === month) {
        resultArray.push(
            {
                month: firstDate.getMonth(),
                date: firstDate.getDate(),
                active: true,
                vendor: null
            }
        );
        firstDate.setDate(firstDate.getDate() + 1);
        currentMonth = firstDate.getMonth();
    }

    dayOfWeek = firstDate.getDay();

    if (!dayOfWeek) {
        return resultArray;
    }

    for (let i = 0; i < 7 - dayOfWeek; i++) {
        resultArray.push(
            {
                month: firstDate.getMonth(),
                date: firstDate.getDate(),
                active: false,
                vendor: null
            }
        );
        firstDate.setDate(firstDate.getDate() + 1);
    }
    return resultArray;
}
