import * as Yup from 'yup';

export const EventFormValidationSchema = Yup.object().shape({
    quantity: Yup.number().required('Required').min(1).typeError('Must be a number!'),
    section_or_category: Yup.string().required('Required').max(51),
    row: Yup.string().max(45).required('Required'),
    ready_to_buy: Yup.string().required('Required'),
    single_seats: Yup.number().when('split_type', {
        is: 'Some singles acceptable',
        then: Yup.number().required('Required').positive('Must be a positive number')
    }),
    price: Yup.number()
        .when('ready_to_buy', {
            is: 'Ready',
            then: Yup.number().required('Required').positive('Must be a positive number')
        })
});
