import {HttpFailureResponse} from './Responses/HttpFailureResponse';
import {HttpSuccessResponse} from './Responses/HttpSuccessResponse';
import axios from 'axios';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import createResponseByAxiosResponse from '../helpers/CreateResponseByAxiosResponse';

const requestWrapper = async <Status extends GlobalStatusCodesEnum = GlobalStatusCodesEnum, SR = any, FR = any>(
  request: Promise<any>,
  validationMapper?: Record<string, string>,
): Promise<HttpSuccessResponse<Status, SR> | HttpFailureResponse<Status, FR>> => {
  try {
    const res = await request;

    return createResponseByAxiosResponse<Status, SR, FR>(res, validationMapper);
  } catch (e: unknown) {
    return axios.isAxiosError(e)
      ? HttpFailureResponse.createByAxiosError(e, validationMapper)
      : HttpFailureResponse.createByError(e as Error);
  }
};

export default requestWrapper;
