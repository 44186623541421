import React, {FC, useEffect, useState} from "react";
import AppTable from "../../../../../ui/Table/AppTable/AppTable";
import {Container} from "react-bootstrap";
import usePagination from "../../../../../helpers/UsePagination";
import {Inventory} from "../../../../../@interfaces/Skybox/Inventory";
import {getNeededInventory} from "../../../../../@api/Inventory";
import AppButton from "../../../../../ui/Buttons/AppButton/AppButton";
import AppModal from "../../../../../ui/Modal/AppModal/AppModal";
import NeedListForm from "./NeedListForm/NeedListForm";
import AppLoader from "../../../../../ui/Loader/AppLoader/AppLoader";

const NeedList: FC = () => {
    const pagination = usePagination<Inventory>(getNeededInventory);

    const tableHeaders = [
        'Purchase ID',
        'Event Name',
        'Event Date&Time',
        'Section',
        'Quantity',
        ''
    ];

    const [neededInventory, setNeededInventory] = useState<Inventory | null>(null);

    const handleCloseModal = () => {
        setNeededInventory(null);
    };

    useEffect(() => {
        pagination.paginate();
    }, []);

    return (
        <Container>
            {pagination.items.length ?
                <AppTable columns={tableHeaders} pagination={pagination}>
                    <>
                        {pagination.items.length ?
                            pagination.items.map((inventory, index) => (
                                <>
                                    <tr key={index} data-row={index}>
                                        <td>{inventory.purchaseIds[0]}</td>
                                        <td>{inventory.event.name}</td>
                                        <td>{inventory.event.date.split('T')[0]} {inventory.event.date.split('T')[1]}</td>
                                        <td>{inventory.section}</td>
                                        <td>{inventory.quantity}</td>
                                        <td>
                                            <div onClick={() => setNeededInventory(inventory)}>
                                                <AppButton text={'Offer'} color={'red'}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <AppModal
                                        size={'lg'}
                                        title={'Make an offer'}
                                        form={
                                            <NeedListForm
                                                inventory={neededInventory}
                                                onClose={handleCloseModal}
                                            />
                                        }
                                        show={neededInventory?.id === inventory.id}
                                        showHandle={() => handleCloseModal()}
                                        onClose={() => {
                                        }}
                                    />
                                </>
                            ))
                            :
                            <tr>
                                <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No
                                    results
                                </td>
                            </tr>
                        }
                    </>
                </AppTable>
                :
                <div className="position-absolute top-50 start-50">
                    <AppLoader/>
                </div>
            }

        </Container>
    )
}

export default NeedList;
