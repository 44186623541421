import * as yup from "yup";
import {appSmartSearchRule} from "../../helpers/YupRules";

export const InvoiceValidationSchema = yup.object().shape({
    customer_display_name: yup.string().required('Required').typeError('Enter name!').test(appSmartSearchRule('customer_input')),
    fulfillment_status: yup.string().required('Required'),
    invoice_status: yup.string().required('Required'),
    internal_notes: yup.string().max(11000).nullable(),
    public_notes: yup.string().max(11000).nullable(),
    price: yup.number().required('Required!').test(
        'is-decimal',
        'Invalid',
        (value) => {
            if (value !== undefined) {
                return /^\d+(\.\d{1,2})?$/.test(String(value));
            }
            return true;
        }
    )
});
