import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";

const urlPrefix = '/integrations/skybox/extra-props/ticket_disclosures'
export const getSbDisclosures = () => {
    return RequestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}`)
    );
};
