import {omit} from 'lodash';
import {ErrorMessageParams} from '../../../@interfaces/ErrorMessageParams';

const InputTooltipError = ({message, show, attributes}: ErrorMessageParams) => {
  const inputClasses = attributes?.class ? attributes?.class : '';
  attributes = omit(attributes, ['class']);

  return (
    <div className={`${inputClasses} ${show() ? 'd-block' : 'd-none'} invalid-tooltip w-100`}
         {...attributes}
    >
      {message}
    </div>
  );
};

export default InputTooltipError;
