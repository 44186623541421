import LogRocket from 'logrocket';

export const initLogRocket = () => {
    if (process.env.REACT_APP_ENV !== 'production' || !process.env.REACT_APP_LOG_ROCKET_APP_ID) {
        return;
    }

    try {
        LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
    } catch (e) {
        console.error(e);
    }
};

export const identifyLogRocket = (userId: string, params: any = {}) => {
    if (process.env.REACT_APP_ENV !== 'production' || !process.env.REACT_APP_LOG_ROCKET_APP_ID) {
        return;
    }

    try {
        LogRocket.identify(userId, {...params});
    } catch (e) {
        console.error(e);
    }
};
