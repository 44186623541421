import Table from "react-bootstrap/Table";
import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import './BrokerPools.scss'
import {getHiddenUsers, getPoolUsers, getUserPools, handleHiddenUsers} from "../../../../../@api/Pools";
import {Pool} from "../../../../../models/Pool";
import {User} from "../../../../../models/User";
import {HiddenUser} from "../../../../../models/HiddenUser";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";

const BrokerPools = () => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);

    const [pools, setPools] = useState<Pool[]>([])
    const [poolUsers, setPoolUsers] = useState<User[]>([])
    const [selectedPoolId, setSelectedPoolId] = useState<number>(0)
    const [hiddenUserIds, setHiddenUserIds] = useState<HiddenUser[]>([]);

    useEffect(() => {
        getMyPools();
    }, []);

    useEffect(() => {
        if (selectedPoolId) {
            fetchHiddenUsers()
        }
    }, [selectedPoolId]);

    const getMyPools = async () => {
        const res = await getUserPools()
        setPools(res.data)
    }

    const handleChangePool = async (e: BaseSyntheticEvent) => {
        const poolId = e.target.value;
        const res = await getPoolUsers(poolId);
        setPoolUsers(res.data);
        setSelectedPoolId(poolId);
    }

    const handleCheckboxChange = async (e: BaseSyntheticEvent, userId: number) => {
        try {
            await handleHiddenUsers(userId, selectedPoolId);
            e.target.checked = !e.target.checked;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const fetchHiddenUsers = async () => {
        const res = await getHiddenUsers();
        setHiddenUserIds(res.data);
    }

    const hiddenUser = (user: User): boolean => {

        if (loggedUser && loggedUser.hidden_users) {
            const foundHiddenUsers = loggedUser.hidden_users.find((el: HiddenUser) =>
                el.pool_id == selectedPoolId && user.id == el.banned_user_id
            );

            return !foundHiddenUsers;
        }

        return true;
    }

    const handleCheckboxStatus = (e:BaseSyntheticEvent) => {
        e.target.checked = !e.target.checked;
        e.preventDefault();
    }

    return (
        <div className={'pools'}>
            <h2 className={'pools_header'}>Select the users whose tickets you want to see</h2>
            <div className={'pools_container'}>
                <div className={'pools_container-select'}>
                    <p className={'pools_container-header'}>Select your pool</p>
                    <select onChange={(e) => handleChangePool(e)}>
                        <option disabled value="" selected>Select pool</option>
                        {pools.map((pool) => (
                                <option key={pool.id} value={pool.id}>{pool.name}</option>
                            )
                        )}
                    </select>
                </div>
            </div>
            <div>
                <Table className={'pools-table'}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Vendor name</th>
                        <th>Display tickets</th>
                    </tr>
                    </thead>
                    <tbody>
                    {poolUsers.length ?
                        poolUsers.map((user, index) => (
                            <tr key={user.id}>
                                <td>{index + 1}</td>
                                <td>{user.sb_vendor?.name || '-'}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        name={`user_${user.id}`}
                                        value={user.id}
                                        onChange={(e) => {
                                            handleCheckboxChange(e, user.id);
                                            handleCheckboxStatus(e);
                                            }
                                        }
                                        checked={hiddenUser(user)}
                                    />
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={10}>
                                Select pool
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default BrokerPools;
