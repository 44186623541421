import {SplitTypes} from "../enums/Skybox/SplitTypes";

export const SPLIT_TYPES_LABEL = [
    {
        key: SplitTypes.Custom,
        label: 'Custom'
    },
    {
        key: SplitTypes.Any,
        label: 'Any'
    },
    {
        key: SplitTypes.NeverLeaveOne,
        label: 'Never Leave One'
    },
    {
        key: SplitTypes.Default,
        label: 'Default'
    },
];
