import RequestWrapper from "../RequestWrapper";
import {GlobalStatusCodesEnum} from "../../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {EuroCategory} from "../../models/Euro/EuroCategory";
import {EuroEvent} from "../../models/Euro/EuroEvent";
import {EuroQuantityCombination} from "../../models/Euro/EuroQuantityCombination";
import {Inventory} from "../../models/Inventory";
import {EuroVendor} from "../../models/Euro/EuroVendor";
import {CalendarResponseDataType} from "../../@interfaces/Euro/CalendarResponseDataType";

const urlPrefix = 'euro';

export const getEuroStaticData = (params: { only_available_events: boolean } = {only_available_events: false}) => {
    return RequestWrapper<GlobalStatusCodesEnum, {
        events: EuroEvent[],
        categories: EuroCategory[],
        quantity_combinations: EuroQuantityCombination[],
        vendors: EuroVendor[]
    }>(
        axios.get(`${urlPrefix}/allStaticData`, {params: params})
    );
};

export const getInventoriesForViagogoInvoice = (params: { event_id: string, category: string, price: string }) => {
    return RequestWrapper<GlobalStatusCodesEnum, { inventories: Inventory[] }>(
        axios.get(`${urlPrefix}/inventoriesForViagogoInvoice`, {params: params})
    );
};

export const getEuroCalendarData = (params: { event_id: string, category: string, quantity_combination: string }) => {
    return RequestWrapper<GlobalStatusCodesEnum, { calendar: CalendarResponseDataType }>(
        axios.get(`${urlPrefix}/calendarData`, {params: params})
    );
};

export const updateEuroVendor = (euro_vendor_id: number, data: { pause: boolean }) => {
    return RequestWrapper<GlobalStatusCodesEnum, CalendarResponseDataType>(
        axios.patch(`${urlPrefix}/euroVendor/${euro_vendor_id}`, data)
    );
};

export const checkInventoryEuroStatus = (inventory_id: string) => {
    return RequestWrapper<GlobalStatusCodesEnum, { status: boolean }>(
        axios.get(`${urlPrefix}/checkInventoryEuroStatus`, {params: {inventory_id: inventory_id}})
    );
};


