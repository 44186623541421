import {RegistrationData} from '../../pages/Auth/RegistrationPage/Data';

export const registrationDataAdapter = (data: RegistrationData) => {
  return {
    email: data.email,
    name: data.name,
    phone_number: {
      phone_number: data.phoneNumber?.phone_number,
      alphabet_country_code: data.phoneNumber?.alphabet_country_code,
    },
    company_address: data.companyAddress,
    company_name: data.companyName,
    password: data.password,
    password_confirmation: data.passwordConfirm,
  };
}
