import React, {FC, useState} from 'react';
import './ResetPasswordPage.scss';
import {Form, Formik} from 'formik';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import {resetPassword} from '../../../@api/Auth';
import DefaultFormikInput from '../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import {ResetData, ResetFormValidationSchema} from './Data';
import {useNavigate, useParams} from 'react-router-dom';

const ResetPasswordPage: FC = () => {
  const navigateTo = useNavigate();

  const {token} = useParams();

  const resetFormData = new ResetData();

  const redirectToLogin = () => navigateTo('/login');
  const redirectToResetPage = () => navigateTo('/reset-password-request');

  if (!token) {
    navigateTo('/login');
  }

  return (
    <AuthPageWrapper>
      <div className={'reset-password-request'}>
        <div>
          <p className="reset-password-request__title">Reset password</p>
          <p className="reset-password-request__sub-title">Enter new password</p>
        </div>
        <Formik
          initialValues={{...resetFormData, token}}
          validationSchema={ResetFormValidationSchema}
          onSubmit={async (values, {resetForm}) => {
            const res = await resetPassword(token as string, values.password);

            alert(res.message);
            resetForm();
            redirectToLogin();
          }}
        >
          {({isSubmitting}) =>
            <Form>
              <DefaultFormikInput type={'password'}
                name={'password'}
                placeholder={'Password'}
                class={'auth-wrapper__input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'passwordConfirm'}
                type={'password'}
                placeholder={'Password Confirm'}
                class={'auth-wrapper__input registration-form__last-input'}
              ></DefaultFormikInput>

              <DefaultFormikInput name={'token'} value={token} type={'hidden'}></DefaultFormikInput>

              <button className="auth-wrapper__submit-btn" type="submit" disabled={isSubmitting}>Submit</button>
            </Form>
          }
        </Formik>

        <div className="reset-password-request__row-under-form">
          <div>
            <span className="clickable-word" onClick={() => redirectToResetPage()}>
              New reset request
            </span>
          </div>
        </div>
      </div>
    </AuthPageWrapper>
  );
};

export default ResetPasswordPage;
