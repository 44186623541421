import "./AppLoader.scss";
import React, {FC} from "react";

interface Props {
    fullHeight?: boolean,
    thickness?: number
    size?: number
}

const AppLoader: FC<Props> = ({fullHeight, thickness = 5, size = 48}) => {
    return (
        <div className={"app-loader-wrapper" + (fullHeight ? ' h-100': '')}>
            <span className="app-loader" style={{width: size, height: size, borderWidth: thickness}}></span>
        </div>
    );
}

export default AppLoader;
