import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {Invoice} from "../../../models/Invoice";
import {purchaseHistoryPaginateRequest} from "../../../@api/Invoice";
import {Container} from "react-bootstrap";
import {Field, Form, Formik, FormikState} from "formik";
import {handleOnFiltersChange} from "../../../helpers/Filters";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {PaymentMethods} from "../../../enums/PaymentMethods";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

interface FilterProps {
    date_from: string;
    date_to: string;
    sort_by: string;
}

const TicketExchangePurchaseHistory: FC = () => {
    const tableHeaders = [
        'Invoice Date',
        'Amount',
        'Quantity',
        'Buyer email',
        'Buyer name',
        'Seller email',
        'Seller name',
        'External Ref.',
        'Event Name',
        'Event Date',
        'Payment Method',
        'Public Notes',
        'Fulfillment Status'
    ];

    const filterInitial: FilterProps = {
        date_from: '',
        date_to: '',
        sort_by: 'ASC'
    };

    const dateFilters = [
        {name: 'date_from', placeholder: 'Date From'},
        {name: 'date_to', placeholder: 'Date To'},
    ];

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const pagination = usePagination<Invoice>(purchaseHistoryPaginateRequest, filterInitial);

    useEffect(() => {
        setInvoices(pagination.items);
    }, [pagination.items]);

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Ticket exchange purchase history</h1>
            <div className="mb-3">
                <Formik
                    initialValues={pagination.filterValues as FilterProps}
                    enableReinitialize={true}
                    onSubmit={async (values: FilterProps) => pagination.setFilterValues(values)}
                >
                    {({resetForm, submitForm, isSubmitting, values, setValues}) => {
                        return (
                            <Form className="table__filters"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div className="table__filters__wrapper">
                                    <div className={'col'}>
                                        <Field name={'sort_by'} as="select">
                                            <option value="ASC">Sort by date ↓</option>
                                            <option value="DESC">Sort by date ↑</option>
                                        </Field>
                                    </div>

                                    {dateFilters.map((data, index) => (
                                        <div className={'col'} key={index + 'Dates'}>
                                            <FormikDateInput
                                                name={data.name}
                                                placeholderText={data.placeholder}
                                                showTimeSelect
                                                autoComplete={'off'}
                                                sendDate={(date: string, name: string) =>
                                                    setValues({...values, [name]: date})
                                                }
                                            />
                                        </div>
                                    ))}
                                    <FiltersFormButtons
                                        pagination={pagination}
                                        filterInitial={filterInitial}
                                        submitForm={submitForm}
                                        resetForm={(values) => resetForm(values as FormikState<FilterProps>)}
                                        isSubmitting={isSubmitting}
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <AppTable columns={tableHeaders} pagination={pagination}>
                <>
                    {invoices.length ?
                        invoices.map((invoice, index) => (
                            <React.Fragment key={index}>
                                <tr className={"invoice-section-page__tr"} data-row={index}>
                                    <td>{dateToLocaleString(invoice.date)}</td>
                                    <td>{invoice.amount}</td>
                                    <td>{invoice.quantity}</td>
                                    <td>{invoice.customers ? invoice.customers.email : invoice.customer_email}</td>
                                    <td>{invoice.customers ? invoice.customers.display_name : invoice.customer_name}</td>
                                    <td>{invoice.users?.length ? invoice.users[0].email : invoice.customer_email}</td>
                                    <td>{invoice.users?.length ? invoice.users[0].name : invoice.customer_name}</td>
                                    <td>{invoice.external_reference || invoice.sb_invoice?.external_reference}</td>
                                    <td>{invoice.event_name}</td>
                                    <td>{dateToLocaleString(invoice.event_date)}</td>
                                    <td>{invoice.customers ? PaymentMethods[invoice.customers.payment_method as keyof typeof PaymentMethods] : invoice.sb_invoice?.payment_method}</td>
                                    <td>{invoice.public_notes}</td>
                                    <td></td>
                                </tr>
                            </React.Fragment>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    )
}

export default TicketExchangePurchaseHistory;
