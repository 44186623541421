import React, {FC} from 'react';

const ErrorPage: FC = () => {
  return (
    <div>
      404 page
    </div>
  );
};

export default ErrorPage;
