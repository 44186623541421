import React, {ChangeEvent, FC, useState} from "react";
import "./ImportFileForm.scss";
import {Dropdown} from "react-bootstrap";
import {loadFile} from "../../../@api/Inventory";
import {downloadFile} from "../../../helpers/DownloadFile";

interface ImportFileFormProps {
    showHandle: () => void;
}

const ImportFileForm: FC<ImportFileFormProps> = ({showHandle}) => {
    const [file, setFile] = useState<File>();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = async () => {
        if (!file) {
            return;
        }

        const formData = new FormData();

        formData.append('file', file);

        await loadFile(formData).then((res) => alert(res?.message));

        showHandle();
    }

    const acceptableValues = [
        [
            'Stock Types',
            'Hard: HARD',
            'E-ticket: ELECTRONIC',
            'Flash: FLASH',
            'Mobile QR: MOBILE_SCREENCAP',
            'Mobile Transfer: MOBILE_TRANSFER',
            'Paperless Walk-In: PAPERLESS',
            'Paperless Gift Card: PAPERLESS_CARD'
        ],
        [
            'Split Types',
            'Custom: CUSTOM',
            'Any: ANY',
            'Never Leave One: NEVERLEAVEONE',
            'Default: DEFAULT',
        ],
        [
            'Seat Types',
            'Consecutive: CONSECUTIVE',
            'Odd/Even: ALTERNATING',
            'GA/General Admission: GA',
            'Piggyback: PIGGYBACK',
        ],
    ];

    return (
        <div className="import-file">
            <div className="import-file__wrapper">
                <Dropdown>
                    <Dropdown.Toggle className="import-file__dropdown">?</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className="import-file__dropdown__item" onClick={() => downloadFile('InventoriesExample.xlsx')}>
                            Example XLSX file
                        </Dropdown.Item>
                        <Dropdown.Item className="import-file__dropdown__item" onClick={() => downloadFile('InventoriesTemplate.xlsx')}>
                            Template XLSX file
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <input className="import-file__input" type='file' accept=".xlsx" onChange={handleFileChange}/>
            </div>
            <p>Only 500 lines of listings may be added at one time.</p>
            <div className="import-file__examples">
                <p className="import-file__examples__p-bold">
                    List of acceptable values for required fields:
                </p>
                {acceptableValues.map((values) =>
                    <div className="import-file__examples__wrapper">
                        {values.map((value) =>
                            <p>{value}</p>
                        )}
                    </div>
                )}
            </div>
            <button className="import-file__button" onClick={handleUploadClick}>
                Upload
            </button>
        </div>
    )
}

export default ImportFileForm;