import React, {FC, useEffect, useState} from "react";
import {Container, Dropdown} from "react-bootstrap";
import ActionIconDark from "../../../assets/images/other/action-icon-dark.png";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import {User} from "../../../models/User";
import {deleteTeammate, getTeammatesRequest} from "../../../@api/User";
import AddTeammateForm from "../../../components/TeammatesPage/AddTeammateForm";
import "./TeammatesPage.scss";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

const TeammatesPage: FC = () => {
    const [teammates, setTeammates] = useState<User[] | undefined>(undefined);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getTeammates();
    }, []);

    const tableHeaders = [
        'Full Name',
        'Mobile',
        'Email',
        ''
    ];

    const getTeammates = () => {
        getTeammatesRequest().then(response => {
            setTeammates(response.data.teammates);
        });
    };

    const handleAddClose = () => {
        getTeammates();
        setShow(false);
    };

    const deleteById = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            const res = await deleteTeammate(id);
            alert(res?.message);
            getTeammates();
        }
    };

    return (
        <Container className={'admin-users-page events'}>
            <div className={"teammates__head"}>
                <h1 className={"admin-users-page__title"}>Teammates</h1>
                <div className="user-sell-page__table-block-upload-file">
                    <span onClick={() => setShow(true)}>Add teammate</span>
                </div>
            </div>
            {typeof teammates !== 'undefined' ?
                <AppTable columns={tableHeaders}>
                    <>
                        {teammates && teammates.length ?
                            teammates.map((user, index) =>
                                <tr key={'Teammates' + index} data-row={'Teammates' + index}
                                    className={"teammates__table__tr"}>
                                    <td className={"teammates__table__td"}>{user.name}</td>
                                    <td className={"teammates__table__td"}>{user.formatted_phone_number}</td>
                                    <td className={"teammates__table__td"}>{user.email}</td>
                                    <td className={"teammates__table__td"}>
                                        <Dropdown>
                                            <Dropdown.Toggle className="teammates__table__action-button">
                                                <img src={ActionIconDark}
                                                     alt="action icon"
                                                     className="teammates__table__dropdown-toggle"
                                                     draggable="false"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => deleteById(user.id)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No
                                    results
                                </td>
                            </tr>
                        }
                        <AppModal key={'addModal'} show={show}
                                  showHandle={handleAddClose} title={'Add Teammate'} form={
                            <AddTeammateForm showHandle={handleAddClose}/>
                        } onClose={() => {
                        }}/>
                    </>
                </AppTable>
                :
                <AppLoader/>
            }
        </Container>
    );
}

export default TeammatesPage;
