import React, {FC, Fragment} from "react";
import {Form, Formik, FormikValues} from "formik";
import {updateUserVendorIdRequest} from "../../@api/User";
import * as Yup from "yup";
import DefaultFormikInput from "../Inputs/DefaultFormikInput/DefaultFormikInput";
import {User} from "../../models/User";
import {useNavigate} from "react-router-dom";

interface VendorIdFormProps {
    user: User;
}

const VendorIdForm: FC<VendorIdFormProps> = ({user}) => {
    const navigate = useNavigate();
    const handleSubmitVendorId = async (values: FormikValues) => {
        const res = await updateUserVendorIdRequest(user.id, values.vendor_id);
        alert(res?.message);
        navigate('/admin/users');
    }

    const vendorIdValues = [
        {key: "vendor_id", value: "Vendor ID"}
    ]

    return (
        <div className={"settings-tab-width"}>
            <Formik
                initialValues={{vendor_id: user.vendor_id ?? ''}}
                validationSchema={
                    Yup.object().shape({
                        vendor_id: Yup.number().required('Must enter new vendor ID').typeError('Must be a number!'),
                    })
                }
                onSubmit={handleSubmitVendorId}
            >
                <Form>
                    {
                        vendorIdValues.map((vendorIdValue, index) => (
                            <Fragment key={index}>
                                <label htmlFor="">{vendorIdValue.value}</label>
                                <DefaultFormikInput name={vendorIdValue.key}
                                                    type='number'
                                                    placeholder={vendorIdValue.value}
                                                    autocomplete={'off'}
                                                    class={'auth-wrapper__input'}
                                ></DefaultFormikInput>
                            </Fragment>
                        ))
                    }
                    <button className="auth-wrapper__submit-btn" type="submit">Update</button>
                </Form>
            </Formik>
        </div>
    )
}

export default VendorIdForm
