import * as Yup from "yup";

export const DuplicateFormValidation = Yup.object().shape({
    quantity: Yup.number().min(1, 'Too Short').max(255, 'Too Long, max 255').test('is-even-when-piggyback', 'Quantity must be even when Seat Type is Piggyback', function(value) {
        const seatType = this.parent.seat_type;
        return seatType !== 'PIGGYBACK' || (value !== undefined && value % 2 === 0);
    }).required('Required'),
    low_seat: Yup.number().min(1, 'Too Short').required('Required'),
    row: Yup.string().required('Required'),
    section: Yup.string().required('Required'),
    price: Yup.number().required('Required'),
    second_row: Yup.string().when('seat_type', {
        is: 'PIGGYBACK',
        then: Yup.string().required('Required'),
        otherwise: Yup.string()
    }),
})
