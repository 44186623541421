import './PageLoading.scss';

const PageLoading = () => {
  return (
    <div className={'page-loading'}>
      <div className={'page-loading__loader-wrapper'}>
        <div className="page-loading__loader-box">
          <div className="page-loading__loader-box-shadow"></div>
          <div className="page-loading__loader-gravity">
            <div className="page-loading__loader-ball"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLoading;
