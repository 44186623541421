import React, {FC, useEffect} from "react";
import {Field} from "formik";
import {Inventory} from "../../../models/Inventory";

interface SplitGroupProps {
    groupsCount: number,
    inventory: Inventory,
    options: (quantity: number, start: number) => number[]
}
const SplitGroup: FC<SplitGroupProps> = ({groupsCount = 2, inventory, options}) => {
    let content = [];

    useEffect(() => {
        for (let i = 1; i <= groupsCount; i++) {
            element(i).selectedIndex = -1;
        }
    }, [groupsCount])

    const handleChangeGroups = (event: { target: HTMLSelectElement }) => {
        const newGroupId = Number(event.target.name.replace(/\D/g, ''));

        clearFollowingGroups(newGroupId);
        disableFollowingGroups(newGroupId);
        setAvailableOptions(newGroupId);
    }

    function clearFollowingGroups(start: number) {
        for (let i = start + 1; i <= groupsCount; i++) {
            element(i).value = '1';
        }
    }

    function disableFollowingGroups(start: number) {
        if (start + 1 < groupsCount) {
            const select = element(start + 1)
            select.disabled = false;
        }
        for (let i = start + 2; i <= groupsCount; i++) {
            element(i).disabled = true;
            element(i).selectedIndex = -1;
        }
    }

    function setAvailableOptions(index: number) {
        let splitCount = 0;

        for (let i = 1; i <= index; i++) {
            splitCount += Number(element(i).value);
        }

        if (index + 1 < groupsCount) {
            let select = element(index + 1);
            while (select.options.length > 0) {
                select.remove(0);
            }
            for (let i = 1; i <= (inventory.quantity - splitCount - (groupsCount - index - 1)); i++) {
                select.add(new Option(String(i), String(i)));
            }
            select.selectedIndex = -1;
        }
        else {
            element(index + 1).value = String(inventory.quantity - splitCount);
        }
    }

    const element = (index: number) => {
        return document.getElementsByName('splitGroups['+ index +']')[0] as HTMLSelectElement;
    }

    for (let i = 1; i <= groupsCount; i++) {
        content.push(
            'splitGroups['+ i +']'
        );
    }

    return (
        <div className={'split-form_groups'}>
            {content.map((name: string) =>
                <div className={'split-form_select'}>
                    <label>Group by</label>
                    <Field name={name} as="select"
                           key={name + groupsCount}
                           className={'split-form_select_field'}
                           onChange={handleChangeGroups}
                           disabled={name !== 'splitGroups[1]'}
                    >
                        {options(inventory.quantity - groupsCount + 1, 1)
                            .map((option) =>
                                <option value={option}>{option}</option>
                            )
                        }
                    </Field>
                    <label>tickets</label>
                </div>
            )}
        </div>
    );
}

export default SplitGroup