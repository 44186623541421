import React, {FC, useState} from "react";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {convert, getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import {StockTypes} from "../../../enums/Skybox/StockTypes";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {createBid} from "../../../@api/Bids";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import * as Yup from "yup";
import {Inventory} from "../../../models/Inventory";
import {getInventoryAmount} from "../../../helpers/InventoryHelper";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import './BidForm.scss';

interface Props {
    inventory: Inventory;
    onClose: () => void,
    quantityOptions: { name: string, value: string }[],
    selectedCurrency: string,
}

const BidForm: FC<Props> = ({inventory, onClose, quantityOptions, selectedCurrency}) => {
    const timeOptions = [
        '5 minutes',
        '10 minutes',
        '15 minutes',
        '30 minutes',
        '1 hour',
        '2 hours',
        '1 day '
    ];

    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const [selectedQuantity, setSelectedQuantity] = useState<number>(0);
    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const bid = async (values: FormikValues) => {
        const {quantity, price} = values;

        if (quantity > inventory.quantity || quantity === 0) {
            alert('Please select quantity!');

            return

        } else if (price === 0) {
            alert('Price can not be 0!');

            return
        }

        const res = await createBid(inventory.id, values);

        if (res instanceof HttpSuccessResponse) {
            onClose();
        } else {
            alert(res.message);
        }
    };

    const bidValidationSchema = Yup.object().shape({
        price: Yup.number().typeError('Must be a number').required('Required'),
        duration: Yup.string().notOneOf([''], 'Please select a duration').required('Required')
    });

    const handleSubmit = async (values: any) => {
        const data = {
            ...values,
            user_email: loggedUser!.email,
            quantity: selectedQuantity,
            currency: selectedCurrency
        };

        await bid(data);
    };

    const handleQuantityClick = (id: number, element: React.MouseEvent<HTMLSelectElement>) => {
        const newQuantity = Number((element.target as HTMLSelectElement).value);

        setSelectedQuantity(newQuantity);

        if (inventory.id === id && newQuantity) {
            inventory.quantity = newQuantity;
        }
    };

    const convertedCost = (inventory: Inventory) => {
        const inventoryCost = getInventoryAmount(inventory);
        return Math.ceil(convert(inventory.currency_symbol, selectedCurrency, inventoryCost, currencies));
    };

    return (
        <>
            <div>
                <div className={'buy-inventory__info'}>
                    <div className={'buy-inventory__event'}>
                        <p>{inventory.sb_event.name}</p>
                        <p>{inventory.sb_event.venue}, {inventory.sb_event.city}</p>
                    </div>
                    <div className={'buy-inventory__event-time'}>
                        <p>{dateToLocaleString(inventory.sb_event.occurs_at)}</p>
                    </div>
                </div>
                <div className={'buy-inventory-container'}>
                    <div className={'buy-inventory__listing'}>
                        <div className={'buy-inventory__listing-header'}>
                            <p>QUANTITY</p>
                            <p>SECTION</p>
                            <p>ROW</p>
                        </div>
                        <div className={'buy-inventory__listing-data'}>
                            <p>{inventory.quantity}</p>
                            <p>{inventory.section}</p>
                            <p>{inventory.row}</p>
                        </div>
                    </div>
                    <div className={'buy-inventory__listing'}>
                        <div className={'buy-inventory__listing-header buy-inventory__listing-header_second'}>
                            <p></p>
                            <p>PRICE</p>
                            <p>TOTAL COST</p>
                        </div>
                        <div className={'buy-inventory__listing-data buy-inventory__listing-data_second'}>
                            <p></p>
                            <p>{getCurrencySymbol(selectedCurrency, currencies)}{convertedCost(inventory)}</p>
                            <p>{getCurrencySymbol(selectedCurrency, currencies)}{convertedCost(inventory)}</p>
                        </div>
                    </div>
                </div>
                <div className={'buy-inventory-container'}>
                    <div className={'buy-inventory__listing-info buy-inventory__listing-info_gray'}>
                        <p className={'buy-inventory-container__text'}>IN-HAND DATE</p>
                        <p>{dateToLocaleString(inventory.in_hand_date)}</p>
                    </div>
                    <div className={'buy-inventory__listing-info'}>
                        <p className={'buy-inventory-container__text'}>STOCK TYPE</p>
                        <p>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</p>
                    </div>
                    <div className={'buy-inventory__listing-info buy-inventory__listing-info_gray'}>
                        <p className={'buy-inventory-container__text'}>PUBLIC NOTES</p>
                        <p>{inventory.public_notes}</p>
                    </div>
                </div>
            </div>
            <Formik
                validationSchema={bidValidationSchema}
                initialValues={{
                    price: '',
                    duration: '',
                    currency: selectedCurrency
                }}
                onSubmit={async (values) => handleSubmit(values)}
            >
                {({isSubmitting}) =>
                    <Form className={'bid-inventory-form'}>
                        <div className={'bid-form-container error-relative'}>
                            <div className={'buy-inventory-form__block'}>
                                <p>PRICE PER TICKET</p>
                                <DefaultFormikInput
                                    name={'price'}
                                    placeholder={'Set price'}
                                    autoComplete={'off'}
                                    showErrorOnFocus={false}
                                ></DefaultFormikInput>
                            </div>
                            <div className={'buy-inventory-form__block'}>
                                <p>CHOOSE QUANTITY</p>
                                <select
                                    className={'bid-select'}
                                    name={'quantity'}
                                    onClick={(event) => {
                                        handleQuantityClick(inventory.id, event)
                                    }}
                                >
                                    {
                                        quantityOptions.map((option: {
                                            name: string,
                                            value: string
                                        }) => (
                                            <option key={option.name} value={option.value}>
                                                {option.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={'buy-inventory-form__block'}>
                                <p>NOTES</p>
                                <DefaultFormikInput
                                    name={'notes'}
                                    placeholder={'Notes'}
                                    autoComplete={'off'}
                                ></DefaultFormikInput>
                            </div>
                            <div className={'buy-inventory-form__block '}>
                                <p>BID</p>
                                <Field className={'bid-select'}
                                       name={'duration'}
                                       as={'select'}
                                       placeholder={''}
                                >
                                    <option value=""></option>
                                    {timeOptions.map((option: string) => (
                                        <option>{option}</option>
                                    ))}
                                </Field>
                            </div>
                            <ErrorMessage name="duration" component="div" className="error-message bid-error"/>
                        </div>
                        <div className={'buy-inventory__buttons'}>
                            <div className={'buy-inventory__buttons-item'}>
                                <AppButton disabled={isSubmitting} text={'Submit bid'}
                                           type={'submit'} color={'red'}/>
                            </div>
                            <div onClick={() => onClose()} className={'buy-inventory__buttons-item'}>
                                <AppButton text={'Cancel'} type={'button'} color={'gray'}/>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </>
    )
}

export default BidForm;
