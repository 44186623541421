import React, {FC, FormEvent, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import './WantedTicketsForm.scss';
import DefaultFormikInput from "../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {EventFormValidationSchema} from "../../../WantedEventValidationSchema";
import {NewWantedEvent} from "../../../../../../models/WantedEvent";
import {SbEvent} from "../../../../../../models/Event";
import {dateToLocaleString} from "../../../../../../helpers/DateFormatter";
import AppButton from "../../../../../../ui/Buttons/AppButton/AppButton";
import getSymbolFromCurrency from 'currency-symbol-map'

interface Props {
    onSubmit: (values: NewWantedEvent) => void;
    event?: SbEvent;
    onClose: () => void;
}

const WantedTicketsForm: FC<Props> = ({onSubmit, event, onClose}) => {
    const formInitial = {
        price: 0,
        quantity: 0,
        section_or_category: '',
        row: '',
        notes: '',
        currency_icon: '',
        delivery_method: '',
        split_type: 'All together only',
        single_seats: 0,
        ready_to_buy: ''
    };

    const formValues = [
        {
            name: 'section_or_category',
            placeholder: 'NEEDED SECTION',
            inputName: 'NEEDED SECTION'
        },
        {
            name: 'row',
            placeholder: 'NEEDED ROW',
            inputName: 'NEEDED ROW'
        },
    ];

    const currencyOptions = ['USD', 'EUR', 'GBP'].map(c => ({
        value: getSymbolFromCurrency(c),
        label: c
    }));

    const deliveryMethods = [
        {value: "Electronic delivery only", label: "Electronic delivery only"},
        {value: "Paper and electronic delivery accepted", label: "E-paper and electronic delivery accepted"},
    ];

    const [customSplitValues, setCustomSplitValues] = useState<boolean[]>([]);
    const [readinessToBuy, setReadinessToBuy] = useState<string>('')
    const [showSingleSeatsInput, setShowSingleSeatsInput] = useState(false);

    const onCustomCheckboxChange = (event: MouseEvent) => {
        const target = event.target as HTMLInputElement;

        customSplitValues[Number(target.defaultValue)] = target.checked;
        setCustomSplitValues(customSplitValues);
    };

    const customSplitElements = (value: number, splitTypeCustom: HTMLElement) => {
        for (let i = 1; i <= value; i++) {
            const label = document.createElement('label');
            const input = document.createElement('input');
            const span = document.createElement('span');

            input.type = 'checkbox';
            input.value = String(i);
            input.onclick = onCustomCheckboxChange;
            span.appendChild(document.createTextNode(String(i)));
            label.appendChild(input);
            label.appendChild(span);
            splitTypeCustom.appendChild(label);
        }
    };

    const handleSplitTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';
        setCustomSplitValues([]);

        if (event.target.value === 'Custom') {
            const quantity = document.getElementsByName('quantity')[0] as HTMLInputElement;
            customSplitElements(Number(quantity.value), splitTypeCustom);
        }

        if (event.target.value === 'Some singles acceptable') {
            setShowSingleSeatsInput(true);
        } else {
            setShowSingleSeatsInput(false);
        }
    };

    const handleQuantityChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        if (Number(target.value) < 1) target.value = '1';
        if (Number(target.value) > 99) target.value = '99';
        setCustomSplitValues([]);

        const splitType = document.getElementsByName('split_type')[0] as HTMLSelectElement;
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';

        if (splitType.value === 'Custom') {
            customSplitElements(Number(target.value), splitTypeCustom);
        }
    };

    const handleShowPriceInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setReadinessToBuy(e.target.value);
    };

    return (
        <>
            <div className={'buy-inventory__info'}>
                <div className={'buy-inventory__event'}>
                    <p>{event?.name}</p>
                    <p>{event?.venue}, {event?.city}</p>
                </div>
                <div className={'buy-inventory__event-time'}>
                    <p>{dateToLocaleString(event!.occurs_at)}</p>
                </div>
            </div>
            <Formik initialValues={formInitial} onSubmit={(values) => {
                const data = {
                    ...values, custom_split: customSplitValues
                }
                onSubmit(data);
            }}
                    validationSchema={EventFormValidationSchema}>
                {({isSubmitting}) =>
                    <Form className={'wanted-inventory-form'}>
                        <div className={'bid-form-container'}>
                            <div className={'buy-inventory__listing-info'}>
                                <p>QUANTITY NEEDED</p>
                                <DefaultFormikInput
                                    name="quantity"
                                    placeholder="Enter wanted quantity"
                                    autocomplete={'off'}
                                    type={'number'}
                                    onInput={handleQuantityChange}
                                ></DefaultFormikInput>
                            </div>
                            {formValues.map((formValue, index) => (
                                <div key={formValue.name + index} className={'buy-inventory__listing-info'}>
                                    <p>{formValue.inputName}</p>
                                    <DefaultFormikInput
                                        name={formValue.name}
                                        placeholder={formValue.placeholder}
                                        autocomplete={'off'}
                                        type={'text'}
                                    ></DefaultFormikInput>
                                </div>
                            ))}
                            <div className={'buy-inventory__listing-info'}>
                                <p className={'drop-padding'}>SEATING ARRANGEMENTS</p>
                                <Field
                                    className={'bid-select'}
                                    name="split_type"
                                    as="select"
                                    onClick={handleSplitTypeChange}
                                >
                                    <option value="Custom">Custom</option>
                                    <option value="All together only">All together only</option>
                                    <option value="In pairs or better – no singles acceptable">In pairs or better – no
                                        singles acceptable
                                    </option>
                                    <option value="Any split acceptable without singles">Any split acceptable without
                                        singles
                                    </option>
                                    <option value="Some singles acceptable">Some singles acceptable</option>
                                </Field>
                            </div>
                            {showSingleSeatsInput && (
                                <div className={'buy-inventory__listing-info'}>
                                    <p>NUMBER OF SINGLES</p>
                                    <DefaultFormikInput
                                        name="single_seats"
                                        placeholder="Enter number of singles"
                                        autocomplete={'off'}
                                        type={'number'}
                                    ></DefaultFormikInput>
                                </div>
                            )}
                            <div className={'buy-inventory__listing-info'}>
                                <p>DELIVERY METHOD</p>
                                <Field name="delivery_method" as="select" className={'bid-select'}>
                                    <option disabled value="" selected>METHOD</option>
                                    {deliveryMethods.map((option) => (
                                        <option key={option.value} value={option.value} label={option.label}/>
                                    ))}
                                </Field>
                            </div>
                            <div className={'buy-inventory__listing-info'}>
                                <p>CURRENCY</p>
                                <Field name="currency_icon" as="select" className={'bid-select'}>
                                    <option disabled value="" selected>CURRENCY</option>
                                    {currencyOptions.map((option) => (
                                        <option key={option.value} value={option.value} label={option.label}/>
                                    ))}
                                </Field>
                            </div>
                            <div className={'buy-inventory__listing-info'}>
                                <p>NOTES</p>
                                <DefaultFormikInput
                                    name={'notes'}
                                    placeholder={'ENTER NOTES'}
                                >
                                </DefaultFormikInput>
                            </div>
                            <div className={'buy-inventory__listing-info error-relative'}>
                                <p className={'drop-padding'}>READY TO BUY OR QUOTE NEEDED?</p>
                                <Field
                                    name={'ready_to_buy'}
                                    as="select"
                                    className={'bid-select'}
                                    onClick={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        handleShowPriceInput(e)
                                    }}
                                >
                                    <option disabled value="" selected>SELECT OPTION</option>
                                    <option value="Ready">Ready to buy</option>
                                    <option value="Quote needed">Quote needed</option>
                                </Field>
                                <ErrorMessage
                                    name="ready_to_buy" component="div"
                                    className="error-message wanted-inventory-error"
                                />
                            </div>
                            {readinessToBuy === 'Ready' && (
                                <div className={'buy-inventory__listing-info'}>
                                    <p>Wanted Price</p>
                                    <DefaultFormikInput
                                        name={'price'}
                                        placeholder={'NEEDED PRICE'}
                                        autocomplete={'off'}
                                        type={'text'}
                                    ></DefaultFormikInput>
                                </div>
                            )}
                            <div className={'split-type'}>
                                <div className={"split-type__custom"}></div>
                            </div>
                            <div className={'buy-inventory__buttons'}>
                                <div className={'buy-inventory__buttons-item'}>
                                    <AppButton disabled={isSubmitting} text={'Add Needed Inventory'}
                                               type={'submit'} color={'red'}/>
                                </div>
                                <div onClick={() => onClose()} className={'buy-inventory__buttons-item'}>
                                    <AppButton text={'Cancel'} type={'button'} color={'gray'}/>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </>
    )
}

export default WantedTicketsForm;
