import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {Bids} from "../models/Bids";

const urlPrefix = 'bids';

export const createBid = (id: number, params: any) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.post(`${urlPrefix}/${id}`, params)
    );
};

export const getPlacedBids = () => {
    return requestWrapper<GlobalStatusCodesEnum, Bids>(
        axios.get(`${urlPrefix}/placed`)
    )
};

export const getReceivedBids = () => {
    return requestWrapper<GlobalStatusCodesEnum, Bids>(
        axios.get(`${urlPrefix}/received`)
    )
};

export const rejectBid = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Bids>(
        axios.post(`${urlPrefix}/${id}/reject`)
    )
};

export const acceptBid = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { bid: Bids }>(
        axios.post(`${urlPrefix}/${id}/accept`)
    );
};
