import {SbEvent} from "../../../models/Event";
import {Inventory} from "../../../models/Inventory";
import React, {FC, FormEvent, useEffect, useState} from "react";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {ErrorMessage, Field, Form, Formik} from "formik";
import "./HoldForm.scss";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import {customerSearchByDisplayName} from "../../../@api/Customers";
import {createHold} from "../../../@api/Inventory";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import CustomerOrVendorForm from "../../../pages/User/CustomersPage/CustomerOrVendorForm/CustomerOrVendorForm";
import {Customer} from "../../../models/Customer";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import AppDatePicker from "../../../ui/Inputs/AppDatePicker/AppDatePicker";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {holdEditFormValidation, holdFormValidation} from "./HoldFormValidation";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import ConfusionIcon from '../../../assets/images/other/confusion.svg'
import SuccessResponseModal from "./SuccesResponseModal/SuccessResponseModal";
import {editHold} from "../../../@api/Holds";
import {SbHold} from "../../../models/SbHold";
import {InitialValuesTypes} from "../../../@interfaces/HoldInitialValuesTypes";

interface Props {
    event: SbEvent | undefined,
    inventory: Inventory | undefined,
    editMode?: boolean,
    showHandle: () => void,
    hold: SbHold | undefined
}

const HoldForm: FC<Props> = ({event, inventory, showHandle, editMode, hold}) => {
    const [tags, setTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCostMultiplier, setTotalCostMultiplier] = useState<number>(1);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showCreateCustomer, setShowCreateCustomer] = useState<boolean>(false);
    const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false);
    const [providedExpiryDate, setProvidedExpiryDate] = useState<string | Date>('');
    const [entity, setEntity] = useState<any>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);

    const initialCreate = {
        quantity: "",
        seats: "",
        expiry_date: "",
        customer_id: null,
        external_reference: "",
        customer_name: "",
        customer_email: selectedCustomer?.email ?? ''
    };

    const initialEdit = {
        external_reference: inventory!.hold?.external_reference || hold?.external_reference || '',
        notes: inventory!.hold?.notes || hold?.notes || '',
        expiry_date: inventory!.hold?.expiry_date || hold?.expiry_date ? new Date(inventory!.hold?.expiry_date ?? hold!.expiry_date) : '',
        quantity: inventory!.hold?.quantity || hold?.quantity || '',
        seats: inventory!.hold?.seats || hold?.seats || '',
        customer_id: inventory!.hold?.customer_id || hold?.customer_id || 0,
        customer_name: inventory!.hold?.customer?.display_name || hold?.customer?.display_name || "",
        customer_email: inventory!.hold?.customer?.email || hold?.customer?.email || selectedCustomer?.email || "",
    };

    const initialValues = editMode && (inventory?.hold || hold) ? initialEdit : initialCreate;

    const onQuantityChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const quantity = (inventory!.shown_quantity < inventory!.quantity && inventory!.shown_quantity) ? inventory!.shown_quantity : inventory!.quantity;
        const seatsSelectHTML = document.getElementsByName("seats")[0] as HTMLSelectElement;
        let quantityValue = parseInt(target.value, 10);

        setTotalCostMultiplier(quantityValue);

        while (seatsSelectHTML.options.length > 0) {
            seatsSelectHTML.remove(0);
        }

        if (!target.value) {
            return setTotalCostMultiplier(0);
        }

        if (quantityValue < 1) {
            target.value = "1";
            quantityValue = 1;
        } else if (quantityValue > quantity) {
            target.value = String(quantity);
            quantityValue = quantity;
            setTotalCostMultiplier(quantityValue);
        }

        if (quantityValue === 1) {
            for (let i = inventory!.low_seat; i <= inventory!.high_seat; i++) {
                seatsSelectHTML.add(new Option(`${i}`, `${i}`));
            }
        } else {
            const groupCount = inventory!.high_seat - inventory!.low_seat - quantityValue + 1;

            for (let i = 0; i <= groupCount; i++) {
                const seats = `${inventory!.low_seat + i}-${inventory!.low_seat + i + quantityValue - 1}`;
                seatsSelectHTML.add(new Option(seats, seats));
            }
        }
    };

    const handleHoldSubmit = async (values: InitialValuesTypes) => {
        setIsLoading(true);
        setIsRequestFailed(false);
        setProvidedExpiryDate(values.expiry_date);

        const customerInput = document.getElementById('customer_input') as HTMLInputElement;
        const userTimezoneOffset = new Date(values.expiry_date).getTimezoneOffset() * 60000;

        values.expiry_date = new Date(new Date(values.expiry_date).getTime() - userTimezoneOffset);

        if (!editMode) {
            values.customer_id = selectedCustomer?.id;
            values.customer_email = selectedCustomer!.email;

            if (!values.seats) {
                const seatsSelectHTML = document.getElementsByName("seats")[0] as HTMLSelectElement;

                values.seats = seatsSelectHTML.item(0)!.value;
            }

            const res = await createHold(inventory!.id, values);

            if (res instanceof HttpSuccessResponse) {
                setShowSuccessModal(true);
            }

            setIsLoading(false);

            if (res instanceof HttpFailureResponse) {
                setIsRequestFailed(true);
                return
            }
        }

        if (customerInput.dataset.entityId) {
            values.customer_id = Number(customerInput.dataset.entityId);
        }

        const res = await editHold(hold!.id, values)

        if (res instanceof HttpSuccessResponse) {

            showHandle();
        } else {
            alert(res.message);
        }
    };

    const createCustomer = () => {
        setShowCreateCustomer(true);
    };

    const handelCreateCustomerClose = (customer_id?: number) => {
        setShowCreateCustomer(false);

        return customer_id ?? null;
    };

    const handleSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const catchNewEntity = (item: any) => {
        setEntity(item);
    };

    useEffect(() => {
        setSelectedCustomer(entity);
    }, [entity]);

    useEffect(() => {
        setSelectedCustomer(hold?.customer);
    }, [hold?.customer]);

    return (
        <>
            <div className={'main-info-container'}>
                <div>
                    <p>{event?.name}</p>
                    <p>{event?.venue}, {event?.city}</p>
                </div>
                <p>{dateToLocaleString(event?.occurs_at)}</p>
            </div>
            <div className={'hold-inventory-info'}>
                <div className={'hold-inventory-info__container'}>
                    <div className={'hold-inventory-info__container-header'}>
                        <p className={'hold-inventory-info__container-text'}>
                            QUANTITY
                        </p>
                        <p className={'hold-inventory-info__container-text'}>
                            SECTION
                        </p>
                        <p className={'hold-inventory-info__container-text'}>
                            ROW
                        </p>
                        <p className={'hold-inventory-info__container-text'}>
                            SEATS
                        </p>
                    </div>
                    <div className={'hold-inventory-info__container'}>
                        <div className={'hold-inventory-info__text-container'}>
                            <p className={'hold-inventory-info__text-container-item'}>
                                {inventory!.quantity}
                            </p>
                            <p className={'hold-inventory-info__container-text'}>
                                {inventory!.section}
                            </p>
                            <p className={'hold-inventory-info__container-text'}>
                                {inventory!.row}
                            </p>
                            <p className={'hold-inventory-info__container-text'}>
                                {inventory!.low_seat}-{inventory!.high_seat}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={'hold-inventory-info__container'}>
                    <div className={'hold-inventory-info__container-header'}>
                        <p className={'hold-inventory-info__container-text custom-hold-basis'}>
                            PRICE
                        </p>
                        <p className={'hold-inventory-info__container-text custom-hold-basis'}>
                            TOTAL COST
                        </p>
                    </div>
                    <div className={'hold-inventory-info__container'}>
                        <div className={'hold-inventory-info__text-container'}>
                            <p className={'hold-inventory-info__text-container-item custom-hold-basis'}>
                                {inventory!.currency_icon}{Math.ceil(inventory!.unit_amount)}
                            </p>
                            <p className={'hold-inventory-info__container-text custom-hold-basis'}>
                                {inventory!.currency_icon} {inventory!.unit_amount * inventory!.quantity}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={'hold-inventory-form'}>
                    <div className={'hold-inventory-form__header'}>
                        <p>Hold until:</p>
                    </div>
                    <Formik
                        validationSchema={!editMode ? holdFormValidation : holdEditFormValidation}
                        initialValues={initialValues}
                        onSubmit={(values: InitialValuesTypes) => handleHoldSubmit(values)}>
                        <Form>
                            <div className={'hold-inventory-form__container'}>
                                <div className={'hold-inventory-form__item'}>
                                    <div className={'hold-inventory-form__item-container'}>
                                        <p>
                                            DATE
                                        </p>
                                        <AppDatePicker
                                            monthsShown={2}
                                            name={'expiry_date'}
                                            placeholder={''}
                                        />
                                        <ErrorMessage name="expiry_date" component="div" className="error-message"/>
                                    </div>
                                    <div className={'hold-inventory-form__item-container'}>
                                        <p>
                                            TIME
                                        </p>
                                        <AppDatePicker
                                            dateFormat="h:mm aa"
                                            shouldShowTime={true}
                                            showTimeSelectOnly={true}
                                            timeIntervals={15}
                                            name={'expiry_date'}
                                            placeholder={''}
                                        />
                                    </div>
                                </div>
                                {!editMode ?
                                    <div className={'hold-inventory-form__item'}>
                                        <div className={'hold-inventory-form__item-container'}>
                                            <p>
                                                QUANTITY
                                            </p>
                                            <DefaultFormikInput
                                                name="quantity"
                                                type="number"
                                                autocomplete="off"
                                                onInput={onQuantityChange}
                                            ></DefaultFormikInput>
                                        </div>
                                        <div className={'hold-inventory-form__item-container'}>
                                            <p>Seats</p>
                                            <Field as="select" name="seats"></Field>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className={'hold-inventory-form__item'}>
                                    <div className={'custom-hold-basis'}>
                                        <p>CUSTOMER NAME</p>
                                        <AppSmartSearch name={'customer_name'}
                                                        placeholder={''}
                                                        inputId={'customer_input'}
                                                        initialInputItem={selectedCustomer ?? undefined}
                                                        showErrorOnFocus={false}
                                                        requestFunction={customerSearchByDisplayName}
                                                        setDataFromRequest={setSelectedCustomer}
                                                        valuesKey={'display_name'}/>
                                    </div>
                                    <div className={'customer-button custom-hold-basis'}
                                         onClick={() => createCustomer()}>
                                        <AppButton text={'Create'} color={'gray'}/>
                                    </div>
                                </div>
                                <div className={'hold-inventory-form__item'}>
                                    <div className={'hold-inventory-form__item-container'}>
                                        <p>EXTERNAL REFERENCE</p>
                                        <DefaultFormikInput
                                            name="external_reference"
                                            type="string"
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div className={'hold-inventory-form__item'}>
                                    <div className={'hold-inventory-form__item-container'}>
                                        <p>NOTES</p>
                                        <Field as="textarea" name={'notes'} placeholder={''}/>
                                    </div>
                                </div>
                                {isRequestFailed &&
                                    <div className={'hold-error-container'}>
                                        <img src={ConfusionIcon}
                                             alt={'error-icon'}
                                        />
                                        <p>UNFORTUNATELY, SOMETHING WENT WRONG, THE HOLD IS NOT CREATED. PLEASE, TRY
                                            AGAIN LATER
                                        </p>
                                    </div>
                                }
                                <div className={'hold-inventory-form__item-buttons'}>
                                    <div onClick={showHandle}>
                                        <AppButton style={{minWidth: '150px'}} color={'gray'} type={'button'}
                                                   text={'Cancel'}/>
                                    </div>
                                    <div>
                                        <AppButton style={{minWidth: '150px'}} text={'Save'} type={'submit'}
                                                   disabled={isLoading}/>
                                    </div>
                                </div>
                            </div>
                            <AppModal title="Create Customer"
                                      centered={true}
                                      modalIndex={1}
                                      size={'lg'}
                                      form={
                                          <CustomerOrVendorForm
                                              onClose={handelCreateCustomerClose}
                                              tags={tags}
                                              handleEntity={catchNewEntity}
                                              selectedTags={selectedTags}
                                              customer={undefined}
                                              setSelectedTags={setSelectedTags}
                                          />
                                      }
                                      show={showCreateCustomer} showHandle={handelCreateCustomerClose} onClose={() => {
                            }}/>
                            <AppModal
                                centered={true}
                                title={'SUCCESS!'}
                                form={
                                    <SuccessResponseModal
                                        event={event}
                                        onClose={showHandle}
                                        quantity={totalCostMultiplier}
                                        expiryDate={providedExpiryDate}
                                    />
                                }
                                show={showSuccessModal}
                                showHandle={handleSuccessModal}
                                onClose={() => {
                                }}
                            />
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default HoldForm;
