import * as Yup from 'yup';

export const NeedListFormValidation = (isUserLogged: boolean) => {
    return Yup.object().shape({
        message: Yup.string().required('Required').trim().min(1),
        name: Yup.string().when('isUserLogged', {
            is: () => !isUserLogged,
            then: Yup.string().required('Required').trim().min(1),
        }),
        email: Yup.string().when('isUserLogged', {
            is: () => !isUserLogged,
            then: Yup.string().email('Invalid email').required('Required').trim().min(1)
        }),
        phone: Yup.string().when('isUserLogged', {
            is: () => !isUserLogged,
            then: Yup.string().required('Required').trim().min(1),
        }),
    });
};
