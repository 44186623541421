import React, {FC} from 'react';
import './ResetPasswordRequestPage.scss';
import {Form, Formik} from 'formik';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import {resetPasswordRequest} from '../../../@api/Auth';
import DefaultFormikInput from '../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import {ResetData, ResetFormValidationSchema} from './Data';
import {useNavigate} from 'react-router-dom';

const ResetPasswordRequestPage: FC = () => {
    const navigateTo = useNavigate();

    const resetFormData = new ResetData();

    const redirectToLogin = () => {
        navigateTo('/login');
    };

    return (
        <AuthPageWrapper>
            <div className={'reset-password-request'}>
                <div>
                    <p className="reset-password-request__title">Reset password</p>
                    <p className="reset-password-request__sub-title">Enter email</p>
                </div>
                <Formik
                    initialValues={resetFormData}
                    validationSchema={ResetFormValidationSchema}
                    onSubmit={async (values, {resetForm}) => {
                        const res = await resetPasswordRequest(values.email);

                        alert(res.message);
                        resetForm();
                    }}
                >
                    {({isSubmitting}) =>
                        <Form>
                            <DefaultFormikInput name={'email'}
                                                placeholder={'Email'}
                                                autocomplete={'off'}
                                                class={'auth-wrapper__input'}
                            ></DefaultFormikInput>

                            <button className="auth-wrapper__submit-btn" type="submit" disabled={isSubmitting}>Submit
                            </button>
                        </Form>
                    }
                </Formik>

                <div className="reset-password-request__row-under-form">
                    <div>
            <span className="clickable-word" onClick={() => redirectToLogin()}>
              Log in
            </span>
                    </div>
                </div>
            </div>
        </AuthPageWrapper>
    );
};

export default ResetPasswordRequestPage;
