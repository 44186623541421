export const appSmartSearchRule = (id: string) => {
    return {
        name: 'app-smart-search',
        message: 'Choose one from the list',
        test: (value: any, context: any): boolean => {
            const input = document.getElementById(id) as HTMLInputElement;

            return !(value && !input?.dataset?.entityId) as unknown as boolean;
        }
    }
}

export const checkSelectEmptyValue = (name: string) => {
    return {
        name: 'check-field-has-empty-value',
        message: 'Inventory should be chosen',
        test: (value: any, context: any): boolean => {
            const input = document.getElementsByName(name)[0] as HTMLInputElement;
            return !!(input.value) as boolean;
        }
    }
}

export const checkFieldHasZeroValue = (name: string) => {
    return {
        name: 'check-field-has-empty-value',
        message: 'Value should be number',
        test: (value: any, context: any): boolean => {
            const input = document.getElementsByName(name)[0] as HTMLInputElement;
            return (Number(input.value) !== 0) as boolean;
        }
    }
}

