import React, {FC} from "react";
import {SbEvent} from "../../../../models/Event";
import {dateToLocaleString} from "../../../../helpers/DateFormatter";
import AppButton from "../../../../ui/Buttons/AppButton/AppButton";
import './SuccessResponseModal.scss';

interface Props {
    quantity?: number,
    event?: SbEvent,
    onClose?: () => void;
    expiryDate?: string | Date
}

const SuccessResponseModal: FC<Props> = ({quantity, event, onClose, expiryDate}) => {
    return (
        <>
            <div>
                <div className={'success-response'}>
                    <div className={'success-response__event-container'}>
                        <p>{quantity} TICKET(S) FOR</p>
                        <div>
                            <h6>{event!.name}</h6>
                            <h6>{event!.venue}, {event!.city}</h6>
                        </div>
                    </div>
                    <div className={'success-response__event-container'}>
                        <p>ON HOLD UNTIL</p>
                        <h6>{expiryDate?.toLocaleString()}</h6>
                    </div>
                </div>

                <div onClick={onClose} className={'success-response__button'}>
                    <AppButton text={'DISMISS'} color={'red'}/>
                </div>
            </div>
        </>
    )
}

export default SuccessResponseModal;
