import * as Yup from 'yup';
import {PhoneNumberInfo} from '../../../@interfaces/PhoneNumberInfo';

export class RegistrationData {
  email: string = '';
  phoneNumber: PhoneNumberInfo | undefined;
  name = '';
  companyName: string = '';
  companyAddress = '';
  password = '';
  passwordConfirm = '';
}

export const RegistrationFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Required'),
  phoneNumber: Yup.mixed().required('Required'),
  companyName: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Required'),
  companyAddress: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Required'),
  password: Yup.string().min(6).required('Required'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
});
