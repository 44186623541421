import {FormikFormReset, FormikFormSubmit} from "../../@interfaces/FormikHelpers";
import {handleReset as handleResetFilters} from "../../helpers/Filters";
import AppButton from "../../ui/Buttons/AppButton/AppButton";
import React from "react";
import {Pagination} from "../../helpers/UsePagination";
import {FormikValues} from "formik";

type FiltersFormButtonsProps = {
    filterInitial: FormikValues,
    pagination?: Pagination<any>
    submitForm: FormikFormSubmit<FormikValues>,
    resetForm: FormikFormReset<FormikValues>,
    isSubmitting?: boolean,
    resetLabel?: string,
    applyLabel?: string,
    className?: string
}

const FiltersFormButtons: React.FC<FiltersFormButtonsProps> = (
    {
        filterInitial,
        pagination,
        submitForm,
        resetForm,
        isSubmitting,
        resetLabel = 'Reset',
        applyLabel = 'Apply Filters',
        className = 'col col-md-2'
    }) => {

    const handleReset = pagination ? async () => {
        await handleResetFilters(resetForm, filterInitial, pagination);
    } : () => resetForm();

    return (
        <>
            <div className={`${className} d-flex justify-content-end gap-2 my-1`}>
                <AppButton
                    type={'reset'}
                    color={'gray'}
                    className={'filters-button-reset'}
                    text={resetLabel}
                    onClick={handleReset}
                    disabled={pagination?.isLoading}
                />
                <AppButton
                    isLoading={isSubmitting}
                    className={'filters-button-apply'}
                    text={applyLabel}
                    disabled={pagination?.isLoading}
                    type={'button'}
                    color={'red'}
                    onClick={() => submitForm()}
                />
            </div>
        </>
    );
}

export default FiltersFormButtons;
