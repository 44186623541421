export enum StockTypes {
    HARD = 'Hard',
    ELECTRONIC = 'E-ticket',
    FLASH = 'Flash',
    MOBILE_SCREENCAP = 'Mobile QR',
    MOBILE_TRANSFER = 'Mobile Transfer',
    PAPERLESS = 'Paperless Walk-In',
    PAPERLESS_CARD = 'Paperless Gift Card',
}

export enum StockTypesV2 {
    HARD = 'HARD',
    ELECTRONIC = 'ELECTRONIC',
    FLASH = 'FLASH',
    MOBILE_SCREENCAP = 'MOBILE_SCREENCAP',
    MOBILE_TRANSFER = 'MOBILE_TRANSFER',
    PAPERLESS = 'PAPERLESS',
    PAPERLESS_CARD = 'PAPERLESS_CARD',
}
