import React, {FC, useEffect, useRef, useState} from "react";
import "./CalendarItem.scss";
import Table from "react-bootstrap/Table";
import CalendarCell from "../CalendarCell/CalendarCell";
import {getDatesForMonth} from "../../../../../helpers/Euro/Calendar";
import {QUANTITY_COMBINATIONS} from "../../../../../constants/Euro";
import {CalendarDisplayData} from "../../../../../@interfaces/Euro/CalendarDisplayData";
import {CalendarResponseDataType} from "../../../../../@interfaces/Euro/CalendarResponseDataType";
import useHandleOutsideClick from "../../../../../hooks/useHandleOutsideClick";
import ClosingCross from "../../../../../assets/images/other/red-closing-cross.svg";


interface CalendarItemProps {
    data: CalendarResponseDataType;
    month: number;
    calendarNumber?: string;
    empty?: boolean;
}

const CalendarItem: FC<CalendarItemProps> = ({data, month, calendarNumber = '', empty = false}) => {
    const headerColumns = [
        'S',
        'M',
        'T',
        'W',
        'T',
        'F',
        'S'
    ];

    const [dates, setDates] = useState<CalendarDisplayData[][]>([])
    const [warningShowStatus, setWarningShowStatus] = useState<boolean>(false);

    useEffect(() => {
        setDateInfo();
        setWarningShowStatus(empty);
    }, [data, month, calendarNumber]);


    const navigationRef = useRef<HTMLDivElement>(null);

    useHandleOutsideClick(navigationRef, () => {
        setWarningShowStatus(false);
    });

    const closeNotification = () => {
        setWarningShowStatus(false);
    }

    const setDateInfo = () => {
        let dates = getDatesForMonth(month);

        let previousMonth = month !== 0 ? month - 1 : month;
        let nextMonth = month !== 11 ? month + 1 : month;
        for (let monthIter = previousMonth; monthIter <= nextMonth; monthIter++) {
            dates = fillMonthDatesInfo(monthIter, month, dates);
        }

        let resultArray: CalendarDisplayData[][] = [];
        let bufferArray: CalendarDisplayData[] = [];
        dates.map((date, index) => {
            index += 1;
            if (index % 7 === 0) {
                bufferArray.push(date);
                resultArray.push(bufferArray);
                bufferArray = [];
            } else {
                bufferArray.push(date);
            }
        })

        setDates(resultArray);
    }

    const fillMonthDatesInfo = (monthIter: number, currentMonthNumber: number, dates: CalendarDisplayData[]) => {
        if (data[calendarNumber] && data[calendarNumber].length > 0) {
            data[calendarNumber].map((queue: any) => {
                queue.dates[monthIter + 1].map((date: any) => {
                    let dateItemIndex = dates.findIndex(dateInfo => {
                        return dateInfo.month === monthIter && dateInfo.date === date
                    })
                    dates[dateItemIndex] = {...dates[dateItemIndex], vendor: queue.euro_vendor}
                })
            })
        }

        return dates;
    }

    return (
        <>
            <div className={'calendar-item-wrapper'}>
                <div className={empty ? "calendar-item-container calendar-blur" : "calendar-item-container"}>
                    <div className={"calendar-header"}>
                        <p className={'event-name'}>
                            {
                                data[calendarNumber] && data[calendarNumber].length > 0
                                    ? data[calendarNumber][0].euro_combination.euro_event.sb_event.name
                                    : 'Name event'
                            }
                        </p>
                        <div className={"combination-info"}>
                            <p className={"combination-info-title"}>
                                Quantity of tickets
                                {
                                    data[calendarNumber] && data[calendarNumber].length > 0
                                        ? ' ' + QUANTITY_COMBINATIONS[data[calendarNumber][0].euro_combination.euro_quantity_combination.id - 1]
                                        : ''
                                }
                            </p>
                            <p className={"combination-info-title"}>
                                {
                                    data[calendarNumber] && data[calendarNumber].length > 0
                                        ? data[calendarNumber][0].euro_combination.euro_category.name
                                        : 'Category'
                                }
                            </p>
                        </div>
                    </div>
                    <div className={"calendar-body"}>
                        <Table>
                            <thead className={"stick-header-calendar-dates"}>
                            <tr>
                                {
                                    headerColumns.map((key, index) => (
                                        <th key={index}><CalendarCell value={key} headerCell={true}/></th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                dates.map((datesArray: CalendarDisplayData[]) =>
                                    <>
                                        <tr>
                                            {
                                                datesArray.map((date: CalendarDisplayData) =>
                                                    <td><CalendarCell headerCell={false} dateInfo={date}/></td>
                                                )
                                            }
                                        </tr>
                                    </>
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {
                    warningShowStatus ?
                        <div className={'empty-calendar-modal'} ref={navigationRef}>
                            <div className={'empty-calendar-modal-body'}>
                                <div className={'empty-calendar-modal-content'}>
                                    You do not have a suitable event with the selected category and number of tickets,
                                    try
                                    to select another event from the list or select another category and quantity.
                                </div>
                                <div className={'empty-calendar-modal-close'} onClick={closeNotification}>
                                    <img src={ClosingCross}
                                         alt="sell icon"
                                         className="main-interface-header__toggle-menu-icon"
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>
    )
}

export default CalendarItem;
