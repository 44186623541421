import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Router from './routes/Router';
import './bootstrap';
import {Provider} from 'react-redux';
import {store} from './store/Store';

function App() {
  return (
    <Provider store={store}>
      <Router/>
    </Provider>
  );
}

export default App;
