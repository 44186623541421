import React, {FC, useEffect, useState} from "react";
import './MatchCard.scss';
import {SbEvent} from "../../../../../models/Event";
import {Performer} from "../../../../../models/Performer";

interface EditFormProps {
    event?: SbEvent;
    performer?: Performer;
    clickFunc: () => void
    isSelected?: boolean
    currentEventId?: number
}

const MatchCard: FC<EditFormProps> = ({event, performer, clickFunc, currentEventId, isSelected}) => {

    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        if (isSelected !== undefined) {
            setSelected(isSelected);
        } else if (event && currentEventId !== undefined) {
            setSelected(currentEventId === event.id);
        }
    }, [isSelected, currentEventId, event]);

    const handleClick = () => {
        clickFunc();
        if (currentEventId === undefined) {
            setSelected(!selected);
        }
    }

    return (
        <div className={selected ? "match-card match-card-selected" : "match-card"} data-event-id={event?.event_id} onClick={handleClick}>
            {performer
                ? <p className="match-card-name">
                    {performer.name}
                </p>
                : <>
                    <div className="match-card-description">
                        <div className="match-card-description-place">
                            <h3 className="match-card-description-stadium">{event?.venue}, {event?.city}</h3>
                            <p className="match-card-description-country">Place</p>
                        </div>
                        <div className="match-card-description-calendar">
                            <h3 className="match-card-description-date">{event?.occurs_at.slice(0, 10)}</h3>
                            <p className="match-card-description-time">{event?.occurs_at.slice(11, 19)}</p>
                        </div>
                    </div>
                    <p className="match-card-name">
                        {event?.name}
                    </p></>}
        </div>
    )
}

export default React.memo(MatchCard);
