import { IntegrationsEnum } from '../enums/IntegrationsEnum';
import { IIntegration } from '../@interfaces/IIntegration';

export const integrations: IIntegration[] = [
    {
        shortName: 'VGG',
        name: 'viagogo',
        placeholder: 'Viagogo key',
        key: IntegrationsEnum.Viagogo,
        inputName: 'Viagogo'
    },
    {
        shortName: 'TC',
        name: 'ticombo',
        placeholder: 'Ticombo key',
        key: IntegrationsEnum.Ticombo,
        inputName: 'Ticombo'
    },
    {
        shortName: 'SH',
        name: 'stubhub',
        placeholder: 'StubHub International key',
        key: IntegrationsEnum.Stubhub,
        inputName: 'Stubhub'
    },
    {
        shortName: 'SNet',
        name: 'seatsnet',
        placeholder: 'SeatsNet key',
        key: IntegrationsEnum.Seatsnet,
        inputName: 'Seatsnet'
    },
    {
        shortName: 'LTG',
        name: 'ltg',
        placeholder: 'LTG key',
        key: IntegrationsEnum.Ltg,
        inputName: 'LiveFootballTickets'
    },
]
