import {useState} from 'react';

function dynamicSortOptions(property: string) {
    let sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    if (property === '') return;
    return function (a: any, b: any) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

        return result * sortOrder;
    }
}

export function useDynamicSortOptions() {
    const [sortOptions, setSortOptions] = useState<string>(' ');

    const sortFunction = dynamicSortOptions(sortOptions);

    return {
        sortOptions,
        setSortOptions,
        sortFunction
    };
}
