import {FC, useEffect, useState} from "react";
import {SbEvent} from "../../../../models/Event";
import {getOwnLocalInventories, getSoldLocalInventories} from "../../../../@api/Inventory";
import {Inventory} from "../../../../models/Inventory";
import {HttpSuccessResponse} from "../../../../@api/Responses/HttpSuccessResponse";
import {dateToLocaleString} from "../../../../helpers/DateFormatter";
import './InventoryInfoModal.scss';
import {SplitTypes} from "../../../../enums/Skybox/SplitTypes";
import {getCurrencySymbol} from "../../../../helpers/CurrencyConverter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/Store";
import {StockTypes} from "../../../../enums/Skybox/StockTypes";

interface Props {
    event: SbEvent,
    soldViewMode: boolean
}

const InventoryInfoModal: FC<Props> = ({event, soldViewMode}) => {
    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const [inventories, setInventories] = useState<Inventory[]>([]);

    useEffect(() => {
        if (soldViewMode) {
            getSoldLocalInventories(event.id).then((res) => {
                if (res instanceof HttpSuccessResponse) {
                    setInventories(res.data.inventories);
                }
            })
        } else {
            getOwnLocalInventories(event.id).then((res) => {
                if (res instanceof HttpSuccessResponse) {
                    setInventories(res.data.inventories);
                }
            })
        }
    }, [])

    return (
        <>
            {event ? (
                inventories && inventories.length ? inventories.map((item) => (
                    <div className={'info-container'}>
                        <div className={'inventory-info'}>
                            <div className={'inventory-info_heading'}>
                                <h5>{event.name} {event.city} {event.venue} {dateToLocaleString(event.occurs_at)}</h5>
                            </div>
                            <div className={'inventory-info_fields'}>
                                {soldViewMode ?
                                    <div className={'fields-container'}>
                                        <h6>Invoice ID:</h6>
                                        <p>{item.invoice_id}</p>
                                    </div>
                                    :
                                    <div className={'fields-container'}>
                                        <h6>Inventory ID:</h6>
                                        <p>{item.inventory_id}</p>
                                    </div>
                                }
                                <div className={'fields-container'}>
                                    <h6>In Hand Date:</h6>
                                    <p>{dateToLocaleString(item.in_hand_date)}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>Cost:</h6>
                                    <p>{getCurrencySymbol(item.currency_symbol, currencies)} {Math.ceil(item.unit_amount)}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>Low seat:</h6>
                                    <p>{item.low_seat}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>High seat:</h6>
                                    <p>{item.high_seat}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>Section:</h6>
                                    <p>{item.section}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>Row:</h6>
                                    <p>{item.row}</p>
                                </div>
                                <div className={'fields-container'}>
                                    <h6>Stock Type:</h6>
                                    <p>{StockTypes[item.stock_type as keyof typeof StockTypes]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <p className={'inventory-info_heading'}>{soldViewMode ? 'No sold inventories for event' : 'No purchased inventories for event'}</p>
            ) : null
            }
        </>
    )
}

export default InventoryInfoModal;
