import React, {FC, FormEvent, useState} from "react";
import {Inventory} from "../../../models/Inventory";
import {Field, Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import AppDatePicker from "../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {availabilityOfUpload} from "../../../helpers/InventoryHelper";
import UploadPdfForm from "../../../pages/User/SellPage/SellTicketPage/components/UploadPdfForm/UploadPdfForm";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import {DuplicateFormValidation} from "./DuplicateFormValidation";
import './DuplicateForm.scss';
import {duplicateInventory} from "../../../@api/Inventory";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import SelectPurchaseOrderForm from "./SelectPurchaseOrderForm/SelectPurchaseOrderForm";

interface DuplicateFormProps {
    inventory: Inventory;
    showHandle: () => void
}

const DuplicateForm: FC<DuplicateFormProps> = ({inventory, showHandle}) => {
    const duplicate = async (values: FormikValues) => {

        const data = {
            ...values,
            purchase_order: poNumber,
            seat_type: seatType,
            custom_split: customSplitValues,
            files: filesLinks,
            barcodes: barcodes,
        }

        const res = await duplicateInventory(inventory.id, data);

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);

            return
        }

        showHandle();
    };

    const handlePoModal = async () => {
        setShowModal(!showModal);
    };

    const formValues = [
        {key: "low_seat", value: "Low Seat*", type: "number"},
        {key: "section", value: "Section*", type: "text"},
        {key: "row", value: "Row*", type: "text"},
        {key: "internal_notes", value: "Internal Notes", type: "text"},
        {key: "public_notes", value: "Public Notes", type: "text"},
    ];

    const [customSplitValues, setCustomSplitValues] = useState<boolean[]>([]);
    let [barcodes, setBarcodes] = useState<string[]>([]);
    const [filesLinks, setFilesLinks] = useState<string[]>([]);
    const [isElectronicTicket, setIsElectronicTicket] = useState<boolean>(true);
    const [showUploadPdf, setShowUploadPdf] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [poNumber, setPoNumber] = useState<number | null>(null);
    const [seatType, setSeatType] = useState(inventory.seat_type || 'CONSECUTIVE');
    const [currentDate] = useState<Date>(new Date());
    const [quantity, setQuantity] = useState(inventory.quantity || '');
    const [price, setPrice] = useState(inventory.unit_amount || '');
    const [groupCost, setGroupCost] = useState(inventory.quantity * inventory.unit_amount);

    const onCustomCheckboxChange = (event: MouseEvent) => {
        const target = event.target as HTMLInputElement;

        customSplitValues[Number(target.defaultValue)] = target.checked;
        setCustomSplitValues(customSplitValues);
    };

    const customSplitElements = (value: number, splitTypeCustom: HTMLElement) => {
        for (let i = 1; i <= value; i++) {
            const label = document.createElement('label');
            const input = document.createElement('input');
            const span = document.createElement('span');

            input.type = 'checkbox';
            input.value = String(i);
            input.onclick = onCustomCheckboxChange;
            span.appendChild(document.createTextNode(String(i)))
            label.appendChild(input);
            label.appendChild(span)
            splitTypeCustom.appendChild(label);
        }
    };

    const handleSplitTypeChange = (event: MouseEvent) => {
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';
        customSplitValues.splice(0, customSplitValues.length);

        if ((event.target as HTMLSelectElement).value === 'CUSTOM') {
            const quantity = document.getElementsByName('quantity')[0] as HTMLInputElement;

            customSplitElements(Number(quantity.value), splitTypeCustom);
        }
    };

    const handleQuantityChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        const newQuantity = Number(target.value);

        if (newQuantity < 1) target.value = '1';
        if (newQuantity > 99) target.value = '99';

        setQuantity(target.value);

        const updatedGroupCost = (Number(newQuantity) * Number(price)).toFixed(2);
        setGroupCost(Number(updatedGroupCost));

        const updatedPrice = (Number(updatedGroupCost) / newQuantity).toFixed(2);
        setPrice(updatedPrice);

        customSplitValues.splice(0, customSplitValues.length);

        const splitType = document.getElementsByName('split_type')[0] as HTMLSelectElement;
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';

        if (splitType.value === 'CUSTOM') {
            customSplitElements(Number(target.value), splitTypeCustom);
        }
    };

    const formInitial = {
        split_type: "NEVERLEAVEONE",
        quantity: quantity ||  inventory.quantity || '',
        low_seat: '',
        section: inventory.section || '',
        row: inventory.row ||  '',
        price: price || inventory.unit_amount || '',
        in_hand_date: currentDate,
        seat_type: seatType,
        stock_type: 'HARD',
        barcodes: [],
        existPo: 'new',
        purchase_order: null,
        second_row: inventory.second_row || '',
        internal_notes: inventory.internal_notes || '',
        public_notes: inventory.public_notes || '',
        group_cost: groupCost || inventory.quantity * inventory.unit_amount || ''
    };

    const handleOrderIdClick = (orderId: number) => {
        setPoNumber(orderId);
        handlePoModal()
    };

    const handlePoTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'new') {
            setPoNumber(null);
        }
    };

    const handleGroupCostChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const newGroupCost = Number(target.value);

        setGroupCost(newGroupCost);

        const updatedPrice = (newGroupCost / Number(quantity)).toFixed(2);
        setPrice(updatedPrice);
    };

    const handlePriceChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const newPrice = Number(target.value);

        setPrice(newPrice);

        const updatedGroupCost = (newPrice * Number(quantity)).toFixed(2);
        setGroupCost(Number(updatedGroupCost));
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={formInitial}
                onSubmit={async (values) => {
                    await duplicate(values)
                }}
                validationSchema={DuplicateFormValidation}
            >
                {({isSubmitting}) =>
                    <Form>
                        <div className={'duplicate__form-container'}>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">Seat Type</label>
                                <Field name={'seat_type'} as="select"
                                       onChange={(e: any) => setSeatType(e.target.value)}
                                       style={{display: 'block', backgroundColor: 'white'}}
                                >
                                    <option value="CONSECUTIVE">Consecutive</option>
                                    <option value="ALTERNATING">Odd/Even</option>
                                    <option value="GA">GA</option>
                                    <option value="PIGGYBACK">Piggyback</option>
                                </Field>
                            </div>
                            {seatType === 'PIGGYBACK' && (
                                <div className={'duplicate__inputs-container'}>
                                    <label htmlFor="" className="add-inventory-form-label">2nd Row*</label>
                                    <DefaultFormikInput
                                        name={'second_row'}
                                        placeholder={'2nd Row'}
                                        autoComplete="on"
                                        class={'form-container_double-input'}
                                    />
                                </div>
                            )}
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor=""
                                       className="add-inventory-form-label">Quantity*</label>
                                <DefaultFormikInput name={'quantity'}
                                                    type={'number'}
                                                    placeholder={'Quantity'}
                                                    autoComplete={'off'}
                                                    value={quantity}
                                                    class={'form-container_double-input'}
                                                    onInput={(e: FormEvent<HTMLInputElement>) => {
                                                        handleQuantityChange(e);
                                                        availabilityOfUpload(setIsElectronicTicket, '', '', '', true);
                                                    }}
                                ></DefaultFormikInput>
                            </div>
                            {
                                formValues.map((formValue, index) => (
                                    <>
                                        <div className={'duplicate__inputs-container'} key={formValue.key + index}>
                                            <label htmlFor=""
                                                   className="add-inventory-form-label">{formValue.value}</label>
                                            <DefaultFormikInput name={formValue.key}
                                                                type={formValue.type}
                                                                placeholder={formValue.value}
                                                                autocomplete={'off'}
                                                                class={'form-container_double-input'}
                                                                onInput={() => availabilityOfUpload(setIsElectronicTicket, '', '', '', true)}
                                            ></DefaultFormikInput>
                                        </div>
                                    </>
                                ))
                            }
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">Price*</label>
                                <DefaultFormikInput
                                    name={'price'}
                                    type={'number'}
                                    value={price}
                                    onInput={handlePriceChange}
                                />
                            </div>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">Group cost</label>
                                <DefaultFormikInput
                                    name={'group_cost'}
                                    value={groupCost}
                                    onInput={handleGroupCostChange}
                                />
                            </div>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">{'In-hand date'}</label>
                                <AppDatePicker name={'in_hand_date'} placeholder={'In-hand date'}/>
                            </div>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">Stock Type</label>
                                <Field name={'stock_type'} as="select"
                                       style={{display: 'block', backgroundColor: 'white'}}
                                       onClick={() => availabilityOfUpload(setIsElectronicTicket, '', '', '', true)}>
                                    <option value="HARD">Hard</option>
                                    <option value="ELECTRONIC">E-ticket</option>
                                    <option value="FLASH">Flash</option>
                                    <option value="MOBILE_SCREENCAP">Mobile QR</option>
                                    <option value="MOBILE_TRANSFER">Mobile Transfer</option>
                                    <option value="PAPERLESS">Paperless Walk-In</option>
                                    <option value="PAPERLESS_CARD">Paperless Gift Card</option>
                                </Field>
                            </div>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor="" className="add-inventory-form-label">Split Type</label>
                                <Field name={'split_type'} as="select"
                                       onClick={handleSplitTypeChange}
                                       style={{display: 'block', backgroundColor: 'white'}}
                                >
                                    <option value="CUSTOM">Custom</option>
                                    <option value="ANY">Any</option>
                                    <option value="NEVERLEAVEONE">Never Leave One</option>
                                    <option value="DEFAULT">Default</option>
                                </Field>
                            </div>
                            <div className={"split-type__custom split-type-align"}></div>
                            <div className={'duplicate__inputs-container'}>
                                <label htmlFor=""
                                       className="add-inventory-form-label">PDF/Barcode</label>
                                <button
                                    className="filters-button-reset w-100"
                                    type="button"
                                    disabled={isElectronicTicket}
                                    onClick={() => setShowUploadPdf(true)}
                                >
                                    Upload
                                </button>
                            </div>
                            <div className={'duplicate__form-po-container'}>
                                <h4>Purchase order</h4>
                                <label htmlFor={'new'}>New</label>
                                <Field
                                    onInput={handlePoTypeChange}
                                    type="radio"
                                    id={'new'}
                                    name={'existPo'}
                                    value="new"
                                />
                                <label htmlFor={'exist'}>Existing</label>
                                <Field
                                    onClick={handlePoModal}
                                    type="radio"
                                    id={'exist'}
                                    name={'existPo'}
                                    value="exist"
                                />
                            </div>
                            {poNumber &&
                                <div className={'duplicate__form-selected-po'}>
                                    <h5>Selected P.O.</h5>
                                    <h5>#{poNumber}</h5>
                                </div>
                            }
                        </div>
                        <button className="auth-wrapper__submit-btn mt-4" type="submit" disabled={isSubmitting}>
                            Copy
                        </button>
                    </Form>
                }
            </Formik>
            <AppModal
                title={'Ticket Uploader'}
                form={<UploadPdfForm
                    quantity={(document.getElementsByName('quantity')[0] as HTMLInputElement)?.value}
                    lowSeat={(document.getElementsByName('low_seat')[0] as HTMLInputElement)?.value}
                    section={(document.getElementsByName('section')[1] as HTMLInputElement)?.value}
                    row={(document.getElementsByName('row')[1] as HTMLInputElement)?.value}
                    inventoryEvent={inventory.sb_event}
                    barcodes={barcodes}
                    setBarcodes={setBarcodes}
                    filesLinks={filesLinks}
                    setFilesLinks={setFilesLinks}
                    showHandle={() => setShowUploadPdf(false)}
                />}
                show={showUploadPdf}
                showHandle={() => setShowUploadPdf(false)}
                onClose={() => {
                }}
            />
            <AppModal
                size={'lg'}
                title={'Select existing P.O.'}
                form={
                    <SelectPurchaseOrderForm
                        onOrderIdClick={handleOrderIdClick}
                    />}
                show={showModal}
                showHandle={handlePoModal}
                onClose={() => {
                }}
            />
        </>
    )
}

export default DuplicateForm;
