import './MapCategoriesModal.scss';
import React, {FC, useEffect, useState} from 'react';
import {Inventory} from '../../../../../models/Inventory';
import Form from 'react-bootstrap/Form';
import AppButton from '../../../../../ui/Buttons/AppButton/AppButton';
import {LTGLocationCategory} from '../../../../../models/Category';
import {TicomboParamsForMap} from '../../../../../@interfaces/TicomboParamsForMap';
import {mapInventory} from '../../../../../@api/Inventory';
import {HttpSuccessResponse} from '../../../../../@api/Responses/HttpSuccessResponse';
import {LTGSection} from '../../../../../models/Section';
import {LtgParamsForMap} from '../../../../../@interfaces/LtgParamsForMap';
import {StubhubParamsForMap} from "../../../../../@interfaces/StubhubParamsForMap";
import AppModal from '../../../../../ui/Modal/AppModal/AppModal';
import isEuropeanMatch from "../../../../../helpers/IsEuropeanMatch";

interface Props {
    inventory: Inventory | null,
    onMap: () => void,
    onClose: () => void
}

const p1Hospitality = 'P1 Hospitality';
const homeFansLabel = 'Home Fans';

const MapCategoriesModal: FC<Props> = ({inventory: initialInventory, onClose, onMap}) => {
    const initialTicomboParams: TicomboParamsForMap = {
        category: null,
        setSectionStatus: 0,
        fanSection: undefined,
        customPrice: null,
        customSection: null,
    };

    const [inventory, setInventory] = useState<Inventory>();
    const [ticomboParams, setTicomboParams] = useState<TicomboParamsForMap>(initialTicomboParams);
    const [ltgLocationCategory, setLtgLocationCategory] = useState<LTGLocationCategory | null>(null);
    const [ltgSection, setLtgSection] = useState<LTGSection | null>(null);
    const [isTicomboParamsIsTouched, setIsTicomboParamsIsTouched] = useState(false);
    const [isLtgParamsIsTouched, setIsLtgParamsIsTouched] = useState(false);
    const [setSectionStatus, setSetSectionStatus] = useState<number>(0);
    const [stubhubParams, setStubhubParams] = useState<StubhubParamsForMap>()
    const [isStubhubParamsIsTouched, setIsStubhubParamsIsTouched] = useState(false);
    const [ltgCustomSectionName, setLtgCustomSectionSectionName] = useState<string>('');
    const [validated, setValidated] = useState(false);
    const [isMapInProgress, setIsMapInProgress] = useState(false);
    const [ltgCustomPriceGbp, setLtgCustomPriceGbp] = useState<string>('');
    const [ltgCustomPublicNotes, setLtgCustomPublicNotes] = useState<string>('');
    const [ticomboCustomPrice, setTicomboCustomPrice] = useState<string>('');
    const [ticomboCustomSection, setTicomboCustomSection] = useState<string>('');
    const [seatingPlanImageModalOpen, setSeatingPlanImageModalOpen] = useState<boolean>(false);

    const fanSections = initialInventory?.sb_event?.ticombo_event?.venue?.fanSections;

    useEffect(() => {
        if (initialInventory) {
            setInventory(initialInventory);

            const existedTicomboParams: TicomboParamsForMap = {
                category: null,
                setSectionStatus: 0,
                fanSection: undefined,
                customPrice: null,
                customSection: null,
            };

            if (!ticomboParams.fanSection) {
                const initialFanSection = initialInventory.ticombo_inventory?.concession?.fanSection;

                const homeFansSectionExists = fanSections?.some(fanSection => fanSection.name === homeFansLabel);

                const isP1Vendor = initialInventory.sb_vendor?.name.trim() === p1Hospitality;
                const firstFanSectionOption = fanSections?.length
                    ? initialInventory?.sb_event?.ticombo_event?.venue.fanSections[0]?.name
                    : undefined;
                existedTicomboParams.fanSection = initialFanSection ?? (
                    isP1Vendor && homeFansSectionExists && isEuropeanMatch(initialInventory)
                        ? homeFansLabel
                        : firstFanSectionOption
                );
            }

            if (initialInventory.ticombo_inventory?.category) {
                existedTicomboParams.category = initialInventory.ticombo_inventory.category;
            }

            if (initialInventory?.ticombo_sb_mapping?.custom_price) {
                existedTicomboParams.customPrice = initialInventory.ticombo_sb_mapping.custom_price;
                setTicomboCustomPrice(initialInventory.ticombo_sb_mapping.custom_price.toString());
            }

            if (initialInventory?.ticombo_sb_mapping?.custom_section) {
                existedTicomboParams.customSection = initialInventory.ticombo_sb_mapping.custom_section;
                setTicomboCustomSection(initialInventory.ticombo_sb_mapping.custom_section.toString());
            }

            if (initialInventory.ticombo_inventory?.set_section_status) {
                existedTicomboParams.setSectionStatus = initialInventory.ticombo_inventory?.set_section_status
                setSetSectionStatus(initialInventory.ticombo_inventory?.set_section_status);
            }

            setTicomboParams(existedTicomboParams);

            if (initialInventory?.ltg_sb_mapping?.ltg_location_category_id) {
                const existedLocationCategory = initialInventory.sb_event.ltg_event?.ltg_venue?.location_categories?.find(
                    locationCategory => locationCategory.id === initialInventory?.ltg_sb_mapping?.ltg_location_category_id
                );

                if (existedLocationCategory) {
                    setLtgLocationCategory(existedLocationCategory);
                }
            }

            if (initialInventory?.ltg_sb_mapping?.ltg_section_id) {
                const existedSection = initialInventory.sb_event.ltg_event?.ltg_venue.sections?.find(section => section.id === initialInventory?.ltg_sb_mapping?.ltg_section_id);

                if (existedSection) {
                    const existedLocationCategory = initialInventory.sb_event.ltg_event?.ltg_venue?.location_categories?.find(
                        locationCategory => locationCategory.id === existedSection.ltg_location_category_id
                    );

                    if (existedLocationCategory) {
                        setLtgLocationCategory(existedLocationCategory);
                    }

                    setLtgSection(existedSection);
                }
            }

            if (initialInventory?.ltg_sb_mapping?.custom_price_gbp) {
                setLtgCustomPriceGbp(initialInventory?.ltg_sb_mapping?.custom_price_gbp.toString());
            }

            if (initialInventory.sb_event.stubhub_event?.stubhub_sections) {
                const existedSection = initialInventory.sb_event.stubhub_event?.stubhub_sections.find(
                    section => section.alias === initialInventory.section
                );

                if (existedSection) {
                    setStubhubParams({sectionId: existedSection.id})
                }
            }

            if (initialInventory?.ltg_sb_mapping?.ltg_custom_section_name) {
                setLtgCustomSectionSectionName(initialInventory.ltg_sb_mapping.ltg_custom_section_name);
            }

            setLtgCustomPublicNotes(initialInventory?.ltg_sb_mapping?.custom_public_notes || '');
        }
    }, []);

    const ticomboSetSectionStatusOptions = [
        {
            name: 'I do not know exact seat location',
            value: 0,
        },
        {
            name: 'I know my exact seat location',
            value: 1,
        },
    ];

    const handleLtgLocationCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIsLtgParamsIsTouched(true);
        const locationCategory = inventory!.sb_event.ltg_event?.ltg_venue?.location_categories?.find(category => category.id === Number(e.target.value));

        if (locationCategory?.id !== ltgLocationCategory?.id) {
            setLtgSection(null);
        }

        if (locationCategory) {
            setLtgLocationCategory(locationCategory);
        } else {
            setLtgLocationCategory(null);
        }
    };

    const handleLtgSectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIsLtgParamsIsTouched(true);

        const section = inventory?.sb_event.ltg_event?.ltg_venue?.sections?.find(section => section.id === Number(e.target.value));

        if (section) {
            setLtgSection(section);
        } else {
            setLtgSection(null);
        }
    };

    const handleSaveButton: React.FormEventHandler<HTMLFormElement> = async (event: React.FormEvent<HTMLFormElement>) => {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        let payload: { ticombo?: TicomboParamsForMap, ltg?: LtgParamsForMap, stubhub?: StubhubParamsForMap } = {};

        if (isTicomboParamsIsTouched) {
            ticomboParams.setSectionStatus = setSectionStatus;
            payload = {
                ...payload,
                ticombo: ticomboParams,
            };
        }

        if (isLtgParamsIsTouched) {
            payload = {
                ...payload,
                ltg: {
                    locationCategoryId: ltgLocationCategory ? ltgLocationCategory.id : null,
                    sectionId: ltgSection ? ltgSection.id : null,
                    customSectionName: ltgCustomSectionName.trim().length ? ltgCustomSectionName.trim() : null,
                    customPriceGbp: ltgCustomPriceGbp ? parseFloat(ltgCustomPriceGbp) : null,
                    customPublicNotes: ltgCustomPublicNotes.trim().length ? ltgCustomPublicNotes.trim() : null,
                },
            };
        }

        if (isStubhubParamsIsTouched) {
            payload = {
                ...payload,
                stubhub: stubhubParams,
            };
        }

        if (form.checkValidity()) {
            setIsMapInProgress(true);
            const response = await mapInventory(
                inventory!.id,
                payload,
            );
            setIsMapInProgress(false);

            if (response instanceof HttpSuccessResponse) {
                onMap();
                onClose();
            }
        }
    };

    const isLtgLocationCategorySectionRequired = () => {
        return (ltgLocationCategory && ltgLocationCategory.section_required === 1) || undefined;
    }

    const shouldShowLtgLocationCategorySelector = () => {
        if (!inventory) {
            return false;
        }

        return inventory.sb_event.ltg_event?.ltg_venue?.location_categories?.length &&
            inventory.sb_event.ltg_event.ltg_layout_id !== 0;
    }

    const shouldShowLtgSectionSelector = () => {
        if (!inventory || inventory.sb_event.ltg_event?.ltg_layout_id === 0) {
            return false;
        }

        if (ltgLocationCategory) {
            return ltgLocationCategory.sections.length > 0;
        } else {
            return !!inventory.sb_event.ltg_event?.ltg_venue?.sections?.length;
        }
    }

    const clearCategoryName = (categoryName: any) => {
        if (typeof categoryName !== 'string') return '';

        return categoryName.trim();
    }

    const getNotes = (notes: string | null) => {
        return notes && notes.trim().length ? notes : null;
    }

    const getSeatingPlanMapBtn = (inventory: Inventory) => (
        <dd className="seatingplan-image">
            {inventory.p1_inventory?.seatingplan_category_image ? (
                <>
                    <span className={'text-red pointer'} onClick={() => setSeatingPlanImageModalOpen(true)}>Map</span>
                    <img className="modal-content modal-body" src={inventory.p1_inventory?.seatingplan_category_image} alt="Seating Map" />
                </>
            ) : (
                <span className={'disabled'}>Map</span>
            )}
        </dd>
    );

    return (
        <>
            {
                inventory &&
                <>
                    <div className="map__event-info map-categories-info">
                        <h5 className="map-categories-info-event">{inventory.sb_event.name}</h5>
                        <h5 className="map-categories-info-event">{inventory.sb_event.venue}</h5>
                        <h5>{inventory.section}</h5>
                    </div>
                    <div className="d-flex flex-column justify-content-center mt-1">
                        <p className="w-100 text-center">SkyBox List Price:{'\u00A0'}
                            <span className="h6">${inventory.list_price}</span>
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-center mt-1">
                        <p className="w-100 text-center">Unit Price:{'\u00A0'}
                            <span className="h6">{inventory.currency_icon}{inventory.unit_amount}</span>
                        </p>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-1 gap-2">
                        <p className="text-center">Initial quantity:{'\u00A0'}
                            <span className="h6">{inventory.initial_quantity}</span>
                        </p>
                        <p className="text-center">Quantity:{'\u00A0'}
                            <span className="h6">{inventory.quantity}</span>
                        </p>
                        <p className="text-center">Shown quantity:{'\u00A0'}
                            <span className="h6">{inventory.shown_quantity}</span>
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-center mt-1">
                        <p className="w-100 text-center">Margin:{'\u00A0'}
                            {inventory.list_price_margin
                                ? <span className="h6">${inventory.list_price_margin}</span>
                                : <span className="h6">--</span>
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-1">
                        <dl className={'col-12 col-md-10 col-lg-8 col-xl-6'}>
                            {(getNotes(inventory.public_notes) || getNotes(inventory.internal_notes)) && (
                                <>
                                    {getNotes(inventory.public_notes) && (
                                        <>
                                            <dt>SkyBox Public Notes:</dt>
                                            <dd>{getNotes(inventory.public_notes)}</dd>
                                        </>
                                    )}
                                    {getNotes(inventory.internal_notes) && (
                                        <>
                                            <dt>SkyBox Internal Notes:</dt>
                                            <dd>{getNotes(inventory.internal_notes)}</dd>
                                        </>
                                    )}
                                </>
                            )}
                            {getSeatingPlanMapBtn(inventory)}
                        </dl>
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSaveButton}>
                        <div className="map-categories-services mt-3 mb-3">
                            <div>
                                <h5 className="text-center ">Live Ticket Group</h5>
                                <p className="text-center">{inventory.sb_event.ltg_event?.name}</p>
                                {
                                    inventory.sb_event.ltg_event ?
                                        <div>
                                            {inventory.sb_event.ltg_event.ltg_layout_id === 0 ?
                                                <div className="mt-2">
                                                    <label htmlFor="ltg-custom-section-name">
                                                        Custom Section Name&nbsp;
                                                        <span className="required-text">*</span>
                                                    </label>
                                                    <input type="text"
                                                           required
                                                           pattern=".*\S+.*"
                                                           value={ltgCustomSectionName}
                                                           id="ltg-custom-section-name"
                                                           className={'form-control'}
                                                           onChange={(e) => {
                                                               setLtgCustomSectionSectionName(e.target.value);
                                                               setIsLtgParamsIsTouched(true);
                                                           }}
                                                    />
                                                </div>
                                                :
                                                null
                                            }

                                            {shouldShowLtgLocationCategorySelector() ?
                                                <div className="mt-2">
                                                    <label htmlFor="ltg-location-category">Location Category</label>
                                                    <Form.Select id="ltg-location-category"
                                                                 onChange={handleLtgLocationCategoryChange}
                                                                 defaultValue={ltgLocationCategory ? ltgLocationCategory.id : ''}
                                                    >
                                                        <option value="">Location category</option>
                                                        {
                                                            inventory.sb_event.ltg_event.ltg_venue.location_categories?.map(category => (
                                                                <option key={category.id} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                                :
                                                null
                                            }

                                            {shouldShowLtgSectionSelector() ?
                                                <div className="mt-2">
                                                    <label className="d-flex" htmlFor="ltg-section">
                                                        Section&nbsp;
                                                        {isLtgLocationCategorySectionRequired() &&
                                                            <span className="ml-4 required-text sub-label-text">
                                                            *
                                                        </span>
                                                        }
                                                    </label>
                                                    <Form.Select id="ltg-section"
                                                                 required={isLtgLocationCategorySectionRequired()}
                                                                 onChange={handleLtgSectionChange}
                                                                 value={ltgSection ? ltgSection.id : ''}
                                                    >
                                                        <option value="">Section</option>
                                                        {
                                                            ltgLocationCategory ?
                                                                ltgLocationCategory.sections.map(section => (
                                                                    <option key={section.id} value={section.id}>
                                                                        {section.name}
                                                                    </option>
                                                                ))
                                                                :
                                                                inventory.sb_event.ltg_event?.ltg_venue.sections.map(section => (
                                                                    <option key={section.id} value={section.id}>
                                                                        {section.name}
                                                                    </option>
                                                                ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className={'mt-2'}>
                                                <label htmlFor="ltg-custom-public-notes">
                                                    Custom Public Notes
                                                </label>
                                                <textarea
                                                    defaultValue={ltgCustomPublicNotes}
                                                    id="ltg-custom-public-notes"
                                                    className={'form-control w-100'}
                                                    onChange={(e) => {
                                                        setLtgCustomPublicNotes(e.target.value);
                                                        setIsLtgParamsIsTouched(true);
                                                    }}
                                                ></textarea>
                                            </div>
                                            <div className="d-flex justify-content-between gap-2">
                                                <div className="mt-2 w-100">
                                                    <label htmlFor="ltg-custom-price">
                                                        Custom Price (£)
                                                    </label>
                                                    <input type="text"
                                                           inputMode={'numeric'}
                                                           pattern="\d+((,|\.)\d\d?)*"
                                                           value={ltgCustomPriceGbp}
                                                           id="ltg-custom-price"
                                                           className={'form-control'}
                                                           onChange={(e) => {
                                                               setLtgCustomPriceGbp(e.target.value);
                                                               setIsLtgParamsIsTouched(true);
                                                           }}
                                                    />
                                                </div>
                                                <div className="mt-2 w-100">
                                                    <label htmlFor="ltg-custom-price">
                                                        Margin (£)
                                                    </label>
                                                    <input type="text"
                                                           inputMode={'numeric'}
                                                           pattern="\d+((,|\.)\d\d?)*"
                                                           value={inventory.ltg_sb_mapping?.custom_price_margin_gbp ? inventory.ltg_sb_mapping.custom_price_margin_gbp : '--'}
                                                           id="ltg-custom-price"
                                                           className={'form-control'}
                                                           disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <p className={'not-mapped-event'}>Event is not mapped</p>
                                }
                            </div>
                            <div>
                                <h5 className="text-center map-categories-title">Ticombo</h5>
                                <p className="text-center">{inventory.sb_event.ticombo_event?.name}</p>
                                {
                                    inventory.sb_event.ticombo_event ?
                                        <div className="mt-2">
                                            {inventory.sb_event.ticombo_event?.ticket_types.length ?
                                                <div className="mt-2">
                                                    <label htmlFor="ticombo-category">Category</label>
                                                    {initialInventory?.ticombo_inventory?.category ?
                                                        <input type="text"
                                                               id="ticombo-category"
                                                               disabled={true}
                                                               className={'form-control'}
                                                               value={ticomboParams.category ?? ''}
                                                        />
                                                        :
                                                        <Form.Select id="ticombo-category"
                                                                     onChange={(e) => {
                                                                         setTicomboParams({
                                                                             ...ticomboParams,
                                                                             category: e.target.value,
                                                                         });

                                                                         setIsTicomboParamsIsTouched(true);
                                                                     }}
                                                                     defaultValue={ticomboParams.category ? ticomboParams.category : ''}
                                                        >
                                                            <option value="" disabled hidden>Category</option>
                                                            {
                                                                inventory.sb_event.ticombo_event?.ticket_types.map(category => (
                                                                    <option key={clearCategoryName(category.name)}
                                                                            value={clearCategoryName(category.name)}
                                                                    >
                                                                        {clearCategoryName(category.name)}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            {fanSections?.length ?
                                                <div className="mt-2">
                                                    <label htmlFor="ticombo-fan-section">
                                                        Fan Section
                                                    </label>
                                                    <Form.Select id="ticombo-fan-section"
                                                                 value={ticomboParams.fanSection}
                                                                 onChange={(e) => {
                                                                     setTicomboParams({
                                                                         ...ticomboParams,
                                                                         fanSection: e.target.value,
                                                                     });

                                                                     setIsTicomboParamsIsTouched(true);
                                                                 }}
                                                    >
                                                        {
                                                            fanSections?.map(section => (
                                                                <option key={section.name} value={section.name}>
                                                                    {section.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className="mt-2 w-100">
                                                <label htmlFor="ticombo-custom-section">
                                                    Custom Section
                                                </label>
                                                <input type="text"
                                                       id="ticombo-custom-section"
                                                       className={'form-control'}
                                                       value={ticomboCustomSection}
                                                       onChange={(e) => {
                                                           setTicomboCustomSection(e.target.value);
                                                           const value = e.target.value.trim().length ? e.target.value : null;
                                                           setTicomboParams(
                                                               {
                                                                   ...ticomboParams,
                                                                   customSection: value,
                                                               }
                                                           )
                                                           setIsTicomboParamsIsTouched(true);
                                                       }}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-between gap-2">
                                                <div className="mt-2 w-100">
                                                    <label htmlFor="ticombo-custom-price">
                                                        Custom Price ($)
                                                    </label>
                                                    <input type="text"
                                                           inputMode={'numeric'}
                                                           pattern="\d+((,|\.)\d\d?)*"
                                                           value={ticomboCustomPrice}
                                                           id="ticombo-custom-price"
                                                           className={'form-control'}
                                                           onChange={(e) => {
                                                               setTicomboCustomPrice(e.target.value);
                                                               const value = e.target.value.trim().length ? parseFloat(e.target.value) : null;
                                                               setTicomboParams(
                                                                   {
                                                                       ...ticomboParams,
                                                                       customPrice: value,
                                                                   }
                                                               )
                                                               setIsTicomboParamsIsTouched(true);
                                                           }}
                                                    />
                                                </div>
                                                <div className="mt-2 w-100">
                                                    <label htmlFor="ticombo-custom-price">
                                                        Margin ($)
                                                    </label>
                                                    <input type="text"
                                                           inputMode={'numeric'}
                                                           pattern="\d+((,|\.)\d\d?)*"
                                                           value={inventory.ticombo_sb_mapping?.custom_price_margin ? inventory.ticombo_sb_mapping.custom_price_margin : '--'}
                                                           id="ticombo-custom-price"
                                                           className={'form-control'}
                                                           disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-2 w-100">
                                                <label>&nbsp;</label>
                                                {ticomboSetSectionStatusOptions.map((option, index) => (
                                                    <div key={`${option.name}-${index}`}>
                                                        <label className={'edit-event-form__result'}>
                                                            <input
                                                                type={'radio'}
                                                                name={'event'}
                                                                value={option.value}
                                                                defaultChecked={option.value === setSectionStatus}
                                                                onChange={(event) => {
                                                                    setSetSectionStatus(Number(event.target.value));
                                                                    setIsTicomboParamsIsTouched(true);
                                                                }}
                                                            />
                                                            {option.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        <p className={'not-mapped-event'}>Event is not mapped</p>
                                }
                            </div>
                            <div>
                                <h5 className="text-center">Stubhub</h5>
                                {
                                    inventory.sb_event.stubhub_event?.stubhub_sections?.length && inventory.sb_event.stubhub_event ?
                                        <div>
                                            <p className="text-center">{inventory.sb_event.stubhub_event.name}</p>
                                            <div className="mt-2">
                                                <label htmlFor="stubhub-section">Section</label>
                                                <Form.Select id="stubhub-section"
                                                             onChange={(event) => {
                                                                 setIsStubhubParamsIsTouched(true);
                                                                 setStubhubParams({sectionId: Number(event.target.value)})
                                                             }}
                                                             defaultValue={stubhubParams ? String(stubhubParams.sectionId) : ''}
                                                >
                                                    <option value="">Section</option>
                                                    {
                                                        inventory.sb_event.stubhub_event?.stubhub_sections.map(section => (
                                                            <option key={section.id}
                                                                    value={section.id}
                                                            >
                                                                {section.name}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                        :
                                        <p className={'not-mapped-event'}>Event is not mapped or has no sections</p>
                                }
                            </div>
                        </div>
                        <div>
                            <AppButton isLoading={isMapInProgress}
                                       disabled={isMapInProgress}
                                       text={'Save'}
                                       type={'submit'} style={{width: '100%'}}
                            />
                        </div>
                    </Form>
                    <AppModal title={'Seating Plan Map'}
                              centered={true}
                              key={'seatingPlanMap'}
                              form={
                                  <img src={inventory.p1_inventory?.seatingplan_category_image} alt="Seating Plan" />
                              }
                              modalIndex={1}
                              show={seatingPlanImageModalOpen}
                              showHandle={() => setSeatingPlanImageModalOpen(false)}
                              onClose={() => null}
                              size={'xl'}
                    />
                </>
            }
        </>
    );
};

export default MapCategoriesModal;
