import React, {FC} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {EditInvoice, Invoice} from "../../../models/Invoice";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import './EditInvoiceForm.scss';
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {EditInvoiceFormValidationSchema} from "./EditInvoiceFormValidationSchema";
import {DeliveryMethods} from "../../../enums/DeliveryMethods";

interface Props {
    invoice: Invoice,
    onSubmit: (values: EditInvoice) => void;
    isLoading: boolean
}

const EditInvoiceForm: FC<Props> = ({invoice, onSubmit, isLoading}) => {

    const formInitial = {
        amount: invoice.amount,
        customer_email: invoice.customer_email || invoice.customers?.email || '',
        quantity: invoice.quantity || 0,
        customer_name: invoice.customer_name || invoice.customers?.display_name || '',
        external_reference: invoice.external_reference || '',
        customers: invoice.customers || null,
        public_notes: invoice.public_notes || '',
        delivery_method: invoice.delivery_method || '',
        recipient: '',
        cc_recipient: '',
        message: '',
        payment_method: invoice.customers?.payment_method || ''
    };

    const formInputsFirst = [
        {
            name: 'customer_email',
            placeholder: 'Enter Customer Email',
            inputName: 'Customer Email'
        },
        {
            name: 'customer_name',
            placeholder: 'Enter Customer Name',
            inputName: 'Customer Name'
        },
        {
            name: 'quantity',
            placeholder: 'Enter Quantity',
            inputName: 'Quantity'
        },
        {
            name: 'amount',
            placeholder: 'Enter amount',
            inputName: 'Amount'
        },
        {
            name: 'external_reference',
            placeholder: 'Enter External Reference',
            inputName: 'External Reference'
        },
        {
            name: 'public_notes',
            placeholder: 'Enter Notes',
            inputName: 'Notes'
        },
    ];

    const paymentMethods = [
        {value: "accrued_credit", label: "Accrued Credit"},
        {value: "ach", label: "ACH"},
        {value: "ap", label: "AP"},
        {value: "ar", label: "AR"},
        {value: "cash", label: "Cash"},
        {value: "check", label: "Check"},
        {value: "comp", label: "Comp"},
        {value: "credit_card", label: "Credit Card"},
        {value: "customer_credit", label: "Customer Credit"},
        {value: "multiple", label: "Multiple"},
        {value: "paypal", label: "PayPal"},
        {value: "trade", label: "Trade"},
        {value: "venmo", label: "Venmo"},
        {value: "other", label: "Other"},
    ];

    return (
        <>
            <div className={'invoice_info'}>
                <div className={'invoice_info-container'}>
                    <h6>Invoice ID</h6>
                    <p>{invoice.id}</p>
                </div>
                <div className={'invoice_info-container'}>
                    <h6>Invoice Date</h6>
                    <p>{dateToLocaleString(invoice.date)}</p>
                </div>
                <div className={'invoice_info-container'}>
                    <h6>Event</h6>
                    <p>{invoice.event_name}</p>
                </div>
                <div className={'invoice_info-container'}>
                    <h6>Event Date</h6>
                    <p>{dateToLocaleString(invoice.event_date)}</p>
                </div>
            </div>

            <Formik
                initialValues={formInitial}
                onSubmit={(values) => onSubmit(values)}
                validationSchema={EditInvoiceFormValidationSchema}
            >
                <Form className={'invoice-edit-form mt-3'}>
                    {formInputsFirst.map((input, index) => (
                        <div className={'form-inputs_item item-gap'}>
                            <p className={'form-inputs_item-header'}>{input.inputName}</p>
                            <DefaultFormikInput
                                name={input.name}
                                placeholder={input.placeholder}
                                autocomplete={'off'}
                            ></DefaultFormikInput>
                        </div>
                    ))}
                    <div className={'form-inputs_item item-gap'}>
                        <p className={'form-inputs_item-header'}>Delivery method</p>
                        <Field name="delivery_method" as="select"
                               className={'add-inventory-form-input invoice-select'}>
                            <option disabled value="" selected>Select method</option>
                            {Object.keys(DeliveryMethods).map((key, index) => (
                                <option key={index} value={key}>
                                    {DeliveryMethods[key as keyof typeof DeliveryMethods]}
                                </option>
                            ))}
                        </Field>
                    </div>
                    <div className={'form-inputs_item item-gap'}>
                        <p className={'form-inputs_item-header'}>Payment Method</p>
                        <div className={'left_error_inline'}>
                            <div className="form-inputs_item__field-error">
                                <Field name="payment_method" as="select"
                                       className={'add-inventory-form-input invoice-select'}>
                                    <option disabled value="" selected>Select method</option>
                                    {paymentMethods.map((option, index) => (
                                        <option key={option.value + index} value={option.value} label={option.label}/>
                                    ))}
                                </Field>
                                <ErrorMessage name="payment_method" component="div" className="error-message"/>
                            </div>
                        </div>
                    </div>
                    <div className={'invoice-email'}>
                        <div className={'invoice-email_inputs'}>
                            <h6>Send To:</h6>
                            <DefaultFormikInput
                                name={'recipient'}
                                placeholder={'Enter recipient'}
                                autocomplete={'off'}
                            ></DefaultFormikInput>
                        </div>
                        <div className={'invoice-email_inputs'}>
                            <h6>CC:</h6>
                            <DefaultFormikInput
                                name={'cc_recipient'}
                                placeholder={'Enter recipient'}
                                autocomplete={'off'}
                            ></DefaultFormikInput>
                        </div>
                        <div className={'invoice-email_inputs'}>
                            <h6>Message:</h6>
                            <DefaultFormikInput
                                name={'message'}
                                placeholder={'Optional'}
                                autocomplete={'off'}
                            ></DefaultFormikInput>
                        </div>
                    </div>
                    <button
                        className={'filters-button-apply mt-3'}
                        type={'submit'}
                        disabled={isLoading}
                    >
                        Edit and send email
                    </button>
                </Form>
            </Formik>
        </>

    )
}

export default EditInvoiceForm;
