import React from 'react';
import './LoginPage.scss';
import {useNavigate} from 'react-router-dom';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import DefaultFormikInput from '../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {HttpSuccessResponse} from '../../../@api/Responses/HttpSuccessResponse';
import {GlobalStatusCodesEnum} from '../../../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {AuthResponseStatusesEnum} from '../../../enums/ResponseStatuses/AuthResponseStatusesEnum';
import {LoginFailureResponse, LoginResponse} from '../../../@interfaces/Response/Auth';
import {HttpFailureResponse} from '../../../@api/Responses/HttpFailureResponse';
import {FormikErrors} from 'formik/dist/types';
import {useDispatch} from 'react-redux';
import {login, setOtpToken} from '../../../store/Reducers/Auth';
import {loginRequest} from '../../../@api/Auth';
import {identify} from "../../../helpers/Analytics";

const credentialValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(6).required('Required'),
});

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const redirectToRegistrationPage = () => navigateTo('/registration');
    const redirectToResetPage = () => navigateTo('/reset-password-request');

    const credential = {email: '', password: ''};

    const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum, LoginResponse>) => {
        dispatch(login({
            user: res.data.user,
            authToken: res.data.auth_token,
            pools: res.data.user.pools,
            hidden_users: res.data.user.hidden_users,
            settings: res.data.user.settings
        }));

        identify(res.data.user);

        if (res.data.user.role_id === 1) {
            navigateTo('/admin');
        } else {
            navigateTo('/user');
        }
    };

    const failureResponse = (
        res: HttpFailureResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum, LoginFailureResponse>,
        setErrors: (errors: FormikErrors<any>) => void,
    ) => {
        if (res.statusCode === AuthResponseStatusesEnum.LoginFormRedirectToOtp && res.data?.otp_token) {
            dispatch(setOtpToken({otpToken: res.data.otp_token}));
            navigateTo('/otp-verification');
            return;
        }

        setErrors(res.validation ?? {});
        alert(res.message);
    };


    return (
        <AuthPageWrapper>
            <div className={'login-form'}>
                <div>
                    <p className="login-form__welcome-title">Welcome</p>
                    <p className="login-form__sub-welcome-title">login to personal account</p>
                </div>
                <Formik
                    initialValues={credential}
                    validationSchema={credentialValidationSchema}
                    onSubmit={async (values, {setErrors}) => {
                        const res = await loginRequest(values);

                        return res instanceof HttpSuccessResponse ? successResponse(res) : failureResponse(res, setErrors);
                    }}
                >
                    {({isSubmitting}) =>
                        <Form>
                            <DefaultFormikInput name={'email'}
                                                placeholder={'Email'}
                                                noLabel={true}
                                                autocomplete={'off'}
                                                class={'auth-wrapper__input'}
                            ></DefaultFormikInput>

                            <DefaultFormikInput type={'password'}
                                                name={'password'}
                                                noLabel={true}
                                                placeholder={'Password'}
                                                class={'auth-wrapper__input'}
                            ></DefaultFormikInput>

                            <button className="auth-wrapper__submit-btn" type="submit" disabled={isSubmitting}>Submit
                            </button>
                        </Form>
                    }
                </Formik>

                <div className="login-form__auth-actions-block">
                    <p className="clickable-word" onClick={redirectToRegistrationPage}>Sign up</p>
                    <p className="clickable-word" onClick={redirectToResetPage}>Forgot your password?</p>
                </div>
            </div>
        </AuthPageWrapper>
    );
};

export default LoginPage;
