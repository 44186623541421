export enum VendorTypes {
    MARKETPLACE = 'Marketplace',
    PRIMARY = 'Primary Market',
    PERFORMER = 'Team/Artist/Venue',
    BROKER = 'Broker',
    INDY = 'Individual',
    MEMBER = 'Member',
    TRAVEL_AGENT = 'Travel Agent',
    CONCIERGE = 'Concierge'
}
