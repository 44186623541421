import {Inventory} from "../models/Inventory";

const soccerCategory = 'Soccer';
const UEFA = 'UEFA';
const europeanCompetitions: string[] = [
    UEFA,
    'Champions League',
    'Europa League',
    'Conference League',
    'European Cup',
    'Europa Conference',
];

const containsCompetitionKeyword = (string: string = '', keywords: string[]): boolean => {
    return keywords.some((keyword) => string.toLowerCase().includes(keyword.toLowerCase()));
};

const isEuropeanMatch = (item: Inventory): boolean => {
    const fieldsToCheck: string[] = [
        item?.description || '',
        item?.sb_event?.name || '',
        item?.sb_event?.sb_performer?.name || '',
        item?.sb_event?.data?.name || '',
        item?.sb_event?.ticombo_event?.name || '',
    ];

    for (const field of fieldsToCheck) {
        if (containsCompetitionKeyword(field, europeanCompetitions)) {
            return true;
        }
    }

    // Additional checks based on known performer or category types
    const category = item?.sb_event?.sb_performer?.category_name || '';
    return category === soccerCategory && containsCompetitionKeyword(item?.sb_event?.sb_performer?.name || '', [UEFA]);
};

export default isEuropeanMatch;
