import * as Yup from "yup";
import * as yup from "yup";
import {checkSelectEmptyValue, checkFieldHasZeroValue} from "../../../../../helpers/YupRules";

export const ViagogoInvoiceValidationSchema = Yup.object().shape({
    quantity: yup.string().test(checkFieldHasZeroValue('quantity')),
    price: Yup.number().required('Required').typeError('Must be a number!'),
    inventory_id: yup.string().test(checkSelectEmptyValue('inventory_id')),
    seats: yup.string().test(checkSelectEmptyValue('seats')),
});
