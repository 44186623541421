import {Currency} from "../../models/Currency";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface CurrencyState {
    currencies: Currency[];
    allCurrencies: Currency[];
}

const initialState: CurrencyState = {
    currencies: [],
    allCurrencies: [],
};

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrenciesState: (state: CurrencyState, action: PayloadAction<CurrencyState>) => {
            state.currencies = action.payload.currencies;
            state.allCurrencies = action.payload.allCurrencies;
        }
    }
});

export const {setCurrenciesState} = currencySlice.actions;

export default currencySlice.reducer;
