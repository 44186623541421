import React, {ChangeEvent, Dispatch, FC, SetStateAction, useEffect} from "react";
import {QuoteBuilderData, QuoteBuilderInventory} from "../../../../../@interfaces/QuoteBuilderData";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";
import {convert, getCurrencySymbol} from "../../../../../helpers/CurrencyConverter";
import './QuoteBuilder.scss';
import {postInventoriesDownload} from "../../../../../@api/Inventory";
import {downloadFile} from "../../../../../helpers/DownloadFile";
import useCurrenciesGet from "../../../../../hooks/useCurrenciesGet";

interface Props {
    data: QuoteBuilderInventory[],
    setData: Dispatch<SetStateAction<QuoteBuilderInventory[]>>;
}

const QuoteBuilder: FC<Props> = ({data, setData}) => {
    const options = [
        {name: '', value: ''},
        {name: 'EUR', value: 'EUR'},
        {name: 'GBP', value: 'GBP'},
        {name: 'USD', value: 'USD'},

    ];

    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const currenciesSet = useCurrenciesGet();

    useEffect(() => {
        currenciesSet();
    }, []);

    const handleChangeCommission = (id: number, isIncrease: boolean) => {
        const element = document.querySelector(`[data-commission-id='${id}']`) as HTMLInputElement;

        let value = (isIncrease) ? Number(element.value) + 1 : Number(element.value) - 1;

        validateCommissionValue(id, value, element);
    };

    const handleInputCommission = (id: number, element: ChangeEvent<HTMLInputElement>) => {
        let value = Number(element.target.value);

        validateCommissionValue(id, value, element.target);
    };

    const validateCommissionValue = (id: number, value: number, element: HTMLInputElement) => {
        if (value < 0) {
            value = 0;
        } else if (value > 99999999) {
            value = 99999999;
        }

        element.value = String(value);

        data.forEach((inventory) => {
            if (inventory.id === id) {
                if (inventory.commission_type === 'unit') {
                    inventory.new_price += (value - inventory.commission);
                    inventory.commission = value;
                } else if (inventory.commission_type === 'percent') {
                    inventory.new_price = inventory.new_price * 100 / (100 + inventory.commission) + (inventory.new_price * 100 / (100 + inventory.commission)) * value / 100;
                    inventory.commission = value;
                }
            }

        });

        setData([...data]);
    };

    const handleRoundUpPrice = (id: number, isUp: boolean) => {
        data.forEach((inventory) => {
            if (inventory.id === id) {
                if (isUp) {
                    inventory.new_price = Math.ceil(inventory.new_price / 10) * 10;
                } else {
                    inventory.new_price = Math.floor(inventory.new_price / 10) * 10;
                }
            }
        });

        setData([...data]);
    };

    const handleSelectCurrency = (id: number, element: ChangeEvent<HTMLSelectElement>) => {
        if (!element.target.value) return;

        data.forEach((inventory) => {
            if (inventory.id === id) {
                inventory.new_price = convert(inventory.new_currency, element.target.value, inventory.new_price, currencies);
                inventory.new_currency = element.target.value;
            }
        });

        setData([...data]);
    };

    const handleQuantityClick = (id: number, element: React.MouseEvent<HTMLSelectElement>) => {
        data.forEach((inventory) => {
            const newQuantity = Number((element.target as HTMLSelectElement).value);

            if (inventory.id === id && newQuantity) {
                inventory.quantity = newQuantity;
            }
        });

        setData([...data]);
    };

    const handleChangeCommissionType = (id: number, element: ChangeEvent<HTMLInputElement>, index: number) => {
        const input = document.getElementsByName('commission')[index] as HTMLInputElement;
        const type = element.target.value;

        data.forEach((inventory) => {
            if (inventory.id === id) {
                inventory.commission = 0;
                inventory.commission_type = type;
            }
        });

        setData([...data]);
        input.value = '0';
    };

    const download = async () => {
        const notes = document.getElementsByName('notes')[0] as HTMLTextAreaElement;
        const event = data[0].sb_event;
        let requestData: QuoteBuilderData[] = [];
        requestData[0] = {
            event_id: event.event_id,
            name: event.name,
            venue: event.venue,
            city: event.city,
            occurs_at: dateToLocaleString(event.occurs_at),
            inventories: data
        };

        postInventoriesDownload(requestData, notes.value)
            .then(response => {
                const href = URL.createObjectURL(response.data);
                const title = new Date().toLocaleDateString() + '.pdf';

                downloadFile(title, href);
            })
            .catch((e) => {
                alert(e);
            });
    };

    return (
        <div className={'quote'}>
            {data.map((inventory, index) => (
                <div key={index} className={'quote-single'}>
                    <div className={'quote-container'}>
                        <div className={'quote-container_info'}>
                            <p>Event: {inventory.sb_event.name}</p>
                            <p>Venue: {inventory.sb_event.venue}</p>
                            <p>City: {inventory.sb_event.city}</p>
                            <p>Date/Time: {dateToLocaleString(inventory.sb_event.occurs_at)}</p>
                            <p>Quantity: {inventory.quantity}</p>
                        </div>
                        <div className={'quote-container_info-pricing'}>
                            <p>
                                Initial
                                price: {getCurrencySymbol(inventory.old_currency, currencies)} {inventory.old_price}
                            </p>
                            <p>
                                Price: {getCurrencySymbol(inventory.new_currency, currencies)} {Math.round(inventory.new_price * 100) / 100}
                            </p>
                            <div className={'quantity-changer-container'}>
                                <p className={'quantity-changer-container_header'}>Quantity for quote</p>
                                <div className={'quote-container_pricing-select w-100'}>
                                    <select name={'quantity'} onClick={(event) => {
                                        handleQuantityClick(inventory.id, event);
                                    }}>
                                        {
                                            inventory.quantity_options.map((option: {
                                                name: string,
                                                value: string
                                            }) => (
                                                <option key={option.name} value={option.value}>
                                                    {option.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'quote-container_pricing'}>
                        <div className={'quote-container_pricing-select'}>
                            <p>Select currency</p>
                            <select
                                onChange={(e) => {
                                    handleSelectCurrency(inventory.id, e);
                                }}>
                                {
                                    options.map((option: { name: string, value: string }) => (
                                        <option key={option.name} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <p>Pricing (round up or down)</p>
                            <div className={'quote-container_pricing-btns'}>
                                <button
                                    onClick={() => handleRoundUpPrice(inventory.id, true)}
                                    className={'pricing-btn pricing-btn_up'}
                                ></button>
                                <button
                                    onClick={() => handleRoundUpPrice(inventory.id, false)}
                                    className={'pricing-btn pricing-btn_down'}
                                ></button>
                            </div>
                        </div>
                    </div>
                    <div className={'quote-container_commission'}>
                        <div>
                            <p>Commission type</p>
                            <div className={'quote-container_commission-btns'}>
                                <input
                                    className={'commission__input'}
                                    type="radio"
                                    id={'unit' + index}
                                    name={'commission' + index}
                                    value="unit"
                                    onChange={(e) => {
                                        handleChangeCommissionType(inventory.id, e, index);
                                    }}
                                    checked={inventory.commission_type === 'unit'}
                                />
                                <label className="pricing-btn" htmlFor={'unit' + index}>$</label>
                                <input
                                    className={'commission__input'}
                                    type="radio"
                                    id={'percent' + index}
                                    name={'commission' + index}
                                    value="percent"
                                    onChange={(e) => {
                                        handleChangeCommissionType(inventory.id, e, index);
                                    }}
                                    checked={inventory.commission_type === 'percent'}
                                />
                                <label className="pricing-btn" htmlFor={'percent' + index}>%</label>
                            </div>
                        </div>
                        <div>
                            <p>Commission amount</p>
                            <div className={'quote-container_commission-input-container'}>
                                <button className="pricing-btn pricing-btn_math" type="button"
                                        onClick={() => handleChangeCommission(inventory.id, false)}>-
                                </button>
                                <input
                                    className="commission-input"
                                    type="number"
                                    name="commission"
                                    defaultValue="0"
                                    data-commission-id={inventory.id}
                                    onChange={(e) => {
                                        handleInputCommission(inventory.id, e);
                                    }}
                                />
                                <button className="pricing-btn pricing-btn_math" type="button"
                                        onClick={() => handleChangeCommission(inventory.id, true)}>+
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {data.length ?
                <>
                    <p>Add Notes</p>
                    <textarea style={{width: '100%', height: 'auto', resize: 'none'}} name="notes"></textarea>
                    <button
                        className={'pricing-btn pricing-btn_generate'}
                        type="button"
                        onClick={() => download()}
                    >
                        Generate PDF
                    </button>
                </>
                : <p style={{color: 'var(--main-color)'}}>First select an inventory!</p>
            }
        </div>
    );
};

export default QuoteBuilder;
