import React, {FC, useState} from 'react';
import './VerifyEmailPage.scss';
import AuthPageWrapper from '../../../components/AuthPageWrapper/AuthPageWrapper';
import {useNavigate, useParams} from 'react-router-dom';
import {verifyEmailRequest} from '../../../@api/Auth';

const OtpVerificationPage: FC = () => {
  const navigateTo = useNavigate();
  const {token} = useParams();

  const sendRequest = async () => {
    const res = await verifyEmailRequest(token as string);

    if (!res.status) {
      navigateTo('/');
    }
  };

  const redirectToLogin = () => {
    navigateTo('/login');
  };

  sendRequest();

  return (
    <AuthPageWrapper>
      <div className='verify-email'>
        <div className='verify-email__title'>
          Email verified successfully
        </div>

        <div className="verify-email__link" onClick={() => redirectToLogin()}>
          Open login page
        </div>
      </div>
    </AuthPageWrapper>
  );
};

export default OtpVerificationPage;
