import React, {FC, Fragment} from "react";
import {Form, Formik, FormikValues} from "formik";
import {updatePasswordUserRequest} from "../../@api/User";
import * as Yup from "yup";
import DefaultFormikInput from "../Inputs/DefaultFormikInput/DefaultFormikInput";
import {User} from "../../models/User";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/Store";

interface PasswordFormProps {
    user: User;
}

const PasswordForm: FC<PasswordFormProps> = ({user}) => {
    const navigate = useNavigate();
    const loggedUser = useSelector((state: RootState) => state.auth.user);

    const handleSubmitPassword = async (values: FormikValues) => {
        values.user_id = loggedUser?.id;
        const res = await updatePasswordUserRequest(user.id, values);
        alert(res?.message);
        navigate('/admin/users');
    }

    const passwordValues = [
        {key: "password_first", value: "New Password"},
        {key: "password_second", value: "Confirm New Password"}
    ]

    return (
        <div className={"settings-tab-width"}>
            <Formik
                initialValues={
                    {
                        password_first: '',
                        password_second: ''
                    }
                }
                validationSchema={
                    Yup.object().shape({
                        password_first: Yup.string().min(6).max(64).required('Must enter new password'),
                        password_second: Yup.string().oneOf([Yup.ref('password_first'), null], 'Passwords must match').required('Must confirm new password')
                    })
                }
                onSubmit={handleSubmitPassword}
            >
                <Form>
                    {
                        passwordValues.map((passwordValue, index) => (
                            <Fragment key={index}>
                                <label htmlFor="">{passwordValue.value}</label>
                                <DefaultFormikInput name={passwordValue.key}
                                                    type='password'
                                                    placeholder={passwordValue.value}
                                                    autocomplete={'off'}
                                                    class={'auth-wrapper__input'}
                                ></DefaultFormikInput>
                            </Fragment>
                        ))
                    }
                    <button className="auth-wrapper__submit-btn" type="submit">Update</button>
                </Form>
            </Formik>
        </div>
    )
}

export default PasswordForm
