import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";

const urlPrefix = '/itags'
export const getSbVendorItags = () => {
    return RequestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}`)
    );
};
