import React from 'react';
import topPointsPng from '../../assets/images/login-page/top-points.png';
import bottomPointsPng from '../../assets/images/login-page/bottom-points.png';
import redRectanglePng from '../../assets/images/login-page/red-rectangle.png';
import ellipsesPng from '../../assets/images/login-page/elipses.png';
import './AuthPageWrapper.scss';

const AuthPageWrapper = ({children}: { children: JSX.Element }) => {
  return (
    <div className={'auth-wrapper'}>
      <div className="auth-wrapper__left-block">
        <div className="auth-wrapper__form">{children}</div>

        <div className="auth-wrapper__top-points">
          <img src={topPointsPng} alt="top-points" draggable="false"/>
        </div>
        <div className="auth-wrapper__bottom-points">
          <img src={bottomPointsPng} alt="bottomPoints" draggable="false"/>
        </div>
      </div>

      <div className="auth-wrapper__right-block">
        <div className="auth-wrapper__red-rectangle">
          <img src={redRectanglePng} alt="redRectangle" draggable="false"/>

          <div className="auth-wrapper__ellipses">
            <img src={ellipsesPng} alt="ellipses" draggable="false"/>
          </div>

          <div className="auth-wrapper__left-message-in-rectangle">
            <div className="b2b-exchange">
              <h1 className={'auth-wrapper__b2b-exchange-title'}>B2B Exchange</h1>
            </div>
            <div className="auth-wrapper__b2b-exchange-message">
              <p>The ticket API and marketplace that connects sellers to fans, buyers to sellers, and everyone in
                between.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPageWrapper;
