import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {Customer} from "../models/Customer";

const urlPrefix = 'vendor_tags'
export const getUserVendorTags = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, { user_vendor_tags: string[] }>(
        axios.get(`${urlPrefix}/${id}`)
    );
};

export const setUserVendorTags = ( vendor_tags: string[], id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, Customer>(
        axios.put(`${urlPrefix}/${id}`, {vendor_tags})
    );
}
