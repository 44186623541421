import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {addPendoScriptsToHead} from "./helpers/Pendo";
import {initLogRocket} from "./helpers/Logrocket";

addPendoScriptsToHead();
initLogRocket();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
);



