import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {IUserTicomboAccount} from "../models/IUserTicomboAccount";
import {FormikValues} from "formik";

interface DataForBind {
    ticombo_id: string,
    event_id: number | undefined,
    sb_id: number,
}

const urlPrefix = 'integrations/ticombo';

export const ticomboEventBindSbEvent = (params: DataForBind) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.post(`${urlPrefix}/events/${params.ticombo_id}/bind`, params),
    );
};

export const ticomboEventUnbindSbEvent = (id: string) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.post(`${urlPrefix}/events/${id}/unbind`),
    );
};

const ticomboUserAccountPrefix = '/integrations/ticombo/accounts'
export const getUserTicomboAccount = () => {
    return requestWrapper<GlobalStatusCodesEnum, IUserTicomboAccount>(
        axios.get(`${ticomboUserAccountPrefix}/show`)
    );
}

export const createUserTicomboAccount = (params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, IUserTicomboAccount>(
        axios.post(`${ticomboUserAccountPrefix}`, params)
    );
}

export const deleteUserTicomboAccount = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${ticomboUserAccountPrefix}/${id}`)
    );
}
