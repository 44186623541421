import RequestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {PurchaseOrder} from "../models/PurchaseOrder";
import {FormikValues} from "formik";
import {PaginationParams} from "../@interfaces/PaginationParams";
import {Inventory} from "../models/Inventory";

const urlPrefix = 'purchase_orders';
export const getPurchaseOrderbyIdRequest = (id: number) => {
    return RequestWrapper<GlobalStatusCodesEnum, { purchase_order: PurchaseOrder }>(
        axios.get(`${urlPrefix}/${id}`)
    );
};

export const editPurchaseOrderRequest = (id: number, values: FormikValues) => {
    return RequestWrapper<GlobalStatusCodesEnum, { purchase_order: PurchaseOrder }>(
        axios.patch(`${urlPrefix}/${id}`, values)
    );
};

export const purchaseOrdersPaginateRequest = (params: PaginationParams<Inventory>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return RequestWrapper<GlobalStatusCodesEnum>(
        axios.get(`${urlPrefix}`, {
            params: params
        })
    );
};

export const getPurchasesNotPaginated = () => {
    return RequestWrapper<GlobalStatusCodesEnum, { orders: Inventory }>(
        axios.get(`${urlPrefix}/notPaginated`)
    );
}
