import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {CreateCustomerValuesTypes, CustomerCreation} from "../models/Customer";
import {PaginationParams} from "../@interfaces/PaginationParams";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import {Customer} from "../models/Customer";

const urlPrefix = 'customers';

export const getCustomersAutocomplete = (keyword: string) => {
    return requestWrapper<GlobalStatusCodesEnum, { options: { label: string, value: string }[] }>(
        axios.get(`${urlPrefix}/autocomplete`, {params: {'keyword': keyword}}),
    );
}

export const postCustomers = (values: CustomerCreation | CreateCustomerValuesTypes) => {
    return requestWrapper<GlobalStatusCodesEnum, Customer>(
        axios.post(`${urlPrefix}`, values)
    )
}

export const editCustomer = (values: CustomerCreation | CreateCustomerValuesTypes | Customer, id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, number>(
        axios.put(`${urlPrefix}/${id}`, {
            ...values, id
        })
    );
}

export const customersPaginate = (params: PaginationParams<Customer>) => {
    const data = paginationParamsForAxios(params);
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Customer>>(axios.get(`${urlPrefix}`, {
        params: {...data, ...params},
    }));
};

export const customerSearchById = (id: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Customer[]>(
        axios.get(`${urlPrefix}/not-paginated`, {
            params: {
                id
            }
        })
    )
}

export const customerSearchByDisplayName = (display_name: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Customer[]>(
        axios.get(`${urlPrefix}/not-paginated`, {
            params: {
                display_name
            }
        })
    )
}

export const getSingleCustomer = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Customer>(
        axios.get(`${urlPrefix}/${id}`)
    )
}

export const customerSearchByEmail = (email: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Customer[]>(
        axios.get(`${urlPrefix}/not-paginated`, {
            params: {
                email
            }
        })
    )
}

export const deleteCustomer =(id :number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${urlPrefix}/${id}`)
    )
}
