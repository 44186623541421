import {PaginationParams} from "../@interfaces/PaginationParams";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {Performer} from "../models/Performer";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {PerformerCategoriesResponse} from "./Responses/PerformerResponses";
import {SbCategory} from "../models/Category";

const urlPrefix = 'performers';

export const performersPaginateRequest = (params: PaginationParams<Performer>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Performer>>(axios.get(`${urlPrefix}`, {
        params: {...data, ...params},
    }));
};

export const performersSetPercentRequest = (data: Performer) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.put(`${urlPrefix}/${data.id}/edit`, data),
    );
};

export const performersGetCategoriesRequest = () => {
    return requestWrapper<GlobalStatusCodesEnum, PerformerCategoriesResponse>(
        axios.get(`${urlPrefix}/categories`)
    );
}

export const performersNotPaginated = (name: string) => {
    return requestWrapper<GlobalStatusCodesEnum, Performer[]>(
        axios.get(`${urlPrefix}/not-paginated`, {
            params: {
                name
            }
        })
    );
};

export const categoriesNotPaginated = (name: string) => {
    return requestWrapper<GlobalStatusCodesEnum, SbCategory[]>(
        axios.get(`${urlPrefix}/category-names`, {
            params: {
                name
            }
        })
    );
};
