import './MainHeader.scss';
import HeaderWrapper from '../../Headers/HeaderWrapper/HeaderWrapper';
import {Link} from 'react-router-dom';
import React, {FC, useEffect, useRef, useState} from 'react';
import HeaderDropdownMenu from '../../Headers/HeaderDropdownMenu/HeaderDropdownMenu';
import {User} from '../../../models/User';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/Store';
import ChevronBottom from '../../../assets/images/other/chevron-bottom.svg';
import SettingsIcon from "../../../assets/images/other/settingIcon.svg";
import SupportIcon from "../../../assets/images/other/supportIcon.svg";
import LogoutIcon from "../../../assets/images/other/logOutIconHD.svg";
import {logout} from '../../../store/Reducers/Auth';
import {Button} from 'react-bootstrap';
import HeaderMobileNavigation from "../../Headers/HeaderMobileNavigation/HeaderMobileNavigation";
import {TABLET_WIDTH} from "../../../constants/Layout";
import useHandleOutsideClick from "../../../hooks/useHandleOutsideClick";
import {logoutRequest} from "../../../@api/Auth";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
import {getEuroStaticData} from "../../../@api/Euro/Euro";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {EuroVendor} from "../../../models/Euro/EuroVendor";
import {getNavigationItems} from "../HeaderNavigation/NavigationItems";
import {identifyPendo} from "../../../helpers/Pendo";

const HeaderUserToggle = ({user}: { user: User }) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <span className="d-block main-interface-header__user-name">{user.name}</span>
            <img src={ChevronBottom}
                 alt="chevron bottom icon"
                 className="d-block main-select-none main-interface-header__toggle-menu-chevron-bottom-icon"
            />
        </div>
    );
};

const HeaderMenuItems = () => {
    const dispatch = useDispatch();

    const logoutClick = async (e: any) => {
        e.preventDefault();
        await logoutRequest();
        dispatch(logout());
        identifyPendo();
    };

    return (
        <div>
            <ul className="main-interface-header__toggle-menu">
                <li>
                    <Link to={'/user/setting'}>
                        <img src={SettingsIcon}
                             alt="settings icon"
                             className="main-interface-header__toggle-menu-icon"
                        />Setting
                    </Link>
                </li>
                <li>
                    <Link to={'support'}>
                        <img src={SupportIcon}
                             alt="support icon"
                             className="main-interface-header__toggle-menu-icon"
                        />Support
                    </Link>
                </li>
                <li>
                    <Link to={''} onClick={logoutClick}>
                        <img src={LogoutIcon}
                             alt="logout icon"
                             className="main-interface-header__toggle-menu-icon"
                        />Logout
                    </Link>
                </li>
            </ul>
        </div>
    );
};

const MainHeader: FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [euroVendorStatus, setEuroVendorStatus] = useState<boolean>(false);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        const getUserVendors = async () => {
            const response = await getEuroStaticData();

            if (response instanceof HttpSuccessResponse) {
                const euroVendor = response.data.vendors?.find((vendor: EuroVendor) => {
                    return vendor.sb_vendor?.user?.id === user!.id
                });

                setEuroVendorStatus(!!euroVendor && !euroVendor.pause);
            }
        };

        getUserVendors();

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    const [openItemId, setOpenItemId] = useState<number | null>(null);
    const [hasPools, setHasPools] = useState<boolean>(false);
    const [paidAccess, setPaidAccess] = useState<boolean>(false);

    useEffect(() => {
        if (user!.pools && user!.pools.length) {
            setHasPools(true)
        }
    }, [user]);

    useEffect(() => {
        if (user!.paid_access) {
            setPaidAccess(true)
        }
    }, [user]);

    const handleColors = (id: number | null) => {
        setOpenItemId(openItemId === id || id === null ? null : id);
    };

    const navigationRef = useRef<HTMLDivElement>(null);

    useHandleOutsideClick(navigationRef, () => {
        handleColors(null);
    });

    const navigationItems = getNavigationItems(() => euroVendorStatus, user!.role_id, hasPools, paidAccess);

    return (
        <HeaderWrapper classes={typeof openItemId === 'number' ? 'header-opened-white' : 'header-opened-default'}>
            <div className="main-interface-header">
                {windowWidth > TABLET_WIDTH
                    ?
                    <>
                        <div className={'header-logo-wrapper'}>
                            <span onClick={() => handleColors(null)}>
                                <Link className="header-logo" to="/">Broker B2B</Link>
                            </span>
                            <button className={'release-note-button'} id="olvy-whats-new">
                                <div className={'bell pointer'}></div>
                            </button>
                        </div>
                        <HeaderNavigation navigationItems={navigationItems} handleColors={handleColors}/>
                    </>
                    :
                    <div className={'header-logo-wrapper'}>
                        <span onClick={() => handleColors(null)}>
                            <Link className="header-logo" to="/">Broker B2B</Link>
                        </span>
                        <button className={'release-note-button'} id="olvy-whats-new">
                            <div className={'bell pointer'}></div>
                        </button>
                        <HeaderMobileNavigation navigationItems={navigationItems} handleColors={handleColors}/>
                    </div>
                }
                {user
                    ?
                    <HeaderDropdownMenu
                        toggle={<HeaderUserToggle user={user}/>}
                        menuItems={<HeaderMenuItems/>}
                        toggleClasses={'main-interface-header__toggle'}
                    />
                    :
                    <Link to="/login">
                        <Button variant="light" className="landing-header__navbar-login-button">
                            Login / Register
                        </Button>
                    </Link>
                }
            </div>
        </HeaderWrapper>
    );
};

export default MainHeader;
