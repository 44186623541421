import {Inventory} from "./Inventory";
import {BidStatus} from "../enums/BidStatus";

export interface Bids {
    id: number,
    quantity: number,
    price: number,
    sb_inventory_id: number,
    user_id: number,
    bid_duration: Date,
    sb_inventories: Inventory,
    status: BidStatus,
    currency: string,
    notes: string
}

export const bidStatusToString = (bid: BidStatus) => {
    switch (bid) {
        case BidStatus.Accepted:
            return 'Accepted';

        case BidStatus.Active:
            return 'Active';

        case BidStatus.Expired:
            return 'Expired';

        case BidStatus.Rejected:
            return 'Rejected';
    }
}
