export const LEV_VENDOR_ID = 1936049;
export const currenciesArray = [
    'EUR',
    'GBP',
    'USD'
];

export const ERROR_DURATION = 6000;
export const SUCCESS_DURATION = 3000;

export const PAGINATION_PER_PAGE_OPTION_DEFAULT = 50;
export const PAGINATION_PER_PAGE_OPTIONS = [10, 20, PAGINATION_PER_PAGE_OPTION_DEFAULT, 100, 250, 500];
