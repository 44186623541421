import {PaginationParams} from "../@interfaces/PaginationParams";
import {Vendor} from "../models/Vendor";
import {paginationParamsForAxios} from "../helpers/PaginationParamsForAxios";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {UserVendor, UserVendorCreation} from "../models/UserVendor";

const urlPrefix = 'user_vendors'

export const userVendorsPaginateRequest = (params: PaginationParams<UserVendor>) => {
    const data = paginationParamsForAxios(params);

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<UserVendor>>(axios.get(`${urlPrefix}`, {
        params: {...data, ...params},
    }));
};

export const editUserVendor = (values: UserVendorCreation | UserVendor, id: number) => {
    return requestWrapper<GlobalStatusCodesEnum, number>(
        axios.put(`${urlPrefix}/${id}`, {
            ...values, id
        })
    );
}

export const postUserVendor = (values: UserVendorCreation | UserVendor) => {
    return requestWrapper<GlobalStatusCodesEnum, UserVendor>(
        axios.post(`${urlPrefix}`, values)
    );
}

export const deleteUserVendor =(id :number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${urlPrefix}/${id}`)
    );
}

export const userVendorSearchById = (id: string) => {
    return requestWrapper<GlobalStatusCodesEnum, UserVendor[]>(
        axios.get(`${urlPrefix}/${id}`)
    );
}

export const userVendorSearchByDisplayName = (display_name: string) => {
    return requestWrapper<GlobalStatusCodesEnum, UserVendor[]>(
        axios.get(`${urlPrefix}/notPaginated`, {
            params: {
                display_name
            }
        })
    );
}

export const getUserVendorsAutocomplete = (keyword: string) => {
    return requestWrapper<GlobalStatusCodesEnum, { options: { label: string, value: string }[] }>(
        axios.get(`${urlPrefix}/autocomplete`, {params: {'keyword': keyword}}),
    );
}
