import {FC} from "react";
import NeedList from "../../User/BuyPage/components/NeedList/NeedList";
import LandingHeader from "../../../layouts/Headers/LandingHeader/LandingHeader";

const NeedsList: FC = () => {
    return (
        <>
            <LandingHeader/>
            <NeedList/>
        </>
    )
};

export default NeedsList;
