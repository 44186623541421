import "./AppToggle.scss";
import React from 'react';
import { Toggle } from 'rsuite';

interface Props {
    label: string;
    id: string;
    value?: boolean;
    name?: string;
    dispatchValue?: React.Dispatch<boolean>;
    disabled?: boolean,
    className?: string,
}

const AppToggle: React.FC<Props> = ({label, value, dispatchValue, ...props}) => {
    return (
        <div className={'app-toggle' + (props.disabled ? ' app-toggle-disabled' : '')}>
            <label className="app-toggle-label" htmlFor={props.id}>
                <Toggle checked={value}
                        onChange={(checked, event) => {
                            event.stopPropagation();
                            dispatchValue && dispatchValue(checked);
                        }}
                        {...props}
                >
                </Toggle>
                <span className={props?.className}>{label}</span>
            </label>
        </div>
    )
}

export default AppToggle;
